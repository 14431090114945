import React, { useEffect, useState } from "react";
import Modal from "../../commonComponents/Modal/Modal";
import "./Modals.scss";
import { useDispatch, useSelector } from "react-redux";
import { add_update_delete_roles_loading, certificate_request_change_status_loading, forward_hr_user_list, logout_loader, update_certificate_request_loading, user_list } from "../../redux/commonReducer";
import { getAllHr, getAllUsers } from "../../redux/actionCreator";
import TextArea from "../../commonComponents/TextArea/TextArea";
import Button from "../../commonComponents/Button/Button";
import Select from "../../commonComponents/Select/Select";
import useTranslate from "../../utils/useTranslate";
import ModalContent from "../../commonComponents/ModalContent/ModalContent";
import FileUpload from "../../commonComponents/FileUpload/FileUpload";

export default function Modals({ show, setShow, type, onAddRequestHandler, input, setInput, modalErr, setModalErr, comment, setComment, details, setCertificateStatus, onDeleteHandler, onLogout }) {
  const dispatch = useDispatch();

  const { t } = useTranslate()

  const flag =
    type === "approve" ? 1
      : type === "forward" ? 2
        : type === "return" ? 3
          : type === "reject" ? 5
            : type === "close" ? 6
              : type === "Success" ? 7
                : type === "logout" ? 10
                  : type === "delete_role" ? 11
                    : 0

  const userList = useSelector(forward_hr_user_list);

  useEffect(() => {
    dispatch(getAllHr());
  }, []);

  return (
    (
      <div id="Modals">
        <Modal show={show} setShow={setShow}>
          {flag === 1 ? (
            <ApproveModal
              flag={flag}
              setShow={setShow}
              input={input}
              setInput={setInput}
              onAddRequestHandler={onAddRequestHandler}
              modalErr={modalErr}
              setModalErr={setModalErr}
              comment={comment}
              setComment={setComment}
              setCertificateStatus={setCertificateStatus}
            />
          ) : flag === 2 ? (
            <ForwardModal
              setShow={setShow}
              onAddRequestHandler={onAddRequestHandler}
              input={input}
              setInput={setInput}
              modalErr={modalErr}
              setModalErr={setModalErr}
              userList={userList}
              comment={comment}
              setComment={setComment}
              details={details}
              setCertificateStatus={setCertificateStatus}
            />
          ) : flag === 3 ? (
            <ReturnModal
              setShow={setShow}
              onAddRequestHandler={onAddRequestHandler}
              input={input}
              setInput={setInput}
              modalErr={modalErr}
              setModalErr={setModalErr}
              comment={comment}
              setComment={setComment}
              setCertificateStatus={setCertificateStatus}
            />
          ) : flag === 5 ? (
            <RejectModal
              setShow={setShow}
              onAddRequestHandler={onAddRequestHandler}
              input={input}
              setInput={setInput}
              modalErr={modalErr}
              setModalErr={setModalErr}
              comment={comment}
              setComment={setComment}
              setCertificateStatus={setCertificateStatus}
            />
          ) : flag === 6 ? (
            <CloseModal
              setShow={setShow}
              onAddRequestHandler={onAddRequestHandler}
              input={input}
              setInput={setInput}
              modalErr={modalErr}
              setModalErr={setModalErr}
              comment={comment}
              setComment={setComment}
              setCertificateStatus={setCertificateStatus}
            />
          ) : flag === 7 ? (
            <SuccessModal />
          ) : flag === 10 ? (
            <LogoutModal
              setShow={setShow}
              onLogout={onLogout}
            />
          ) : flag === 11 ? (
            <DeletePermission
              setShow={setShow}
              onDeleteHandler={onDeleteHandler}
            />
          ) :
            null}
        </Modal>
      </div>
    )
  );
}

const ApproveModal = ({ flag, setShow, input, setInput, onAddRequestHandler, modalErr, setModalErr, comment, setComment, setCertificateStatus }) => {
  const [nextPage, setNextPage] = useState("");
  const { t } = useTranslate()

  const certficateUpdateLoading = useSelector(update_certificate_request_loading)
  const certficateChangeStatus = useSelector(certificate_request_change_status_loading)

  const nextPageHandler = () => {
    if (comment?.trim()?.length != 0) setNextPage("upload attachment");
    else setModalErr({ ...modalErr, comment: t("Please Add Comment") });
  };

  const onAddHandler = () => {
    if (input.file?.length != 0) onAddRequestHandler();
    else setModalErr({ ...modalErr, file: t("Please Upload Document") });
  };

  const onChangeHandler = (e) => {
    if (e[0].type === "application/pdf" || e[0].type === "pdf") {
      setInput((s) => ({ ...s, file: e[0].metaFile }));
      setModalErr({ ...modalErr, file: "" });
    } else setModalErr({ ...modalErr, file: t("Only pdf format is supported") });
  }

  return (
    <div className="modal-item">
      {nextPage !== "upload attachment" && flag === 1 && (
        <ModalContent header={'Approve'}>
          <h3>{t("Are you sure to Approve ?")}</h3>
          <TextArea className='css-txt-esc3' error={modalErr.comment} value={comment} onChange={(e) => setComment(e)} />
          <Button className="css-btn-esc3" onClick={() => nextPageHandler()} content="confirm" />
          <Button className="css-btn-esc3" onClick={() => (setInput({ file: "", finalComment: "", forwardUser: "", returnComment: "", rejectionComment: "", closeComment: "", }), setShow(false), setCertificateStatus(""), setModalErr({}))} content="Cancel" />
        </ModalContent>
      )}
      {nextPage === "upload attachment" && (
        <ModalContent header='Upload Certificate'>
          <h3>{t("Upload Certificate (PDF)")}</h3>
          {input.file
            ? <object data={URL.createObjectURL(input.file)} type="application/pdf" />
            : <FileUpload className='css-atc-esc1' buttonLabel={t('Add Attachments')} error={modalErr.file} accept={['pdf']} onChange={onChangeHandler} />
          }
          <Button className="css-btn-esc3" onClick={() => onAddHandler()} content="Confirm" disabled={certficateUpdateLoading || certficateChangeStatus} />
          <Button className="css-btn-esc3" onClick={() => (setInput({ file: "", finalComment: "", forwardUser: "", returnComment: "", rejectionComment: "", closeComment: "", }), setShow(false), setCertificateStatus(""), setModalErr({}))} content="Cancel" />
        </ModalContent>
      )}
    </div>
  )
}

const ForwardModal = ({ setShow, onAddRequestHandler, input, setInput, modalErr, setModalErr, userList, details, setCertificateStatus }) => {

  const { t } = useTranslate()
  const certficateUpdateLoading = useSelector(update_certificate_request_loading)
  const certficateChangeStatus = useSelector(certificate_request_change_status_loading)

  const onAddHandler = () => {
    if (input.forwardUser?.length != 0) {
      onAddRequestHandler();
    } else {
      setModalErr({ ...modalErr, forwardUser: t("Please Add User") });
    }
  };

  return (
    <ModalContent header='Forward'>
      <h3>{t("Select the user you wish to forward")}</h3>
      <Select className='css-slc-esc1' placeholder='* Select User' error={modalErr.forwardUser}
        options={userList.filter((item) => { return (item._id != localStorage.getItem("user_id") && item._id != details?.forwarded_to); })}
        value={input.forwardUser} labelSetter={(e) => e.name} valueSetter={(e) => e._id} onChange={(e) => setInput({ ...input, forwardUser: e })} />
      <Button className="css-btn-esc3" onClick={() => onAddHandler()} content="Confirm" disabled={certficateUpdateLoading || certficateChangeStatus} />
      <Button className="css-btn-esc3" content="Cancel"
        onClick={() => (setInput({ file: "", finalComment: "", forwardUser: "", returnComment: "", rejectionComment: "", closeComment: "", }), setShow(false), setCertificateStatus(""), setModalErr({}))} />
    </ModalContent>
  );
};
const ReturnModal = ({ setShow, onAddRequestHandler, setInput, modalErr, setModalErr, comment, setComment, setCertificateStatus }) => {
  const { t } = useTranslate()
  const certficateUpdateLoading = useSelector(update_certificate_request_loading)
  const certficateChangeStatus = useSelector(certificate_request_change_status_loading)


  const onAddHandler = () => {
    if (comment?.length != 0) {
      onAddRequestHandler();
    } else {
      setModalErr({ ...modalErr, comment: t("Please Add Comment") });
    }
  };

  return (
    <ModalContent header='Return'>
      <h3 className="right">{t("Reason for Return ?")}</h3>
      <TextArea className='css-txt-esc3' error={modalErr.comment} value={comment} onChange={(e) => setComment(e)} />
      <Button className="css-btn-esc3" onClick={() => onAddHandler()} content="Submit" disabled={certficateUpdateLoading || certficateChangeStatus} />
      <Button className="css-btn-esc3" onClick={() => (setInput({ file: "", finalComment: "", forwardUser: "", returnComment: "", rejectionComment: "", closeComment: "", }), setShow(false), setCertificateStatus(""), setModalErr({}))} content="Cancel" />
    </ModalContent>
  );
};
const RejectModal = ({ setShow, onAddRequestHandler, setInput, modalErr, setModalErr, comment, setComment, setCertificateStatus }) => {
  const { t } = useTranslate()
  const certficateUpdateLoading = useSelector(update_certificate_request_loading)
  const certficateChangeStatus = useSelector(certificate_request_change_status_loading)


  const onAddHandler = () => {
    if (comment?.length != 0) {
      onAddRequestHandler();
    } else {
      setModalErr({ ...modalErr, comment: t("Please Add Comment") });
    }
  };

  return (
    <ModalContent header='Reject'>
      <h3 className="right">{t("Reason for Rejection ?")}</h3>
      <TextArea className='css-txt-esc3' error={modalErr.comment} value={comment} onChange={(e) => setComment(e)} />
      <Button className="css-btn-esc3" onClick={() => onAddHandler()} content="Submit" disabled={certficateUpdateLoading || certficateChangeStatus} />
      <Button className="css-btn-esc3" onClick={() => (setInput({ file: "", finalComment: "", forwardUser: "", returnComment: "", rejectionComment: "", closeComment: "", }), setShow(false), setCertificateStatus(""), setModalErr({}))} content="Cancel" />
    </ModalContent>
  );
};
const CloseModal = ({ setShow, onAddRequestHandler, setInput, modalErr, setModalErr, comment, setComment, setCertificateStatus }) => {
  const { t } = useTranslate()
  const certficateUpdateLoading = useSelector(update_certificate_request_loading)
  const certficateChangeStatus = useSelector(certificate_request_change_status_loading)

  const onAddHandler = () => {
    if (comment?.length != 0) {
      onAddRequestHandler();
    } else {
      setModalErr({ ...modalErr, comment: t("Please Add Comment") });
    }
  };

  return (
    <ModalContent header='Close'>
      <h3 className="right">{t("Reason to close the Request ?")}</h3>
      <TextArea className='css-txt-esc3' error={modalErr.comment} value={comment} onChange={(e) => setComment(e)} />
      <Button className="css-btn-esc3" onClick={() => onAddHandler()} content="Submit" disabled={certficateUpdateLoading || certficateChangeStatus} />
      <Button className="css-btn-esc3" onClick={() => (setInput && setInput({ file: "", finalComment: "", forwardUser: "", returnComment: "", rejectionComment: "", closeComment: "", }), setShow(false), setCertificateStatus && setCertificateStatus(""), setModalErr({}))} content="Cancel" />
    </ModalContent>
  );
}

const LogoutModal = ({ setShow, onLogout }) => {

  const logOutloading = useSelector(logout_loader)
  const { t } = useTranslate()

  return (
    <ModalContent header='Logout'>
      <h3>{t("?Are you sure you want to logout")}</h3>
      <Button className="css-btn-esc3" onClick={() => onLogout()} disabled={logOutloading} content={'Confirm'} />
      <Button className="css-btn-esc3" onClick={() => (setShow(false))} content="Cancel" />
    </ModalContent>
  );
};

const DeletePermission = ({ setShow, onDeleteHandler }) => {

  const rolesActionsLoading = useSelector(add_update_delete_roles_loading)
  const { t } = useTranslate()

  return (
    <ModalContent header='Delete Permission'>
      <h3>{t("?Are you sure you want to delete the permission from the user")}</h3>
      <Button className="css-btn-esc3" onClick={() => onDeleteHandler()} disabled={rolesActionsLoading} content={'Confirm'} />
      <Button className="css-btn-esc3" onClick={() => (setShow(false))} content="Cancel" />
    </ModalContent>
  )
}

const SuccessModal = ({ }) => {
  const { t } = useTranslate()
  return (
    <div className="modal-item SuccessModal">
      <i><img src="/assets/icons/Success.svg" alt="" /></i>
      <h2>{t("Submitted Successfully")}</h2>
    </div>
  )
}