import React from 'react'
import { Link } from 'react-router-dom'
import useTranslate from '../../utils/useTranslate'

export default function Test() {
   
    const { t } = useTranslate()

    return (
        <div className='d-flex justify-content-center align-items-end' style={{ height: '40vh' }}>
            <h1 style={{ fontSize: '34px' }}>!{t('Page Not Found')}</h1>
        </div>
    )
}
