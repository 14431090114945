import React, { useEffect, useState } from 'react'
import Title from '../../commonComponents/Title/Title'
import Table from '../../commonComponents/Table/Table'
import { errorToast, getDynamicTexts, importDynamicExcel, insertDynamicText, successToast, updateDynamicTexts } from '../../redux/actionCreator'
import { useDispatch } from 'react-redux'
import Actions from '../../commonComponents/Actions/Actions'
import useTranslate from '../../utils/useTranslate'
import Modal from '../../commonComponents/Modal/Modal'
import Button from '../../commonComponents/Button/Button'
import Input from '../../commonComponents/Input/Input'
import { useNavigate } from 'react-router-dom'
import ModalContent from '../../commonComponents/ModalContent/ModalContent'

export default function Translation() {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { t } = useTranslate()

    const [data, setData] = useState()
    const [isModalShow, setModalShow] = useState(false)
    const [itemToEdit, setItemToEdit] = useState(null)
    const [inputs, setInputs] = useState({ english: '', arabic: '' })
    const [loader, setLoader] = useState(false)

    useEffect(() => {
        getData()
    }, [])

    function getData() {
        setData(null)
        setLoader(true)
        dispatch(getDynamicTexts((texts) => {
            setLoader(false)
            setData(texts.map((text, i) => {
                return {
                    sl_no: i + 1,
                    key: text.title,
                    english: text.english_text,
                    arabic: text.arabic_text,
                    action: <Actions rootPath='/assets/icons/' buttons={{ edit: 'Edit.svg' }} onEdit={() => EditHandler(text)} />
                }
            }))
        }))
    }

    function updateHandler() {
        if (!inputs) return
        let obj = {
            id: itemToEdit._id,
            title: itemToEdit.title,
            english_text: inputs.english,
            arabic_text: inputs.arabic
        }
        dispatch(updateDynamicTexts(obj, ({ status, message, data }) => {
            status && successToast(t(message))
            !status && errorToast(t(message))
            getData()
            setModalShow(false)
        }))
    }

    function onChangeHandler(e, name) {
        setInputs(s => ({ ...s, [name]: e }))
    }

    function EditHandler(item) {
        setModalShow(true)
        setItemToEdit(item)
        setInputs({ arabic: item.arabic_text, english: item.english_text })
    }

    return (
        <div className='d-flex flex-column gap-4'>
            <div><Button className='css-btn-esc2' content='Add Text' onClick={() => navigate('../settings/dynamicTextImport')} /></div>
            <Title content={t('Dynamic Texts')} />
            <Table
                className='css-tbl-esc2'
                titles={[
                    { title: 'Sl no', key: 'sl_no', width: '4%', h_align: 'center', d_align: 'center' },
                    { title: t('Key'), key: 'key', width: '20%', h_align: 'center' },
                    { title: 'English', key: 'english', h_align: 'center' },
                    { title: 'Arabic', key: 'arabic', h_align: 'center' },
                    { title: '', key: 'action', width: '3%' },
                ]}
                content={data}
            />
            <Modal show={isModalShow} setShow={setModalShow} buttonClose>
                <ModalContent header='Translation'>
                    <h3 className='text-center'>{t('Dynamic Texts')}</h3>
                    <i className='d-block text-center'>"{itemToEdit?.title}"</i>
                    <Input className='css-inp-esc1 w-100' label='عربي' value={inputs.arabic} onChange={(e) => onChangeHandler(e, 'arabic')} />
                    <Input className='css-inp-esc1 w-100 mt-3' label='English' value={inputs.english} onChange={(e) => onChangeHandler(e, 'english')} />
                    <Button className='css-btn-esc3' content='Cancel' onClick={() => setModalShow(false)} />
                    <Button className='css-btn-esc3' content='Update' onClick={updateHandler} />
                </ModalContent>
            </Modal>
        </div>
    )
}

export const ImportDynamicText = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [input, setInput] = useState({})

    function onChangeHandler(e) {
        if (e.target.files[0].type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") return
        const formdata = new FormData()
        formdata.append('user_list', e.target.files[0])
        dispatch(importDynamicExcel(formdata))
    }

    function onSubmitHandler() {
        if (!input.title || !input.arabic_text || !input.english_text) {
            errorToast('All fields are mandatory'); return
        }
        dispatch(insertDynamicText(input, ({ status, msg }) => {
            status && successToast('Successfully Added')
            !status && errorToast('Action failed')
        }))
    }


    return (
        <div >
            <div className='d-flex gap-4 my-4'>
                <label className='btn bg-primary px-4 py-2 text-light' style={{ width: '200px' }} htmlFor='input-file'>
                    Import
                    <input type="file" name="" id="input-file" hidden onClick={(e) => e.target.value = null} onChange={onChangeHandler} />
                </label>
                {/* <button className='bg-primary px-4 py-2 text-light'>Export</button> */}
            </div>

            <div className='d-flex flex-column gap-4'>
                <label>
                    <p className='m-0'>Title</p>
                    <input style={{ width: '615px' }} onChange={(e) => setInput(s => ({ ...s, title: e.target.value, english_text: e.target.value, arabic_text: e.target.value + "_a" }))} />
                </label>
                <div className='d-flex gap-3'>
                    <label>
                        <p className='m-0'>Arabic</p>
                        <input style={{ width: '300px' }} value={input.arabic_text} onChange={(e) => setInput(s => ({ ...s, arabic_text: e.target.value }))} />
                    </label>
                    <label>
                        <p className='m-0'>English</p>
                        <input style={{ width: '300px' }} value={input.english_text} onChange={(e) => setInput(s => ({ ...s, english_text: e.target.value }))} />
                    </label>
                </div>
                <div className='d-flex gap-3'>
                    <button className='btn bg-secondary px-4 py-2 text-light' onClick={() => navigate('../settings/translation')}
                    >Back</button>
                    <button className='btn bg-secondary px-4 py-2 text-light' onClick={onSubmitHandler}
                    >Submit</button>
                </div>
            </div >
        </div >
    )
}