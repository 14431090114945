import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import './NavBarHeader.scss'
import { useDispatch, useSelector } from 'react-redux'
import { current_user_permissions, updateRedux } from '../../redux/commonReducer'
import { LangSwitch } from '../../App'
import useTranslate from '../../utils/useTranslate'
import { moduleMainAccess } from '../../utils/helpers'

export default function NavBarHeader({ setSelected }) {

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const userPermissions = useSelector(current_user_permissions)
  const { language, setLanguage } = useContext(LangSwitch)
  const { t } = useTranslate()
  const location = useLocation()

  const [role, setRole] = useState('User')

  function langHandler() {
    setLanguage(s => {
      let lang = s === 'En' ? 'Ar' : 'En'
      localStorage.setItem('lang', lang)
      return lang
    })
  }

  function roleHandler() {
    setSelected({ item: 1, subItem: 0 })
    if (role === 'Admin') {
      setRole('User')
      localStorage.setItem('user_logged', "User")
      dispatch(updateRedux({ key: "user_logged_in", value:  "User" }))
      navigate('/')
    } else if (role === 'User') {
      setRole('Admin')
      localStorage.setItem('user_logged', "Admin")
      dispatch(updateRedux({ key: "user_logged_in", value:  "Admin" }))
      navigate('/admin')
    }
  }

  useEffect(() => {
    const path = location.pathname
    if (path.split('/')[1] === 'admin') {
      setRole('Admin')
      localStorage.setItem('user_logged', "Admin")
      dispatch(updateRedux({ key: "user_logged_in", value:  "Admin" }))
    } else {
      setRole('User')
      localStorage.setItem('user_logged', "User")
      dispatch(updateRedux({ key: "user_logged_in", value:  "User" }))
    }
  }, [location])

  useEffect(() => {
    if (!userPermissions) {
      let permissionObject = JSON.parse(localStorage.getItem("current_user_permissions"))
      dispatch(updateRedux({ key: "current_user_permissions", value: permissionObject }))
    }
  }, [])


  const hasAdminPrivilage = () => {
    let flag = false;
    // if (userPermissions && userPermissions.length && userPermissions.some(item => item.module_name === "certificate_request" && item.admin === "1")) {
    //   flag = true
    // }
    if(moduleMainAccess()?.length){
      flag = true
    }
    let userObject = JSON.parse(localStorage.getItem("user"))
    // console.log(userObject)
    if (userObject && userObject.role?.[0] === "super_admin") {
      flag = true
    }
    return flag
  }

  return (
    <div id='NavBarHeader'>
      <div className='logo'>
        <img src="/assets/icons/Option-1.svg" alt="" />
      </div>
      <div className='options'>
        <span onClick={() => langHandler()}>{language}</span>
        <span style={{ visibility: hasAdminPrivilage() ? 'visible' : 'hidden' }} onClick={() => roleHandler()}>{t(`Switch to ${role === 'Admin' ? 'User' : 'Admin'}`)}</span>
      </div>
    </div>
  )
}
