import React, { useState, useEffect } from 'react'
import './FileUpload.scss'
import AttachmentContent from '../AttachmentContent/AttachmentContent'
import { v4 } from 'uuid'

import uploadIcon from './upload.svg'
import useTranslate from '../../utils/useTranslate'

export default function FileUpload({ reverse, files, onChange, className, error, maxFileSizeMB, showPreview, info, multiple, required, label, buttonLabel, accept, width }) {

  let uuid = v4()
  const { t } = useTranslate()

  const [input, setInput] = useState([])
  const [isInvalidFile, setIsInvalidFile] = useState(false)

  useEffect(() => {
    files && setInput(files)
    return () => setInput([])
  }, [files])


  function fileSizeChecker(files, cb) {
    let MBdivisor = 1048576
    let maxSize = maxFileSizeMB || 0 / MBdivisor
    let filesSize = [...files].reduce((acc, crr) => acc + crr.size, 0) / MBdivisor
    if (filesSize > maxSize) cb()
  }

  function changeHandler(e) {
    let candidates = []
    if (multiple) candidates = [...input, ...fileObjectSetter(e)]
    else candidates = fileObjectSetter(e)
    let [isInvalid, msg] = validation(candidates)
    setIsInvalidFile(isInvalid)
    setInput(candidates)
    onChange && onChange(candidates, isInvalid, msg)
  }

  function closeHandler(e) {
    let [isInvalid, msg] = validation(e)
    setInput(e)
    onChange && onChange(e, isInvalid, msg)
  }

  function fileObjectSetter(e) {
    return Array.from(e.target.files).map(item => {
      let type = fileParser(item.type)
      return {
        name: item.name,
        url: URL.createObjectURL(item),
        type: type,
        metaFile: item,
        isInvalid: !accept.includes(type)
      }
    })
  }

  function validation(candidates) {
    let isInvalid = false
    let msg = ''
    maxFileSizeMB && fileSizeChecker(candidates, () => {
      isInvalid = true
      msg = `File size shouldn't exceed ${maxFileSizeMB} MB`
    })
    if (candidates.some(item => item.isInvalid)) {
      isInvalid = true
      msg = 'Invalid file format'
    }
    return [isInvalid, msg]
  }

  function fileParser(type) {
    switch (type) {
      case 'application/pdf':
      case 'pdf': return 'pdf'
      case 'application/msword':
      case 'doc': return 'doc'
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      case 'docx': return 'docx'
      case 'video/quicktime':
      case 'mov': return 'mov'
      case 'video/mp4':
      case 'mp4': return 'mp4'
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      case 'xlsx': return 'xlsx'
      case 'image/png': return 'png'
      case 'image/jpg': return 'jpg'
      case 'image/jpeg': return 'jpeg'
      case 'jpg': return 'jpg'
      case 'jpeg': return 'jpeg'
      case 'png': return 'png'
      case 'image': return 'image'
      case 'text/csv':
      case 'csv': return 'csv'
      case 'application/vnd.ms-powerpoint':
      case 'ppt': return 'ppt'
      case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      case 'pptx': return 'pptx'
      case 'application/vnd.ms-excel':
      case 'xls': return 'xls'
      default: return ''
    }
  }

  return (
    <span id='FileUpload' className={className} style={{ width: width }}>
      {label && <p className='label'>{t(label)}{required && <em>*</em>}</p>}
      <span >
        <label htmlFor={'attach_' + uuid} className={reverse ? 'reverse' : ''}>
          <input type="file" id={'attach_' + uuid} hidden multiple={multiple} onClick={(e) => e.target.value = null} onChange={changeHandler}
            accept={accept?.map(item => '.' + item)} />
          {t(buttonLabel || 'Upload file')}
          <img className='upload_icon' src={uploadIcon} alt="" />
        </label>
        <a className={'file_info' + (input[0] && !isInvalidFile ? ' file' : '')} target='_blank' href={input.length !== 0 ? input[0]?.url : ''}>
          {input[0]?.name ? (isInvalidFile ? t('Invalid file') : input[0].name) : t('No file chosen')}
        </a>
      </span>
      {error && <small id='error' className='error'>{error}</small>}
      {
        info && <p className="attachment-info">
          <img src="/assets/icons/info.svg" alt="" />
          {t(info)}
        </p>
      }
      {showPreview && <AttachmentContent files={input} closeButton onClose={closeHandler} accept={accept} />}
    </span >
  )
}



{/*

<FileUpload
  className=''
  label
  buttonLabel
  files={[
    {
      type: 'pdf',
      url: '',
      name: ''
    }
  ]}
  accept={['jpeg', 'pdf', 'doc', 'docx', 'xlsx', 'png', 'mp4']}
  onChange={(e, isInvalid, message) => { }}
  error=''
  info=''
  showPreview
  reverse
  multiple
/>

*/}