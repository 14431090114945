import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SelectEmployee from "../SelectEmployeeForPermission/SelectEmployee";
import {
  getAllUsers,
  getAllUsersForHR,
  getHRModules,
  getModules,
  getRequestStatus,
  getUsersWithoutRolePermissions,
  insertRoles,
  updateRoles,
} from "../../../redux/actionCreator";
import {
  add_update_delete_roles_loading,
  all_hr_users_list,
  all_hr_users_without_roles_list,
  all_hr_users_without_roles_list_page_count,
  all_hr_users_without_roles_list_page_status,
  all_hr_users_without_roles_list_total_count,
  modules,
} from "../../../redux/commonReducer";
import Modals from "../../../components/Modals/Modals";
import { useLocation, useNavigate } from "react-router-dom";
import Title from "../../../commonComponents/Title/Title";
import Breadcrump from "../../../commonComponents/Breadcrump/Breadcrump";
import Table from "../../../commonComponents/Table/Table";
import Input from "../../../commonComponents/Input/Input";
import FooterButtons from "../../../commonComponents/FooterButtons/FooterButtons";
import Button from "../../../commonComponents/Button/Button";
import PageHeader from "../../../commonComponents/PageHeader/PageHeader";
import TitleBar from "../../../commonComponents/TitleBar/TitleBar";
import useTranslate from "../../../utils/useTranslate";
import { Form } from "react-bootstrap";

export default function AddPermission({ page }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userList = useSelector(all_hr_users_without_roles_list);
  const pageCount = useSelector(all_hr_users_without_roles_list_total_count);
  const pageStatus = useSelector(all_hr_users_without_roles_list_page_status);
  const { t } = useTranslate();

  const allUsersList = useSelector(all_hr_users_list);
  const moduleList = useSelector(modules);
  const rolesActionsLoading = useSelector(add_update_delete_roles_loading);

  const [department, setDepartment] = useState("");
  const [section, setSection] = useState("");
  const [user, setUser] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [usersToList, setUsersToList] = useState([]);
  const [err, setErr] = useState({});
  const [redo, setRedo] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [moduleData, setModuleData] = useState([]);

  useEffect(() => {
    dispatch(getModules());
  }, []);

  useEffect(() => {
    dispatch(getRequestStatus());
    dispatch(getAllUsers());
    let formData = new FormData();
    formData.append("department", "");
    formData.append("section", "");
    formData.append("page", 0);
    formData.append("perPage", perPage);
    dispatch(getUsersWithoutRolePermissions(formData));
    dispatch(getAllUsersForHR());
  }, []);

  useEffect(() => {
    setUsersToList(userList);
  }, [userList]);

  useEffect(() => {
    if (moduleList?.length) {
      setModuleData(moduleList);
    }
  }, [moduleList]);

  const validate = () => {
    let temp = {};
    if (selectedUsers?.length == 0) {
      temp = { ...temp, userData: "Please Select Users" };
    }
    if (moduleData?.length) {
      let flag = false;
      moduleData?.forEach((ele) => {
        let temp = ele?.modules?.some((e) => e.admin || e.report);
        if (temp) flag = true;
      });
      if (!flag) {
        temp = { ...temp, module: "Please Select Any Module" };
      }
    }
    if (Object.values(temp).length != 0) {
      setErr(temp);
      return false;
    } else {
      return true;
    }
  };

  const onSaveHandler = () => {
    if (validate()) {
      let formData = new FormData();
      selectedUsers.forEach((item, index) => {
        formData.append(`users[${index}]`, item._id);
      });
      let finalModuleData = [];
      moduleData.forEach((item, index) => {
        finalModuleData = [...finalModuleData, ...item?.modules];
      });
      finalModuleData.forEach((ele, i) => {
        formData.append(`modules[${i}]`, ele.key);
        formData.append(`permissions[${i}][admin]`, ele?.admin == true ? 1 : 0);
        formData.append(
          `permissions[${i}][report]`,
          ele?.report == true ? 1 : 0
        );
      });
      console.log(formData);
      dispatch(
        insertRoles(formData, () => navigate("/admin/settings/permissions"))
      );
    }
  };

  const handleCheckEmployee = (employee, checked) => {
    if (checked) {
      setSelectedUsers((prev) => [...prev, employee]);
      setErr({
        ...err,
        userData: "",
      });
    } else {
      setSelectedUsers(selectedUsers.filter((item) => item !== employee));
    }
  };

  const handleCheckModule = (
    parent,
    module,
    type,
    checked,
    mainIndex,
    subIndex
  ) => {
    let obj = {
      ...moduleData[mainIndex]?.modules[subIndex],
      [type]: checked,
    };
    let arr = [...moduleData];
    let arrModules = [...arr[mainIndex].modules];
    arrModules[subIndex] = { ...obj };
    arr[mainIndex] = {
      ...arr[mainIndex],
      modules: [...arrModules],
    };
    setModuleData([...arr]);
    setErr({
      ...err,
      module: "",
    });
  };

  const handleAllCheckModule = (parent, type, checked, mainIndex) => {
    let arr = [...moduleData];
    let mainObj = { ...arr[mainIndex] };
    let modifiedArr = mainObj?.modules?.map((ele) => {
      return { ...ele, [type]: checked };
    });
    arr[mainIndex] = {
      ...arr[mainIndex],
      modules: [...modifiedArr],
    };
    setModuleData([...arr]);
    setErr({
      ...err,
      module: "",
    });
  };

  const applySearch = () => {
    console.log({ user, section, department });
  };

  const onClear = () => {
    setDepartment("");
    setSection("");
    setUser("");
    let formData = new FormData();
    formData.append("department", "");
    formData.append("section", "");
    formData.append("user", "");
    formData.append("page", 0);
    formData.append("perPage", perPage);
    setCurrentPage(0);
    dispatch(getUsersWithoutRolePermissions(formData));
  };

  const onClose = () => {
    setDepartment("");
    setSection("");
    setUser("");
    let formData = new FormData();
    formData.append("department", "");
    formData.append("section", "");
    formData.append("user", "");
    dispatch(getUsersWithoutRolePermissions(formData));
    setSelectedUsers([]);
    setModuleData([]);
    setRedo(!redo);
  };

  const handlePageChange = (e) => {
    setCurrentPage(e.selected);
    let formData = new FormData();
    formData.append("department", "");
    formData.append("section", "");
    formData.append("page", e.selected);
    formData.append("perPage", perPage);
    dispatch(getUsersWithoutRolePermissions(formData));
  };

  return (
    <div id="AddRequest_admin">
      <section>
        <PageHeader>
          <Title content={"Add Permission"} />
          <Breadcrump
            content={[
              {
                name: "Add Permission",
                path: "/admin/settings/permissions/add-permission",
              },
              { name: "Home", path: "/admin" },
            ]}
          />
        </PageHeader>
      </section>
      <section>
        <SelectEmployee
          department={department}
          setDepartment={setDepartment}
          section={section}
          setSection={setSection}
          user={user}
          setUser={setUser}
          err={err}
          page="add-permission"
          applySearch={applySearch}
          onClear={onClear}
          currentPage={currentPage}
          perPage={perPage}
          setCurrentPage={setCurrentPage}
        />
      </section>
      <section>
        <Table
          className="css-tbl-esc3"
          titles={[
            { title: "Employee No", key: "employee_no" },
            { title: "Employee Name", key: "employee_name" },
            { title: "Department", key: "department" },
            { title: "Section", key: "section" },
            { title: "Designation", key: "designation" },
            { title: "Action", key: "action" },
          ]}
          content={usersToList
            ?.filter((item, index) => index < 10)
            .map((ele, i) => {
              return {
                employee_no: ele?.employee_id,
                employee_name: ele?.firstname + " " + ele.lastname,
                section: ele?.section?.length
                  ? ele?.section[0]?.section_name
                  : "-",
                department: ele?.department?.department_name ?? "-",
                designation: ele?.designation?.designation_name ?? "-",
                action: (
                  <Input
                    type="checkbox"
                    className="css-inp-esc1 mt-2"
                    checked={selectedUsers?.some(
                      (item) => item._id === ele._id
                    )}
                    field=""
                    onChange={(value) => handleCheckEmployee(ele, value)}
                  />
                ),
              };
            })}
          page={currentPage}
          pagesCount={pageCount}
          pageStatus={pageStatus}
          onPageChange={handlePageChange}
        />
      </section>
      {selectedUsers?.length ? (
        <section>
          <TitleBar title="Selected Employees" top />
          <Table
            className="css-tbl-esc3"
            titles={[
              { title: "Employee No", key: "employee_no" },
              { title: "Employee Name", key: "employee_name" },
              { title: "Department", key: "department" },
              { title: "Section", key: "section" },
              { title: "Action", key: "action" },
            ]}
            content={selectedUsers?.map((ele, i) => {
              return {
                employee_no: ele?.employee_id,
                employee_name: ele?.firstname + " " + ele?.lastname,
                section: ele?.section.length
                  ? ele?.section[0]?.section_name
                  : "-",
                department: ele.department.department_name ?? "-",
                action: (
                  <img
                    src="/assets/icons/delete.svg"
                    alt=""
                    style={{ cursor: "pointer", height: 20 }}
                    onClick={() => {
                      handleCheckEmployee(ele, false);
                    }}
                  />
                ),
              };
            })}
          />
        </section>
      ) : null}
      <div className="text-danger">
        <small>{err.userData}</small>
      </div>
      <h4
        style={{
          textTransform: "capitalize",
          fontWeight: 600,
          padding: "12px",
        }}
      >
        {t("Access Permissions")}
      </h4>
      {moduleData?.map((ele, i) => {
        return (
          <TitleBar
            TitleBar
            title={ele?.main_access}
            toggleButton
            defaultToggle={"show"}
            key={i}
          >
            <div id="Table" className="style__3 mt-1">
              <table>
                <thead>
                  <tr>
                    <th style={{ width: 300 }}>{t("Module")}</th>
                    <th style={{ width: 200 }}>
                      <div
                        style={{ display: "flex" }}
                        className="table-head-module"
                      >
                        <Form.Check
                          checked={ele?.modules?.every((e) => e?.admin == true)}
                          onChange={(e) =>
                            handleAllCheckModule(
                              ele?.main_access,
                              "admin",
                              e.target.checked,
                              i
                            )
                          }
                          style={{ marginLeft: 10 }}
                        />
                        {t("All")}
                      </div>
                    </th>
                    <th>
                      <div
                        style={{ display: "flex" }}
                        className="table-head-module"
                      >
                        <Form.Check
                          checked={ele?.modules
                            ?.filter((el) => "Employee Management" != el.value)
                            ?.every((e) => e?.report == true)}
                          onChange={(e) =>
                            handleAllCheckModule(
                              ele?.main_access,
                              "report",
                              e.target.checked,
                              i
                            )
                          }
                          style={{ marginLeft: 10 }}
                        />
                        {t("Reports")}
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody style={{ marginTop: 20 }}>
                  {ele?.modules?.map((elem, j) => {
                    return (
                      <tr key={j}>
                        <td>
                          <h6>{elem.value}</h6>
                        </td>
                        <td>
                          <div
                            style={{ display: "flex" }}
                            className="table-head-module"
                          >
                            <Form.Check
                              checked={elem.admin == true ? true : false}
                              onChange={(e) =>
                                handleCheckModule(
                                  ele?.main_access,
                                  elem.key,
                                  "admin",
                                  e.target.checked,
                                  i,
                                  j
                                )
                              }
                              style={{ marginLeft: 10 }}
                              className="blue-checkbox"
                            />
                            {t("Admin")}
                          </div>
                        </td>
                        <td>
                          {!["Employee Management"]?.includes(elem.value) ? (
                            <div
                              style={{ display: "flex" }}
                              className="table-head-module"
                            >
                              <Form.Check
                                checked={elem.report == true ? true : false}
                                onChange={(e) =>
                                  handleCheckModule(
                                    ele?.main_access,
                                    elem.key,
                                    "report",
                                    e.target.checked,
                                    i,
                                    j
                                  )
                                }
                                style={{ marginLeft: 10 }}
                              />
                              {t("Reports")}
                            </div>
                          ) : (
                            <></>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </TitleBar>
        );
      })}
      <div className="text-danger">
        <small>{err.module}</small>
      </div>
      <section>
        <FooterButtons>
          <Button
            className="css-btn-esc3"
            right
            content="Back"
            icon="/assets/icons/Back.svg"
            reverse
            iconWidth="15px"
            onClick={() => navigate("/admin/settings/permissions")}
          />
          <Button
            className="css-btn-esc3"
            left
            content="Submit"
            onClick={onSaveHandler}
            disabled={rolesActionsLoading}
          />
          <Button
            className="css-btn-esc3"
            left
            content="Cancel"
            onClick={onClose}
          />
        </FooterButtons>
      </section>
    </div>
  );
}
