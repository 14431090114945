import React, { useState } from 'react'
import Title from '../../commonComponents/Title/Title';
import Button from '../../commonComponents/Button/Button';
import SearchBox from '../../commonComponents/SearchBox/SearchBox';
import EntriesCounter from '../../commonComponents/EntriesCounter/EntriesCounter';
import Input from '../../commonComponents/Input/Input';
import { useNavigate, useParams } from 'react-router-dom';
import { log_from_date, log_list_loading, log_page, log_per_page, log_sort_ascending, log_sort_column, log_to_date, log_user_data, updateRedux } from '../../redux/commonReducer';
import { useDispatch, useSelector } from 'react-redux';
import { getUserActivityLog, getUserSessionLog } from '../../redux/actionCreator';
import moment from 'moment';
import Breadcrump from '../../commonComponents/Breadcrump/Breadcrump';
import ActivityLogData from './ActivityLog';
import SessionLogData from './sessionLog';
import { toast } from 'react-toastify';
import useTranslate from '../../utils/useTranslate';
import PageHeader from '../../commonComponents/PageHeader/PageHeader';

const UsersLog = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  // const { t } = useTrans()
  const { t } = useTranslate()

  const loading = useSelector(log_list_loading);
  const fromDate = useSelector(log_from_date);
  const toDate = useSelector(log_to_date);
  const page = useSelector(log_page);
  const sortColumn = useSelector(log_sort_column);
  const sortAscending = useSelector(log_sort_ascending);
  const perPage = useSelector(log_per_page);
  const userData = useSelector(log_user_data);

  const [logType, setLogType] = useState("activity_log");
  const [filterData, setFilterData] = useState({
    start_date: "",
    end_date: "",
  });
  const [appliedFilterData, setAppliedFilterData] = useState({
    start_date: "",
    end_date: "",
  });

  const clearFilter = () => {
    setFilterData({
      start_date: "",
      end_date: "",
    })
    setAppliedFilterData({
      start_date: "",
      end_date: "",
    })
    dispatch(updateRedux({ key: "log_per_page", value: 10 }));
    dispatch(updateRedux({ key: "log_page", value: 0 }));
    dispatch(updateRedux({ key: "log_sort_ascending", value: false }));
    dispatch(updateRedux({ key: "log_sort_column", value: "createdAt" }));
    dispatch(updateRedux({ key: "log_from_date", value: "" }));
    dispatch(updateRedux({ key: "log_to_date", value: "" }));
    let value = {
      userId: id,
      page: 0,
      from_date: "",
      to_date: "",
      sort_key: "createdAt",
      sort_order: false,
      perPage: 10,
    };
    if (logType == "activity_log") {
      dispatch(getUserActivityLog(value));
    } else {
      dispatch(getUserSessionLog(value));
    }
  };
  // moment(toDate).format("YYYY-MM-DD hh:mm:ss")?.split(" ")[0] +" 23:59:59";
  // new Date(fromDate).toISOString()
  const applySearch = () => {
    if (filterData?.start_date || filterData?.end_date) {
      if (!(filterData?.start_date) || !(filterData?.end_date)) {
        toast.error("Start date and end date are mandatory", {
          position: "bottom-center",
          autoClose: 3000
        })
        return
      }
    }
    setAppliedFilterData(filterData)
    dispatch(updateRedux({ key: "log_page", value: 0 }));
    let value = {
      userId: id,
      page: 0,
      from_date: filterData?.start_date ? moment(filterData?.start_date).format("YYYY-MM-DD") : "",
      to_date: filterData?.end_date ? moment(filterData?.end_date).format("YYYY-MM-DD") : "",
      sort_key: sortColumn,
      sort_order: !sortAscending ? "1" : "-1",
      perPage: perPage,
    };
    console.log(value);
    if (logType == "activity_log") {
      dispatch(getUserActivityLog(value));
    } else {
      dispatch(getUserSessionLog(value));
    }
  };

  const onLimitChangeHandler = (value) => {
    dispatch(updateRedux({ key: "log_per_page", value: parseInt(value) }));
    dispatch(updateRedux({ key: "log_page", value: 0 }));
    let dataValue = {
      userId: id,
      page: 0,
      from_date: fromDate ? moment(fromDate).format("YYYY-MM-DD") : "",
      to_date: toDate ? moment(toDate).format("YYYY-MM-DD") : "",
      sort_key: sortColumn,
      sort_order: sortAscending ? "1" : "-1",
      perPage: parseInt(value),
    };
    console.log({ dataValue })
    if (logType == "activity_log") {
      dispatch(getUserActivityLog(dataValue));
    } else {
      dispatch(getUserSessionLog(dataValue));
    }
  };

  const logTypeChangeHandler = (type) => {
    dispatch(updateRedux({ key: "log_per_page", value: 10 }));
    dispatch(updateRedux({ key: "log_page", value: 0 }));
    dispatch(updateRedux({ key: "log_sort_ascending", value: false }));
    dispatch(updateRedux({ key: "log_sort_column", value: "createdAt" }));
    setLogType(type);
  };

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    return currentDate.getTime() < selectedDate.getTime();
  };

  function searchBoxChangeHandler(e, key) {
    switch (key) {
      case 'request_type': setFilterData({ ...filterData, request_type: e })
        break;
      case 'date_from': setFilterData({ ...filterData, start_date: e })
        break;
      case 'date_to': setFilterData({ ...filterData, end_date: e })
        break;
      case 'employee': setFilterData({ ...filterData, user: e })
        break;
      case 'status': setFilterData({ ...filterData, status: e })
        break;
      default:
    }
  }

  return (
    <div id="HumanResource_admin">
      <section>
        <PageHeader>
          <Title content="Users Log" />
          <Breadcrump content={[{ name: "Log", path: `/admin/users/user-log/${id}` }, { name: "Users", path: "/admin/users" }, { name: "Home", path: "/admin" }]} />
          <Button className="css-btn-esc1" width={150} content="Sync Users" icon="/assets/icons/sync-svgrepo-com.svg" reverse onClick={() => { }} />
        </PageHeader>
      </section>
      <section>
        <SearchBox
          elements={[
            { label: 'Date', key: 'date_from', type: 'datePicker', className: 'css-dtp-esc3', placeholder: 'From' },
            { label: '', key: 'date_to', type: 'datePicker', className: 'css-dtp-esc3', placeholder: 'To' },
          ]}
          options={{
            date_from: { selected: filterData?.start_date, filterTime: filterPassedTime, maxDate: filterData?.end_date ? filterData?.end_date : new Date(), dateFormat: "dd/MM/yy" },
            date_to: { selected: filterData?.end_date, filterTime: filterPassedTime, minDate: filterData?.start_date && filterData?.start_date, maxDate: new Date(), dateFormat: "dd/MM/yy" },
          }}
          onSearch={applySearch}
          onClear={clearFilter}
          showClearBtn={filterData?.request_type || filterData?.start_date || filterData?.end_date || filterData?.department || filterData?.user || filterData?.status || filterData.keyword}
          onChange={searchBoxChangeHandler} />
      </section>
      <section className="d-flex justify-content-between">
        <div className="d-flex gap-2">
          <Button className={'css-btn-esc2' + (logType !== 'activity_log' ? ' inactive' : '')} content={t('Activity Log')} onClick={() => logTypeChangeHandler("activity_log")} />
          <Button className={'css-btn-esc2' + (logType === 'activity_log' ? ' inactive' : '')} content={t('Session Log')} onClick={() => logTypeChangeHandler("session_log")} />
        </div>
        <EntriesCounter value={perPage} onChange={onLimitChangeHandler} />
      </section>
      <div className="col-md-12 user_detail d-flex gap-2">
        <div>
          <span>{userData?.name}</span>
          <b> : {t('User Name')} </b>
        </div>
        <div>
          <span>{userData?.department?.department_name ?? ""}</span>
          <b> : {t('Department')}</b>
        </div>
      </div>
      {logType == "activity_log" ? <ActivityLogData /> : <SessionLogData />}
    </div>
  )
}

export default UsersLog