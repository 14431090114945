import React from 'react'
import './DateTimeViewer.scss'
import moment from 'moment'

export default function DateTimeViewer({ dateTime, dateOnly, dir }) {

  return (
    <span id='DateTimeViewer'>
      {
        <>
          {
            <p dir={dir}>
              {moment.utc(dateTime).format('DD MMM YYYY')}
              {!dateOnly && (', ' + moment.utc(dateTime).format('HH:mm'))}
            </p>
          }

        </>
      }
    </span >
  )
}
