import React, { useEffect, useMemo, useState } from "react";
import PageHeader from "../../../commonComponents/PageHeader/PageHeader";
import Title from "../../../commonComponents/Title/Title";
import Breadcrump from "../../../commonComponents/Breadcrump/Breadcrump";
import TitleBar from "../../../commonComponents/TitleBar/TitleBar";
import TitleValue from "../../../commonComponents/TitleValue/TitleValue";
import Table from "../../../commonComponents/Table/Table";
import FooterButtons from "../../../commonComponents/FooterButtons/FooterButtons";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../commonComponents/Button/Button";
import Modal from "../../../commonComponents/Modal/Modal";
import ModalContent from "../../../commonComponents/ModalContent/ModalContent";
import {
  OnboardingRequestDetails,
  downloadIdCard,
  editOnboardingRequest,
  errorToast,
  generateIDCard,
  getDepartment,
  getSection,
  successToast,
  uploadFile,
} from "../../../redux/actionCreator";
import { useDispatch, useSelector } from "react-redux";
import {
  admin_onboarding_details,
  admin_onboarding_details_loading,
  admin_onboarding_history,
  department_details,
  downloadLoader,
  fileUploadLoader,
  section_details,
} from "../../../redux/commonReducer";
import DateTimeViewer from "../../../commonComponents/DateTimeViewer/DateTimeViewer";
import StatusBox from "../../../commonComponents/StatusBox/StatusBox";
import FileUpload from "../../../commonComponents/FileUpload/FileUpload";
import Select from "../../../commonComponents/Select/Select";
import { Tooltip } from "react-bootstrap";
import useTranslate from "../../../utils/useTranslate";
import { toast } from "react-toastify";
import { API_BASE_URL } from "../../../constants/configuration";
import TextArea from "../../../commonComponents/TextArea/TextArea";
import AttachmentContent from "../../../commonComponents/AttachmentContent/AttachmentContent";
import AttachmentsEyeView from "../../../commonComponents/AttachmentsEyeView/AttachmentsEyeView";
import TableCommentsViewer from "../../../commonComponents/TableCommentsViewer/TableCommentsViewer";
import moment from "moment";
import { detailOnboardingSaveValidation } from "../../../utils/validation";
import RadioButtons from "../../../commonComponents/RadioButtons/RadioButtons";
import { Capitalize } from "../../../utils/helpers";

function OnboardingDetails({ userpage }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslate();
  const { id, mode } = useParams();

  const isEditMode = mode === "edit";

  const onBoardDetails = useSelector(admin_onboarding_details);
  const history = useSelector(admin_onboarding_history);
  const upload_loader = useSelector(fileUploadLoader);
  const download_loader = useSelector(downloadLoader);
  const loading = useSelector(admin_onboarding_details_loading);
  const departmentList = useSelector(department_details);
  const sectionList = useSelector(section_details);

  const [show, setShow] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [showPreviewImage, setShowPreviewImage] = useState("");
  const [historyData, setHistoryData] = useState([]);
  const [err, setErr] = useState({});
  const [trackId, setTrackId] = useState("");
  const [data, setData] = useState({
    request_id: id,
    new_photo: "",
    photo: "",
    access_card_provided: false,
    email_generated: false,
    pc_gathered: false,
    ad_updated: false,
    comment: "",
    uploadFiles: [],
  });
  const [status, setStatus] = useState("");
  const [showReturnModal, setShowReturnModal] = useState(false);
  const [department, setDepartment] = useState("");
  const [section, setSection] = useState("");

  const statusOptions = [
    { label: "Submit", value: "submit" },
    { label: "Return", value: "return" },
  ];

  useEffect(() => {
    dispatch(getDepartment());
  }, []);

  useEffect(() => {
    if (id) {
      let formData = new FormData();
      formData.append("id", id);
      formData.append(
        "list_type",
        userpage == "hr_report"
          ? "hr"
          : userpage == "it_report"
          ? "it"
          : userpage
      );
      dispatch(OnboardingRequestDetails(formData));
    }
  }, [id]);

  useEffect(() => {
    setHistoryData([]);
    if (history) {
      history?.forEach((item, i) => {
        setHistoryData((s) => [
          ...s,
          {
            sl_no: history?.length - i,
            received_date: <DateTimeViewer dateTime={item?.received_date} />,
            last_update: <DateTimeViewer dateTime={item?.last_update} />,
            handled_by: item?.handled_by?.name ?? "-",
            status: (
              <StatusBox
                status={
                  item?.status?.handle_key == "return"
                    ? "Need Info"
                    : item?.status?.request_status
                }
              />
            ),
            assigned_to: item?.assigned_to || "-",
            attachments: (
              <AttachmentsEyeView
                files={{
                  path: API_BASE_URL + "onboarding_files/",
                  endpoints: item?.attachments,
                }}
              />
            ),
            comments: <TableCommentsViewer comment={item?.comment} />,
          },
        ]);
      });
    }
  }, [history]);

  useEffect(() => {
    if (onBoardDetails) {
      let result = {
        ...data,
        access_card_provided: onBoardDetails?.access_card_provided ?? false,
        email_generated: onBoardDetails?.email_generated ?? false,
        pc_gathered: onBoardDetails?.pc_gathered ?? false,
        ad_updated: onBoardDetails?.ad_updated ?? false,
      };
      if (onBoardDetails?.attachments?.length) {
        let arr = onBoardDetails?.attachments?.map((ele, i) => {
          let obj = {
            type: ele?.split(".").pop(),
            url: `${API_BASE_URL}onboarding_files/${ele}`,
            name: `${ele}`,
            response: `${ele}`,
          };
          return obj;
        });
        result = {
          ...result,
          attachments: [...arr],
        };
      }
      setData({
        ...result,
      });
      if (onBoardDetails?.department) {
        setDepartment(onBoardDetails?.department);
        setSection(onBoardDetails?.section);
      }
    }
  }, [onBoardDetails]);

  const adminPermission = useMemo(() => {
    if (onBoardDetails) {
      return onBoardDetails?.status?.handle_key != "close";
    } else {
      return true;
    }
  }, [onBoardDetails]);

  const onChangeHandler = (key, value) => {
    setData({
      ...data,
      [key]: value,
    });
    setErr({
      ...err,
      [key]: "",
    });
  };

  const singleImageSelectedHandler = (field, e) => {
    const file = e[0];
    const fileType = file.name
      .substr(file.name.lastIndexOf(".") + 1)
      .toLowerCase();
    if (["jpeg", "jpg", "png"].includes(fileType)) {
      setData({
        ...data,
        [field]: e[0],
      });
    } else {
      setErr({
        ...err,
        [field]: "Invalid file format",
      });
    }
  };

  const handleGenerate = () => {
    let formData = new FormData();
    formData.append("request_id", data?.request_id);
    formData.append("new_photo", data?.new_photo ?? "");
    formData.append(
      "photo",
      data?.new_photo == 1 ? data?.photo?.metaFile ?? "" : ""
    );
    if (trackId?.length == 0) {
      let track_id = Math.floor(1000 + Math.random() * 9000).toString();
      setTrackId(track_id);
      formData.append("track_id", track_id);
    } else {
      formData.append("track_id", trackId);
    }
    dispatch(
      generateIDCard(formData, (res) => {
        if (res.data.status == true) {
          successToast(res.data.message);
          setShowPreviewImage(res.data.data.file_name);
          setShowPreview(true);
        } else {
          errorToast(res.data.message);
        }
      })
    );
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {props.label}
    </Tooltip>
  );

  const fileSelectedHandler = (e, isInvalid, message) => {
    if (isInvalid) {
      setErr({
        ...err,
        uploadFiles: message ?? "file format not supported",
      });
    } else {
      if (e?.some((elem, i) => elem?.metaFile)) {
        let formData = new FormData();
        if (trackId?.length == 0) {
          let track_id = Math.floor(1000 + Math.random() * 9000).toString();
          setTrackId(track_id);
          formData.append("track_id", track_id);
        } else {
          formData.append("track_id", trackId);
        }
        e?.filter((el) => el?.metaFile != undefined)?.forEach((item, i) =>
          formData.append(`file`, item?.metaFile)
        );
        dispatch(
          uploadFile(formData, (res) => {
            toast.success(`attachments uploaded successfully`, {
              position: "bottom-center",
              autoClose: 3000,
            });
            let oldFiles = e?.filter((el) => el?.metaFile == undefined);
            let arr = e
              ?.filter((el) => el?.metaFile != undefined)
              ?.map((ele, i) => {
                return {
                  ...ele,
                  response: res?.data[i],
                };
              });
            setErr({
              ...err,
              uploadFiles: "",
            });
            setData({
              ...data,
              uploadFiles: [...oldFiles, ...arr],
            });
          })
        );
      } else {
        setData({
          ...data,
          uploadFiles: e?.length ? e : [],
        });
      }
    }
  };

  const onStatusChangeHandler = (value) => {
    setStatus(value);
    if (value) {
      onSaveHandler(value?.value, value);
    }
  };

  const onSaveHandler = async (button, value) => {
    if (button !== "save") {
      if (userpage == "hr") {
        if (data?.uploadFiles?.length || data?.comment?.trim()?.length != 0) {
          updateHandler(value);
        } else {
          errorToast(t("No changes found"));
        }
      } else {
        let isValidated = await detailOnboardingSaveValidation(
          { ...data, button },
          setErr
        );
        if (isValidated) {
          if (button == "return") {
            setShowReturnModal(true);
          } else {
            updateHandler(value);
          }
        } else {
          setStatus("");
        }
      }
    } else {
      if (
        data?.uploadFiles?.length ||
        data?.comment?.trim()?.length != 0 ||
        data?.access_card_provided != onBoardDetails?.access_card_provided ||
        data?.email_generated != onBoardDetails?.email_generated ||
        data?.pc_gathered != onBoardDetails?.pc_gathered ||
        data?.ad_updated != onBoardDetails?.ad_updated
      ) {
        updateHandler();
      } else {
        errorToast(t("No changes found"));
      }
    }
  };

  const updateHandler = (value) => {
    let formData = new FormData();
    formData.append("id", id);
    formData.append("comment", data?.comment);
    data?.uploadFiles?.forEach((item, i) =>
      formData.append("history_attachments", item?.response)
    );
    if (value != undefined) {
      formData.append("submit_type", value?.value);
    } else if (status != "") {
      formData.append("submit_type", status?.value);
    } else {
      formData.append("submit_type", "save");
    }
    formData.append("access_card_provided", data?.access_card_provided);
    formData.append("email_generated", data?.email_generated);
    formData.append("pc_gathered", data?.pc_gathered);
    formData.append("ad_updated", data?.ad_updated);
    formData.append(
      "id_card",
      showPreviewImage ?? onBoardDetails?.id_card ?? ""
    );
    formData.append("track_id", trackId);
    formData.append("list_type", userpage);
    console.log(formData);
    dispatch(
      editOnboardingRequest(id, formData, (res) => {
        if (res.data.status == true) {
          successToast(res.data.message);
          setShow(false);
          setShowPreviewImage("");
          setShowPreview(false);
          let formData = new FormData();
          formData.append("id", id);
          formData.append("list_type", userpage);
          dispatch(OnboardingRequestDetails(formData));
          setData({
            ...data,
            new_photo: "",
            photo: "",
            comment: "",
          });
          setStatus("");
          if (!showPreviewImage) {
            navigate(-1);
          }
        } else {
          errorToast(res.data.message);
        }
      })
    );
  };

  function downloadHandler(file, name) {
    const url = `${API_BASE_URL}onboarding_files/${file}`;
    // const url = `${API_BASE_URL}temp_files/${trackId}/${file}`;
    const a = document.createElement("a");
    a.href = url;
    a.target = "_blank";
    a.download = name;
    document.body.appendChild(a);
    a.click();
  }

  const onDownloadHandler = () => {
    dispatch(downloadIdCard(id));
  };

  const valueSplitFunction = (value) => {
    let temp = value?.split("_");
    let tempSplit = temp?.splice(1);
    let tempFinal = tempSplit?.join("");
    return tempFinal;
  };

  const onCancel = () => {
    setErr({});
    setData({
      comment: "",
      uploadFiles: [],
      new_photo: "",
      photo: "",
      access_card_provided: onBoardDetails?.access_card_provided ?? false,
      email_generated: onBoardDetails?.email_generated ?? false,
      pc_gathered: onBoardDetails?.pc_gathered ?? false,
      ad_updated: onBoardDetails?.ad_updated ?? false,
    });
    if (id) {
      let formData = new FormData();
      formData.append("id", id);
      formData.append("list_type", userpage);
      dispatch(OnboardingRequestDetails(formData));
    }
  };

  const modalCancelHandler = () => {
    setShow(false);
    setShowPreviewImage("");
    setShowPreview(false);
    setData({
      ...data,
      new_photo: "",
      photo: "",
    });
    setErr({
      ...err,
      new_photo: "",
      photo: "",
    });
  };

  return (
    console.log("onBoardDetails", department, section),
    (
      <div>
        <section>
          <PageHeader>
            <Title
              content={mode === "edit" ? "Edit Details" : "View Details"}
            />
            <Breadcrump
              content={[
                {
                  name: "Onboarding Process",
                  path:
                    userpage == "hr_report"
                      ? "/admin/report/onboarding_hr_report"
                      : userpage == "it_report"
                      ? "/admin/report/onboarding_it_report"
                      : userpage == "hr"
                      ? "/admin/human_resource/onboarding_process"
                      : "/admin/it/onboarding_process",
                },
                {
                  name:
                    userpage == "hr" || userpage == "hr_report"
                      ? "Human Resource"
                      : "IT",
                  path:
                    userpage == "hr_report"
                      ? "/admin/report/onboarding_hr_report"
                      : userpage == "it_report"
                      ? "/admin/report/onboarding_it_report"
                      : userpage == "hr"
                      ? "/admin/human_resource/onboarding_process"
                      : "/admin/it/onboarding_process",
                },
                { name: "Home", path: "/admin" },
              ]}
            />
          </PageHeader>
        </section>
        <section className="details__page">
          <TitleBar
            title={"Personal Information"}
            toggleButton
            defaultToggle={"show"}
            top
          >
            <div className="details__inner">
              <div className="row row-cols-5">
                <div className="col">
                  <div className="d-flex align-items-start pt-1">
                    <img
                      src={
                        onBoardDetails?.photo
                          ? `${API_BASE_URL}onboarding_files/${onBoardDetails?.photo}`
                          : "https://cdn-icons-png.flaticon.com/512/3177/3177440.png"
                      }
                      className="avatar"
                      alt=""
                    />
                    <TitleValue
                      title="Employee Type"
                      value={onBoardDetails?.employee_type}
                    />
                  </div>
                </div>

                <div className="col">
                  <TitleValue
                    title="First Name"
                    value={onBoardDetails?.first_name}
                  />
                </div>
                <div className="col">
                  <TitleValue
                    title="First Name Arabic"
                    value={onBoardDetails?.first_name_ar}
                  />
                </div>
                <div className="col">
                  <TitleValue
                    title="Second Name"
                    value={onBoardDetails?.second_name}
                  />
                </div>
                <div className="col">
                  <TitleValue
                    title="Second Name Arabic"
                    value={onBoardDetails?.second_name_ar}
                  />
                </div>
                <div className="col">
                  <TitleValue
                    title="Last Name"
                    value={onBoardDetails?.last_name}
                  />
                </div>
                <div className="col">
                  <TitleValue
                    title="Last Name Arabic"
                    value={onBoardDetails?.last_name_ar}
                  />
                </div>
                <div className="col">
                  <TitleValue
                    title="Personal Email"
                    value={onBoardDetails?.email}
                  />
                </div>

                <div className="col">
                  <TitleValue title="Phone No" value={onBoardDetails?.phone} />
                </div>
                <div className="col">
                  <TitleValue
                    title="Alternative Phone No"
                    value={onBoardDetails?.alternate_phone}
                  />
                </div>
                <div className="col">
                  <TitleValue title="Gender" value={onBoardDetails?.gender} />
                </div>
                <div className="col">
                  <TitleValue
                    title="Nationality"
                    value={onBoardDetails?.nationality}
                  />
                </div>
                <div className="col">
                  <TitleValue
                    title="Date of Birth"
                    value={
                      onBoardDetails?.dob &&
                      moment(onBoardDetails?.dob).format("DD MMM YYYY")
                    }
                  />
                </div>
                <div className="col">
                  <TitleValue
                    title="Birth Certificate"
                    value={valueSplitFunction(
                      onBoardDetails?.birth_certificate
                    )}
                    valueIcon="/assets/icons/document.svg"
                    url={`${API_BASE_URL}onboarding_files/${onBoardDetails?.birth_certificate}`}
                  />
                </div>
                <div className="col">
                  <TitleValue
                    title="Military Certificate"
                    value={valueSplitFunction(
                      onBoardDetails?.military_certificate
                    )}
                    valueIcon="/assets/icons/document.svg"
                    url={`${API_BASE_URL}onboarding_files/${onBoardDetails?.military_certificate}`}
                  />
                </div>
                <div className="col">
                  <TitleValue
                    title="Emirates ID No"
                    value={onBoardDetails?.emirates_id}
                  />
                </div>
                <div className="col">
                  <TitleValue
                    title="Emirates ID Proof"
                    value={valueSplitFunction(
                      onBoardDetails?.emirates_id_proof
                    )}
                    valueIcon="/assets/icons/document.svg"
                    url={`${API_BASE_URL}onboarding_files/${onBoardDetails?.emirates_id_proof}`}
                  />
                </div>
                <div className="col">
                  <TitleValue
                    title="Passport No"
                    value={onBoardDetails?.passport_no}
                  />
                </div>
                <div className="col">
                  <TitleValue
                    title="Passport ID Proof"
                    value={valueSplitFunction(onBoardDetails?.passport_proof)}
                    valueIcon="/assets/icons/document.svg"
                    url={`${API_BASE_URL}onboarding_files/${onBoardDetails?.passport_proof}`}
                  />
                </div>
                <div className="col">
                  <TitleValue
                    title="Medical Report"
                    value={valueSplitFunction(onBoardDetails?.medical_report)}
                    valueIcon="/assets/icons/document.svg"
                    url={`${API_BASE_URL}onboarding_files/${onBoardDetails?.medical_report}`}
                  />
                </div>
                <div className="col">
                  <TitleValue
                    title="Police Clearance Certificate"
                    value={valueSplitFunction(
                      onBoardDetails?.police_clearance_certificate
                    )}
                    valueIcon="/assets/icons/document.svg"
                    url={`${API_BASE_URL}onboarding_files/${onBoardDetails?.police_clearance_certificate}`}
                  />
                </div>
                <div className="col">
                  <TitleValue
                    title="Educational Certificates"
                    value={valueSplitFunction(
                      onBoardDetails?.educational_certificate
                    )}
                    valueIcon="/assets/icons/document.svg"
                    url={`${API_BASE_URL}onboarding_files/${onBoardDetails?.educational_certificate}`}
                  />
                </div>
              </div>
            </div>
          </TitleBar>
          <TitleBar
            title={"Job Details"}
            className="mt-4"
            toggleButton
            defaultToggle={"show"}
            top
          >
            <div className="details__inner">
              <div className="row row-cols-5">
                <div className="col">
                  <TitleValue
                    title="Designation"
                    value={onBoardDetails?.designation?.designation_name}
                  />
                </div>
                <div className="col">
                  <TitleValue
                    title="Secondary Designation"
                    value={
                      onBoardDetails?.secondary_designation?.designation_name
                    }
                  />
                </div>
                <div className="col">
                  <TitleValue
                    title="Date of Joining"
                    value={
                      onBoardDetails?.joining_date && (
                        <DateTimeViewer
                          dateTime={onBoardDetails?.joining_date}
                          dateOnly
                          dir={"ltr"}
                        />
                      )
                    }
                  />
                </div>
                <div className="col">
                  <TitleValue
                    title="Department"
                    value={department?.department_name}
                  />
                </div>
                <div className="col">
                  <TitleValue title="Section" value={section?.section_name} />
                </div>
                <div className="col">
                  <TitleValue
                    title="Employee No"
                    value={onBoardDetails?.employee_no}
                  />
                </div>
                <div className="col">
                  <TitleValue title="Grade" value={onBoardDetails?.grade} />
                </div>
                <div className="col">
                  <TitleValue
                    title="Job Description"
                    value={valueSplitFunction(
                      onBoardDetails?.job_description_doc
                    )}
                    valueIcon="/assets/icons/document.svg"
                    url={`${API_BASE_URL}onboarding_files/${onBoardDetails?.job_description_doc}`}
                  />
                </div>
                <div className="col">
                  <TitleValue
                    title="Updated CV"
                    value={valueSplitFunction(onBoardDetails?.cv)}
                    valueIcon="/assets/icons/document.svg"
                    url={`${API_BASE_URL}onboarding_files/${onBoardDetails?.cv}`}
                  />
                </div>
                <div className="col">
                  <TitleValue
                    title="Offer Letter"
                    value={valueSplitFunction(onBoardDetails?.offer_letter)}
                    valueIcon="/assets/icons/document.svg"
                    url={`${API_BASE_URL}onboarding_files/${onBoardDetails?.offer_letter}`}
                  />
                </div>
                <div className="col">
                  <TitleValue
                    title="NDA"
                    value={valueSplitFunction(onBoardDetails?.nda)}
                    valueIcon="/assets/icons/document.svg"
                    url={`${API_BASE_URL}onboarding_files/${onBoardDetails?.nda}`}
                  />
                </div>
                <div className="col">
                  <TitleValue
                    title="Letter from Executive Director"
                    value={valueSplitFunction(
                      onBoardDetails?.executive_letter_certificate
                    )}
                    valueIcon="/assets/icons/document.svg"
                    url={`${API_BASE_URL}onboarding_files/${onBoardDetails?.executive_letter_certificate}`}
                  />
                </div>
                <div className="col">
                  <TitleValue
                    title="Financial Disclousure"
                    value={valueSplitFunction(
                      onBoardDetails?.financial_disclosure
                    )}
                    valueIcon="/assets/icons/document.svg"
                    url={`${API_BASE_URL}onboarding_files/${onBoardDetails?.financial_disclosure}`}
                  />
                </div>
                <div className="col">
                  <TitleValue
                    title="Latest Experience Certifcate"
                    value={valueSplitFunction(
                      onBoardDetails?.experience_certificate
                    )}
                    valueIcon="/assets/icons/document.svg"
                    url={`${API_BASE_URL}onboarding_files/${onBoardDetails?.experience_certificate}`}
                  />
                </div>
                <div className="col">
                  <TitleValue
                    title="Previous Salary Certificate"
                    value={valueSplitFunction(
                      onBoardDetails?.previous_salary_certificate
                    )}
                    valueIcon="/assets/icons/document.svg"
                    url={`${API_BASE_URL}onboarding_files/${onBoardDetails?.previous_salary_certificate}`}
                  />
                </div>
                <div className="col">
                  <TitleValue
                    title="Scanned Welcome email"
                    value={valueSplitFunction(onBoardDetails?.welcome_email)}
                    valueIcon="/assets/icons/document.svg"
                    url={`${API_BASE_URL}onboarding_files/${onBoardDetails?.welcome_email}`}
                  />
                </div>
              </div>
            </div>
          </TitleBar>
          <TitleBar
            title={"Family Details"}
            className="mt-4"
            toggleButton
            defaultToggle={"show"}
            top
          >
            <div className="details__inner">
              <div className="row row-cols-5">
                <div className="col">
                  <TitleValue
                    title="Family Book"
                    value={valueSplitFunction(onBoardDetails?.family_book)}
                    valueIcon="/assets/icons/document.svg"
                    url={`${API_BASE_URL}onboarding_files/${onBoardDetails?.family_book}`}
                  />
                </div>
                <div className="col">
                  <TitleValue
                    title="Marital Status"
                    value={Capitalize(onBoardDetails?.marital_status)}
                  />
                </div>
                {onBoardDetails?.marital_status == "other" ? (
                  <div className="col">
                    <TitleValue
                      title="Marital status proof"
                      value={valueSplitFunction(onBoardDetails?.marital_other)}
                      valueIcon="/assets/icons/document.svg"
                      url={`${API_BASE_URL}onboarding_files/${onBoardDetails?.marital_other}`}
                    />
                  </div>
                ) : (
                  <></>
                )}
                {onBoardDetails?.marital_status == "divorced" ? (
                  <div className="col">
                    <TitleValue
                      title="Divorce certificate from court"
                      value={valueSplitFunction(
                        onBoardDetails?.divorce_certificate
                      )}
                      valueIcon="/assets/icons/document.svg"
                      url={`${API_BASE_URL}onboarding_files/${onBoardDetails?.divorce_certificate}`}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <TitleBar className={"css-tbr-esc2"} title="Parent's Details" />
              <div className="row row-cols-5">
                <div className="col">
                  <TitleValue
                    title="Father's Emirates ID No"
                    value={onBoardDetails?.father_emirates_id}
                  />
                </div>
                <div className="col">
                  <TitleValue
                    title="Father's Emirates ID Proof"
                    value={valueSplitFunction(
                      onBoardDetails?.father_emirates_id_proof
                    )}
                    valueIcon="/assets/icons/document.svg"
                    url={`${API_BASE_URL}onboarding_files/${onBoardDetails?.father_emirates_id_proof}`}
                  />
                </div>
                <div className="col">
                  <TitleValue
                    title="Father's Passport No"
                    value={onBoardDetails?.father_passport_no}
                  />
                </div>
                <div className="col">
                  <TitleValue
                    title="Father's Passport Proof"
                    value={valueSplitFunction(
                      onBoardDetails?.father_passport_proof
                    )}
                    valueIcon="/assets/icons/document.svg"
                    url={`${API_BASE_URL}onboarding_files/${onBoardDetails?.father_passport_proof}`}
                  />
                </div>
              </div>
              <div className="row row-cols-5">
                <div className="col">
                  <TitleValue
                    title="Mother's Emirates ID No"
                    value={onBoardDetails?.mother_emirates_id}
                  />
                </div>
                <div className="col">
                  <TitleValue
                    title="Mother’s Emirates ID Proof"
                    value={valueSplitFunction(
                      onBoardDetails?.mother_emirates_id_proof
                    )}
                    valueIcon="/assets/icons/document.svg"
                    url={`${API_BASE_URL}onboarding_files/${onBoardDetails?.mother_emirates_id_proof}`}
                  />
                </div>
                <div className="col">
                  <TitleValue
                    title="Mother’s's Passport No"
                    value={onBoardDetails?.mother_passport_no}
                  />
                </div>
                <div className="col">
                  <TitleValue
                    title="Mother’s's Passport Proof"
                    value={valueSplitFunction(
                      onBoardDetails?.mother_passport_proof
                    )}
                    valueIcon="/assets/icons/document.svg"
                    url={`${API_BASE_URL}onboarding_files/${onBoardDetails?.mother_passport_proof}`}
                  />
                </div>
              </div>
              {onBoardDetails?.marital_status == "married" ? (
                <>
                  <TitleBar className={"css-tbr-esc2"} title="Spouse Details" />
                  <div className="row row-cols-5">
                    <div className="col">
                      <TitleValue
                        title="Spouse Name"
                        value={onBoardDetails?.spouse_name}
                      />
                    </div>
                    <div className="col">
                      <TitleValue
                        title="Spouse's Emirates ID No"
                        value={onBoardDetails?.spouse_emirates_id}
                      />
                    </div>
                    <div className="col">
                      <TitleValue
                        title="Spouse's Emirates ID Proof"
                        value={valueSplitFunction(
                          onBoardDetails?.spouse_emirates_id_proof
                        )}
                        valueIcon="/assets/icons/document.svg"
                        url={`${API_BASE_URL}onboarding_files/${onBoardDetails?.spouse_emirates_id_proof}`}
                      />
                    </div>
                    <div className="col">
                      <TitleValue
                        title="Spouse Passport No"
                        value={onBoardDetails?.spouse_passport_no}
                      />
                    </div>
                    <div className="col">
                      <TitleValue
                        title="Spouse Passport Proof"
                        value={valueSplitFunction(
                          onBoardDetails?.spouse_passport_proof
                        )}
                        valueIcon="/assets/icons/document.svg"
                        url={`${API_BASE_URL}onboarding_files/${onBoardDetails?.spouse_passport_proof}`}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
              <TitleBar className={"css-tbr-esc2"} title="Children Details" />
              <div className="col">
                <TitleValue
                  title="?Have Children"
                  value={onBoardDetails?.children?.length ? "Yes" : "No"}
                />
              </div>
              {onBoardDetails?.children?.length ? (
                <>
                  {onBoardDetails?.children?.map((ele) => {
                    return (
                      <>
                        <div className="row row-cols-5">
                          <div className="col">
                            <TitleValue
                              title="Name of Child"
                              value={ele?.name}
                            />
                          </div>
                          <div className="col">
                            <TitleValue title="Gender" value={ele?.gender} />
                          </div>
                          <div className="col">
                            <TitleValue
                              title="Date of Birth"
                              value={moment(ele?.dob).format("DD MMM YYYY")}
                            />
                          </div>
                        </div>
                        <div className="row row-cols-5">
                          <div className="col">
                            <TitleValue
                              title="Child Emirates ID No"
                              value={ele?.emirates_id}
                            />
                          </div>
                          <div className="col">
                            <TitleValue
                              title="Child's Emirates ID Proof"
                              value={valueSplitFunction(ele?.emirates_id_proof)}
                              valueIcon="/assets/icons/document.svg"
                              url={`${API_BASE_URL}onboarding_files/${ele?.emirates_id_proof}`}
                            />
                          </div>
                          <div className="col">
                            <TitleValue
                              title="Child Passport No"
                              value={ele?.passport_no}
                            />
                          </div>
                          <div className="col">
                            <TitleValue
                              title="Child's Passport Proof"
                              value={valueSplitFunction(ele?.passport_proof)}
                              valueIcon="/assets/icons/document.svg"
                              url={`${API_BASE_URL}onboarding_files/${ele?.passport_proof}`}
                            />
                          </div>
                        </div>
                      </>
                    );
                  })}
                </>
              ) : (
                <></>
              )}
            </div>
          </TitleBar>
          {onBoardDetails?.attachments?.length ? (
            <>
              <TitleBar
                title={"Attachments"}
                className="mt-5"
                toggleButton
                defaultToggle={"show"}
                top
              >
                <AttachmentContent files={data?.attachments} />
              </TitleBar>
            </>
          ) : (
            <></>
          )}
          <TitleBar
            title={"HR Update"}
            className="mt-4"
            toggleButton
            defaultToggle={"show"}
            top
          >
            <div className="details__inner">
              <div className="row row-cols-5">
                <div className="col">
                  <TitleValue
                    title="Employee Joined ?"
                    value={onBoardDetails?.employee_joined ? "Yes" : "No"}
                  />
                </div>
              </div>
              <div className="row row-cols-5">
                <div className="col">
                  <TitleValue
                    title="Updated in Oracle ?"
                    value={onBoardDetails?.oracle_updated ? "Yes" : "No"}
                  />
                </div>
              </div>
            </div>
          </TitleBar>

          {isEditMode && userpage == "it" ? (
            <TitleBar
              title={"IT Updates"}
              className="mt-4"
              toggleButton
              defaultToggle={"show"}
              top
            >
              <div className="details__inner">
                <div className="row">
                  <div className="col-md-6 itUpadtes">
                    <RadioButtons
                      className="css-rdb-esc2 mb-3"
                      label="Access Card provided ?"
                      buttons={[
                        { label: "Yes", value: true },
                        { label: "No", value: false },
                      ]}
                      name="access_card_provided"
                      value={{
                        access_card_provided: data.access_card_provided,
                      }}
                      error={err.access_card_provided}
                      onChange={(key, value) => onChangeHandler(key, value)}
                    />
                    <RadioButtons
                      className="css-rdb-esc2 mb-3"
                      label="Email generated ?"
                      buttons={[
                        { label: "Yes", value: true },
                        { label: "No", value: false },
                      ]}
                      name="email_generated"
                      value={{ email_generated: data.email_generated }}
                      error={err.email_generated}
                      onChange={(key, value) => onChangeHandler(key, value)}
                    />
                    <RadioButtons
                      className="css-rdb-esc2 mb-3"
                      label="PC & other configurations gathered ?"
                      buttons={[
                        { label: "Yes", value: true },
                        { label: "No", value: false },
                      ]}
                      name="pc_gathered"
                      value={{ pc_gathered: data.pc_gathered }}
                      error={err.pc_gathered}
                      onChange={(key, value) => onChangeHandler(key, value)}
                    />
                    <RadioButtons
                      className="css-rdb-esc2 mb-3"
                      label="Updated in AD ?"
                      buttons={[
                        { label: "Yes", value: true },
                        { label: "No", value: false },
                      ]}
                      name="ad_updated"
                      value={{ ad_updated: data.ad_updated }}
                      error={err.ad_updated}
                      onChange={(key, value) => onChangeHandler(key, value)}
                    />
                  </div>
                  <div className="col-md-6 generate d-flex flex-column align-items-end">
                    <Button
                      right
                      className="css-btn-esc5"
                      content="Generate ID Card"
                      onClick={() => setShow(true)}
                    />
                    {onBoardDetails?.id_card != undefined &&
                    onBoardDetails?.id_card != "" ? (
                      <Button
                        right
                        className="css-btn-esc3 mt-4"
                        icon="/assets/icons/Download2.svg"
                        reverse
                        width={50}
                        content="Idcard.pdf"
                        onClick={() => {
                          onDownloadHandler(onBoardDetails?._id);
                        }}
                        loader={download_loader}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </TitleBar>
          ) : (
            <></>
          )}
          {history?.length ? (
            <section>
              <TitleBar
                title={"History"}
                toggleButton
                defaultToggle={"hide"}
                className="mt-4"
                boxClassName="p-0"
              >
                <Table
                  className={"css-tbl-esc1"}
                  titles={[
                    { title: "Sl No", key: "sl_no", width: "6%" },
                    {
                      title: "Received Date",
                      key: "received_date",
                      width: "12%",
                      dir: "ltr",
                    },
                    {
                      title: "Last Update",
                      key: "last_update",
                      width: "11%",
                      dir: "ltr",
                    },
                    { title: "Handled By", key: "handled_by", width: "11%" },
                    { title: "Assigned To", key: "assigned_to", width: "11%" },
                    { title: "Attachments", key: "attachments", width: "10%" },
                    { title: "Status", key: "status", width: "12%" },
                    { title: "Comments", key: "comments", width: "31%" },
                  ]}
                  content={historyData}
                />
              </TitleBar>
            </section>
          ) : null}
          {isEditMode ? (
            <div className="add__comment mt-3">
              <TextArea
                className="css-txt-esc2 mb-3"
                label="Comment"
                value={data.comment}
                onChange={(e) => onChangeHandler("comment", e)}
                error={err.comment}
              />
              <FileUpload
                label="Add Attachments"
                className="css-atc-esc1"
                buttonLabel="Add Attachments"
                accept={[
                  "jpg",
                  "jpeg",
                  "png",
                  "pdf",
                  "docx",
                  "doc",
                  "xlsx",
                  "xls",
                  "csv",
                  "ppt",
                  "pptx",
                ]}
                onChange={(e, isInvalid, message) =>
                  fileSelectedHandler(e, isInvalid, message)
                }
                error={err.uploadFiles}
                id="uploadFiles"
                maxFileSizeMB={50}
                multiple={true}
                showPreview
                files={data?.uploadFiles}
              />
            </div>
          ) : (
            <></>
          )}

          <FooterButtons>
            <Button
              right
              className="css-btn-esc3"
              content="Back"
              icon="/assets/icons/Back.svg"
              reverse
              iconWidth="15px"
              onClick={() => navigate(-1)}
            />

            {isEditMode &&
              (userpage == "hr" ? (
                <Button
                  left
                  className="css-btn-esc3"
                  content={"Submit"}
                  onClick={() =>
                    onSaveHandler("submit", {
                      label: "Resubmit",
                      value: "resubmit",
                    })
                  }
                  disabled={upload_loader || loading}
                  loader={loading}
                />
              ) : (
                <Select
                  className={"css-slc-esc3"}
                  left
                  placeholder="Select"
                  options={statusOptions}
                  labelSetter={(e) => e.label}
                  valueSetter={(e) => e._id}
                  error={err.status}
                  value={status}
                  onChange={(e) => onStatusChangeHandler(e)}
                  disabled={upload_loader || loading}
                />
              ))}
            {isEditMode && userpage == "it" && (
              <Button
                left
                className="css-btn-esc3"
                content={"Save"}
                onClick={() => onSaveHandler("save")}
                disabled={upload_loader || loading}
                loader={loading}
              />
            )}
            {isEditMode && (
              <Button
                left
                className="css-btn-esc3"
                content="Cancel"
                onClick={onCancel}
                disabled={upload_loader}
              />
            )}
          </FooterButtons>
        </section>
        <Modal show={show} setShow={setShow}>
          <ModalContent
            className="idcard_generate_modal"
            width={500}
            header={
              onBoardDetails?.id_card ? "View ID Card" : "Generate ID Card"
            }
          >
            {!showPreview ? (
              <>
                <div>
                  <RadioButtons
                    className="css-rdb-esc3"
                    buttons={[
                      { label: "Use existing photo", value: "0" },
                      { label: "Upload New Photo", value: "1" },
                    ]}
                    name="new_photo"
                    value={{ new_photo: data.new_photo }}
                    error={err.new_photo}
                    onChange={(key, value) => onChangeHandler(key, value)}
                  />
                </div>
                <>
                  {data.new_photo == "1" ? (
                    <div className="upload__image">
                      <FileUpload
                        className="css-atc-esc2"
                        buttonLabel="Choose file"
                        accept={["jpeg", "jpg", "png"]}
                        onChange={(e) => singleImageSelectedHandler("photo", e)}
                        error={err.photo}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </>
                <div
                  className="d-flex justify-content-center"
                  style={{ gap: "20px" }}
                >
                  <Button
                    right
                    className="css-btn-esc3 mt-4"
                    content="Cancel"
                    onClick={() => {
                      modalCancelHandler();
                    }}
                  />
                  <Button
                    right
                    className="css-btn-esc3 mt-4"
                    content={"Next"}
                    onClick={() => {
                      if (data.new_photo == "") {
                        setErr({
                          ...err,
                          new_photo: "Please select value",
                        });
                      } else if (data.new_photo == "1" && data?.photo == "") {
                        setErr({
                          ...err,
                          photo: "Please upload photo",
                        });
                      } else {
                        handleGenerate();
                      }
                    }}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="idcardpreviewdiv">
                  <object
                    data={
                      API_BASE_URL +
                      `temp_files/${trackId}/` +
                      `${showPreviewImage}#toolbar=0&navpanes=0&scrollbar=0&view=FitH`
                    }
                    type="application/pdf"
                    className="idpreviewStyle"
                    aria-label="preview"
                  />
                </div>
                <div
                  className="d-flex justify-content-center"
                  style={{ gap: "20px" }}
                >
                  <Button
                    right
                    className="css-btn-esc3 mt-4"
                    content="Cancel"
                    onClick={() => {
                      modalCancelHandler();
                    }}
                  />
                  <Button
                    right
                    className="css-btn-esc3 mt-4"
                    content={"Submit"}
                    onClick={() => updateHandler()}
                  />
                </div>
              </>
            )}
          </ModalContent>
        </Modal>
        <Modal show={showReturnModal} setShow={() => setShowReturnModal()}>
          <ModalContent header="Return">
            <h3 className="right">{t("Reason for Return ?")}</h3>
            <TextArea
              className="css-txt-esc3"
              value={data.comment}
              onChange={(e) => onChangeHandler("comment", e)}
              error={err.comment}
            />
            <Button
              className="css-btn-esc3"
              onClick={() => {
                if (data.comment?.length != 0) {
                  updateHandler({ label: "Return", value: "return" });
                } else {
                  setErr({
                    ...err,
                    comment: t("Please Add Comment"),
                  });
                }
              }}
              content="Submit"
              disabled={loading}
            />
            <Button
              className="css-btn-esc3"
              onClick={() => {
                setShowReturnModal(false);
                setStatus("");
              }}
              content="Cancel"
            />
          </ModalContent>
        </Modal>
      </div>
    )
  );
}

export default OnboardingDetails;
