import React, { useState, useEffect } from 'react'
import './Table.scss'
import Pagination from '../Pagination/Pagination'
import useTranslate from '../../utils/useTranslate'
import { useRef } from 'react'

export default function Table({ titles = [{ title: '', width: '', key: '' }], content = [], className, sortValue, onSort, page, pagesCount, onPageChange, pageStatus, top }) {

  const [inTitles, setTitles] = useState([])
  const [data, setData] = useState([])
  const [sort, setSort] = useState({})
  const [paginationWidth, setPaginationWidth] = useState(0)

  const { t } = useTranslate()
  const tableRef = useRef()

  useEffect(() => {
    titles && setTitles(titles)
    content && setData(content)
    return () => {
      setTitles([])
      setData([])
    }
  }, [titles])

  useEffect(() => {
    setPaginationWidth(tableRef.current?.clientWidth)
  }, [inTitles])

  useEffect(() => {
    if (sortValue?.key && sortValue?.value) {
      setSort({ [sortValue.key]: sortValue.value === 1 })
    }
  }, [sortValue])

  function sortHandler(key) {
    let value = key in sort ? !sort[key] : true
    setSort({ [key]: value })
    onSort && onSort({ key: key, value: value ? 1 : -1 })
  }

  function pageChangeHandler(e) {
    onPageChange && onPageChange(e)
  }

  return (
    <div id='Table' className={className + ' ' + (top ? 'top' : '')}>
      <table ref={tableRef}>
        <thead>
          <tr>
            {
              inTitles.map((column, i) => {
                return (
                  <th
                    key={i}
                    className={column.sort ? 'sort' : ''}
                    width={column.width}
                    style={{ textAlign: column.h_align, paddingRight: column.h_align === 'center' ? '0' : '' }}
                  >
                    {column.sort && <span
                      className={column.key in sort ? (sort?.[column.key] ? 'sort__asc' : 'sort__dsc') : ''}
                      onClick={() => sortHandler(column.key)}></span>}
                    {t(column.title)}
                  </th>
                )
              })
            }
          </tr>
        </thead>
        <tbody>
          {
            data && data.map((row, row_index) => {
              return (
                <tr key={row_index}>
                  {
                    inTitles.map((column, col_index) => {
                      return (
                        <td
                          className={column.key}
                          key={col_index}
                          dir={column.dir}
                          style={{ textAlign: column.d_align, paddingRight: column.d_align === 'center' ? '0' : '' }}
                        >
                          {row[column.key]}
                        </td>
                      )
                    })
                  }
                </tr>
              )
            })
          }
        </tbody>
      </table>
      {
        data.length === 0 && inTitles.length !== 0
          ? <p className='no__data'>{t("No Data Available")}</p>
          : <Pagination page={page} pageCount={pagesCount} pageStatus={pageStatus} onChange={pageChangeHandler} width={paginationWidth} />
      }
    </div >
  )
}


{/*

<Table
  className='css-tbl-esc1'
  titles={[
    { title: '', key: '', width: '', h_align: '', d_align: '' },
  ]}
  content={[{},{}]}
  sortValue={{ key: '', value: '' }}
  onSort={() => { }}
  page={''}
  pagesCount={''}
  onPageChange={() => { }}
  pageStatus={''}
  top
/>

*/}