import React from 'react'
import './RequestDetailsData.scss'
import TitleValue from '../../commonComponents/TitleValue/TitleValue'
import moment from 'moment'
import useTranslate from '../../utils/useTranslate'
import DateTimeViewer from '../../commonComponents/DateTimeViewer/DateTimeViewer'

export default function RequestDetailsData({ data }) {

    const { t } = useTranslate()

    return (
        <div id='RequestDetailsData'>
            <article>
                {data?.request_no && <TitleValue title={t("Request No")} value={data?.request_no} />}
                {data?.request_date && <TitleValue title={t("Requested Date")} value={<DateTimeViewer dateTime={moment(data?.request_date)} dateOnly dir={'ltr'} />} />}
                {data?.request_type?.request_type && <TitleValue title={t("Certificate Type")} value={data?.request_type?.request_type} />}
                {data?.certificate_langage && <TitleValue title={t('Certificate Language')} value={data?.certificate_langage} />}
                {data?.certificate_addressee && <TitleValue title={t('Addressee')} value={data?.certificate_addressee} />}
                {data?.request_comment && <TitleValue title={t('Comment')} value={data?.request_comment} />}
            </article>
        </div>
    )
}
