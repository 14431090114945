import React from "react";
import "./TitleValue.scss";
import useTranslate from "../../utils/useTranslate";

export default function TitleValue({ title, value, dir, className, children, error, valueIcon, url, }) {

  const { t } = useTranslate();

  const Value = () => {
    return (
      <p className="value" dir={dir}>
        {valueIcon && value && <img src={valueIcon} alt="icon" />}
        {value || (children ? '' : "--")}
      </p>
    )
  }

  return (
    <span id="TitleValue" className={className || ""}>
      <p className="key">{t(title) || "title"}</p>
      {(url && value)
        ? <a href={url} target="_blank" rel="noreferrer"> <Value /> </a>
        : <Value />
      }
      {children && <span className="inputs">{children}</span>}
      {error && <small id='error' className="error">{error}</small>}
    </span>
  );
}