import { Modal } from "react-bootstrap";
import { API_BASE_URL } from "../../constants/configuration";

const FileViewModal = (props) => {
  const {
    modalshow,
    setModalShow,
    fileView,
    oldFileView,
    url,
    fileType,
    fileNumber,
    setOldFileView,
    setFileView,
    setFileNumber,
    setFileType,
  } = props;

  return (
    // console.log(fileView),
    <Modal show={modalshow} onHide={() => setModalShow(false)} centered>
      <Modal.Body>
        {fileView != null ? (
          <>
            <div>
              {fileView?.type === "image/jpg" ||
                fileView?.type === "image/jpeg" ||
                fileView?.type === "image/webp" ||
                fileView?.type === "image/png" ? (
                <img
                  className="file img-full"
                  src={URL.createObjectURL(fileView)}
                  alt=""
                />
              ) : fileView.type === "video/mp4" ? (
                <video
                  src={URL.createObjectURL(fileView)}
                  width="320"
                  height="240"
                  controls
                ></video>
              ) : fileView.type === "application/pdf" ? (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <a href={`${URL.createObjectURL(fileView)}`} target="_blank">
                    <img
                      style={{ width: 100 }}
                      src="/assets/icons/files/pdf.svg"
                      alt=""
                    />
                   
                  </a>
                </div>
              ) : null}
            </div>
            {/* <p className="file-name">{fileView?.name}</p> */}
          </>
        ) : null}
        {oldFileView != null ? (
          <>
            <div  className="file-div p-0 show_image">
              {["pdf"].includes(oldFileView.split(".").reverse()[0]) ? (
                <>
                  <span className="">
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <a
                        href={`${API_BASE_URL}${url}/${oldFileView}`}
                        target="_blank"
                      >
                        <img
                          // className="file"
                          src="/assets/icons/files/pdf.svg"
                          alt=""
                          style={{ width: 100, marginBottom: 15 }}
                        />
                      </a>
                    </div>
                  </span>
                </>
              ) : ["jpg", "jpeg", "webp", "png"].includes(
                oldFileView.split(".").reverse()[0]
              ) ? (
                <>
                  <img
                    className="file"
                    src={`${API_BASE_URL}${url}/${oldFileView}`}
                    alt=""
                  />
                </>
              ) : ["mp4"].includes(oldFileView.split(".").reverse()[0]) ? (
                <>
                  <video
                    src={`${API_BASE_URL}${url}/${oldFileView}`}
                    width="320"
                    height="240"
                    controls
                  ></video>
                </>
              ) : null}
            </div>
          </>
        ) : null}
        {/* {fileType ? <p className="file-name">File type : {fileType}</p> : null}
        {fileNumber ? (
          <p className="file-name">File number :{fileNumber}</p>
        ) : null} */}
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-danger close-icon"
          onClick={() => {
            setOldFileView(null);
            setFileView(null);
            setFileNumber(null);
            setFileType(null);
            setModalShow(false);
          }}
        >
        <img src="/assets/icons/Close_circle.svg" alt="" />
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default FileViewModal;
