import React, { useEffect, useState } from 'react'
import './TextArea.scss'
import { v4 } from 'uuid'
import useTranslate from '../../utils/useTranslate'

export default function TextArea({ label, value, onChange, placeholder, className, error }) {

  let uuid = v4()
  const { t } = useTranslate()

  const [inValue, setInValue] = useState('')
  const [inLabel, setInLabel] = useState('')
  const [inPlaceholder, setInPlaceholder] = useState('')

  useEffect(() => {
    value && setInValue(value)
    placeholder && setInPlaceholder(placeholder)
    label && setInLabel(label)
    return () => {
      setInValue('')
      setInPlaceholder('')
      setInLabel('')
    }
  }, [value, placeholder, label])

  function onChangeHandler(e) {
    setInValue(e.target.value)
    onChange && onChange(e.target.value)
  }

  return (
    <div id='TextArea' className={className}>
      {inLabel && <label htmlFor={uuid}>{t(inLabel)}</label>}
      <textarea
        id={uuid}
        value={inValue}
        placeholder={inPlaceholder}
        onChange={onChangeHandler}
      />
      {error && <small id='error' className='error'>{error}</small>}
    </div>
  )
}


{/* 

<TextArea
  className={'css-txt-esc1'}
  label={'Comment'}
  error={'error message'}
  placeholder={'Comment here'}
  value={''}
  onChange={(e) => { }}
/> 

*/}