import React, { useEffect, useState } from "react";
// import "../style.scss";
// import "../ManageIncidents/incidents.scss";
import { Form, Spinner, } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  activity_log_list,
  activity_log_page_status,
  activity_log_total_count,
  log_from_date,
  log_list_loading,
  log_page,
  log_per_page,
  log_sort_ascending,
  log_sort_column,
  log_to_date,
  updateRedux,
} from "../../redux/commonReducer";
import {
  getIncidentUserLog,
  getUserActivityLog,
} from "../../redux/actionCreator";
// import Pagination from "../../../components/Pagination/Pagination";
// import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Table from "../../commonComponents/Table/Table";
// import useTrans from "../../../utils/useTrans";

const ActivityLogData = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  //   const { t } = useTrans();
  const t = text => text

  const [data, setData] = useState([])

  const logData = useSelector(activity_log_list);
  const loading = useSelector(log_list_loading);
  const pageCount = useSelector(activity_log_total_count);
  const pageStatus = useSelector(activity_log_page_status);
  const sortColumn = useSelector(log_sort_column);
  const sortAscending = useSelector(log_sort_ascending);
  const page = useSelector(log_page);
  const perPage = useSelector(log_per_page);
  const fromDate = useSelector(log_from_date);
  const toDate = useSelector(log_to_date);

  useEffect(() => {
    if (id) {
      let value = {
        userId: id,
        page: 0,
        from_date: fromDate ? moment(fromDate).format("YYYY-MM-DD") : "",
        to_date: toDate ? moment(toDate).format("YYYY-MM-DD") : "",
        sort_key: sortColumn,
        sort_order: sortAscending ? "1" : "-1",
        perPage: perPage,
      };
      dispatch(getUserActivityLog(value));
    }
  }, [id]);

  useEffect(() => {
    setData([]);
    if (logData) {
      logData?.forEach((item, i) => {
        setData((s) => [...s,
        {
          date: moment(item?.time).format("DD MMM YYYY"),
          time: moment(item?.time?.split(".")[0]).format("hh:mm:ss A"),
          module: item?.action_module,
          action: <>
            {`${item?.action_type} `}
            <span style={{ color: "gray" }}>
              ({`${item?.ticket_no} ${item?.secondary_ticket_no ? `- ${item?.secondary_ticket_no}` : ""}`})
            </span>
          </>
        }
        ]);
      });
    }
  }, [logData])


  const tableHeaders = [
    { name: t("Date"), sort_key: "date" },
    { name: t("Time"), sort_key: "time" },
    { name: t("Module"), sort_key: "action_module" },
    { name: t("Action"), sort_key: "action_type" },
  ];

  const getSortingClassName = (title) => {
    let className = "";
    if (sortColumn === title) {
      if (sortAscending) {
        className = "active";
      } else {
        className = "inactive";
      }
    }
    return className;
  };

  const handlePageChange = (e) => {
    dispatch(updateRedux({ key: "log_page", value: e.selected }));
    let value = {
      userId: id,
      page: e.selected,
      from_date: fromDate ? moment(fromDate).format("YYYY-MM-DD") : "",
      to_date: toDate ? moment(toDate).format("YYYY-MM-DD") : "",
      sort_key: sortColumn,
      sort_order: sortAscending ? "1" : "-1",
      perPage: perPage,
    };
    dispatch(getUserActivityLog(value));
  };

  const onSortHandler = (type) => {
    dispatch(updateRedux({ key: "log_sort_ascending", value: !sortAscending }));
    dispatch(updateRedux({ key: "log_sort_column", value: type.key }));
    let value = {
      userId: id,
      page: 0,
      from_date: fromDate ? moment(fromDate).format("YYYY-MM-DD") : "",
      to_date: toDate ? moment(toDate).format("YYYY-MM-DD") : "",
      sort_key: type.key,
      sort_order: !sortAscending ? "1" : "-1",
      perPage: perPage,
    };
    dispatch(getUserActivityLog(value));
  };

  console.log({ data })

  return (
    <>
      <section className="table">
        <Table className={'css-tbl-esc2'}
          titles={[
            { title: "Date", key: "date", sort: true, dir: 'ltr' },
            { title: "Time", key: "time", sort: true, dir: 'ltr' },
            { title: "Module", key: "module", sort: true },
            { title: "Action", key: "action", width: '400px' },
          ]}
          content={data}
          onSort={(e) => onSortHandler(e)}
          sortValue={{ key: sortColumn, value: sortAscending ? 1 : -1 }}
          page={page}
          pagesCount={pageCount}
          pageStatus={pageStatus}
          onPageChange={handlePageChange}
        />
      </section>
    </>
  );
};

export default ActivityLogData;
