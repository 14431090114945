export const validator = async (errState, items) => {
    return Promise.all(
        items.map(async (item) => {
            return await item.then((e) => e).catch((e) => e)
        })
    ).then((e) => {
        let obj = {}
        e.forEach(item => { if (!obj[Object.keys(item)[0]]) Object.assign(obj, item) })
        errState(obj)
        let isTrue = Object.values(obj).every(item => item === '')
        setTimeout(() => { if (!isTrue) document.getElementById('error')?.scrollIntoView({ behavior: 'auto', block: 'center', inline: 'nearest' }) }, 0);
        return isTrue
    })
}

export function minCharLength({ obj, key, errMessage, minLength, condition }) {
    let text = obj[key] || ''
    if (condition === undefined) condition = true
    return new Promise((resolve, reject) => {
        if (condition) {
            if (text.trim().length < minLength || 0) reject({ [key]: errMessage || `Minimum ${minLength} characters required` })
            else resolve({ [key]: '' })
        } else resolve({ [key]: '' })
    })
}

export function maxCharLength({ obj, key, errMessage, maxLength, condition }) {
    let text = obj[key] || ''
    if (condition === undefined) condition = true
    return new Promise((resolve, reject) => {
        if (condition) {
            if (text.trim().length <= maxLength || 0) resolve({ [key]: '' })
            else reject({ [key]: errMessage || `You have exceeded the maximum limit of ${maxLength} characters` })
        } else resolve({ [key]: '' })
    })
}

export function requiredField({ obj, key, errMessage, condition }) {
    if (condition === undefined) condition = true
    return new Promise((resolve, reject) => {
        if (condition) {
            if (isValueExist(obj[key])) resolve({ [key]: '' })
            else reject({ [key]: errMessage || 'Required field' })
        } else resolve({ [key]: '' })
    })
}

export function validEmail({ obj, key, errMessage, condition }) {
    let text = obj[key] || ''
    if (condition === undefined) condition = true
    return new Promise((resolve, reject) => {
        if (condition) {
            let regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/
            if (regex.test(text.trim())) resolve({ [key]: '' })
            else reject({ [key]: errMessage || 'Invalid email' })
        } else resolve({ [key]: '' })
    })
}

export function alphabetsOnly({ obj, key, errMessage, condition, lang, more }) {
    let text = obj[key] || ''
    let alphabets = lang === 'ar' ? 'ء-ي' : lang === 'en' ? 'a-zA-Z' : 'a-zA-Zء-ي'
    if (condition === undefined) condition = true
    return new Promise((resolve, reject) => {
        if (condition) {
            let regex = new RegExp(`^[${alphabets}${more || ''}]*$`)
            if (regex.test(text.trim())) resolve({ [key]: '' })
            else reject({ [key]: errMessage || 'Should have only alphabets' })
        } else resolve({ [key]: '' })
    })
}

export function alphaNumeric({ obj, key, errMessage, more, condition }) {
    let text = obj[key] || ''
    if (condition === undefined) condition = true
    return new Promise((resolve, reject) => {
        if (condition) {
            let regex = new RegExp(`^[a-zA-Z0-9${more || ''}]*$`)
            if (regex.test(text.trim())) resolve({ [key]: '' })
            else reject({ [key]: errMessage || 'Invalid characters' })
        } else resolve({ [key]: '' })
    })
}

export function validPhoneNumber({ obj, key, errMessage, digits, condition }) {
    let text = obj[key] || ''
    if (condition === undefined) condition = true
    return new Promise((resolve, reject) => {
        if (condition) {
            let regex = new RegExp(`^[0-9]{${digits || 10}}$`)
            if (regex.test(text.trim())) resolve({ [key]: '' })
            else reject({ [key]: errMessage || 'Not a valid phone number' })
        } else resolve({ [key]: '' })
    })
}

export function checkIfTrue({ obj, key, errMessage, condition }) {
    let value = obj[key] || false
    if (condition === undefined) condition = true
    return new Promise((resolve, reject) => {
        if (condition) {
            if (value) resolve({ [key]: '' })
            else reject({ [key]: errMessage || 'Required to be checked' })
        } else resolve({ [key]: '' })
    })
}

export function validDate({ obj, key, errMessage, condition }) {
    let date = obj[key] || new Date()
    if (condition === undefined) condition = true
    return new Promise((resolve, reject) => {
        if (condition) {
            if (isDate(date)) resolve({ [key]: '' })
            else reject({ [key]: errMessage || 'Invalid date' })
        } else resolve({ [key]: '' })
    })
}

export function minDate({ obj, key, errMessage, minDate, condition }) {
    let date = obj[key] || new Date()
    let min = new Date(minDate)
    let startDate = min.toISOString().split('T')[0]
    startDate = new Date(startDate)
    startDate = `${startDate.getDate()}/${startDate.getMonth() + 1}/${startDate.getFullYear()}`
    if (condition === undefined) condition = true
    return new Promise((resolve, reject) => {
        if (condition) {
            if (isDate(min)) {
                if (date >= min) resolve({ [key]: '' })
                else reject({ [key]: errMessage || `Date before ${startDate} is not acceptable` })
            } else reject({ [key]: 'Invalid min date' })
        } else resolve({ [key]: '' })
    })
}

export function maxDate({ obj, key, errMessage, maxDate, condition }) {
    let date = obj[key] || new Date()
    let min = new Date(maxDate)
    let endDate = min.toISOString().split('T')[0]
    endDate = new Date(endDate)
    endDate = `${endDate.getDate()}/${endDate.getMonth() + 1}/${endDate.getFullYear()}`
    if (condition === undefined) condition = true
    return new Promise((resolve, reject) => {
        if (condition) {
            if (isDate(min)) {
                if (date <= min) resolve({ [key]: '' })
                else reject({ [key]: errMessage || `Date after ${endDate} is not acceptable` })
            } else reject({ [key]: 'Invalid max date' })
        } else resolve({ [key]: '' })
    })
}

export function isValueExist(text) {
    let value
    if (Array.isArray(text)) value = text
    else if (typeof text === 'string') value = text.trim()
    else if (typeof text === 'object') value = JSON.stringify(text).replace(/^\{/, '').replace(/\}$/, '').trim()
    else value = ''
    return value.length !== 0
}

function isDate(dateToTest) {
    return !isNaN(Date.parse(dateToTest))
}

// checkIfAnyExist({ obj: inputs, key: 'allFields', fields: ['access_card_provided', 'email_generated', 'pc_gathered', 'ad_updated', 'comment'] })
// export function checkIfAnyExist({ obj, key, fields, errMessage, condition }) {
//     let acc = {}
//     fields.forEach(item => { acc = { ...acc, [item]: obj[item] } })
//     let values = Object.values(acc)
//     // values.every
//     console.log(values)
//     if (condition === undefined) condition = true
//     return new Promise((resolve, reject) => {
//         if (condition) {
//             // if (noData) resolve({ [key]: '' })
//             // else reject({ [key]: errMessage || 'No changes found' })
//         } else resolve({ [key]: '' })
//     })
// }