import React, { useState } from 'react'
import './AdvancedSearch.scss'
import Select from '../../commonComponents/Select/Select'

export default function AdvancedSearch({ className, onChange, advancedFilter }) {

  const [selections, setSelections] = useState(advancedFilter)

  function changeHandler(e) {
    if (selections.some(item => JSON.stringify(e) === JSON.stringify(item))) return
    setSelections(s => {
      let value = [...s, e]
      onChange && onChange(value)
      return value
    })
  }

  function deleteHandler(e) {
    setSelections(selections.filter(item => JSON.stringify(e) !== JSON.stringify(item)))
    onChange(selections.filter(item => JSON.stringify(e) !== JSON.stringify(item)))
  }

  return (
    <div id='AdvancedSearch' className={className}>
      <Select
        className={'css-slc-esc2'}
        title={'Advance Filter'}
        style={{ width: '300px' }}
        showToggleBtn
        options={[
          { label: 'Department', value: 'department' },
          { label: 'Section', value: 'section' },
          { label: 'Employee', value: 'employee' },
          { label: 'Employee ID', value: 'employee_id' },
          { label: 'Request Type', value: 'request_type' },
          { label: 'Requested Date', value: 'request_date' },
          { label: 'Last Update', value: 'last_update' },
          { label: 'Resolution Time', value: 'resolution_time' },
          { label: 'Status', value: 'status' },
          { label: 'Handled By', value: 'handled_by' },
          { label: 'Assigned To', value: 'assigned_to' },
          { label: 'Certificate Language', value: 'certificate_language' },
          { label: 'Addressee', value: 'addressee' },
        ].filter(item=> selections.every(obj=> obj.value !== item.value))}
        labelSetter={(e) => e.label}
        valueSetter={(e) => e.value}
        onChange={changeHandler}
      // onToggle={() => { }}
      />
      <article className="items">
        {
          selections.map((item, i) => {
            return (
              <span key={i}>
                <p>{item.label}</p>
                <img src="/assets/icons/close.svg" alt="" onClick={() => { deleteHandler(item) }} />
              </span>
            )
          })
        }
      </article>
    </div>
  )
}




{/*

<AdvancedSearch
  className='mt-3'
  onChange={() => { }}
/>

*/}