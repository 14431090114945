import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { deleteRequestType, getRequestType, insertRequestType, updateRequestType } from "../../redux/actionCreator";
import { request_type_details, request_type_loading, update_request_type_loading } from "../../redux/commonReducer";
import { useState } from "react";
import Modal from "../../commonComponents/Modal/Modal";
import Title from "../../commonComponents/Title/Title";
import Breadcrump from "../../commonComponents/Breadcrump/Breadcrump";
import Button from "../../commonComponents/Button/Button";
import Input from "../../commonComponents/Input/Input";
import Table from "../../commonComponents/Table/Table";
import Actions from "../../commonComponents/Actions/Actions";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import useTranslate from "../../utils/useTranslate";
import PageHeader from "../../commonComponents/PageHeader/PageHeader";
import ModalContent from "../../commonComponents/ModalContent/ModalContent";

export default function CertificateTypeList() {
  const dispatch = useDispatch();
  const { t } = useTranslate()

  const certficateTypeData = useSelector(request_type_details);
  const loading = useSelector(request_type_loading);
  const updateLoading = useSelector(update_request_type_loading)

  const [showAddModal, setShowAddModal] = useState(false);
  const [addDetails, setAddDetails] = useState({
    request_type: "",
    request_type_ar: "",
  });
  const [err, setErr] = useState({})
  const [edit, setEdit] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteID, setDeleteID] = useState("");
  const [data, setData] = useState([])

  const [keyword, setKeyword] = useState("")

  useEffect(() => {
    dispatch(getRequestType());
  }, []);

  const onAddChangeHandler = (key, value) => {
    setAddDetails({
      ...addDetails,
      [key]: value,
    });
  };

  const validate = () => {
    let obj = {};
    if (addDetails?.request_type?.trim()?.length == 0) {
      obj = { ...obj, request_type: "please enter certificate type in english" };
    }
    if (addDetails?.request_type_ar?.trim()?.length == 0) {
      obj = { ...obj, request_type_ar: "please enter certificate type in arabic" };
    }
    if (Object.values(obj).length != 0) {
      setErr(obj);
      return false;
    } else {
      setErr({});
      return true;
    }
  };

  const onAddHandler = () => {
    if (validate()) {
      if (!edit) {
        let value = {
          request_type: addDetails?.request_type,
          request_type_ar: addDetails?.request_type_ar,
        }
        dispatch(insertRequestType(value, () => {
          handleCloseAddModal()
        }))
      } else {
        let value = {
          id: addDetails?._id,
          request_type: addDetails?.request_type,
          request_type_ar: addDetails?.request_type_ar,
        }
        dispatch(updateRequestType(value, () => {
          handleCloseAddModal()
        }))
      }

    }
  }

  const handleCloseAddModal = () => {
    setAddDetails({
      request_type: "",
      request_type_ar: "",
      _id: ""
    })
    setErr({})
    setEdit(false)
    setShowAddModal(false)
  }

  const editHandler = (ele) => {
    setAddDetails({
      _id: ele?._id,
      request_type: ele?.request_type,
      request_type_ar: ele?.request_type_ar,
    })
    setEdit(true)
    setShowAddModal(true)
  };

  const showDeletePopup = (id) => {
    setShowDeleteModal(true);
    setDeleteID(id);
  };

  const deleteHandler = () => {
    const value = {
      id: deleteID,
    };
    dispatch(
      deleteRequestType(value, () => {
        setShowDeleteModal(false)
      })
    );
  };

  const titles = [
    {
      title: "Sl No",
      key: "sl_no",
      width: "5%",
    },
    {
      title: "Certificate Type in English",
      key: "certificate_type",
      width: "100px",
    },
    {
      title: "Certificate Type in Arabic",
      key: "certificate_type_arabic",
      width: "100px",
    },
    { title: "Action", key: "action", width: "8%" },
  ];

  useEffect(() => {
    setData([]);
    if (certficateTypeData) {
      certficateTypeData?.forEach((item, index) => {
        setData((s) => [
          ...s,
          {
            sl_no: index + 1,
            certificate_type: item?.request_type,
            certificate_type_arabic: item?.request_type_ar,
            action: <Actions
              rootPath='/assets/icons/'
              buttons={{ edit: 'Edit.svg', delete: 'delete.svg' }}
              onEdit={() => editHandler(item)}
              onDelete={() => showDeletePopup(item?._id)}
            />,
          },
        ]);
      });
    }
  }, [certficateTypeData]);

  const applySearch = () => {
    let formData = new FormData()
    keyword && formData.append("keyword", keyword)
    dispatch(getRequestType(keyword ? formData : null));
  }


  return (
    <div id="CertificateRequests_admin_report">
      {
        loading ? <LoadingSpinner height="600px" /> :
          <>
            <Modal show={showAddModal} setShow={() => handleCloseAddModal()} >
              <ModalContent header='Edit Certificate Type'>
                <Input type='text' className="css-inp-esc1 w-100" placeholder={t('Enter Certificate Type')} label={t('Certificate Type English')} error={err.request_type}
                  value={addDetails?.request_type} field={'request_type'} onChange={(value, key) => onAddChangeHandler(key, value)}
                />
                <Input type='text' className="css-inp-esc1 w-100" placeholder={t('Enter Certificate Type')} label={t('Certificate Type Arabic')} error={err.request_type_ar}
                  value={addDetails?.request_type_ar} field={'request_type_ar'} onChange={(value, key) => onAddChangeHandler(key, value)}
                />
                <Button className="css-btn-esc3" content={t("Submit")} onClick={onAddHandler} disabled={updateLoading} />
                <Button className="css-btn-esc3" content={t("Cancel")} onClick={handleCloseAddModal} />
              </ModalContent>
            </Modal>
            <Modal show={showDeleteModal} setShow={() => setShowDeleteModal()} >
              <ModalContent header='Delete Certificate Type'>
                <h3 className="mb-3">{t("Are you sure you want to delete ?")}</h3>
                <Button className="css-btn-esc3" onClick={deleteHandler} content={t("Yes")} disabled={updateLoading} />
                <Button className="css-btn-esc3" onClick={() => setShowDeleteModal(false)} content={t("No")} />
              </ModalContent>
            </Modal>
            <section>
              <PageHeader>
                <Title content="Certificate Type" />
                <Breadcrump content={[
                  { name: "Certificate Type", path: "/admin/settings/certificate_type" },
                  { name: "Settings", path: "/admin/settings/certificate_type" },
                  { name: "Home", path: "/admin" }]} />
                <Button className="css-btn-esc1" content={t("Add Certificate Type")} icon="/assets/icons/Add Request.svg" reverse onClick={() => setShowAddModal(true)} />
              </PageHeader>
            </section>
            <section className="d-flex justify-content-end">
              <Input value={keyword} onChange={value => setKeyword(value)} type='text' className="css-inp-esc2 search" icon='/assets/icons/Search1.svg' placeholder={t('Generic Search')} reverse width='300px'
                onSubmit={(value) => { applySearch() }} onIconClick={applySearch}
              />
            </section>
            <section className="table">
              <Table className={'css-tbl-esc2'}
                titles={titles}
                content={data}
              />
            </section>
          </>
      }
    </div>
  );
}