import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateRedux } from '../redux/commonReducer'
import { useLocation } from 'react-router'
import { useNavigate } from 'react-router-dom'

export default function useFilter({ initialFilter, dataFetch, dependency = [] }) {

    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()

    let path = location.pathname
    let key = path.split('/').join('')
    let prevRoute = useSelector(s => s.commonReducer.prevRoute)
    let preFilter = useSelector(s => s.commonReducer[key])
    let pageInside = useSelector(s => s.commonReducer.pageInside)

    const [data, setData] = useState(initialFilter)

    console.log(pageInside)

    useEffect(() => {
        if (prevRoute?.curr === path) {
            if (prevRoute && pageInside && prevRoute?.prev.includes(pageInside)) {
                if (preFilter) {
                    setData(preFilter)
                    dataFetch && dataFetch(preFilter)
                } else dataFetch && dataFetch(initialFilter)
            } else dataFetch && dataFetch(initialFilter)
        }
    }, [preFilter, prevRoute, pageInside, ...dependency])

    function navigationHandler(route) {
        dispatch(updateRedux({ key: key, value: data }))
        dispatch(updateRedux({ key: 'pageInside', value: route }))
        navigate(route)
    }

    return [data, setData, navigationHandler]
}