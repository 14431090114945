import React from 'react'
import './TitleBar.scss'
import { useState } from 'react'
import { useEffect } from 'react'
import useTranslate from '../../utils/useTranslate'

export default function TitleBar({ title, toggleButton, defaultToggle, top, className, boxClassName, children }) {

  const { t } = useTranslate()

  const [inTitle, setTitle] = useState('')
  const [expandToggle, setExpandToggle] = useState(false)

  useEffect(() => {
    title && setTitle(title)
    defaultToggle === 'show' && setExpandToggle(true)
    return () => {
      setTitle('')
      setExpandToggle(false)
    }
  }, [title, defaultToggle])

  return (
    <div>
      <div id='TitleBar' className={className || '' + (top ? ' top' : '')}>
        <h3>{t(inTitle)}</h3>
        {toggleButton &&
          <img className={expandToggle ? 'tb__expand' : 'tb__collapse'}
            onClick={() => setExpandToggle(s => !s)}
            src="/assets/icons/Icon ionic-ios-arrow-down tan.svg" alt="" />
        }
      </div>
      {children &&
        <section className={'expandable__section ' + boxClassName + ((expandToggle || !toggleButton) ? ' tb__show' : ' tb__hide')}>
          {children}
        </section >
      }
    </div>
  )
}


{/*

<TitleBar
  title={'History'}
  toggleButton
  defaultToggle={'hide' || 'show'}
>
</TitleBar>

*/}