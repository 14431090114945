import React, { useRef, useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useDispatch, useSelector } from "react-redux";
import { getDynamicTexts, login } from "../../redux/actionCreator";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { login_loading } from "../../redux/commonReducer";
import { useEffect } from "react";

export default function Login() {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const pwdInput = useRef()


  const loading = useSelector(login_loading)

  useEffect(() => {
    if (localStorage.getItem("token")) {
      navigate(-1)
    }
  }, [])

  const handleSubmit = () => {
    if (username?.trim() == "" || password?.trim() == "") {
      toast.error("Please enter username and password", {
        position: "bottom-center",
        autoClose: 3000,
      });
    } else {
      const data = {
        username,
        password,
      };
      dispatch(login(data, (msg) => {
        if (msg == true) {
          dispatch(getDynamicTexts())
          navigate("/human_resource/certificate_requests");
          toast.success("Login successfully", {
            position: "bottom-center",
            autoClose: 3000,
          });
        }
      }));
    }
  };

  const onUsernamekeyPress = (e) => {
    if (e.key == "Enter") {
      pwdInput.current.focus()
    }
  };

  const onEnterLogin = (e) => {
    if (e.key == "Enter") {
      handleSubmit();
    }
  };



  return (
    <>
      <section className="hr__login">
        <img className="logo" src="/assets/icons/Option-1.svg" alt="" />
        <div className="container">
          <div className="row vh-100 justify-content-center">
            <div className="col-md-6">
              <div className="login__form">
                <div className="mb-3">
                  <h3>Sign In</h3>
                </div>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Control
                    type="username"
                    onChange={(e) => setUsername(e.target.value)}
                    placeholder="Username"
                    autoComplete="off"
                    onKeyDown={(e) => onUsernamekeyPress(e)}
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Control
                    type="password"
                    ref={pwdInput}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                    autoComplete="off"
                    onKeyDown={(e) => onEnterLogin(e)}
                  />
                </Form.Group>
                <div className="mb-3">
                  <button
                    className="btn btn-primary"
                    type="submit"
                    onClick={handleSubmit}
                    disabled={loading}
                  >
                    {loading ? <Spinner animation="border" role="status" size="sm"></Spinner> : 'Login'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
