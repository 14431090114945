import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteCertificateRequest,
  downloadCertificate,
  getAllUsersForHR,
  getCertificateRequests,
  getDepartment,
  getRequestStatus,
  getRequestStatusForAdmin,
  getRequestType,
  getSection,
} from "../../../../redux/actionCreator";
import {
  all_hr_users_list,
  certificate_loading,
  certificate_page_status,
  certificate_pages,
  certificate_request_list,
  department_details,
  request_status_details,
  request_status_details_for_admin,
  request_type_details,
  updateRedux,
} from "../../../../redux/commonReducer";
import { useState } from "react";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import Button from "../../../../commonComponents/Button/Button";
import Table from "../../../../commonComponents/Table/Table";
import StatusBox from "../../../../commonComponents/StatusBox/StatusBox";
import Actions from "../../../../commonComponents/Actions/Actions";
import Input from "../../../../commonComponents/Input/Input";
import EntriesCounter from "../../../../commonComponents/EntriesCounter/EntriesCounter";
import Title from "../../../../commonComponents/Title/Title";
import Breadcrump from "../../../../commonComponents/Breadcrump/Breadcrump";
import SearchBox from "../../../../commonComponents/SearchBox/SearchBox";
import LoadingSpinner from "../../../../components/LoadingSpinner/LoadingSpinner";
import { toast } from "react-toastify";
import { statusLabelSetter } from "../../../../utils/helpers";
import useTranslate from "../../../../utils/useTranslate";
import DateTimeViewer from "../../../../commonComponents/DateTimeViewer/DateTimeViewer";
import PageHeader from "../../../../commonComponents/PageHeader/PageHeader";
import { APPROVE, CLOSE, REJECT } from "../../../../constants/configuration";

export default function HumanResource_admin() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslate()

  const certificateRequestData = useSelector(certificate_request_list);
  const pageCount = useSelector(certificate_pages);
  const pageStatus = useSelector(certificate_page_status);
  const requestTypeList = useSelector(request_type_details);
  const departmentList = useSelector(department_details);
  const userList = useSelector(all_hr_users_list);
  const requestStatus = useSelector(request_status_details);
  const requestStatusForAdmin = useSelector(request_status_details_for_admin);
  const loading = useSelector(certificate_loading);

  const [data, setData] = useState([]);
  const [sortAscending, setSortAscending] = useState(false);
  const [sortColumn, setSortColumn] = useState("last_update");
  const [page, setPage] = useState(0);
  const [filterData, setFilterData] = useState({
    request_type: "",
    start_date: "",
    end_date: "",
    department: "",
    user: "",
    status: "",
    keyword: "",
  });
  const [appliedFilterData, setAppliedFilterData] = useState({
    request_type: "",
    start_date: "",
    end_date: "",
    department: "",
    user: "",
    status: "",
    keyword: "",
  });
  const [perPage, setPerPage] = useState(10);
  const [delId, setDelId] = useState("");
  const [closeComment, setCloseComment] = useState("")
  const [modalErr, setModalErr] = useState("")

  useEffect(() => {
    let currPage = localStorage.getItem('curr_page') ?? 0;
    if (currPage === 0) {
      getDataHandler()
    } else {
      currPage && handlePageChange({ selected: Number(currPage) })
    }
    setTimeout(() => {
      localStorage.setItem('curr_page', 0)
    }, 500);



    dispatch(getRequestStatus())
    dispatch(getRequestStatusForAdmin())
  }, []);

  useEffect(() => {
    dispatch(getRequestType());
    dispatch(getDepartment())
    dispatch(getAllUsersForHR())
  }, []);

  function currPageSetter() {
    localStorage.setItem('curr_page', page)
  }

  useEffect(() => {
    setData([]);
    if (certificateRequestData) {
      certificateRequestData?.forEach((item, i) => {
        setData((s) => [...s,
        {
          request_no: item?.request_no,
          employee: item?.request_user[0]?.name,
          department: item?.request_user[0]?.department?.department_name,
          request_type: item?.request_type?.request_type,
          request_date: <DateTimeViewer dateTime={item?.request_date} />,
          last_update: <DateTimeViewer dateTime={item?.last_update} />,
          handled_by: (item?.status?.handle_key == "close" && item?.handled_by?.length === 0) ? "System" : item?.handled_by?.length ? item?.handled_by[0]?.name : "-",
          status: <StatusBox status={item?.status?.handle_key == "return" ? "Need Info" : item?.status?.request_status} />,
          action: <Actions rootPath='/assets/icons/'
            buttons={{ edit: (item?.status?.handle_key !== "return" && item?.status?.handle_key !== "close" && item?.status?.handle_key !== "approve" && item?.status?.handle_key !== "reject") ? 'Edit.svg' : 'Edit Grey.svg', view: 'Eye-Open.svg', download: 'Group 12237.svg' }}
            visibility={{ download: item?.status?.handle_key == "approve" }}
            onView={() => { navigate(`/admin/human_resource/certificate_requests/request_details/${item?._id}/view`); currPageSetter() }}
            onEdit={() => {
              currPageSetter()
              return (item?.status?.handle_key !== "return" && item?.status?.handle_key !== "close" && item?.status?.handle_key !== "approve" && item?.status?.handle_key !== "reject") ? navigate(`/admin/human_resource/certificate_requests/request_details/${item?._id}/edit`) : () => { }
            }}
            onDownload={() => { downloadHandler(item?._id) }}
          />
        },
        ]);
      });
    }
  }, [certificateRequestData]);

  const getDataHandler = () => {
    let formData = new FormData();
    formData.append("page", 0);
    formData.append("perPage", perPage);
    formData.append("sort_order", sortAscending ? 1 : -1);
    formData.append("sort_key", sortColumn);
    formData.append("is_admin_mode", 1);
    dispatch(getCertificateRequests(formData));
  }

  const getSearchDataHandler = (pag, perpage, sort, column, enablePaginationFilter) => {
    let currentFilter = enablePaginationFilter ? appliedFilterData : filterData
    let formData = new FormData();
    formData.append("status", currentFilter?.status ? currentFilter?.status?._id : "");
    formData.append(
      "department",
      currentFilter?.department ? currentFilter?.department?._id : ""
    );
    formData.append(
      "request_type",
      currentFilter?.request_type ? currentFilter?.request_type?._id : ""
    );
    formData.append("employee", currentFilter?.user ? currentFilter?.user?._id : "");
    if (currentFilter?.start_date?.length != 0) {
      let s_date =
        moment(currentFilter?.start_date)
          .format("YYYY-MM-DD hh:mm:ss")
          ?.split(" ")[0] + " 00:00:00";

      formData.append("start_date", s_date);
    } else {
      formData.append("start_date", "");
    }
    if (currentFilter?.end_date?.length != 0) {
      let e_date =
        moment(currentFilter?.end_date)
          .format("YYYY-MM-DD hh:mm:ss")
          ?.split(" ")[0] + " 23:59:59";

      formData.append("end_date", e_date);
    } else {
      formData.append("end_date", "");
    }
    formData.append("keyword", currentFilter?.keyword.trim());
    formData.append("page", pag);
    formData.append("perPage", perpage);
    formData.append("sort_order", sort ?? -1);
    formData.append("sort_key", column);
    formData.append("is_admin_mode", 1);
    dispatch(getCertificateRequests(formData));
    enablePaginationFilter && setFilterData(appliedFilterData)
  }

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    return currentDate.getTime() < selectedDate.getTime();
  };

  const getSortingClassName = (title) => {
    let className = "";
    if (sortColumn === title) {
      if (sortAscending) {
        className = "active";
      } else {
        className = "inactive";
      }
    }
    return className;
  };

  const clearFilter = () => {
    setFilterData({
      request_type: "",
      start_date: "",
      end_date: "",
      department: "",
      user: "",
      status: "",
      keyword: "",
    });
    setAppliedFilterData({
      request_type: "",
      start_date: "",
      end_date: "",
      department: "",
      user: "",
      status: "",
      keyword: "",
    });
    getDataHandler()
  };

  const applySearch = () => {
    if (filterData?.start_date || filterData?.end_date) {
      if (!(filterData?.start_date) || !(filterData?.end_date)) {
        toast.error("Start date and end date are mandatory", {
          position: "bottom-center",
          autoClose: 3000
        })
        return
      }
    }
    setAppliedFilterData(filterData)
    setPage(0)
    let sort = sortAscending ? 1 : -1
    getSearchDataHandler(0, perPage, sort, sortColumn)
  };

  const onSortHandler = ({ key, value }) => {
    setSortAscending(value === 1);
    setSortColumn(key);
    getSearchDataHandler(page, perPage, value, key, true)
  };

  const handlePageChange = (e) => {
    setPage(e.selected);
    let sort = sortAscending ? 1 : -1
    getSearchDataHandler(e.selected, perPage, sort, sortColumn, true)
  };

  const onLimitChangeHandler = (value) => {
    setPerPage(parseInt(value));
    setPage(0);
    let sort = sortAscending ? 1 : -1
    getSearchDataHandler(0, parseInt(value), sort, sortColumn, true)
  };

  const downloadHandler = (id) => {
    dispatch(downloadCertificate(id))
  }

  const onDepartmentChangeHandler = (value) => {
    setFilterData({ ...filterData, department: value, section: "" });
    dispatch(updateRedux({ key: "section_details", value: [] }));
    value._id && dispatch(getSection(value._id));
    let formData = new FormData();
    formData.append("department", value?._id || '');
    formData.append("section", "");
    dispatch(getAllUsersForHR(formData));
  };

  function searchBoxChangeHandler(value, key) {
    switch (key) {
      case 'request_type': setFilterData({ ...filterData, request_type: value })
        break;
      case 'date_from': setFilterData({ ...filterData, start_date: value })
        break;
      case 'date_to': setFilterData({ ...filterData, end_date: value })
        break;
      case 'department': onDepartmentChangeHandler(value)
        break;
      case 'employee': setFilterData({ ...filterData, user: value })
        break;
      case 'status': setFilterData({ ...filterData, status: value })
        break;
      default:
    }
  }

  const getStatusForAdminUSer = () => {
    return requestStatus?.filter(item=> item.handle_key !== APPROVE && item.handle_key !== CLOSE && item.handle_key !== REJECT)
  }

  return (
    <div id="HumanResource_admin">
      <section>
        <PageHeader>
          <Title content='Certificate Requests' />
          <Breadcrump content={[{ name: t("Certificate Requests"), path: "/admin/human_resource/certificate_requests" }, { name: t("HR"), path: "/admin/human_resource/certificate_requests" }, { name: t("Home"), path: "/admin" }]} />
          <Button className="css-btn-esc1" content={t("Add Request")} icon="/assets/icons/Add Request.svg" reverse onClick={() => navigate('add_request')} />
        </PageHeader>
      </section>
      <section>
        <SearchBox
          elements={[
            { label: 'Date', key: 'date_from', type: 'datePicker', className: 'css-dtp-esc3', placeholder: 'From' },
            { label: '', key: 'date_to', type: 'datePicker', className: 'css-dtp-esc3', placeholder: 'To' },
            { label: 'Request Type', key: 'request_type', type: 'select', className: 'css-slc-esc2', placeholder: 'Select Request Type', width: '210px' },
            { label: 'Department', key: 'department', type: 'select', className: 'css-slc-esc2', placeholder: 'Select Department', width: '200px' },
            { label: 'Employee', key: 'employee', type: 'select', className: 'css-slc-esc2', placeholder: 'Select User', width: '200px' },
            { label: 'Status', key: 'status', type: 'select', className: 'css-slc-esc2', placeholder: 'Select Status', width: '160px' },
          ]}
          options={{
            date_from: { selected: filterData?.start_date, filterTime: filterPassedTime, maxDate: filterData?.end_date ? filterData?.end_date : new Date(), dateFormat: "dd/MM/yy" },
            date_to: { selected: filterData?.end_date, filterTime: filterPassedTime, minDate: filterData?.start_date && filterData?.start_date, maxDate: new Date(), dateFormat: "dd/MM/yy" },
            request_type: { options: requestTypeList, value: filterData?.request_type, labelSetter: (option) => option.request_type, valueSetter: (option) => option._id },
            department: { options: departmentList, value: filterData?.department, labelSetter: (option) => option.department_name, valueSetter: (option) => option._id },
            employee: { options: userList, labelSetter: (option) => option.name, valueSetter: (option) => option._id, value: filterData?.user },
            status: { options: requestStatusForAdmin, labelSetter: (option) => statusLabelSetter(option), valueSetter: (option) => option._id, value: filterData?.status, },
            // status: { options: getStatusForAdminUSer(), labelSetter: (option) => statusLabelSetter(option), valueSetter: (option) => option._id, value: filterData?.status, },
          }}
          onSearch={applySearch}
          onClear={clearFilter}
          showClearBtn={filterData?.request_type || filterData?.start_date || filterData?.end_date || filterData?.department || filterData?.user || filterData?.status || filterData.keyword}
          onChange={searchBoxChangeHandler} />
      </section>
      <section className="d-flex justify-content-between">
        <EntriesCounter value={perPage} onChange={onLimitChangeHandler} />
        <Input value={filterData.keyword} type='text' className="css-inp-esc2 search" icon='/assets/icons/Search1.svg' placeholder={t('Generic Search')} reverse width='300px'
          onChange={(value) => { setFilterData(s => ({ ...s, keyword: value })); }} onSubmit={applySearch} onIconClick={applySearch}
        />
      </section>
      {loading ? <LoadingSpinner height="200px" /> :
        <section className="table">
          <Table className={'css-tbl-esc2'}
            titles={[
              { title: t("Request No"), key: "request_no", sort: true },
              { title: t("Employee"), key: "employee", sort: true },
              { title: t("Department"), key: "department", sort: true },
              { title: t("Request Type"), key: "request_type", sort: true },
              { title: t("Requested Date"), key: "request_date", sort: true, dir: 'ltr' },
              { title: t("Last Update"), key: "last_update", sort: true, dir: 'ltr' },
              { title: t("Handled By"), key: "handled_by", sort: true },
              { title: t("Status"), key: "status", width: '170px' },
              { title: t("Action"), key: "action", width: '130px' },
            ]}
            content={data}
            onSort={(e) => onSortHandler(e)}
            sortValue={{ key: sortColumn, value: sortAscending ? 1 : -1 }}
            page={page}
            pagesCount={pageCount}
            pageStatus={pageStatus}
            onPageChange={handlePageChange}
          />
        </section>}
    </div>
  );
}