import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import './AttachmentContent.scss'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

import closeIcon from './close.svg'
import docIcon from './doc.svg'
import docxIcon from './docx.svg'
import mp4Icon from './mp4.svg'
import movIcon from './mov.svg'
import pdfIcon from './pdf.svg'
import xlsxIcon from './xlsx.svg'
import xlsIcon from './xls.svg'
import csvIcon from './csv.svg'
import pptxIcon from './pptx.svg'
import pptIcon from './ppt.svg'
import errorFile from './errorFile.svg'

export default function AttachmentContent({ files, closeButton, onClose }) {

  const [inFiles, setFiles] = useState([])

  useEffect(() => {
    files && setFiles(files)
    return () => setFiles([])
  }, [files])

  function fileCloseHandler(fileToDelete) {
    let result = inFiles.filter(file => file !== fileToDelete)
    onClose && onClose(result.map(item => item))
  }

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {props.label}
    </Tooltip>
  );

  let icons = {
    png: 'file',
    jpg: 'file',
    jpeg: 'file',
    png: 'file',
    pdf: pdfIcon,
    doc: docIcon,
    docx: docxIcon,
    mov: movIcon,
    mp4: mp4Icon,
    xlsx: xlsxIcon,
    csv: csvIcon,
    ppt: pptIcon,
    pptx: pptxIcon,
    xls: xlsIcon,
  }

  function fileParser(file) {
    let src = icons[file.type]
    if (src === 'file') src = file.url
    if (file.isInvalid) src = errorFile
    let isImage = icons[file.type] === 'file' && !file.isInvalid
    return [src, isImage]
  }

  if (inFiles.length === 0) return null

  return (
    <div id='AttachmentContent' >
      {inFiles.map((file, i) => {
        let [src, isImage] = fileParser(file)
        return (
          <span key={i} className={isImage ? 'image' : ''}>
            <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderTooltip({ label: file.name, })} >
              <Link to={file.url} target='_blank'>
                <img className='file' src={src} />
              </Link>
            </OverlayTrigger>
            {closeButton && <img onClick={() => fileCloseHandler(file)} className='close' src={closeIcon} alt="" />}
          </span>
        )
      })}
    </div >
  )
}



{/* 

<AttachmentContent
  files={[
    {
      type: 'pdf',
      url: '',
      name: ''
    }
  ]}
  onClose={() => { }}
  closeButton
]} />

*/}