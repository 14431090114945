export const modulePermission = () => {
    const permission = localStorage.getItem('permission');
    return permission;
};

export const moduleAccess = () => {
    const access = localStorage.getItem('access') ? JSON.parse(localStorage.getItem('access')) : [];
    return access;
};

export const moduleMainAccess = () => {
    const main_access = localStorage.getItem('main_access') ? JSON.parse(localStorage.getItem('main_access')) : [];
    return main_access;
};

export const Capitalize = (text) => {
    return text ? text[0].toUpperCase() + text.slice(1) : ''
}

export const userType = (text) => {
    const user_type = localStorage.getItem('user_type') ?? "";
    return user_type;
}

export const statusLabelSetter = (option) => {
    let status = ""
    switch(option.handle_key){
        case 'forward': {
            status = "Forwarded";
            break;
        }
        case 'approve': {
            status = "Approved";
            break;
        }
        case 'return': {
            status = "Need Info";
            break;
        }
        case 'reject': {
            status = "Rejected";
            break;
        }
        case 'close': {
            status = "Closed";
            break;
        }
        default: {
            status = option.request_status
        }
    }
    return status
}