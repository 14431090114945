import React from 'react'
import './Actions.scss'

export default function Actions({ buttons, visibility, rootPath, ...props }) {

  function clickHandler(btn) {
    let funcName = 'on' + btn[0].toUpperCase() + btn.slice(1)
    let func = props[funcName]
    func && func()
  }

  return (
    <span id='Actions'>
      {
        buttons && Object.keys(buttons).map((btn, index) => {
          let src = rootPath ? rootPath + buttons[btn] : buttons[btn]
          if (!visibility?.[btn] && visibility?.[btn] !== undefined) return null
          return (
            <img key={index} src={src} alt="" onClick={() => clickHandler(btn)} />
          )
        })
      }
    </span>
  )
}