import React from 'react'
import TitleBar from '../../../commonComponents/TitleBar/TitleBar'
import PageHeader from '../../../commonComponents/PageHeader/PageHeader'
import Title from '../../../commonComponents/Title/Title'
import Breadcrump from '../../../commonComponents/Breadcrump/Breadcrump'
import Select from '../../../commonComponents/Select/Select'
import Button from '../../../commonComponents/Button/Button'
import TextArea from '../../../commonComponents/TextArea/TextArea'
import FooterButtons from '../../../commonComponents/FooterButtons/FooterButtons'
import TitleValue from '../../../commonComponents/TitleValue/TitleValue'
import FileUpload from '../../../commonComponents/FileUpload/FileUpload'
import Checkbox from '../../../commonComponents/Checkbox/Checkbox'
import Input from '../../../commonComponents/Input/Input'
import DatePick from '../../../commonComponents/DatePick/DatePick'
import RadioButtons from '../../../commonComponents/RadioButtons/RadioButtons'

import './educationalAllowance.scss'

export default function AddRequest() {
  return (
    <div id='AddRequest'>
      <section className='mb-4'>
        <PageHeader>
          <Title content="Add Request" />
          <Breadcrump content={[
            { name: "Add Request", path: "/admin/human_resource/educational_allowance" },
            { name: "Educational Allowance", path: "/admin/human_resource/educational_allowance" },
            { name: "Human Resource", path: "/admin/human_resource/certificate_requests" },
            { name: "Home", path: "/admin" }
          ]} />
        </PageHeader>
      </section>
      <section className='mb-4'>
        <TitleBar title='Request Details'>
          <div className='request_add_child'>
            <Input className='css-inp-esc1' label='Child Name' />
            <DatePick className='css-dtp-esc1' title='Date of Birth' />
            <RadioButtons className='css-rdb-esc1'
              label='Select Gender'
              name='gender'
              buttons={[
                { label: 'Male', value: 'male' },
                { label: 'Female', value: 'female' }
              ]} />
            <Input className='css-inp-esc1' label='Emirates ID No' />
            <FileUpload className='css-atc-esc2' label='Upload Emirates ID Proof' />
            <br />
            <Input className='css-inp-esc1' label='Enter Passport No' />
            <FileUpload className='css-atc-esc2' label='Upload Passport Proof' error='this is an error' />
            <Button className='css-btn-esc2' content='Submit' height={40} width={100} />
          </div>
          <Select className='css-slc-esc1' options={[{ year: 2010 }, { year: 2011 }]} width={300}
            title='Academic Year' valueSetter={(o) => o.year} labelSetter={(o) => o.year} />
          <br />
          <FileUpload className='css-atc-esc2' buttonLabel='Choose File' label='Letter from parent' width={300}
            info={'This is info about the below file upload field. Here pls upload a pdf file of letter from parent'} />
          <div className='d-flex align-items-end gap-4'>
            <Select className='css-slc-esc1' options={[{ year: 2010 }, { year: 2011 }]} width={300}
              title='Select Children' valueSetter={(o) => o.year} labelSetter={(o) => o.year} />
            <Button icon='/assets/icons/plus.svg' className='css-btn-esc7 sm mb-3' iconWidth={15} />
          </div>
        </TitleBar>
      </section>
      <section>
        <TextArea label='Comment' className='css-txt-esc2 mb-3' />
        <Checkbox label='I am aware that when a husband and wife meet to work in one of the federal government deparments / local bodies, the two are not entitled to enjoy together the tution fee allowance. In this context, I declare that my wife/husband does not receive tuition fees from the employer.'
          reverse className='css-cbx-esc2 px-4' />
      </section>
      <FooterButtons>
        <Button right content='Back' className='css-btn-esc3' icon='/assets/icons/Back.svg' reverse iconWidth='15px' />
        <Button left content='Submit' className='css-btn-esc3' />
        <Button left content='Cancel' className='css-btn-esc3' />
      </FooterButtons>
    </div>
  )
}
