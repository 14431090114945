import { useContext } from 'react'
import { useSelector } from 'react-redux'
import { LangSwitch } from '../App'

export default function useTranslate() {

    const WORDS = useSelector(s => s.commonReducer.words)
    const { language } = useContext(LangSwitch)

    function keyGetter() {
        return language === 'En' ? '_ar' : '_en'
    }

    function t(text) {
        let word = WORDS?.[text?.toLowerCase() + keyGetter()] || text
        // text === 'Request Status' && console.log(text, text?.toLowerCase() + keyGetter(), word)
        return word
    }

    return { t, _lang: keyGetter(), lang: language }
}