import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getModules,
  getRoleDetails,
  updateRoles,
} from "../../../redux/actionCreator";
import {
  add_update_delete_roles_loading,
  modules,
  role_details,
} from "../../../redux/commonReducer";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Title from "../../../commonComponents/Title/Title";
import Breadcrump from "../../../commonComponents/Breadcrump/Breadcrump";
import Table from "../../../commonComponents/Table/Table";
import Input from "../../../commonComponents/Input/Input";
import FooterButtons from "../../../commonComponents/FooterButtons/FooterButtons";
import Button from "../../../commonComponents/Button/Button";
import PageHeader from "../../../commonComponents/PageHeader/PageHeader";
import TitleBar from "../../../commonComponents/TitleBar/TitleBar";
import useTranslate from "../../../utils/useTranslate";
import { Form } from "react-bootstrap";

export default function EditPermission({ page }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const { t } = useTranslate();

  const moduleList = useSelector(modules);
  const rolesActionsLoading = useSelector(add_update_delete_roles_loading);
  const roleDetails = useSelector(role_details);

  const [redo, setRedo] = useState(false);
  const [moduleData, setModuleData] = useState([]);

  useEffect(() => {
    if (id) {
      dispatch(getRoleDetails(id));
    }
  }, [id]);

  useEffect(() => {
    dispatch(getModules());
  }, []);

  useEffect(() => {
    if (roleDetails && moduleList) {
      let permission = [...roleDetails?.permissions];
      let moduleObj = {};
      moduleList?.forEach((ele, i) => {
        let temp = [];
        ele?.modules?.forEach((e) => {
          let value = permission?.find((item) => item?.module_name == e?.key);
          temp.push({
            ...e,
            is_editable:
              value?.is_editable != undefined
                ? value?.is_editable == "1"
                  ? true
                  : false
                : true,
            admin: value?.admin == "1" ? true : false,
            report: value?.report == "1" ? true : false,
          });
        });
        moduleObj[ele.main_access] = temp;
      });
      let arr = moduleList?.map((item) => {
        return {
          ...item,
          modules: moduleObj[item?.main_access],
        };
      });
      setModuleData([...arr]);
    }
  }, [roleDetails, redo, moduleList]);

  const onSaveHandler = () => {
    let formData = new FormData();
    formData.append(`users[]`, roleDetails?._id);
    let finalModuleData = [];
    moduleData.forEach((item, index) => {
      finalModuleData = [...finalModuleData, ...item?.modules];
    });
    finalModuleData.forEach((ele, i) => {
      formData.append(`modules[${i}]`, ele.key);
      formData.append(`permissions[${i}][admin]`, ele?.admin == true ? 1 : 0);
      formData.append(`permissions[${i}][report]`, ele?.report == true ? 1 : 0);
      formData.append(
        `permissions[${i}][is_editable]`,
        ele?.is_editable == true ? 1 : 0
      );
    });
    console.log(formData);
    dispatch(
      updateRoles(formData, () => navigate("/admin/settings/permissions"))
    );
  };

  const handleCheckModule = (
    parent,
    module,
    type,
    checked,
    mainIndex,
    subIndex
  ) => {
    let obj = {
      ...moduleData[mainIndex]?.modules[subIndex],
      [type]: checked,
    };
    let arr = [...moduleData];
    let arrModules = [...arr[mainIndex].modules];
    arrModules[subIndex] = { ...obj };
    arr[mainIndex] = {
      ...arr[mainIndex],
      modules: [...arrModules],
    };
    setModuleData([...arr]);
  };

  const handleAllCheckModule = (parent, type, checked, mainIndex) => {
    let arr = [...moduleData];
    let mainObj = { ...arr[mainIndex] };
    let modifiedArr = mainObj?.modules?.map((ele) => {
      return { ...ele, [type]: checked };
    });
    arr[mainIndex] = {
      ...arr[mainIndex],
      modules: [...modifiedArr],
    };
    setModuleData([...arr]);
  };

  const onClose = () => {
    setModuleData([]);
    setRedo(true);
  };

  return (
    <div id="AddRequest_admin">
      <section>
        <PageHeader>
          <Title content={"Edit Permission"} />
          <Breadcrump
            content={[
              {
                name: "Edit Permission",
                path: "/admin/settings/permissions/update-permission",
              },
              { name: "Home", path: "/admin" },
            ]}
          />
        </PageHeader>
      </section>
      <section>
        <Table
          className="css-tbl-esc3"
          titles={[
            { title: "Employee No", key: "employee_no" },
            { title: "Employee Name", key: "employee_name" },
            { title: "Department", key: "department" },
            { title: "Section", key: "section" },
            { title: "Designation", key: "designation" },
            { title: "Action", key: "action" },
          ]}
          content={[
            {
              employee_no: roleDetails?.employee_id,
              employee_name: roleDetails?.firstname
                ? `${roleDetails?.firstname} ${roleDetails?.lastname}`
                : "-",
              section: roleDetails?.section.length
                ? roleDetails?.section[0]?.section_name
                : "-",
              department: roleDetails?.department?.department_name ?? "-",
              designation: roleDetails?.designation?.designation_name ?? "-",
              action: (
                <Input
                  type="checkbox"
                  className="css-inp-esc1 mt-2"
                  checked={true}
                />
              ),
            },
          ]}
        />
      </section>
      <h4
        style={{
          textTransform: "capitalize",
          fontWeight: 600,
          padding: "12px",
        }}
      >
        {t("Access Permissions")}
      </h4>
      {moduleData?.map((ele, i) => {
        return (
          <TitleBar
            TitleBar
            title={ele?.main_access}
            toggleButton
            defaultToggle={"show"}
            key={i}
          >
            <div id="Table" className="style__3 mt-1">
              <table>
                <thead>
                  <tr>
                    <th style={{ width: 300 }}>{t("Module")}</th>
                    <th style={{ width: 200 }}>
                      <div
                        style={{ display: "flex" }}
                        className="table-head-module"
                      >
                        <Form.Check
                          checked={ele?.modules?.every((e) => e?.admin == true)}
                          onChange={(e) =>
                            handleAllCheckModule(
                              ele?.main_access,
                              "admin",
                              e.target.checked,
                              i
                            )
                          }
                          style={{ marginLeft: 10 }}
                          disabled={ele?.modules?.some(
                            (e) => e?.is_editable == false
                          )}
                        />
                        {t("All")}
                      </div>
                    </th>
                    <th>
                      <div
                        style={{ display: "flex" }}
                        className="table-head-module"
                      >
                        <Form.Check
                          checked={ele?.modules
                            ?.filter((el) => "Employee Management" != el.value)
                            ?.every((e) => e?.report == true)}
                          onChange={(e) =>
                            handleAllCheckModule(
                              ele?.main_access,
                              "report",
                              e.target.checked,
                              i
                            )
                          }
                          style={{ marginLeft: 10 }}
                          disabled={ele?.modules?.some(
                            (e) => e?.is_editable == false
                          )}
                        />
                        {t("Reports")}
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody style={{ marginTop: 20 }}>
                  {ele?.modules?.map((elem, j) => {
                    return (
                      <tr key={j}>
                        <td>
                          <h6>{elem.value}</h6>
                        </td>
                        <td>
                          <div
                            style={{ display: "flex" }}
                            className="table-head-module"
                          >
                            <Form.Check
                              checked={elem.admin == true ? true : false}
                              onChange={(e) =>
                                handleCheckModule(
                                  ele?.main_access,
                                  elem.key,
                                  "admin",
                                  e.target.checked,
                                  i,
                                  j
                                )
                              }
                              style={{ marginLeft: 10 }}
                              className="blue-checkbox"
                              disabled={!elem?.is_editable}
                            />
                            {t("Admin")}
                          </div>
                        </td>
                        <td>
                          {!["Employee Management"]?.includes(elem.value) ? (
                            <div
                              style={{ display: "flex" }}
                              className="table-head-module"
                            >
                              <Form.Check
                                checked={elem.report == true ? true : false}
                                onChange={(e) =>
                                  handleCheckModule(
                                    ele?.main_access,
                                    elem.key,
                                    "report",
                                    e.target.checked,
                                    i,
                                    j
                                  )
                                }
                                style={{ marginLeft: 10 }}
                                disabled={!elem?.is_editable}
                              />
                              {t("Reports")}
                            </div>
                          ) : (
                            <></>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </TitleBar>
        );
      })}
      <section>
        <FooterButtons>
          <Button
            className="css-btn-esc3"
            right
            content="Back"
            icon="/assets/icons/Back.svg"
            reverse
            iconWidth="15px"
            onClick={() => navigate("/admin/settings/permissions")}
          />
          <Button
            className="css-btn-esc3"
            left
            content="Submit"
            onClick={onSaveHandler}
            disabled={rolesActionsLoading}
          />
          <Button
            className="css-btn-esc3"
            left
            content="Cancel"
            onClick={onClose}
          />
        </FooterButtons>
      </section>
    </div>
  );
}
