import React, { useEffect, useState } from 'react'
import './RadioButtons.scss'
import { v4 } from 'uuid'
import useTranslate from '../../utils/useTranslate'

export default function RadioButtons({ className, label, buttons, name, required, value, error, onChange }) {

  let uuid = v4()
  const { t } = useTranslate()

  const [state, setState] = useState()

  useEffect(() => {
    setState(String(value?.[name]))
  }, [value])

  function changeHandler(value) {
    setState(String(value))
    onChange && onChange(name, value)
  }

  return (
    <span id='RadioButtons' className={className || ''}>
      <span className='wrapper'>
        {label && <label>{t(label)}{required && <em>*</em>}</label>}
        <span className='buttons'>
          {
            buttons && buttons.map((btn, i) => {
              return (
                <span id={'btn' + i} className='radiobtn' key={i}>
                  <label htmlFor={uuid + btn.label}>{t(btn.label) || 'label'}</label>
                  <input
                    type="radio"
                    id={uuid + btn.label}
                    name={name}
                    checked={state === String(btn?.value)}
                    onChange={(e) => changeHandler(btn.value)}
                  />
                </span>
              )
            })
          }
        </span>
      </span>
      {error && <small id='error'>{error}</small>}
    </span>
  )
}

{/* 

<RadioButtons
  label='Select Gender'
  buttons={[
    { label: 'Male', value: 'male' },
    { label: 'Female', value: 'female' }
  ]}
  name='gender'
  value={{ gender: 'value' }}
  error=''
  onChange={(key, value) => { }}
/>

*/}