import React, { useState } from "react";
import { HashRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import RouteHandler from "./router/routes";
import { getDynamicTexts } from "./redux/actionCreator";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import useTranslate from "./utils/useTranslate";

export const LangSwitch = React.createContext()
function App() {

  const dispatch = useDispatch()
  const [language, setLanguage] = useState('En')

  useEffect(() => {
    let lang = localStorage.getItem('lang')
    if (!lang) localStorage.setItem('lang', language)
    else setLanguage(lang)
  }, [])

  useEffect(() => {
    // localStorage.removeItem('curr_page')
    if (localStorage.getItem("token")) {
      dispatch(getDynamicTexts())
    }
  }, [])


  return (
    <div className="App">
      <LangSwitch.Provider value={{ setLanguage, language }}>
        <HashRouter>
          <ToastContainer />
          <RouteHandler />
        </HashRouter>
      </LangSwitch.Provider>
    </div>
  );
}

export default App;
