import React from 'react'
import './Checkbox.scss'
import { v4 } from 'uuid'
import useTranslate from '../../utils/useTranslate'

export default function Checkbox({ label, className, inputClassName, checked, required, error, onChange, reverse }) {

  let uuid = v4()
  const { t } = useTranslate()

  return (
    <span id='Checkbox' className={className || ''}>
      <span className={reverse ? 'reverse' : ''}>
        <label htmlFor={uuid}>{t(label) || 'label'}{required && <em>*</em>}</label>
        <input
          type="checkbox"
          className={inputClassName || ''}
          id={uuid}
          checked={checked}
          onChange={(e) => onChange && onChange(e.target.checked)}
        />
      </span>
      {error && <small id='error'>{t(error)}</small>}
    </span>
  )
}