import { alphaNumeric, alphabetsOnly, checkIfTrue, isValueExist, maxCharLength, maxDate, minCharLength, minDate, requiredField, validEmail, validPhoneNumber, validator } from "./validationHelpers"

export const addOnboardingSaveValidation = async (inputs, setErrors) => {
    let required = inputs.button === 'submit'
        ? inputs.employeeType === ''
            ? [
                requiredField({ obj: inputs, key: 'employeeType', errMessage: "Please select employee type" }),
            ]
            : (inputs.employeeType.value === "military")
                ? [
                    requiredField({ obj: inputs, key: 'first_name', errMessage: "Please enter first name" }),
                    requiredField({ obj: inputs, key: 'first_name_ar', errMessage: "Please enter first name arabic" }),
                    requiredField({ obj: inputs, key: 'second_name', errMessage: "Please enter second name" }),
                    requiredField({ obj: inputs, key: 'second_name_ar', errMessage: "Please enter second name arabic" }),
                    requiredField({ obj: inputs, key: 'military_certificate', errMessage: "Please upload military certificate" }),
                    requiredField({ obj: inputs, key: 'photo', errMessage: "Please upload photo" }),
                    requiredField({ obj: inputs, key: 'joining_date', errMessage: 'Please enter joining date' }),
                    checkIfTrue({ obj: inputs, key: 'employee_joined' }),
                    checkIfTrue({ obj: inputs, key: 'oracle_updated' }),
                ]
                : [
                    requiredField({ obj: inputs, key: 'first_name', errMessage: "Please enter first name" }),
                    requiredField({ obj: inputs, key: 'second_name', errMessage: "Please enter second name" }),
                    // requiredField({ obj: inputs, key: 'last_name', errMessage: "Please enter last name" }),
                    requiredField({ obj: inputs, key: 'first_name_ar', errMessage: "Please enter first name arabic" }),
                    requiredField({ obj: inputs, key: 'second_name_ar', errMessage: "Please enter second name arabic" }),
                    // requiredField({ obj: inputs, key: 'last_name_ar', errMessage: "Please enter last name arabic" }),
                    requiredField({ obj: inputs, key: 'email', errMessage: "Please enter email" }),
                    requiredField({ obj: inputs, key: 'phone', errMessage: "Please enter phone number" }),
                    requiredField({ obj: inputs, key: 'gender', errMessage: "Please choose gender" }),
                    requiredField({ obj: inputs, key: 'nationality', errMessage: "Please select nationality" }),
                    requiredField({ obj: inputs, key: 'dob', errMessage: "Please enter date of birth" }),
                    requiredField({ obj: inputs, key: 'birth_certificate', errMessage: "Please upload birth certificate" }),
                    requiredField({ obj: inputs, key: 'military_certificate', errMessage: "Please upload military certificate", condition: inputs.gender === 'Male' }),
                    requiredField({ obj: inputs, key: 'emirates_id', errMessage: "Please enter emirates id" }),
                    requiredField({ obj: inputs, key: 'passport_no', errMessage: "Please enter passport number" }),
                    requiredField({ obj: inputs, key: 'designation', errMessage: 'Please enter designation' }),
                    requiredField({ obj: inputs, key: 'joining_date', errMessage: 'Please enter joining date' }),
                    requiredField({ obj: inputs, key: 'department', errMessage: 'Please select department' }),
                    requiredField({ obj: inputs, key: 'section', errMessage: 'Please select section' }),
                    // requiredField({ obj: inputs, key: 'employee_no', errMessage: 'Please enter employee no' }),
                    requiredField({ obj: inputs, key: 'grade', errMessage: 'Please enter grade' }),
                    requiredField({ obj: inputs, key: 'maritalStatus', errMessage: 'Please select marital status' }),
                    requiredField({ obj: inputs, key: 'father_emirates_id', errMessage: "Please enter father's emirates id" }),
                    requiredField({ obj: inputs, key: 'father_passport_no', errMessage: "Please enter father's passport number" }),
                    requiredField({ obj: inputs, key: 'mother_emirates_id', errMessage: "Please enter mother's emirates id" }),
                    requiredField({ obj: inputs, key: 'mother_passport_no', errMessage: "Please enter mother's passport number" }),
                    requiredField({ obj: inputs, key: 'spouse_name', errMessage: "Please enter spouse name", condition: inputs.maritalStatus.value === 'married' }),
                    requiredField({ obj: inputs, key: 'spouse_passport_no', errMessage: "Please enter spouse passport number", condition: inputs.maritalStatus.value === 'married' }),
                    requiredField({ obj: inputs, key: 'spouse_emirates_id', errMessage: "Please enter spouse emirates id", condition: inputs.maritalStatus.value === 'married' }),
                    requiredField({ obj: inputs, key: 'have_children', errMessage: "Please choose children status" }),
                    // requiredField({ obj: inputs, key: 'request_comment', errMessage: "Please enter comment" }),
                    requiredField({ obj: inputs, key: 'photo', errMessage: "Please upload photo" }),
                    requiredField({ obj: inputs, key: 'emirates_id_proof', errMessage: "Please upload emirates id proof" }),
                    requiredField({ obj: inputs, key: 'passport_proof', errMessage: "Please upload passport" }),
                    requiredField({ obj: inputs, key: 'police_clearance_certificate', errMessage: "Please upload police clearance certificate" }),
                    requiredField({ obj: inputs, key: 'educational_certificate', errMessage: "Please upload educational certificate" }),
                    requiredField({ obj: inputs, key: 'medical_report', errMessage: "Please upload medical certificate" }),
                    // requiredField({ obj: inputs, key: 'job_description_doc', errMessage: 'Please upload job description doc' }),
                    requiredField({ obj: inputs, key: 'cv', errMessage: 'Please upload CV' }),
                    requiredField({ obj: inputs, key: 'offer_letter', errMessage: 'Please upload offer letter' }),
                    // requiredField({ obj: inputs, key: 'nda', errMessage: 'Please upload NDA certificate' }),
                    requiredField({ obj: inputs, key: 'executive_letter_certificate', errMessage: 'Please upload letter from executive' }),
                    requiredField({ obj: inputs, key: 'financial_disclosure', errMessage: 'Please upload financial disclosure' }),
                    requiredField({ obj: inputs, key: 'experience_certificate', errMessage: 'Please upload latest experience certificate' }),
                    requiredField({ obj: inputs, key: 'previous_salary_certificate', errMessage: 'Please upload salary certificate' }),
                    requiredField({ obj: inputs, key: 'welcome_email', errMessage: 'Please upload scanned welcome mail' }),
                    requiredField({ obj: inputs, key: 'family_book', errMessage: 'Please upload family book' }),
                    requiredField({ obj: inputs, key: 'other', errMessage: 'Please upload marital status proof', condition: inputs.maritalStatus.value === 'other' }),
                    requiredField({ obj: inputs, key: 'divorce_certificate', errMessage: 'Please upload divorce proof from court', condition: inputs.maritalStatus.value === 'divorced' }),
                    requiredField({ obj: inputs, key: 'father_emirates_id_proof', errMessage: "Please upload father's emirates id" }),
                    requiredField({ obj: inputs, key: 'father_passport_proof', errMessage: "Please upload father's passport" }),
                    requiredField({ obj: inputs, key: 'mother_emirates_id_proof', errMessage: "Please upload mother's emirates id" }),
                    requiredField({ obj: inputs, key: 'mother_passport_proof', errMessage: "Please upload mother's passport" }),
                    requiredField({ obj: inputs, key: 'spouse_passport_proof', errMessage: 'Please upload spouse passport', condition: inputs.maritalStatus.value === 'married' }),
                    requiredField({ obj: inputs, key: 'spouse_emirates_id_proof', errMessage: 'Please upload spouse emirates id', condition: inputs.maritalStatus.value === 'married' }),

                    checkIfTrue({ obj: inputs, key: 'employee_joined' }),
                    checkIfTrue({ obj: inputs, key: 'oracle_updated' }),
                ]
        : inputs.button === 'save' && [
            requiredField({ obj: inputs, key: 'first_name', errMessage: "Please enter first name" }),
            requiredField({ obj: inputs, key: 'first_name_ar', errMessage: "Please enter first name arabic" }),
            requiredField({ obj: inputs, key: 'second_name', errMessage: "Please enter second name" }),
            requiredField({ obj: inputs, key: 'second_name_ar', errMessage: "Please enter second name arabic" }),
        ]


    let childData = inputs.childData.map(item => {
        let obj = {
            ['child_name' + item.id]: item.child_name,
            ['child_dob' + item.id]: item.child_dob,
            ['child_emirates_id' + item.id]: item.child_emirates_id,
            ['child_emirates_id_proof' + item.id]: item.child_emirates_id_proof,
            ['child_gender' + item.id]: item.child_gender,
            ['child_passport' + item.id]: item.child_passport,
            ['child_passport_proof' + item.id]: item.child_passport_proof,
        }

        let childData_required = inputs.button === 'submit' ? [
            requiredField({ obj: obj, key: ['child_name' + item.id], errMessage: "Please enter child name", condition: inputs.have_children === 'yes' }),
            requiredField({ obj: obj, key: ['child_gender' + item.id], errMessage: "Please choose child's gender", condition: inputs.have_children === 'yes' }),
            requiredField({ obj: obj, key: ['child_dob' + item.id], errMessage: "Please enter child's date of birth", condition: inputs.have_children === 'yes' }),
            requiredField({ obj: obj, key: ['child_emirates_id' + item.id], errMessage: "Please enter child's emirates id", condition: inputs.have_children === 'yes' }),
            requiredField({ obj: obj, key: ['child_passport' + item.id], errMessage: "Please enter child's passport number", condition: inputs.have_children === 'yes' }),
            requiredField({ obj: obj, key: ['child_emirates_id_proof' + item.id], errMessage: "Please upload child's emirates id", condition: inputs.have_children === 'yes' }),
            requiredField({ obj: obj, key: ['child_passport_proof' + item.id], errMessage: "Please upload child's passport", condition: inputs.have_children === 'yes' }),
        ] : []

        let childData_others = [
            alphabetsOnly({ obj: obj, key: ['child_name' + item.id], errMessage: "Should have only alphabets", condition: inputs.have_children === 'yes', more: ' ' }),

            alphaNumeric({ obj: obj, key: ['child_emirates_id' + item.id], condition: inputs.have_children === 'yes', more: '-' }),
            alphaNumeric({ obj: obj, key: ['child_passport' + item.id], condition: inputs.have_children === 'yes' }),

            maxCharLength({ obj: obj, key: ['child_emirates_id' + item.id], maxLength: 20, condition: inputs.have_children === 'yes' }),
            maxCharLength({ obj: obj, key: ['child_passport' + item.id], maxLength: 20, condition: inputs.have_children === 'yes' }),
        ]

        return [...childData_required, ...childData_others]
    }).flat()

    let others = [
        alphabetsOnly({ obj: inputs, key: 'first_name', errMessage: "First Name accepts only alphabets without space" }),
        alphabetsOnly({ obj: inputs, key: 'second_name', errMessage: "Second name accepts only alphabets without space" }),
        alphabetsOnly({ obj: inputs, key: 'last_name', errMessage: "Last name should have only alphabets", more: ' ' }),
        alphabetsOnly({ obj: inputs, key: 'first_name_ar', lang: 'ar', errMessage: "Should have only arabic letters" }),
        alphabetsOnly({ obj: inputs, key: 'second_name_ar', lang: 'ar', errMessage: "Should have only arabic letters" }),
        alphabetsOnly({ obj: inputs, key: 'last_name_ar', lang: 'ar', errMessage: "Should have only arabic letters", more: ' ' }),

        validEmail({ obj: inputs, key: 'email', condition: isValueExist(inputs.email) }),

        validPhoneNumber({ obj: inputs, key: 'phone', digits: '9,13', errMessage: "Invalid phone number", condition: isValueExist(inputs.phone) }),
        validPhoneNumber({ obj: inputs, key: 'alternate_phone', digits: '9,13', errMessage: "Invalid phone number", condition: isValueExist(inputs?.alternate_phone) }),

        maxDate({ obj: inputs, key: 'dob', maxDate: new Date().setFullYear(new Date().getFullYear() - 18), condition: isValueExist(inputs.dob) }),

        alphaNumeric({ obj: inputs, key: 'emirates_id', more: '-' }),
        alphaNumeric({ obj: inputs, key: 'passport_no' }),
        alphaNumeric({ obj: inputs, key: 'father_emirates_id', more: '-' }),
        alphaNumeric({ obj: inputs, key: 'father_passport_no' }),
        alphaNumeric({ obj: inputs, key: 'mother_emirates_id', more: '-' }),
        alphaNumeric({ obj: inputs, key: 'mother_passport_no' }),
        alphaNumeric({ obj: inputs, key: 'spouse_passport_no', condition: inputs.maritalStatus?.value === 'married' }),
        alphaNumeric({ obj: inputs, key: 'spouse_emirates_id', condition: inputs.maritalStatus?.value === 'married', more: '-' }),

        maxCharLength({ obj: inputs, key: 'emirates_id', maxLength: 20 }),
        maxCharLength({ obj: inputs, key: 'passport_no', maxLength: 20 }),
        maxCharLength({ obj: inputs, key: 'father_emirates_id', maxLength: 20 }),
        maxCharLength({ obj: inputs, key: 'father_passport_no', maxLength: 20 }),
        maxCharLength({ obj: inputs, key: 'mother_emirates_id', maxLength: 20 }),
        maxCharLength({ obj: inputs, key: 'mother_passport_no', maxLength: 20 }),
        maxCharLength({ obj: inputs, key: 'spouse_passport_no', maxLength: 20, condition: inputs.maritalStatus?.value === 'married' }),
        maxCharLength({ obj: inputs, key: 'spouse_emirates_id', maxLength: 20, condition: inputs.maritalStatus?.value === 'married' }),
    ]

    return await validator(setErrors, [...required, ...others, ...childData])
}

export const detailOnboardingSaveValidation = async (inputs, setErrors) => {

    let required = inputs.button === 'submit'
        ? [
            checkIfTrue({ obj: inputs, key: 'access_card_provided', errMessage: 'Required to be checked as yes' }),
            checkIfTrue({ obj: inputs, key: 'email_generated', errMessage: 'Required to be checked as yes' }),
            checkIfTrue({ obj: inputs, key: 'pc_gathered', errMessage: 'Required to be checked as yes' }),
            checkIfTrue({ obj: inputs, key: 'ad_updated', errMessage: 'Required to be checked as yes' }),
            requiredField({ obj: inputs, key: 'comment' }),
        ]
        : inputs.button === 'return'
            ? [requiredField({ obj: inputs, key: 'comment' })]
            : []

    return await validator(setErrors, [...required])
}

export const employeeSaveValidation = async (inputs, setErrors) => {
    let childData = inputs.childData.map(item => {
        let obj = {
            ['child_name' + item.id]: item.child_name,
            ['child_dob' + item.id]: item.child_dob,
            ['child_emirates_id' + item.id]: item.child_emirates_id,
            ['child_emirates_id_proof' + item.id]: item.child_emirates_id_proof,
            ['child_gender' + item.id]: item.child_gender,
            ['child_passport' + item.id]: item.child_passport,
            ['child_passport_proof' + item.id]: item.child_passport_proof,
        }
        return ([
            alphabetsOnly({ obj: obj, key: ['child_name' + item.id], errMessage: "Should have only alphabets", condition: inputs.have_children === 'yes', more: ' ' }),

            alphaNumeric({ obj: obj, key: ['child_emirates_id' + item.id], condition: inputs.have_children === 'yes', more: '-' }),
            alphaNumeric({ obj: obj, key: ['child_passport' + item.id], condition: inputs.have_children === 'yes' }),

            maxCharLength({ obj: obj, key: ['child_emirates_id' + item.id], maxLength: 20, condition: inputs.have_children === 'yes' }),
            maxCharLength({ obj: obj, key: ['child_passport' + item.id], maxLength: 20, condition: inputs.have_children === 'yes' }),
        ])
    }).flat()

    let others = [
        alphabetsOnly({ obj: inputs, key: 'firstname', errMessage: "First name accepts only alphabets without space" }),
        alphabetsOnly({ obj: inputs, key: 'middlename', errMessage: "Second name accepts only alphabets without space" }),
        alphabetsOnly({ obj: inputs, key: 'lastname', errMessage: "Last name should have only alphabets", more: ' ' }),
        alphabetsOnly({ obj: inputs, key: 'firstname_ar', lang: 'ar', errMessage: "Should have only arabic letters" }),
        alphabetsOnly({ obj: inputs, key: 'middlename_ar', lang: 'ar', errMessage: "Should have only arabic letters" }),
        alphabetsOnly({ obj: inputs, key: 'lastname_ar', lang: 'ar', errMessage: "Should have only arabic letters", more: ' ' }),

        validEmail({ obj: inputs, key: 'email', condition: isValueExist(inputs.email) }),

        validPhoneNumber({ obj: inputs, key: 'phone', digits: '9,13', errMessage: "Invalid phone number", condition: isValueExist(inputs.phone) }),
        validPhoneNumber({ obj: inputs, key: 'alternate_phone', digits: '9,13', errMessage: "Invalid phone number", condition: isValueExist(inputs?.alternate_phone) }),

        maxDate({ obj: inputs, key: 'dob', maxDate: new Date().setFullYear(new Date().getFullYear() - 18), condition: isValueExist(inputs.dob) }),

        alphaNumeric({ obj: inputs, key: 'emirates_id', more: '-' }),
        alphaNumeric({ obj: inputs, key: 'passport_no' }),
        alphaNumeric({ obj: inputs, key: 'father_emirates_id', more: '-' }),
        alphaNumeric({ obj: inputs, key: 'father_passport_no' }),
        alphaNumeric({ obj: inputs, key: 'mother_emirates_id', more: '-' }),
        alphaNumeric({ obj: inputs, key: 'mother_passport_no' }),
        alphaNumeric({ obj: inputs, key: 'spouse_passport_no', condition: inputs.maritalStatus?.value === 'married' }),
        alphaNumeric({ obj: inputs, key: 'spouse_emirates_id', condition: inputs.maritalStatus?.value === 'married', more: '-' }),

        maxCharLength({ obj: inputs, key: 'emirates_id', maxLength: 20 }),
        maxCharLength({ obj: inputs, key: 'passport_no', maxLength: 20 }),
        maxCharLength({ obj: inputs, key: 'father_emirates_id', maxLength: 20 }),
        maxCharLength({ obj: inputs, key: 'father_passport_no', maxLength: 20 }),
        maxCharLength({ obj: inputs, key: 'mother_emirates_id', maxLength: 20 }),
        maxCharLength({ obj: inputs, key: 'mother_passport_no', maxLength: 20 }),
        maxCharLength({ obj: inputs, key: 'spouse_passport_no', maxLength: 20, condition: inputs.maritalStatus?.value === 'married' }),
        maxCharLength({ obj: inputs, key: 'spouse_emirates_id', maxLength: 20, condition: inputs.maritalStatus?.value === 'married' }),
    ]

    return await validator(setErrors, [...others, ...childData])
}



// minCharLength({ obj: inputs, key: 'key', errMessage: 'custom_err_msg', minLength: 0, condition: true })
// maxCharLength({ obj: inputs, key: 'key', errMessage: 'custom_err_msg', maxLength: 0, condition: true })
// requiredField({ obj: inputs, key: 'key', errMessage: 'custom_err_msg', condition: true })
// validEmail({ obj: inputs, key: 'key', errMessage: 'custom_err_msg', condition: true })
// alphabetsOnly({ obj: inputs, key: 'key', errMessage: 'custom_err_msg', more: '', condition: true })
// alphaNumeric({ obj: inputs, key: 'key', errMessage: 'custom_err_msg', more: '', condition: true })
// validPhoneNumber({ obj: inputs, key: 'key', errMessage: 'custom_err_msg', digits: 0, condition: true })
// checkIfTrue({ obj: inputs, key: 'key', errMessage: 'custom_err_msg', condition: true })
// isValueExist(item)