import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./NavItemsListing.scss";
import {
  current_user_permissions,
  user_logged_in,
} from "../../redux/commonReducer";
import { useDispatch, useSelector } from "react-redux";
import useTranslate from "../../utils/useTranslate";
import { moduleAccess, moduleMainAccess, userType } from "../../utils/helpers";
import { getUserAccess } from "../../redux/actionCreator";

export default function NavItemsListing({ selected, setSelected }) {
  const location = useLocation();
  const { t } = useTranslate();
  const dispatch = useDispatch();

  const user_logged = localStorage.getItem("user_logged");

  const navItems = [
    {
      id: 1,
      item: "Dashboard",
      icon: "dashboard",
      path: "",
      roles: "",
      accesskey: "",
      show: true,
    },
    {
      id: 2,
      item: "Human Resource",
      icon: "human_resource",
      path: "human_resource/certificate_requests",
      roles: "",
      accesskey: "HR",
      userAccess: true,
      subItems: [
        {
          id: 2.1,
          item: "Certificate Requests",
          path: "human_resource/certificate_requests",
          roles: "",
          subAccesskey: "certificate_request_admin",
          userAccess: true,
        },
        {
          id: 2.2,
          item: "On Boarding Process",
          path: "human_resource/onboarding_process",
          roles: "",
          subAccesskey: "onboarding_hr_admin",
        },
        {
          id: 2.3,
          item: "Employee List",
          path: "human_resource/employee_list",
          roles: "",
          subAccesskey: "employee_management_admin",
          userAccess: false,
        },
        {
          id: 2.4,
          item: "Educational Allowance",
          path: "human_resource/educational_allowance",
          roles: "",
          subAccesskey: "educational_allowance_admin",
          userAccess: true,
        },
      ],
    },
    {
      id: 3,
      item: "IT",
      icon: "IT",
      path: "it/onboarding_process",
      roles: "",
      accesskey: "IT",
      subItems: [
        {
          id: 3.1,
          item: "On Boarding Process",
          path: "it/onboarding_process",
          roles: "",
          subAccesskey: "onboarding_it_admin",
        },
      ],
    },
    {
      id: 4,
      item: "Settings",
      icon: "settings",
      path: "settings/certificate_type",
      roles: "",
      adminOnly: true,
      accesskey: "",
      subItems: [
        {
          id: 4.1,
          item: "Certificate Type",
          path: "settings/certificate_type",
          roles: "",
          subAccesskey: "",
        },
        {
          id: 4.2,
          item: "Educational Allowance",
          path: "settings/educational_allowance",
          roles: "",
          subAccesskey: "",
        },
        {
          id: 4.3,
          item: "Translation",
          path: "settings/translation",
          roles: "",
          subAccesskey: "",
        },
      ],
    },
    {
      id: 5,
      item: "Manage Users",
      icon: "manageusers",
      path: "users",
      iconStyle: { height: "14px" },
      roles: "",
      adminOnly: true,
      accesskey: "",
      subItems: [
        {
          id: 5.1,
          item: "Users",
          path: "users",
          roles: "",
          subAccesskey: "",
        },
        {
          id: 5.2,
          item: "Permissions",
          path: "settings/permissions",
          roles: "",
          subAccesskey: "",
        },
      ],
    },
    {
      id: 6,
      item: "Report",
      icon: "report",
      path: "report/certificate_requests",
      roles: "",
      // adminOnly: true,
      accesskey: "REPORTS",
      subItems: [
        {
          id: 6.1,
          item: "Certificate Requests",
          path: "report/certificate_requests",
          roles: "",
          subAccesskey: "certificate_request_report",
        },
        // {
        //   id: 6.2,
        //   item: "Onboarding Hr Report",
        //   path: "report/onboarding_hr_report",
        //   roles: "",
        //   subAccesskey:"onboarding_hr_report",
        // },
        // {
        //   id: 6.3,
        //   item: "Onboarding It Report",
        //   path: "report/onboarding_it_report",
        //   roles: "",
        //   subAccesskey: "onboarding_it_report",
        // },
      ],
    },
  ];

  useEffect(() => {
    dispatch(getUserAccess());
  }, []);

  useEffect(() => {
    const path = location.pathname;
    if (path === "/admin" || path === "/") {
      setSelected({ item: 1, subItem: 1.1 });
    } else {
      for (let i = 0; i < navItems?.length; i++) {
        for (let j = 0; j < navItems[i].subItems?.length; j++) {
          if (path.includes(navItems[i].subItems[j].path)) {
            setSelected({
              item: navItems[i].id,
              subItem: navItems[i].subItems[j].id,
            });
          }
        }
      }
    }
  }, [location]);

  const findPathHandler = (data) => {
    if (data?.subItems) {
      let temp = data?.subItems?.filter((ele) => {
        if (user_logged == "User" && ele?.userAccess) {
          return ele;
        } else if (
          (user_logged == "Admin" &&
            moduleAccess()?.length &&
            moduleAccess()?.includes(ele?.subAccesskey))
        ) {
          return ele;
        } else if (user_logged == "Admin" && userType() == "super_admin") {
          return ele;
        }
      });
      if (temp?.length) {
        return temp[0]?.path;
      } else {
        return "";
      }
    } else {
      return data?.path;
    }
  };

  const permissionHandler = (permissionKey) => {
    if (Array.isArray(permissionKey)) {
      return permissionKey.some((e) => moduleAccess()?.includes(e));
    }
  };

  return (
    <div id="NavItemsListing">
      <ul>
        {navItems
          ?.filter((element) => {
            if (element?.show) {
              return element;
            } else if (user_logged == "User" && element?.userAccess) {
              return element;
            } else if (
              user_logged == "Admin" &&
              moduleAccess()?.length &&
              moduleMainAccess()?.includes(element?.accesskey)
            ) {
              return element;
            } else if (user_logged == "Admin" && userType() == "super_admin") {
              return element;
            }
          })
          ?.map((item, i) => (
            <li className="item" key={i}>
              <span
                className={
                  "item-span" + (selected.item === item.id ? " selected" : "")
                }
                onClick={() =>
                  (item.path || item.path === "") &&
                  setSelected({ item: item.id, subItem: item.id + 0.1 })
                }
              >
                <Link to={findPathHandler(item)}>
                  <i>
                    <img
                      style={item.iconStyle}
                      src={`/assets/icons/${item.icon}.svg`}
                      alt=""
                    />
                  </i>
                  <p>{t(item.item)}</p>
                </Link>
              </span>
              {
                <ul
                  className={
                    (item.subItems && selected.item === item.id ? "" : "hide") +
                    " animation fadeInUp animated"
                  }
                >
                  {item?.subItems
                    ?.filter((ele) => {
                      if (user_logged == "User" && ele?.userAccess) {
                        return ele;
                      } else if (
                        (user_logged == "Admin" &&
                          moduleAccess()?.length &&
                          moduleAccess()?.includes(ele?.subAccesskey))
                      ) {
                        return ele;
                      } else if (
                        user_logged == "Admin" &&
                        userType() == "super_admin"
                      ) {
                        return ele;
                      }
                    })
                    ?.map((subItem, si) => (
                      <li
                        key={si}
                        className="sub-item"
                        onClick={() =>
                          subItem.path &&
                          setSelected((s) => ({ ...s, subItem: subItem.id }))
                        }
                      >
                        <Link to={subItem.path}>
                          <p
                            className={
                              selected.subItem === subItem.id ? "selected" : ""
                            }
                          >
                            {t(subItem.item)}
                          </p>
                        </Link>
                      </li>
                    ))}
                </ul>
              }
            </li>
          ))}
      </ul>
    </div>
  );
}
