import React, { useEffect, useState } from 'react'
import './StatusBox.scss'
import useTranslate from '../../utils/useTranslate'

export default function StatusBox({ status = '', width, label }) {

  const { t } = useTranslate()

  function formatter(text) {
    return statusFormatter(text)?.toLowerCase().replace(' ', '')
  }

  function statusFormatter(status) {
    switch (status?.toLowerCase()) {
      case 'close': return 'Closed'
      case 'forward': return 'Forwarded'
      case 'reject': return 'Rejected'
      case 'approve': return 'Approved'
      default: return status
    }
  }

  return (
    <span id='StatusBox'>
      {label && <p>{label}</p>}
      <span className={'box' + ' ' + formatter(status)}>
        {t(statusFormatter(status))}
      </span>
    </span >
  )
}
StatusBox.displayName = 'StatusBox'