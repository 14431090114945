import React, { useState, useEffect } from 'react'
import './AttachmentsPreview.scss'
import { OverlayTrigger } from "react-bootstrap";
import { Tooltip } from "react-bootstrap";

import downloadIcon from './download.svg'
import docIcon from './doc.svg'
import docxIcon from './docx.svg'
import mp4Icon from './mp4.svg'
import movIcon from './mov.svg'
import pdfIcon from './pdf.svg'
import xlsxIcon from './xlsx.svg'
import xlsIcon from './xls.svg'
import csvIcon from './csv.svg'
import pptxIcon from './pptx.svg'
import pptIcon from './ppt.svg'
import useTranslate from '../../utils/useTranslate';

export default function AttachmentsPreview({ data = {}, translate }) {
  let sliderLength = 4

  const { t } = useTranslate()

  const [highlightedFiles, setHighlightedFiles] = useState([])
  const [slicer, setSlicer] = useState({ from: 0, to: sliderLength, preview: 0 });
  const [current, setCurrent] = useState(0)
  const [files, setFiles] = useState([])

  useEffect(() => {
    setFiles(data.endpoints ? data.endpoints.map(endpoint => ({ file: data.path + endpoint, endpoint: endpoint })) : [])
    return () => {
      setFiles([])
      setCurrent(0)
      setHighlightedFiles([])
    }
  }, [data])

  useEffect(() => {
    setHighlightedFiles(files.slice(0, sliderLength))
  }, [files])

  function sliceMover(value) {
    if (!(value === 1 && current === sliderLength - 1) && !(value === -1 && current === 0)) {
      setCurrent(current + value)
    } else if (!(value === -1 && slicer.from === 0) && !(value === 1 && slicer.to === files.length + 3)) {
      setSlicer({ from: slicer.from + value, to: slicer.to + value, preview: slicer.preview + value, })
      setHighlightedFiles(files.slice(slicer.from + value, slicer.to + value))
    }
  }

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {props.label}
    </Tooltip>
  );

  function fileOpenHandler() {
    let file = highlightedFiles?.[current].file
    // let extension = file.split('.').pop().toLowerCase()
    // if ( extension !== 'pdf') return
    const url = file
    const a = document.createElement('a')
    a.href = url
    a.target = '_blank'
    document.body.appendChild(a);
    a.click();
  }

  function downloadHandler(file, name) {
    fetch(file)
      .then((response) => response.blob())
      .then(blob => {
        const url = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.target = '_blank'
        a.download = name
        document.body.appendChild(a);
        a.click();
      })
      .catch(err => console.log({ err }))
  }

  const FileIconView = ({ caption, icon }) => {
    return (
      <div style={{ height: '300px', width: '500px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '20px' }}>
        <img style={{ height: '100px', width: '100px', cursor: 'pointer' }} src={icon} onClick={() => fileOpenHandler()} />
        <p>{caption}</p>
      </div>
    )
  }

  return (
    <div id='AttachmentsPreview'>
      <div id="ViewAttachmentModal" className="modal-item">
        {
          highlightedFiles?.[current]?.file?.includes(".jpg")
            ? <img className="preview" src={highlightedFiles?.[current]?.file} onClick={() => fileOpenHandler()} />
            : highlightedFiles?.[current]?.file?.includes(".jpeg")
              ? <img className="preview" src={highlightedFiles?.[current]?.file} onClick={() => fileOpenHandler()} />
              : highlightedFiles?.[current]?.file?.toLowerCase().includes(".png")
                ? <img className="preview" src={highlightedFiles?.[current]?.file} onClick={() => fileOpenHandler()} />
                : highlightedFiles?.[current]?.file?.toLowerCase().includes(".pdf")
                  ? <FileIconView caption={highlightedFiles?.[current]?.endpoint} icon={pdfIcon} />
                  : highlightedFiles?.[current]?.file?.toLowerCase().includes(".docx")
                    ? <FileIconView caption={highlightedFiles?.[current]?.endpoint} icon={docxIcon} />
                    : highlightedFiles?.[current]?.file?.toLowerCase().includes(".doc")
                      ? <FileIconView caption={highlightedFiles?.[current]?.endpoint} icon={docIcon} />
                      : highlightedFiles?.[current]?.file?.toLowerCase().includes(".xlsx")
                        ? <FileIconView caption={highlightedFiles?.[current]?.endpoint} icon={xlsxIcon} />
                        : highlightedFiles?.[current]?.file?.toLowerCase().includes(".xls")
                          ? <FileIconView caption={highlightedFiles?.[current]?.endpoint} icon={xlsIcon} />
                          : highlightedFiles?.[current]?.file?.toLowerCase().includes(".csv")
                            ? <FileIconView caption={highlightedFiles?.[current]?.endpoint} icon={csvIcon} />
                            : highlightedFiles?.[current]?.file?.toLowerCase().includes(".pptx")
                              ? <FileIconView caption={highlightedFiles?.[current]?.endpoint} icon={pptxIcon} />
                              : highlightedFiles?.[current]?.file?.toLowerCase().includes(".ppt")
                                ? <FileIconView caption={highlightedFiles?.[current]?.endpoint} icon={pptIcon} />
                                : highlightedFiles?.[current]?.file?.toLowerCase().includes(".mp4")
                                  ? <video className='preview' controls src={highlightedFiles?.[current]?.file} />
                                  : highlightedFiles?.[current]?.file?.toLowerCase().includes(".mov")
                                    ? <video className='preview' controls src={highlightedFiles?.[current]?.file} />
                                    : null
        }
        <section className="slider">
          <i className="arrow right"> {files[slicer.preview - 1] && <img src="/assets/icons/Icon ionic-ios-arrow-down white.svg" onClick={() => sliceMover(-1)} alt="" />}</i>
          <div className="files">
            {files.slice(slicer.from, slicer.to).map((file, i) => {
              return (
                <span onClick={() => setCurrent(i)} key={i} className={i === current ? "preview" : ""}>
                  <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderTooltip({ label: file.endpoint.replace(' ', '') })} >
                    {
                      file.file?.toLowerCase().includes(".jpg") ? (<img src={file.file} />)
                        : file.file?.toLowerCase().includes(".jpeg") ? (<img src={file.file} />)
                          : file.file?.toLowerCase().includes(".png") ? (<img src={file.file} />)
                            : file.file?.toLowerCase().includes(".pdf") ? <img src={pdfIcon} />
                              : file.file?.toLowerCase().includes(".docx") ? <img src={docxIcon} />
                                : file.file?.toLowerCase().includes(".doc") ? <img src={docIcon} />
                                  : file.file?.toLowerCase().includes(".mp4") ? <img src={mp4Icon} />
                                    : file.file?.toLowerCase().includes(".mov") ? <img src={movIcon} />
                                      : file.file?.toLowerCase().includes(".xlsx") ? <img src={xlsxIcon} />
                                        : file.file?.toLowerCase().includes(".xls") ? <img src={xlsIcon} />
                                          : file.file?.toLowerCase().includes(".csv") ? <img src={csvIcon} />
                                            : file.file?.toLowerCase().includes(".ppt") ? <img src={pptIcon} />
                                              : file.file?.toLowerCase().includes(".pptx") ? <img src={pptxIcon} />
                                                : null
                    }
                  </OverlayTrigger>
                </span>
              );
            })}
          </div>
          <i className="arrow left">{files[slicer.preview + 4] && <img src="/assets/icons/Icon ionic-ios-arrow-down white.svg" onClick={() => sliceMover(1)} alt="" />}</i>
        </section>
        <button className='download' style={{ display: 'flex', gap: '10px', alignItems: 'center', padding: '3px 13px' }}
          onClick={() => downloadHandler(highlightedFiles?.[current]?.file, highlightedFiles?.[current]?.endpoint)}>
          <img style={{ height: '18px' }} src={downloadIcon} alt="" />
          {t('Download')}
        </button>
      </div>
    </div >
  )
}