import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllEmployeeList,
  getAllUsers,
  getUsersWithoutRolePermissions,
  getAllUsersForHr,
  getDepartment,
  getSection,
} from "../../../redux/actionCreator";
import {
  all_employee_list,
  all_hr_users_list,
  all_hr_users_without_roles_list,
  department_details,
  section_details,
  updateRedux,
  user_list,
} from "../../../redux/commonReducer";
import "./SelectEmployee.scss";
import Select from "../../../commonComponents/Select/Select";
import TitleBar from "../../../commonComponents/TitleBar/TitleBar";
import Button from "../../../commonComponents/Button/Button";
import useTranslate from "../../../utils/useTranslate";
// import Select from "react-select";

export default function SelectEmployee({
  department,
  setDepartment,
  section,
  setSection,
  user,
  setUser,
  err,
  page,
  applySearch,
  onClear,
  perPage,
  currentPage,
  setCurrentPage
}) {
  const dispatch = useDispatch();
  const { t } = useTranslate()

  const departmentList = useSelector(department_details);
  const sectionList = useSelector(section_details);
  const userList = useSelector(all_hr_users_without_roles_list);

  useEffect(() => {
    dispatch(getDepartment());
    let formData = new FormData();
    formData.append("department", "");
    formData.append("section", "");
    formData.append("page", currentPage);
    formData.append("perPage", perPage);
    dispatch(getUsersWithoutRolePermissions(formData));
  }, []);

  const onDepartmentChangeHandler = (value) => {
    setDepartment(value);
    setSection("");
    dispatch(updateRedux({ key: "section_details", value: [] }));
    value._id && dispatch(getSection(value._id));
    let formData = new FormData();
    formData.append("department", value?._id || '');
    formData.append("section", "");
    formData.append("page", 0);
    formData.append("perPage", perPage);
    setCurrentPage(0)
    dispatch(getUsersWithoutRolePermissions(formData));
  };

  const onSectionChangeHandler = (value) => {
    setSection(value);
    let formData = new FormData();
    formData.append("department", department?._id);
    formData.append("section", value?._id);
    formData.append("page", 0);
    formData.append("perPage", perPage);
    setCurrentPage(0)
    dispatch(getUsersWithoutRolePermissions(formData));
  };

  const onUserChangeHandler = (value) => {
    setUser(value);
    let formData = new FormData();
    formData.append("department", department ? department._id : "");
    formData.append("section", section ? section._id : "");
    formData.append("user", value?._id);
    formData.append("page", 0);
    formData.append("perPage", perPage);
    setCurrentPage(0)
    dispatch(getUsersWithoutRolePermissions(formData));
  };

  return (
    <div id="SelectEmployee">
      <TitleBar title='Select Employee' top />
      <div className="inputs">
        <Select
          className='css-slc-esc2'
          placeholder={t('Select Department')}
          options={departmentList}
          value={department}
          labelSetter={(e) => e.department_name}
          valueSetter={(e) => e._id}
          onChange={(e) => onDepartmentChangeHandler(e)}
          error={err.department}
        />
        <Select
          className='css-slc-esc2'
          placeholder={t('Select Section')}
          options={sectionList}
          value={section}
          labelSetter={(e) => e.section_name}
          valueSetter={(e) => e._id}
          onChange={(e) => onSectionChangeHandler(e)}
          error={err.section}
        />
        <Select
          className='css-slc-esc2'
          placeholder={t('Select User')}
          options={userList}
          value={user}
          labelSetter={(e) => e.name}
          valueSetter={(e) => e._id}
          onChange={(e) => onUserChangeHandler(e)}
          error={err.user}
        />
        <div className="search_button">
          {(department || section || user) ?
            <Button className="css-btn-esc2" onClick={onClear && onClear} content="X" width='30px' /> : ""}
        </div>
      </div>

    </div>
  );
}