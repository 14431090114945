import React, { useMemo, useState } from "react";
import CommentsWithAttachments from "../../../../components/CommentsWithAttachments/CommentsWithAttachments";
import Modals from "../../../../components/Modals/Modals";
import PersonalInformation from "../../../../components/PersonalInformation/PersonalInformation";
import RequestDetailsData from "../../../../components/RequestDetailsData/RequestDetailsData";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  approveCertificateRequest,
  certificateRequestDetails,
  changeCertificateRequestStatus,
  errorToast,
  forwardCertificateRequest,
  getRequestStatus,
  successToast,
  updateCertificateRequest,
} from "../../../../redux/actionCreator";
import {
  certificate_details,
  certificate_details_loading,
  certificate_details_path,
  request_status_details,
  update_certificate_request_loading,
} from "../../../../redux/commonReducer";

import { Tooltip } from "react-bootstrap";
import FileViewModal from "../../../../components/FileViewModal/fileViewModal";
import moment from "moment";
import TitleBar from "../../../../commonComponents/TitleBar/TitleBar";
import Table from "../../../../commonComponents/Table/Table";
import TextArea from "../../../../commonComponents/TextArea/TextArea";
import AttachmentsEyeView from "../../../../commonComponents/AttachmentsEyeView/AttachmentsEyeView";
import StatusBox from "../../../../commonComponents/StatusBox/StatusBox";
import TableCommentsViewer from "../../../../commonComponents/TableCommentsViewer/TableCommentsViewer";
import LoadingSpinner from "../../../../components/LoadingSpinner/LoadingSpinner";
import Title from "../../../../commonComponents/Title/Title";
import Breadcrump from "../../../../commonComponents/Breadcrump/Breadcrump";
import FooterButtons from "../../../../commonComponents/FooterButtons/FooterButtons";
import { API_BASE_URL } from "../../../../constants/configuration";
import Button from "../../../../commonComponents/Button/Button";
import Select from "../../../../commonComponents/Select/Select";
import useTranslate from "../../../../utils/useTranslate";
import { toast } from "react-toastify";
import DateTimeViewer from "../../../../commonComponents/DateTimeViewer/DateTimeViewer";
import PageHeader from "../../../../commonComponents/PageHeader/PageHeader";

export default function RequestDetails_admin() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslate()

  const { id, mode } = useParams();

  const isEditMode = mode === "edit"

  const details = useSelector(certificate_details);
  const url = useSelector(certificate_details_path);
  const loading = useSelector(certificate_details_loading)
  const requestStatus = useSelector(request_status_details);
  const certficateUpdateLoading = useSelector(update_certificate_request_loading)

  const [show, setShow] = useState(false);
  const [certificateStatus, setCertificateStatus] = useState("");
  const [comment, setComment] = useState("");
  const [err, setErr] = useState({});
  const [uploadFiles, setUploadFiles] = useState([]);
  const [fileView, setFileView] = useState(null);
  const [fileType, setFileType] = useState(null);
  const [fileNumber, setFileNumber] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [oldFileView, setOldFileView] = useState(null);
  const [input, setInput] = useState({
    file: "",
    finalComment: "",
    forwardUser: "",
    returnComment: "",
    rejectionComment: "",
    closeComment: "",
  });
  const [modalErr, setModalErr] = useState({});
  const [data, setData] = useState([]);

  useEffect(() => {
    if (id) {
      dispatch(certificateRequestDetails(id));
    }
  }, [id]);

  useEffect(() => {
    dispatch(getRequestStatus());
  }, []);

  const getAssignedTo = (item, index) => {
    return ((item?.status?.handle_key == "return" || item?.status?.handle_key == "reject" || item?.status?.handle_key == "approve" || item?.status?.handle_key == "close") ? `${details?.request_user?.name}` :
      item?.status?.handle_key == "open" ? item?.handled_by?._id === details?.request_user?._id ? details?.actions[index + 1]?.handled_by?.name || item?.handled_by?.name : item?.handled_by?.name :
        item?.forwarded_to?.name ?? item?.handled_by?.name)
  }

  useEffect(() => {
    setData([]);
    if (details?.actions) {
      details?.actions?.forEach((item, i) => {
        let userObject = JSON.parse(localStorage.getItem("user"))
        setData((s) => [
          ...s,
          {
            sl_no: details?.actions?.length - i,
            received_date: <DateTimeViewer dateTime={item?.received_date} />,
            last_update: <DateTimeViewer dateTime={item?.last_update} />,
            handled_by: (item?.status?.handle_key == "close" && item?.handled_by === null) ? "System" : item?.handled_by?.name ?? "-",
            status: <StatusBox status={item?.status?.handle_key == "return" ? "Need Info" : item?.status?.request_status} />,
            assigned_to: getAssignedTo(item, i),
            attachments: <AttachmentsEyeView files={{ path: API_BASE_URL + 'certificateRequests/', endpoints: item?.attachments }} />,
            comments: <TableCommentsViewer comment={item?.comment} />,
          },
        ]);
      });
    }
  }, [details]);

  const onFileChangeHandler = (e, isInvalid, message) => {
    if (isInvalid) {
      setErr({
        ...err,
        source_file:
          message
      });
    } else {
      setUploadFiles(e);
    }
    // console.log({e})
    // let temp = [];
    // let size = 0;
    // e?.forEach((ele) => {
    //   const file = ele?.metaFile;
    //   const fileType = file.name
    //     .substr(file.name.lastIndexOf(".") + 1)
    //     .toLowerCase();
    //   size = size + ele.size;
    //   if (
    //     ["jpeg", "jpg", "png", "pdf", "mp4", "docx", "doc"].includes(
    //       fileType
    //     )
    //   ) {
    //     setErr({ ...err, source_file: "" });
    //   } else {
    //     temp.push(ele);
    //     setErr({
    //       ...err,
    //       uploadFiles:
    //         t("Please upload files with jpeg, jpg, png, pdf, mp4, docx, doc formats"),
    //     });
    //   }
    // });
    // let totalFileSize = size;
    // const fileSize = Math.round(totalFileSize / 1024);
    // if (fileSize > 50 * 1024) {
    //   setErr({
    //     ...err,
    //     uploadFiles: t("File too Big, please select files less than 50mb"),
    //   });
    // } else {
    //   if (temp?.length === 0) {
    //     setErr({
    //       ...err,
    //       uploadFiles: "",
    //     });
    //   }
    // }
    // if (fileSize <= 50 * 1024 && temp?.length == 0) {
    //   setUploadFiles(e);
    // }
  };

  // console.log({ uploadFiles })

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {props.label}
    </Tooltip>
  );

  const fileUploadViewHandler = (ele) => {
    console.log(ele);
    setFileView(ele);
    setFileType("");
    setFileNumber("");
    setModalShow(true);
  };

  const onSaveHandler = () => {
    // if (validate()) {
    if (certificateStatus && certificateStatus.handle_key !== "processing") {
      setShow(true);
    } else {
      onAddRequestHandler();
    }
    // }
  };

  const onAddRequestHandler = () => {
    if (err.uploadFiles) {
      toast.error(err.uploadFiles, {
        position: "bottom-center",
        autoClose: 3000
      })
      return
    }
    if (
      (certificateStatus == "" && uploadFiles?.length) ||
      comment?.trim()?.length != 0
    ) {
      updateHandler();
    }
    if (comment.trim() === "") {
      setErr({
        ...err,
        comment: t("Please enter comment"),
      });
      return;
    }
    if (certificateStatus != "") {
      // console.log(comment, 'laksjdflkasdjdfl;')
      // if (!/^[a-zA-Z0-9ء-ي .؟،,-:!"?()@;'\n]+$/.test(comment)) {
      //   setErr({
      //     ...err,
      //     comment: t("Invalid characters"),
      //   });
      // return;
      // }
      let formData = new FormData();
      switch (certificateStatus.handle_key) {
        //open
        case "open": {
          formData.append("id", id);
          formData.append("status", certificateStatus._id);
          formData.append("comment", comment);
          uploadFiles?.forEach((item, i) =>
            formData.append("attachment", item.metaFile)
          );
          console.log(formData);
          dispatch(
            changeCertificateRequestStatus(formData, () => {
              setShow(false);
              navigate("/admin/human_resource/certificate_requests");
            })
          );
          break;
        }
        //approve
        case "approve": {

          console.log({ input })
          formData.append("id", id);
          formData.append("comment", comment);
          formData.append("status", certificateStatus._id);
          formData.append("attachment", input.file)
          dispatch(
            approveCertificateRequest(formData, (status, msg) => {
              if (status) {
                successToast(t(msg))
                setShow(false);
                navigate("/admin/human_resource/certificate_requests");
              } else errorToast(t(msg))
            })
          );
          break;
        }
        //forward
        case "forward": {
          formData.append("id", id);
          formData.append("comment", comment);
          formData.append("status", certificateStatus._id);
          uploadFiles?.forEach((item, i) =>
            formData.append("attachment", item.metaFile)
          );
          formData.append("forwarded_to", input?.forwardUser?._id);
          dispatch(
            forwardCertificateRequest(formData, (status, msg) => {
              if (status) {
                successToast(t(msg))
                setShow(false)
                navigate("/admin/human_resource/certificate_requests");
              }
              else errorToast(t(msg))
            })
          );
          break;
        }
        //return
        case "return": {
          formData.append("id", id);
          formData.append("status", certificateStatus._id);
          formData.append("comment", comment);
          uploadFiles?.forEach((item, i) =>
            formData.append("attachment", item.metaFile)
          );
          console.log(formData);
          dispatch(
            changeCertificateRequestStatus(formData, () => {
              setShow(false);
              navigate("/admin/human_resource/certificate_requests");
            })
          );
          break;
        }
        //Pending
        case "pending": {
          formData.append("id", id);
          formData.append("status", certificateStatus._id);
          formData.append("comment", comment);
          uploadFiles?.forEach((item, i) =>
            formData.append("attachment", item.metaFile)
          );
          console.log(formData);
          dispatch(
            changeCertificateRequestStatus(formData, () => {
              setShow(false);
              navigate("/admin/human_resource/certificate_requests");
            })
          );
          break;
        }
        //Processing
        case "processing": {
          formData.append("id", id);
          formData.append("status", certificateStatus._id);
          formData.append("comment", comment);
          uploadFiles?.forEach((item, i) =>
            formData.append("attachment", item.metaFile)
          );
          console.log(formData);
          dispatch(
            changeCertificateRequestStatus(formData, () => {
              setShow(false);
              navigate("/admin/human_resource/certificate_requests");
            })
          );
          break;
        }
        //reject
        case "reject": {
          formData.append("id", id);
          formData.append("status", certificateStatus._id);
          formData.append("comment", comment);
          uploadFiles?.forEach((item, i) =>
            formData.append("attachment", item.metaFile)
          );
          console.log(formData);
          dispatch(
            changeCertificateRequestStatus(formData, () => {
              setShow(false);
              navigate("/admin/human_resource/certificate_requests");
            })
          );
          break;
        }
        //close
        case "close": {
          formData.append("id", id);
          formData.append("status", certificateStatus._id);
          formData.append("comment", comment);
          uploadFiles?.forEach((item, i) =>
            formData.append("attachment", item.metaFile)
          );
          console.log(formData);
          dispatch(
            changeCertificateRequestStatus(formData, () => {
              setShow(false);
              navigate("/admin/human_resource/certificate_requests");
            })
          );
          break;
        }

        default: {
          console.log("default");
          break;
        }
      }
    }
  };

  const updateHandler = () => {
    let hideSuccess;
    if (certificateStatus == "") {
      hideSuccess = false;
    } else {
      hideSuccess = true;
    }
    // console.log(comment, 'alskdfjlasjfdlj')
    // if (!/^[a-zA-Z0-9ء-ي .؟،,-:!"?()@;'\n]+$/.test(comment)) {
    //   setErr({
    //     ...err,
    //     comment: t("Invalid characters"),
    //   });
    // return;
    // }
    let formData = new FormData();
    formData.append("id", id);
    formData.append("is_status_changed", certificateStatus == "" ? 0 : 1);
    formData.append("comment", comment);
    // formData.append("user_id", details?.request_user._id);
    formData.append("user_id", localStorage.getItem("user_id"));
    formData.append("request_type", details?.request_type?._id);
    certificateStatus === "" && formData.append("status", details?.status?._id);
    formData.append("certificate_langage", details?.certificate_langage);
    formData.append("certificate_addressee", details.certificate_addressee);
    console.log({ uploadFiles })
    // if (certificateStatus == "") {
    uploadFiles?.forEach((item, i) => { formData.append("attachments", item.metaFile) });
    // }
    console.log(formData, "update");
    dispatch(
      updateCertificateRequest(formData, (res) => {
        if (certificateStatus === "") {
          navigate("/admin/human_resource/certificate_requests");
        }
        if (!hideSuccess) {
        }
      })
    );
  };

  const adminPermission = useMemo(() => {
    // console.log(details);
    if (details) {
      // console.log(details?.status?.handle_key != "approve");
      return details?.status?.handle_key != "approve";
    } else {
      return true;
    }
  }, [details]);

  const onCancel = () => {
    setComment("")
    setErr({})
    setUploadFiles([])
  }

  function selectHandler(e) {
    setCertificateStatus(e);
    if (e && e.handle_key !== "processing") setShow(true)
  }

  // console.log({ certificateStatus })

  return (
    <div id="RequestDetails_admin">
      {loading ?
        <LoadingSpinner height="600px" /> :
        <>
          {/* FILE VIEW */}
          {/* < FileViewModal
            modalshow={modalShow}
            setModalShow={setModalShow}
            fileView={fileView}
            oldFileView={oldFileView}
            url={url}
            fileType={fileType}
            fileNumber={fileNumber}
            setOldFileView={setOldFileView}
            setFileView={setFileView}
            setFileNumber={setFileNumber}
            setFileType={setFileType}
          /> */}
          <section>
            <PageHeader>
              <Title content={isEditMode ? 'Edit Request Details' : "Request Details"} />
              <Breadcrump content={[
                { name: "Certificate Requests", path: "/admin/human_resource/certificate_requests" },
                { name: "HR", path: "/admin/human_resource/certificate_requests" },
                { name: "Home", path: "/admin" }]}
              />
              <StatusBox status={details?.status?.handle_key === "return" ? "Need Info" : details?.status?.request_status} />
            </PageHeader>
          </section>
          <section className="details">
            <TitleBar title={'Personal Information'} toggleButton defaultToggle={'show'}>
              <PersonalInformation user={details?.request_user} page='requestDetails_admin' />
            </TitleBar>
          </section>
          <section>
            <TitleBar title={'Request Details'} toggleButton defaultToggle={'show'}>
              <RequestDetailsData data={details} />
            </TitleBar>
          </section>
          {details?.actions?.length ?
            <section>
              <TitleBar title={'History'} toggleButton defaultToggle={'show'} >
                <Table
                  className={'css-tbl-esc1'}
                  titles={[
                    { title: 'Sl No', key: 'sl_no', width: '6%' },
                    { title: 'Received Date', key: 'received_date', width: '12%', dir: 'ltr' },
                    { title: 'Last Update', key: 'last_update', width: '11%', dir: 'ltr' },
                    { title: 'Handled By', key: 'handled_by', width: '11%' },
                    { title: 'Assigned To', key: 'assigned_to', width: '11%' },
                    { title: 'Attachments', key: 'attachments', width: '10%' },
                    { title: 'Status', key: 'status', width: '12%' },
                    { title: 'Comments', key: 'comments', width: '31%' },
                  ]}
                  content={data}
                />
              </TitleBar>
            </section> : null}
          {adminPermission && isEditMode ? (
            <section>
              <CommentsWithAttachments
                comment={comment}
                setComment={setComment}
                setErr={setErr}
                setModalErr={setModalErr}
                err={err}
                onFileChangeHandler={onFileChangeHandler}
                uploadFiles={uploadFiles}
                renderTooltip={renderTooltip}
                fileUploadViewHandler={fileUploadViewHandler}
                setUploadFiles={onFileChangeHandler}
              />

            </section>
          ) : null}
          <section>
            <FooterButtons>
              <Button right className="css-btn-esc3" content='Back' icon='/assets/icons/Back.svg' reverse iconWidth='15px'
                onClick={() => navigate(-1)} />
              {isEditMode &&
                <Select className={'css-slc-esc3'} left placeholder='Select' value={certificateStatus}
                  options={requestStatus?.filter((ele, i) => ele.handle_key != "open")} disabled={certficateUpdateLoading}
                  labelSetter={(e) => e.request_status} valueSetter={(e) => e._id} onChange={selectHandler}
                />}
              {isEditMode &&
                <Button left className="css-btn-esc3" content={'Save'} onClick={onSaveHandler} disabled={certficateUpdateLoading} />}
              {isEditMode &&
                <Button left className="css-btn-esc3" content='Cancel' onClick={onCancel} />}
            </FooterButtons>
          </section>
          <Modals
            show={show}
            setShow={setShow}
            type={certificateStatus.handle_key}
            onAddRequestHandler={onAddRequestHandler}
            input={input}
            setInput={setInput}
            modalErr={modalErr}
            setModalErr={setModalErr}
            comment={comment}
            setComment={setComment}
            details={details}
            setCertificateStatus={setCertificateStatus}
          />
        </>}
    </div >
  );
}
