import React from 'react'
import './ModalContent.scss'

export default function ModalContent({ header, children, className, width }) {

  return (
    <div id='ModalContent' className={className || ''} style={{ minWidth: width || '600px' }}>
      <p className='header'>{header}</p>
      <div className="modal_inner_content">
        {children && (!Array.isArray(children) ? [children] : children).filter(obj => obj.type.displayName !== 'Button')}
        <div className='modal_buttons'>
          {children && (!Array.isArray(children) ? [children] : children).filter(obj => obj.type.displayName === 'Button')}
        </div>
      </div>
    </div>
  )
}
