export function requiredFields(obj) {

    let initial = {
        employeeType: true,
        first_name: true,
        first_name_ar: true,
        second_name: true,
        second_name_ar: true,
    }

    let military = {
        first_name: true,
        first_name_ar: true,
        second_name: true,
        second_name_ar: true,
        military_certificate: true,
        photo: true,
        employee_joined: true,
        oracle_updated: true,
        joining_date: true,
    }

    let civilian = {
        first_name: true,
        second_name: true,
        first_name_ar: true,
        second_name_ar: true,
        email: true,
        phone: true,
        gender: true,
        nationality: true,
        dob: true,
        birth_certificate: true,
        military_certificate: obj?.gender === 'Male',
        emirates_id: true,
        passport_no: true,
        designation: true,
        joining_date: true,
        department: true,
        section: true,
        grade: true,
        maritalStatus: true,
        father_emirates_id: true,
        father_passport_no: true,
        mother_emirates_id: true,
        mother_passport_no: true,
        spouse_name: true,
        spouse_passport_no: true,
        spouse_emirates_id: true,
        have_children: true,
        photo: true,
        emirates_id_proof: true,
        passport_proof: true,
        police_clearance_certificate: true,
        educational_certificate: true,
        medical_report: true,
        cv: true,
        offer_letter: true,
        executive_letter_certificate: true,
        financial_disclosure: true,
        experience_certificate: true,
        previous_salary_certificate: true,
        welcome_email: true,
        family_book: true,
        other: true,
        divorce_certificate: true,
        father_emirates_id_proof: true,
        father_passport_proof: true,
        mother_emirates_id_proof: true,
        mother_passport_proof: true,
        spouse_passport_proof: true,
        spouse_emirates_id_proof: true,
        employee_joined: true,
        oracle_updated: true
    }

    let children = {
        child_name: true,
        child_gender: true,
        child_dob: true,
        child_emirates_id: true,
        child_passport: true,
        child_emirates_id_proof: true,
        child_passport_proof: true,
    }

    let result = {
        ...initial,
        ...children,
        ...(obj?.employeeType?.value === 'military' && military),
        ...(obj?.employeeType?.value === 'civilian' && civilian)
    }

    return result
}