import React from 'react'
import './PageHeader.scss'

export default function PageHeader({ children }) {

  return (
    <div id='PageHeader'>
      <div>
        {children && (!Array.isArray(children) ? [children] : children).filter(obj => (['Title', 'Breadcrump'].includes(obj.type.displayName)))}
      </div>
      {children && (!Array.isArray(children) ? [children] : children).filter(obj => (['Button', 'StatusBox'].includes(obj.type.displayName)))}
    </div>
  )
}