import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  deleteRole,
  getAllUsersForHR,
  getDepartment,
  getHRModules,
  getModules,
  getRoles,
  getSection,
} from "../../../redux/actionCreator";
import {
  all_hr_users_list,
  department_details,
  hr_modules,
  modules,
  permission_list,
  permission_list_loading,
  permission_page_status,
  permission_pages,
  request_status_details,
  request_type_details,
  section_details,
  updateRedux,
} from "../../../redux/commonReducer";
import { useState } from "react";
import Pagination from "../../../components/Pagination/Pagination";
import Title from "../../../commonComponents/Title/Title";
import BreadCrump from "../../../commonComponents/Breadcrump/Breadcrump";
import Button from "../../../commonComponents/Button/Button";
import EntriesCounter from "../../../commonComponents/EntriesCounter/EntriesCounter";
import Input from "../../../commonComponents/Input/Input";
import Table from "../../../commonComponents/Table/Table";
import Modal from "../../../commonComponents/Modal/Modal";
import { Form } from "react-bootstrap";
import SearchBox from "../../../commonComponents/SearchBox/SearchBox";
import Actions from "../../../commonComponents/Actions/Actions";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import Modals from "../../../components/Modals/Modals";
import useTranslate from "../../../utils/useTranslate";
import PageHeader from "../../../commonComponents/PageHeader/PageHeader";
import ModalContent from "../../../commonComponents/ModalContent/ModalContent";

export default function PermissionList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslate();

  const permissionData = useSelector(permission_list);
  const loading = useSelector(permission_list_loading);
  const pageCount = useSelector(permission_pages);
  const pageStatus = useSelector(permission_page_status);
  const hrModules = useSelector(modules);
  const moduleList = useSelector(modules);
  const requestTypeList = useSelector(request_type_details);
  const departmentList = useSelector(department_details);
  const sectionList = useSelector(section_details);
  const userList = useSelector(all_hr_users_list);
  const requestStatus = useSelector(request_status_details);

  const [data, setData] = useState([]);
  const [sortAscending, setSortAscending] = useState(false);
  const [sortColumn, setSortColumn] = useState("last_update");
  const [page, setPage] = useState(0);
  const [filterData, setFilterData] = useState({
    department: "",
    section: "",
    user: "",
    keyword: "",
  });
  const [appliedFilterData, setAppliedFilterData] = useState({
    department: "",
    section: "",
    user: "",
    keyword: "",
  });
  const [perPage, setPerPage] = useState(10);
  const [modalRequestshow, setModalRequestShow] = useState(false);
  const [delId, setDelId] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [itemToDelete, setItemToDelete] = useState();

  useEffect(() => {
    getDataHandler();
    dispatch(getModules());
    dispatch(getDepartment());
    dispatch(getAllUsersForHR());
  }, []);

  useEffect(() => {
    setData([]);
    if (permissionData) {
      permissionData?.forEach((item) => {
        setData((s) => [
          ...s,
          {
            employee_id: item?.employee_id,
            employee_name: item?.name,
            department: item?.department?.department_name ?? (
              <span className="mr-5">--</span>
            ),
            section: item?.section[0]?.section_name ?? (
              <span className="mr-5">--</span>
            ),
            designation: item.designation?.[0]?.designation_name || (
              <span className="mr-5">--</span>
            ),
            privilege: (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                <img
                  style={{ cursor: "pointer", width: 20 }}
                  src="/assets/icons/view_permission.svg"
                  alt="edit"
                  onClick={() => {
                    setShowModal(true);
                    setModalData(handleModule(item.permissions));
                  }}
                />
                {/* {item?.permissions?.length} */}
                {permissionCountHandler(item?.permissions)}
              </div>
            ),
            action: (
              <Actions
                rootPath="/assets/icons/"
                buttons={{
                  edit: "Edit.svg",
                  // non_edit: "Edit Grey.svg",
                  delete: "delete.svg",
                  // non_delete: "delete_grey.svg",
                }}
                // visibility={{
                //   edit: !checkForDesignation(item?.designation),
                //   // non_edit: checkForDesignation(item?.designation),
                //   delete: !checkForDesignation(item?.designation),
                //   // non_delete: checkForDesignation(item?.designation),
                // }}
                onEdit={() =>
                  // navigate("update-permission", {
                  //   state: {
                  //     employeeId: item.employee_id,
                  //     permission: item.permissions,
                  //   },
                  // })
                  navigate(`update-permission/${item?._id}`)
                }
                onDelete={() => handleShowDeleteModal(item)}
              />
            ),
          },
        ]);
      });
    }
  }, [permissionData]);

  const handleModule = (permissionData) => {
    let moduleObj = {};
    moduleList?.forEach((ele, i) => {
      let temp = [];
      ele.modules?.forEach((e) => {
        let value = permissionData?.find((item) => item?.module_name == e?.key);
        temp.push({
          ...e,
          admin: value?.admin == "1" ? true : false,
          report: value?.report == "1" ? true : false,
        });
      });
      moduleObj[ele.main_access] = temp;
    });
    let arr = moduleList?.map((item) => {
      return {
        ...item,
        modules: moduleObj[item?.main_access],
      };
    });
    return [...arr];
  };

  const handleShowDeleteModal = (obj) => {
    setItemToDelete(obj);
    setShowDeleteModal(true);
  };

  const getSortingClassName = (title) => {
    let className = "";
    if (sortColumn === title) {
      if (sortAscending) {
        className = "active";
      } else {
        className = "inactive";
      }
    }
    return className;
  };

  const titles = [
    {
      title: "Employee ID",
      key: "employee_id",
      sort: true,
      width: "11%",
    },
    {
      title: "Employee Name",
      key: "employee_name",
      sort: true,
      width: "100px",
    },
    {
      title: "Department",
      key: "department",
      sort: true,
      width: "110px",
    },
    {
      title: "Section",
      key: "section",
      sort: true,
      width: "90px",
    },
    {
      title: "Designation",
      key: "designation",
      sort: true,
      width: "90px",
    },
    {
      title: "Module Privileges",
      key: "privilege",
      sort: true,
      width: "90px",
    },
    { title: "Action", key: "action", width: "85px" },
  ];

  const getDataHandler = () => {
    let formData = new FormData();
    formData.append("page", 0);
    formData.append("perPage", perPage);
    formData.append("sort_order", sortAscending ? 1 : -1);
    formData.append("sort_key", sortColumn);
    dispatch(getRoles(formData));
  };

  const getSearchDataHandler = (
    pag,
    perpage,
    sort,
    column,
    enableAppliedFilter
  ) => {
    let currentFilter = enableAppliedFilter ? appliedFilterData : filterData;
    let formData = new FormData();
    formData.append(
      "department",
      currentFilter?.department ? currentFilter?.department?._id : ""
    );
    formData.append(
      "section",
      currentFilter?.section ? currentFilter?.section?._id : ""
    );
    formData.append(
      "user",
      currentFilter?.user ? currentFilter?.user?._id : ""
    );
    formData.append("keyword", currentFilter?.keyword.trim());
    formData.append("page", pag);
    formData.append("perPage", perpage);
    formData.append("sort_order", sort ?? -1);
    formData.append("sort_key", column);
    dispatch(getRoles(formData));
    enableAppliedFilter && setFilterData(appliedFilterData);
  };

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    return currentDate.getTime() < selectedDate.getTime();
  };

  const clearFilter = () => {
    setFilterData({
      department: "",
      section: "",
      user: "",
      keyword: "",
    });
    setAppliedFilterData({
      department: "",
      section: "",
      user: "",
      keyword: "",
    });
    getDataHandler();
    dispatch(updateRedux({ key: "section_details", value: [] }));
    let formData = new FormData();
    formData.append("department", "");
    formData.append("section", "");
    dispatch(getAllUsersForHR(formData));
  };

  const applySearch = () => {
    let sort = sortAscending ? 1 : -1;
    setAppliedFilterData(filterData);
    getSearchDataHandler(0, perPage, sort, sortColumn);
  };

  const onSortHandler = (type) => {
    setSortAscending(!sortAscending);
    setSortColumn(type.key);
    let sort = !sortAscending ? 1 : -1;
    getSearchDataHandler(page, perPage, sort, type.key, true);
  };

  const handlePageChange = (e) => {
    setPage(e.selected);
    let sort = sortAscending ? 1 : -1;
    getSearchDataHandler(e.selected, perPage, sort, sortColumn, true);
  };

  const onLimitChangeHandler = (value) => {
    setPerPage(parseInt(value));
    setPage(0);
    let sort = sortAscending ? 1 : -1;
    getSearchDataHandler(0, parseInt(value), sort, sortColumn, true);
  };

  const onDepartmentChangeHandler = (value) => {
    setFilterData({ ...filterData, department: value, section: "" });
    dispatch(updateRedux({ key: "section_details", value: [] }));
    value._id && dispatch(getSection(value._id));
    let formData = new FormData();
    formData.append("department", value?._id || "");
    formData.append("section", "");
    dispatch(getAllUsersForHR(formData));
  };

  function searchBoxChangeHandler(e, key) {
    switch (key) {
      case "request_type":
        setFilterData({ ...filterData, request_type: e });
        break;
      case "date_from":
        setFilterData({ ...filterData, start_date: e });
        break;
      case "date_to":
        setFilterData({ ...filterData, end_date: e });
        break;
      case "department":
        onDepartmentChangeHandler(e);
        break;
      case "employee":
        setFilterData({ ...filterData, user: e });
        break;
      case "section":
        setFilterData({ ...filterData, section: e });
        break;
      case "status":
        setFilterData({ ...filterData, status: e });
        break;
      default:
    }
  }

  const ViewPermissionModalContent = () => {
    return (
      <div className="modal__padd">
        <ModalContent header="Permission Privileges">
          <div className="d-flex flex-column gap-4">
            {modalData?.length &&
              modalData?.map((ele, j) => {
                return (
                  <div key={j}>
                    <h3>{ele.main_access}</h3>
                    <table>
                      {ele?.modules?.map((item, i) => (
                        <tr key={i}>
                          <td style={{ width: 200 }}>{item.value}</td>
                          <td style={{ width: 150 }}>
                            <div style={{ display: "flex" }}>
                              <Form.Check
                                checked={item?.admin == true ? true : false}
                                disabled
                                style={{ marginLeft: 10 }}
                              />
                              Admin
                            </div>
                          </td>
                          <td style={{ width: 150 }}>
                            {!["Employee Management"]?.includes(item.value) ? (
                              <div style={{ display: "flex" }}>
                                <Form.Check
                                  checked={item?.report == true ? true : false}
                                  disabled
                                  style={{ marginLeft: 10 }}
                                />
                                Reports
                              </div>
                            ) : (
                              <></>
                            )}
                          </td>
                        </tr>
                      ))}
                    </table>
                  </div>
                );
              })}
          </div>
        </ModalContent>
      </div>
    );
  };

  const onDeleteHandler = () => {
    const value = {
      id: itemToDelete?._id,
    };
    dispatch(
      deleteRole(value, () => {
        setShowDeleteModal(false);
        dispatch(getRoles());
      })
    );
  };

  const permissionCountHandler = (value) => {
    let temp = value?.filter((ele) => ele?.admin == "1" || ele?.report == "1");
    return temp?.length;
  };

  const checkForDesignation = (value) => {
    let arr = ["Section Head", "Director", "Deputy Director"];
    let temp = arr?.includes(value[0]?.designation_name);
    return temp;
  };

  return (
    <div id="CertificateRequests">
      <>
        <section>
          <PageHeader>
            <Title content="Permission" />
            <BreadCrump
              content={[
                { name: "Permission", path: "/admin/settings/permissions" },
                { name: "Home", path: "/admin" },
              ]}
            />
            <Button
              className="css-btn-esc1"
              content="Add Permission"
              icon="/assets/icons/Add Request.svg"
              reverse
              onClick={() => navigate("add-permission")}
            />
          </PageHeader>
        </section>
        <section>
          <SearchBox
            elements={[
              {
                label: "Department",
                key: "department",
                type: "select",
                className: "css-slc-esc2",
                placeholder: "Select Department",
                width: "250px",
              },
              {
                label: "Section",
                key: "section",
                type: "select",
                className: "css-slc-esc2",
                placeholder: "Select Section",
                width: "200px",
              },
              {
                label: "Employee",
                key: "employee",
                type: "select",
                className: "css-slc-esc2",
                placeholder: "Select User",
                width: "200px",
              },
            ]}
            options={{
              department: {
                options: departmentList,
                value: filterData?.department,
                labelSetter: (option) => option.department_name,
                valueSetter: (option) => option._id,
              },
              section: {
                options: sectionList,
                value: filterData?.section,
                labelSetter: (option) => option.section_name,
                valueSetter: (option) => option._id,
              },
              employee: {
                options: userList,
                labelSetter: (option) => option.name,
                valueSetter: (option) => option._id,
                value: filterData?.user,
              },
            }}
            onSearch={applySearch}
            onClear={clearFilter}
            showClearBtn={
              filterData?.department ||
              filterData?.user ||
              filterData?.section ||
              filterData.keyword
            }
            onChange={searchBoxChangeHandler}
          />
        </section>
        <section className="d-flex justify-content-between">
          <EntriesCounter value={perPage} onChange={onLimitChangeHandler} />
          <Input
            value={filterData.keyword}
            type="text"
            className="css-inp-esc2 search"
            icon="/assets/icons/Search1.svg"
            placeholder={t("Generic Search")}
            reverse
            width="300px"
            onChange={(value) => {
              setFilterData((s) => ({ ...s, keyword: value }));
            }}
            onSubmit={applySearch}
            onIconClick={applySearch}
          />
        </section>
        {loading ? (
          <LoadingSpinner height={"600px"} />
        ) : (
          <section className="table">
            <Table
              className={"css-tbl-esc2"}
              titles={titles}
              content={data}
              onSort={(e) => onSortHandler(e)}
              page={page}
              pagesCount={pageCount}
              pageStatus={pageStatus}
              onPageChange={handlePageChange}
              sortValue={{ key: sortColumn, value: sortAscending ? 1 : -1 }}
            />
          </section>
        )}
        <Modal
          show={showModal}
          setShow={setShowModal}
          children={<ViewPermissionModalContent />}
        />
        <Modals
          show={showDeleteModal}
          setShow={setShowDeleteModal}
          type={"delete_role"}
          onDeleteHandler={onDeleteHandler}
        />
      </>
    </div>
  );
}
