import React, { useEffect, useState } from 'react'
import './Input.scss'
import { v4 } from 'uuid'
import useTranslate from '../../utils/useTranslate'

export default function Input({ type, label, value, field, onChange, onSubmit, onIconClick, placeholder, disabled, required, icon, className, reverse, width, height, error, checked, showCloseBtn, fixed }) {

  let uuid = v4()
  const { t } = useTranslate()

  const [inValue, setInValue] = useState('')
  const [inLabel, setInLabel] = useState('')
  const [inPlaceholder, setInPlaceholder] = useState('')

  useEffect(() => {
    value && setInValue(value)
    placeholder && setInPlaceholder(placeholder)
    label && setInLabel(label)
    return () => {
      setInValue('')
      setInPlaceholder('')
      setInLabel('')
    }
  }, [value, placeholder, label])

  function changeHandler(e) {
    setInValue(e.target.value)
    if (onChange) {
      type === 'checkbox'
        ? onChange(e.target.checked, field)
        : onChange(e.target.value, field)
    }
  }

  function keyDownHandler(e) {
    if (e.key === 'Enter')
      onSubmit && onSubmit(inValue, field)
  }

  function classNameFormatter(type, className, reverse) {
    let str = ''
    str = type ? type : str
    str = className ? str + ' ' + className : str
    str = reverse ? str + ' ' + 'reverse' : str
    return str
  }

  return (
    <span id='Input' className={classNameFormatter(type, className, reverse)}>
      {inLabel && <label htmlFor={uuid}>{t(inLabel)}{required && <em>*</em>}</label>}
      <span className={'wrapper' + (fixed ? ' has-fixed-value' : '')}>
        {fixed && <span className='fixed-value'>{fixed}</span>}
        <input
          id={uuid}
          type={type && type}
          value={inValue}
          checked={checked && checked}
          placeholder={t(inPlaceholder)}
          style={{ width: width, height: height }}
          onChange={(e) => changeHandler(e)}
          onKeyDown={keyDownHandler}
          disabled={disabled}
        />
        {icon && <img src={icon} onClick={() => onIconClick && onIconClick(inValue, field)} style={{ cursor: onIconClick ? 'pointer' : 'auto' }} />}
        {showCloseBtn && inValue && <span className='close_btn' onClick={() => { setInValue(''); onChange && onChange('', field) }}>✖</span>}
      </span>
      {error && <small id='error' className='error'>{t(error)}</small>}
    </span >
  )
}



{/*

<Input
  type=''
  label=''
  value=''
  field=''
  onChange={() => { }}
  onSubmit={() => { }}
  onIconClick={() => { }}
  placeholder=''
  icon=''
  className=''
  reverse
  width=''
  height=''
  error=''
  checked=''
  showCloseBtn
/>

*/}