import React, { useState } from 'react'
import './AttachmentsEyeView.scss'
import openEye from './Eye-Open.svg'
import closeEye from './Eye-Close.svg'
import AttachmentsPreview from '../AttachmentsPreview/AttachmentsPreview'
import Modal from '../Modal/Modal'

export default function AttachmentsEyeView({ files }) {

  const [modalShow, setModalShow] = useState(false)
  const [modalFiles, setModalFiles] = useState({})

  return (
    <span id='AttachmentsEyeView'>
      {
        files?.endpoints?.length > 0 ?
          <>
            <img className='eye_icon' src={openEye} alt="" onClick={() => { setModalShow(true); setModalFiles(files) }} />
            <p>{files?.endpoints?.length}</p>
          </>
          : <img className='eye_icon' src={closeEye} alt="" />
      }
      <Modal setShow={setModalShow} show={modalShow} backdropClose>
        {modalFiles && modalShow && <AttachmentsPreview data={modalFiles} />}
      </Modal>
    </span>
  )
}

// <AttachmentsEyeView
//   files={{path:'',endpoints:[]}}
// /> 