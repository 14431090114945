import React, { useEffect, useState } from 'react'
import PageHeader from '../../../commonComponents/PageHeader/PageHeader';
import Title from '../../../commonComponents/Title/Title';
import Breadcrump from '../../../commonComponents/Breadcrump/Breadcrump';
import Button from '../../../commonComponents/Button/Button';
import SearchBox from '../../../commonComponents/SearchBox/SearchBox';
import EntriesCounter from '../../../commonComponents/EntriesCounter/EntriesCounter';
import Input from '../../../commonComponents/Input/Input';
import Table from '../../../commonComponents/Table/Table';
import useTranslate from '../../../utils/useTranslate';
import { useNavigate } from 'react-router-dom';
import StatusBox from '../../../commonComponents/StatusBox/StatusBox';
import Actions from '../../../commonComponents/Actions/Actions';

export default function EA_settings() {


    const navigate = useNavigate()
    const { t } = useTranslate()

    const [data, setData] = useState([])
    const [filterData, setFilterData] = useState({})

    useEffect(() => {
        setData([
            {
                sl_no: 1,
                grade: 10,
                private_allowance: 12000.00,
                public_allowance: 6000.00,
                action: <Actions />
            }
        ])
    }, [])

    function applySearch() {

    }

    function onSortHandler() {

    }

    function clearFilter() {

    }


    function filterPassedTime(time) {
        const currentDate = new Date();
        const selectedDate = new Date(time);
        return currentDate.getTime() < selectedDate.getTime();
    }

    return (
        <div id='EA_settings'>
            <section className='mb-4'>
                <PageHeader>
                    <Title content="Education Allowance" />
                    <Breadcrump content={[
                        { name: "Educational Allowance", path: "/admin/settings/educational_allowance" },
                        { name: "Settings", path: "/admin/settings/certificate_type" },
                        { name: "Home", path: "/admin" }
                    ]} />
                    <Button width={80} content={"Add"} className="css-btn-esc1" icon="/assets/icons/Add Request.svg" reverse
                        onClick={() => navigate("/admin/settings/educational_allowance/add_allowance")} />
                </PageHeader>
            </section>
            <section className='mb-4'>
                <SearchBox
                    elements={[
                        { label: "Grade", key: "grade", type: "select", className: "css-slc-esc2", placeholder: "Select User", width: "200px", },
                        { label: "Allowance", key: "allowance", type: "select", className: "css-slc-esc2", placeholder: "Select Request Status", },
                    ]}
                    options={{
                        grade: { options: [], labelSetter: (o) => o.name, valueSetter: (o) => o._id, value: filterData?.user, },
                        allowance: { options: [], labelSetter: (o) => o.label, valueSetter: (o) => o.value, value: filterData?.status, },
                    }}
                    onSearch={applySearch}
                    onClear={clearFilter}
                    showClearBtn
                    onChange={() => { }}
                />
            </section>
            <section className="d-flex justify-content-between mb-4">
                <EntriesCounter value={filterData.entries} onChange={() => { }} />
                <Input value={filterData.keyword} type="text" className="css-inp-esc2 search" icon="/assets/icons/Search1.svg"
                    placeholder={t("Generic Search")} reverse width="300px" field="keyword"
                    onChange={(value) => { setFilterData((s) => ({ ...s, keyword: value })); }}
                    onSubmit={applySearch} showCloseBtn onIconClick={applySearch} />
            </section>
            <section className="table">
                <Table
                    className={"css-tbl-esc2"}
                    titles={[
                        { title: 'Sl No', key: 'sl_no' },
                        { title: 'Grade', key: 'grade' },
                        { title: 'Private School Allowance', key: 'private_allowance' },
                        { title: 'Public / Government School Allowance', key: 'public_allowance' },
                        { title: 'Action', key: 'action' },
                    ]}
                    content={data}
                    onSort={(e) => onSortHandler(e)}
                    page={filterData.page}
                    pagesCount={data?.pagesCount}
                    pageStatus={data?.pageStatus}
                    onPageChange={() => { }}
                    sortValue={{ key: '', value: 1 }}
                />
            </section>
        </div>
    )
}
