import React, { useEffect, useState } from 'react'
import './Breadcrump.scss'
import { useNavigate } from 'react-router-dom'
import useTranslate from '../../utils/useTranslate'

export default function Breadcrump({ content = [{ name: '', path: '' }] }) {

  const navigate = useNavigate()
  const { t } = useTranslate()

  const [items, setItems] = useState([])

  useEffect(() => {
    setItems(content)
    return () => {
      setItems([])
    }
  }, [content])

  function clickHandler(path) {
    path !== null && navigate(path)
  }

  return (
    <span id='Breadcrump'>
      {
        items.map((item, i) => (
          <p key={i} onClick={() => clickHandler(item.path)}>{t(item.name)}</p>
        ))
      }
    </span>
  )
}
Breadcrump.displayName = 'Breadcrump'