// export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL ? process.env.REACT_APP_API_BASE_URL : "http://10.10.16.31:5400/"
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL ? process.env.REACT_APP_API_BASE_URL : "https://tsd.shtdevops.xyz/ecom-full-crm/"

export const roles = {
    super_admin: "Super Admin",
    admin: "Admin",
    incident_manager: "Incident Manager",
    pool_admin: "Pool Admin",
    pool_user: "Pool User",
    user: "User"
}

export const REJECT = "reject"
export const APPROVE = "approve"
export const CLOSE = "close"
