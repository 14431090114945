import axios from 'axios';
import { toast } from 'react-toastify';
import { API_BASE_URL } from "../constants/configuration";
import axiosInstance from "../utils/axiosInterceptor";
import axiosFormInstance from '../utils/axiosFormInterceptor';
import { updateRedux } from './commonReducer';
// import { data } from 'jquery';

// const formHeader={
//   headers: {
//     'Content-Type': 'multipart/form-data',
//     'Authorization' : `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NDI1NGZlNTRjMDRkN2I4NjgyNGIxYmMiLCJpYXQiOjE2ODAxODAyMDIsImV4cCI6MTY4MDI2NjYwMn0._2vTRyPPMTUkoSsSoojI04sdTQhR2QHys_MdHj2_lwk`
//   },
// }

// const header={
//   headers: {
//     'Authorization' : `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NDI1NGZlNTRjMDRkN2I4NjgyNGIxYmMiLCJpYXQiOjE2ODAxODAyMDIsImV4cCI6MTY4MDI2NjYwMn0._2vTRyPPMTUkoSsSoojI04sdTQhR2QHys_MdHj2_lwk`
//   },
// }


export function successToast(msg) {
  toast.success(msg, {
    position: "bottom-center",
    autoClose: 3000
  })
}
export function errorToast(msg) {
  toast.error(msg, {
    position: "bottom-center",
    autoClose: 3000
  })
}

export const login = (data, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "login_loading", value: true }))
  axios.post(`${API_BASE_URL}hr/checkLogin`, data).then(res => {
    dispatch(updateRedux({ key: "login_loading", value: false }))
    if (res.data.status == true) {
      localStorage.setItem('token', res.data.accessToken)
      localStorage.setItem('user_type', res.data.role)
      localStorage.setItem('username', res.data.username)
      localStorage.setItem('user', JSON.stringify(res.data))
      localStorage.setItem('user_id', res.data._id)
      localStorage.setItem('current_user_permissions', JSON.stringify(res.data.permissions))
      localStorage.setItem('access', JSON.stringify(res.data.access))
      localStorage.setItem('main_access', JSON.stringify(res.data.main_access))
      dispatch(updateRedux({ key: "current_user_permissions", value: res.data.permissions }))
      dispatch(updateRedux({ key: "access", value: res.data.access }))
      dispatch(updateRedux({ key: "main_access", value: res.data.main_access }))
      if (callback) {
        callback(res.data.status)
      }
    } else {
      toast.error("Please check your username or password", {
        position: "bottom-center",
        autoClose: 3000
      })
      if (callback) {
        callback(res.data.status)
      }
    }
  }).catch((err) => {
    dispatch(updateRedux({ key: "login_loading", value: false }))
    console.log(err)
  })
}

export const logout = (callback) => (dispatch) => {
  let data = {
    accessToken: localStorage.getItem("token")
  }
  dispatch(updateRedux({ key: "logout_loader", value: true }))
  axiosInstance.post(`${API_BASE_URL}hr/logout`, data).then((res) => {
    dispatch(updateRedux({ key: "logout_loader", value: false }))
    if (res.data.status) {
      if (callback) {
        callback()
      }
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    dispatch(updateRedux({ key: "logout_loader", value: false }))
    console.log(err)
  })
}

export const getAllUsers = (data) => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}hr/getAllUsers`).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "user_list", value: res.data.user_list }))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}


export const getDepartment = () => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}hr/getDepartment`).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "department_details", value: res.data.department_details }))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getSection = (id, callback) => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}hr/getSection/${id}`).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "section_details", value: res.data.section_details }))
      if (callback) callback(res.data.section_details)
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getRequestStatus = () => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}hr/getRequestStatus`).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "request_status_details", value: res.data.request_status_details }))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getRequestStatusForAdmin = () => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}hr/getRequestStatusForAdmin`).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "request_status_details_for_admin", value: res.data.request_status_details }))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getRequestType = (data) => (dispatch) => {
  let formData = new FormData()
  if (data) {
    formData = data
  }
  dispatch(updateRedux({ key: "request_type_loading", value: true }))
  axiosInstance.post(`${API_BASE_URL}hr/getRequestType`, formData).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "request_type_loading", value: false }))
      dispatch(updateRedux({ key: "request_type_details", value: res.data.request_type_details }))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    dispatch(updateRedux({ key: "request_type_loading", value: false }))
    console.log(err)
  })
}

export const insertRequestType = (data, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_request_type_loading", value: true }))
  axiosInstance.post(`${API_BASE_URL}hr/insertRequestType`, data).then((res) => {
    dispatch(updateRedux({ key: "update_request_type_loading", value: false }))
    if (res.data.status) {
      toast.success(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
      dispatch(getRequestType())
      if (callback) {
        callback()
      }
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    dispatch(updateRedux({ key: "update_request_type_loading", value: false }))
    console.log(err)
  })
}

export const updateRequestType = (data, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_request_type_loading", value: true }))
  axiosInstance.post(`${API_BASE_URL}hr/updateRequestType`, data).then((res) => {
    dispatch(updateRedux({ key: "update_request_type_loading", value: false }))
    if (res.data.status) {
      toast.success(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
      dispatch(getRequestType())
      if (callback) {
        callback()
      }
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    dispatch(updateRedux({ key: "update_request_type_loading", value: false }))
    console.log(err)
  })
}

export const deleteRequestType = (data, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_request_type_loading", value: true }))
  axiosInstance.post(`${API_BASE_URL}hr/deleteRequestType`, data).then((res) => {
    dispatch(updateRedux({ key: "update_request_type_loading", value: false }))
    if (res.data.status) {
      toast.success(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
      dispatch(getRequestType())
      if (callback) {
        callback()
      }
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    dispatch(updateRedux({ key: "update_request_type_loading", value: false }))
    console.log(err)
  })
}

export const getUsersWithoutRolePermissions = (formData) => (dispatch) => {
  dispatch(updateRedux({ key: "all_hr_users_without_roles_list_loading", value: true }))
  axiosFormInstance.post(`${API_BASE_URL}hr/getUsersWithoutRolePermissions`, formData).then((res) => {
    dispatch(updateRedux({ key: "all_hr_users_without_roles_list_loading", value: false }))
    if (res.data.status) {
      dispatch(updateRedux({ key: "all_hr_users_without_roles_list", value: res.data.data }))
      dispatch(updateRedux({ key: "all_hr_users_without_roles_list_total_count", value: res.data.pages }))
      dispatch(updateRedux({ key: "all_hr_users_without_roles_list_page_count", value: res.data.page }))
      dispatch(updateRedux({ key: "all_hr_users_without_roles_list_page_status", value: res.data.page_status }))
      dispatch(updateRedux({ key: "all_hr_users_without_roles_list_loading", value: false }))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    dispatch(updateRedux({ key: "all_hr_users_without_roles_list_loading", value: false }))
    console.log(err)
  })
}

export const getAllUsersForHR = (formData) => (dispatch) => {
  dispatch(updateRedux({ key: "users_list_log_loading", value: true }))
  axiosFormInstance.post(`${API_BASE_URL}hr/getAllUsersForHR`, formData).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "users_list_log_loading", value: false }))
      dispatch(updateRedux({ key: "all_hr_users_list", value: res.data.data }))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    dispatch(updateRedux({ key: "users_list_log_loading", value: false }))
    console.log(err)
  })
}

export const getAllUsersForLogs = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "all_hr_users_list_for_log_loading", value: true }))
  axiosFormInstance.post(`${API_BASE_URL}hr/getAllUsersForLogs`, formData).then((res) => {
    dispatch(updateRedux({ key: "all_hr_users_list_for_log_loading", value: false }))
    if (res.data.status) {
      // console.log(res.data.user_list, 'res.data')
      dispatch(updateRedux({ key: "all_hr_users_list_for_log", value: res.data.users_list }))
      dispatch(updateRedux({ key: "users_list_for_log_total_count", value: res.data.pages }))
      dispatch(updateRedux({ key: "users_list_for_log_page_status", value: res.data.page_status }))
      callback && callback(true, res.data)
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    dispatch(updateRedux({ key: "all_hr_users_list_for_log_loading", value: false }))
    console.log(err)
  })
}


export const getCertificateRequests = (formData) => (dispatch) => {
  console.log('getCertificateRequests')
  dispatch(updateRedux({ key: "certificate_loading", value: true }))
  axiosFormInstance.post(`${API_BASE_URL}hr/getCertificateRequests`, formData).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "certificate_loading", value: false }))
      dispatch(updateRedux({ key: "certificate_request_list", value: res.data.certificate_list }))
      dispatch(updateRedux({ key: "certificate_path", value: res.data.path }))
      dispatch(updateRedux({ key: "certificate_pages", value: res.data.pages }))
      dispatch(updateRedux({ key: "certificate_page_status", value: res.data.page_status }))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
    dispatch(updateRedux({ key: "certificate_loading", value: false }))
  }).catch((err) => {

    console.log(err)
  })
}

export const addCertificateRequest = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "add_certificate_loading", value: true }))
  axiosFormInstance.post(`${API_BASE_URL}hr/insertCertificateRequest`, formData)
    .then(res => {
      dispatch(updateRedux({ key: "add_certificate_loading", value: false }))
      if (res.data.status == true) {
        if (callback) {
          callback(res.data.status);
        }
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000
        })
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000
        })
      }
    }).catch((err) => {
      dispatch(updateRedux({ key: "add_certificate_loading", value: false }))
      console.log(err);
    })
}

export const certificateRequestDetails = (id, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "certificate_details_loading", value: true }))
  axiosFormInstance.get(`${API_BASE_URL}hr/certificateRequestDetails/${id}`)
    .then(res => {
      dispatch(updateRedux({ key: "certificate_details_loading", value: false }))
      if (res.data.status == true) {
        dispatch(updateRedux({ key: "certificate_details", value: res.data.certificate_list }))
        dispatch(updateRedux({ key: "certificate_details_path", value: res.data.path }))
        if (callback) {
          callback();
        }
      } else {
        dispatch(updateRedux({ key: "certificate_details", value: undefined }))
        dispatch(updateRedux({ key: "certificate_details_path", value: undefined }))
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000
        })
      }
    }).catch((err) => {
      dispatch(updateRedux({ key: "certificate_details_loading", value: false }))
      console.log(err);
    })
}

export const updateCertificateRequest = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_certificate_request_loading", value: true }))
  axiosFormInstance.post(`${API_BASE_URL}hr/updateCertificateRequest`, formData).then(res => {
    dispatch(updateRedux({ key: "update_certificate_request_loading", value: false }))
    if (res.data.status) {
      if (callback) {
        callback(res.data)
      }
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    dispatch(updateRedux({ key: "update_certificate_request_loading", value: false }))
    console.log(err)
  })
}

export const changeCertificateRequestStatus = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "certificate_request_change_status_loading", value: true }))
  axiosFormInstance.post(`${API_BASE_URL}hr/changeCertificateRequestStatus`, formData).then(res => {
    dispatch(updateRedux({ key: "certificate_request_change_status_loading", value: false }))
    if (res.data.status) {
      toast.success(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
      if (callback) {
        callback()
      }
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    dispatch(updateRedux({ key: "certificate_request_change_status_loading", value: false }))
    console.log(err)
  })
}

export const forwardCertificateRequest = (data, callback) => (dispatch) => {
  axiosFormInstance.post(`${API_BASE_URL}hr/forwardCertificateRequest`, data)
    .then(res => { callback && callback(true, res.data.message) })
    .catch(err => { console.log(err) })
}

export const approveCertificateRequest = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "certificate_request_change_status_loading", value: true }))
  axiosFormInstance.post(`${API_BASE_URL}hr/approveCertificateRequest`, formData).then(res => {
    dispatch(updateRedux({ key: "certificate_request_change_status_loading", value: false }))
    if (res.data.status) {
      callback && callback(true, res.data.message)
    } else {
      if (typeof res.data.message === "object" && JSON.stringify(res.data.message).includes("File Type Not Supported")) {
        callback && callback(false, "File Type Not Supported")
      } else {
        callback && callback(false, res.data.message)
      }
    }
  }).catch((err) => {
    dispatch(updateRedux({ key: "certificate_request_change_status_loading", value: false }))
    console.log(err)
  })
}

export const getAllHr = () => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}hr/getAllCRPermissionUsers`).then(res => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "forward_hr_user_list", value: res.data.data }))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const deleteCertificateRequest = (data, callback) => (dispatch) => {
  axiosInstance.post(`${API_BASE_URL}hr/deleteCertificateRequest`, data).then(res => {
    if (res.data.status) {
      if (callback) {
        callback()
      }
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}


export const downloadCertificate = (data) => (dispatch) => {
  axiosInstance.post(`${API_BASE_URL}hr/downloadCertificate/${data}`, '', {
    responseType: "arraybuffer",
    responseEncoding: "binary",
    headers: {
      "Content-Type": "application/pdf",
    },
  }).then((res) => {
    if (res.status == 200) {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        'certificate.pdf',
      );
      document.body.appendChild(link);
      link.click();
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getRoles = (formData) => (dispatch) => {
  console.log(formData)
  dispatch(updateRedux({ key: "permission_list_loading", value: true }))
  axiosFormInstance.post(`${API_BASE_URL}hr/getRoles`, formData).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "permission_list_loading", value: false }))
      dispatch(updateRedux({ key: "permission_list", value: res.data.roles }))
      dispatch(updateRedux({ key: "permission_pages", value: res.data.total_pages }))
      dispatch(updateRedux({ key: "permission_page_status", value: res.data.page_status }))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    dispatch(updateRedux({ key: "permission_list_loading", value: false }))
    console.log(err)
  })
}

export const getModules = (formData) => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}hr/getModules`, formData).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "modules", value: res.data.modules }))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const insertRoles = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "add_update_delete_roles_loading", value: true }))
  axiosFormInstance.post(`${API_BASE_URL}hr/insertRoles`, formData).then((res) => {
    dispatch(updateRedux({ key: "add_update_delete_roles_loading", value: false }))
    if (res.data.status) {
      if (callback) {
        callback()
      }
      toast.success(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    dispatch(updateRedux({ key: "add_update_delete_roles_loading", value: false }))
    console.log(err)
  })
}

export const deleteRole = (data, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "add_update_delete_roles_loading", value: true }))
  axiosInstance.post(`${API_BASE_URL}hr/deleteRoles`, data).then((res) => {
    dispatch(updateRedux({ key: "add_update_delete_roles_loading", value: false }))
    if (res.data.status) {
      if (callback) {
        callback()
      }
      toast.success(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    dispatch(updateRedux({ key: "add_update_delete_roles_loading", value: false }))
    console.log(err)
  })
}

export const updateRoles = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "add_update_delete_roles_loading", value: true }))
  axiosFormInstance.post(`${API_BASE_URL}hr/updateRoles`, formData).then((res) => {
    dispatch(updateRedux({ key: "add_update_delete_roles_loading", value: false }))
    if (res.data.status) {
      if (callback) {
        callback()
      }
      toast.success(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    dispatch(updateRedux({ key: "add_update_delete_roles_loading", value: false }))
    console.log(err)
  })
}

export const getAllIncidentUsers = (formData) => (dispatch) => {
  dispatch(updateRedux({ key: "users_list_loading", value: true }))
  axiosFormInstance.post(`${API_BASE_URL}hr/getAllIncidentUsers`, formData).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "users_list", value: res.data.users_list }))
      dispatch(updateRedux({ key: "users_list_total_count", value: res.data.pages }))
      dispatch(updateRedux({ key: "users_list_page_count", value: res.data.page }))
      dispatch(updateRedux({ key: "users_list_page_status", value: res.data.page_status }))
      dispatch(updateRedux({ key: "users_list_loading", value: false }))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
      dispatch(updateRedux({ key: "users_list_loading", value: false }))

    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getNotification = (formData, callback) => (dispatch) => {
  axiosFormInstance.post(`${API_BASE_URL}getNotification`, formData).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "notification_lists", value: res.data.notifications }))
      dispatch(updateRedux({ key: "notification_count", value: res.data.count }))
      callback && callback()
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    // console.log(err)
  })
}

export const deleteAllNotification = (callback) => (dispatch) => {
  axiosFormInstance.post(`${API_BASE_URL}deleteAllNotification`).then((res) => {
    if (res.data.status) {
      toast.success(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
      callback()
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const deleteNotification = (id, callback) => (dispatch) => {
  axiosInstance.post(`${API_BASE_URL}deleteNotification`, id).then((res) => {
    if (res.data.status) {
      // toast.success(res.data.message, {
      //   position: "bottom-center",
      //   autoClose: 3000
      // })
      if (callback) {
        callback()
      }
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}


export const getCertificateRequestsReport = (formData) => (dispatch) => {
  dispatch(updateRedux({ key: "certificate_report_loading", value: true }))
  axiosFormInstance.post(`${API_BASE_URL}hr/getCertificateRequestsReport`, formData).then((res) => {
    dispatch(updateRedux({ key: "certificate_report_loading", value: false }))
    if (res.data.status) {
      dispatch(updateRedux({ key: "certificate_requests_report", value: res.data.certificate_list }))
      dispatch(updateRedux({ key: "page_status_reports", value: res.data.page_status }))
      dispatch(updateRedux({ key: "page_count_reports", value: res.data.pages }))
      // let countObject = {
      //   open: res.data.openCount,
      //   forwarded: res.data.forwardedCount,
      //   needInfo: res.data.returnedCount,
      //   rejected: res.data.rejectedCount,
      //   processing: res.data.processingCount,
      //   closed: res.data.closedCount,
      //   total: res.data.totalCount,
      //   approved: res.data.approvedCount
      // }
      let count = [
        { title: 'Total', count: res.data.totalCount },
        { title: 'Open', count: res.data.openCount },
        { title: 'Forwarded', count: res.data.forwardedCount },
        { title: 'Need Info', count: res.data.returnedCount},
        { title: 'Processing', count: res.data.processingCount },
        { title: 'Approved', count: res.data.approvedCount },
        { title: 'Rejected', count: res.data.rejectedCount },
        { title: 'Closed', count: res.data.closedCount },
      ]
      dispatch(updateRedux({ key: "certificate_requests_report_count", value: count }))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    dispatch(updateRedux({ key: "certificate_report_loading", value: false }))
    console.log(err)
  })
}

export const getUserActivityLog = (data) => (dispatch) => {
  // dispatch(updateRedux({ key: "log_list_loading", value: true }))
  axiosInstance.post(`${API_BASE_URL}hr/user-activity-logs`, data).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "activity_log_list", value: res.data.data.logs }))
      dispatch(updateRedux({ key: "activity_log_total_count", value: res.data.data.pages }))
      dispatch(updateRedux({ key: "activity_log_page_count", value: res.data.data.page }))
      dispatch(updateRedux({ key: "activity_log_page_status", value: res.data.data.page_status }))
      dispatch(updateRedux({ key: "log_user_data", value: res.data.data.user }))
      dispatch(updateRedux({ key: "log_list_loading", value: false }))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
      dispatch(updateRedux({ key: "log_list_loading", value: false }))

    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getUserSessionLog = (data) => (dispatch) => {
  console.log(data)
  // dispatch(updateRedux({ key: "log_list_loading", value: true }))
  axiosInstance.post(`${API_BASE_URL}hr/getSessionLogs`, data).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "session_log_list", value: res.data.data }))
      dispatch(updateRedux({ key: "session_log_total_count", value: res.data.pages }))
      dispatch(updateRedux({ key: "session_log_page_count", value: res.data.page }))
      dispatch(updateRedux({ key: "session_log_page_status", value: res.data.page_status }))
      dispatch(updateRedux({ key: "log_user_data", value: res.data.user }))
      dispatch(updateRedux({ key: "log_list_loading", value: false }))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
      dispatch(updateRedux({ key: "log_list_loading", value: false }))

    }
  }).catch((err) => {
    console.log(err)
  })
}

export const exportCertificateRequestReports = (formData) => (dispatch) => {
  axios.post(`${API_BASE_URL}hr/exportCertificateRequestReports`, formData, {
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': `Bearer ${localStorage.getItem("token")}`
    },
  }).then((res) => {
    if (res.status == 200) {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        'export.xlsx',
      );
      document.body.appendChild(link);
      link.click();
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}


export const getDynamicTexts = (callback) => (dispatch) => {
  axiosFormInstance.get(`${API_BASE_URL}hr/getTexts`).then(res => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "dynamic_text_list", value: res.data.translate_details }))
      dispatch(updateRedux({ key: "words", value: res.data.translate_details.reduce((acc, curr) => { return { ...acc, [curr.title.toLowerCase() + '_ar']: curr.arabic_text, [curr.title.toLowerCase() + '_en']: curr.english_text } }, {}) }))
      callback && callback(res.data.translate_details)
    } else {
      errorToast(res.data.message)
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const updateDynamicTexts = (data, callback) => (dispatch) => {
  axiosInstance.post(`${API_BASE_URL}hr/updateText`, data).then(res => {
    if (res.data.status) {
      callback && callback({ status: true, message: res.data.message, data: res.data })
    } else {
      callback && callback({ status: false, message: res.data.message })
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const insertDynamicText = (data, callback) => (dispatch) => {
  axiosInstance.post(`${API_BASE_URL}hr/insertText`, data).then(res => {
    if (res.data.status) {
      callback && callback({ status: true, message: res.data.message })
    } else {
      callback && callback({ status: false, message: res.data.message })
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const importDynamicExcel = (formdata, callback) => (dispatch) => {
  axiosFormInstance.post(`${API_BASE_URL}hr/importDynamicExcel`, formdata)
    .then(res => {
      if (res.data.status) {
        successToast(res.data.message)
        callback && callback(res.data)
      } else {
        errorToast(res.data.message)
      }
    }).catch((err) => {
      console.log(err)
    })
}

export const getOnboardingRequests = (formData) => (dispatch) => {
  // console.log(formData)
  dispatch(updateRedux({ key: "onborading_loading", value: true }))
  axiosFormInstance.post(`${API_BASE_URL}hr/get-all-onboarding-request`, formData).then((res) => {
    dispatch(updateRedux({ key: "onborading_loading", value: false }))
    if (res.data.status) {
      dispatch(updateRedux({ key: "admin_onboarding_list", value: res.data.data.requests }))
      dispatch(updateRedux({ key: "admin_onboarding_pages", value: res.data.data.pages }))
      dispatch(updateRedux({ key: "admin_onboarding_page_status", value: res.data.data.page_status }))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
    dispatch(updateRedux({ key: "onborading_loading", value: false }))
  }).catch((err) => {

    console.log(err)
  })
}

export const getEmployeeManagementList = (formData) => (dispatch) => {
  // console.log(formData)
  dispatch(updateRedux({ key: "employee_management_list_loading", value: true }))
  axiosFormInstance.post(`${API_BASE_URL}hr/employee-management-list`, formData).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "employee_management_list_loading", value: false }))
      dispatch(updateRedux({ key: "employee_management_list", value: res.data.data.requests }))
      dispatch(updateRedux({ key: "employee_management_pages", value: res.data.data.pages }))
      dispatch(updateRedux({ key: "employee_management_page_status", value: res.data.data.page_status }))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
    dispatch(updateRedux({ key: "employee_management_list_loading", value: false }))
  }).catch((err) => {

    console.log(err)
  })
}

export const addOnboardingRequest = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "admin_onboarding_details_loading", value: true }))
  axiosFormInstance.post(`${API_BASE_URL}hr/create-onboarding-request`, formData)
    .then(res => {
      dispatch(updateRedux({ key: "admin_onboarding_details_loading", value: false }))
      if (callback) {
        callback(res);
      }
    }).catch((err) => {
      dispatch(updateRedux({ key: "admin_onboarding_details_loading", value: false }))
      console.log(err);
    })
}

export const OnboardingRequestDetails = (formData, callback) => (dispatch) => {
  axiosFormInstance.post(`${API_BASE_URL}hr/get-onboarding-request-details`, formData)
    .then(res => {
      if (res.data.status) {
        dispatch(updateRedux({ key: "admin_onboarding_details", value: res.data.data.request }))
        dispatch(updateRedux({ key: "admin_onboarding_history", value: res.data.data.history }))
      }
      if (callback) {
        callback(res);
      }
    }).catch((err) => {
      console.log(err);
    })
}

export const editOnboardingRequest = (id, formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "admin_onboarding_details_loading", value: true }))
  axiosFormInstance.put(`${API_BASE_URL}hr/edit-onboarding-request/${id}`, formData)
    .then(res => {
      dispatch(updateRedux({ key: "admin_onboarding_details_loading", value: false }))
      if (callback) {
        callback(res);
      }
    }).catch((err) => {
      dispatch(updateRedux({ key: "admin_onboarding_details_loading", value: false }))
      console.log(err);
    })
}

export const generateIDCard = (formData, callback) => (dispatch) => {
  axiosFormInstance.post(`${API_BASE_URL}hr/generate-idcard`, formData)
    .then(res => {
      if (callback) {
        callback(res);
      }
    }).catch((err) => {
      console.log(err);
    })
}

export const deleteOnboard = (id, callback) => (dispatch) => {
  axiosInstance.delete(`${API_BASE_URL}hr/delete-onboarding-request/${id}`,).then((res) => {
    if (res.data.status) {
      if (callback) {
        callback(res)
      }
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}



export const exportOnboardings = (formData) => (dispatch) => {
  axios.post(`${API_BASE_URL}hr/export-onboardings`, formData, {
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': `Bearer ${localStorage.getItem("token")}`
    },
  }).then((res) => {
    if (res.status == 200) {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        'export.xlsx',
      );
      document.body.appendChild(link);
      link.click();
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const uploadFile = (formData, callback) => (dispatch) => {
  console.log(formData)
  dispatch(updateRedux({ key: "fileUploadLoader", value: true }))
  axiosFormInstance.post(`${API_BASE_URL}hr/upload-file`, formData).then(res => {
    dispatch(updateRedux({ key: "fileUploadLoader", value: false }))
    if (res.data.status == true) {
      if (callback) {
        callback(res.data)
      }
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getEmployeeNo = (callback) => (dispatch) => {
  dispatch(updateRedux({ key: "admin_onboarding_details_loading", value: true }))
  axiosInstance.get(`${API_BASE_URL}hr/get-emplopyee-no`).then((res) => {
    dispatch(updateRedux({ key: "admin_onboarding_details_loading", value: false }))
    if (res.data.status) {
      // dispatch(updateRedux({ key: "employeeNumber", value: res.data.data }))
      if (callback) callback(res.data)
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    dispatch(updateRedux({ key: "admin_onboarding_details_loading", value: false }))
    console.log(err)
  })
}

export const checkEmployeeNo = (formData, callback) => (dispatch) => {
  axiosFormInstance.post(`${API_BASE_URL}hr/check-emplopyee-no`, formData).then((res) => {
    if (res.data.status) {
      if (callback) {
        callback(res.data.data)
      }
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const downloadIdCard = (id) => (dispatch) => {
  dispatch(updateRedux({ key: "downloadLoader", value: true }))
  axiosInstance.get(`${API_BASE_URL}hr/download-idcard/${id}`, {
    responseType: "arraybuffer",
    responseEncoding: "binary",
    headers: {
      "Content-Type": "application/pdf",
    },
  }).then((res) => {
    dispatch(updateRedux({ key: "downloadLoader", value: false }))
    if (res.data && res.data.status) {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    } else {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        'id_card.pdf',
      );
      document.body.appendChild(link);
      link.click();
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getUserAccess = (callback) => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}hr/getUserAccess`).then((res) => {
    if (res.data.status) {
      localStorage.setItem('access', JSON.stringify(res.data.access))
      localStorage.setItem('main_access', JSON.stringify(res.data.main_access))
      dispatch(updateRedux({ key: "access", value: res.data.access }))
      dispatch(updateRedux({ key: "main_access", value: res.data.main_access }))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getDesignations = (callback) => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}hr/get-designations`).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "designations_list", value: res.data.data }))
      if (callback) callback(res.data)
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getEmployeeDetails = (formData, callback) => (dispatch) => {
  axiosFormInstance.post(`${API_BASE_URL}hr/employee-details`, formData)
    .then(res => {
      if (res.data.status) {
        dispatch(updateRedux({ key: "employee_details", value: res.data.data }))
      }
      if (callback) {
        callback(res);
      }
    }).catch((err) => {
      console.log(err);
    })
}

export const editEmployeeDetails = (id, formData, callback) => (dispatch) => {
  // dispatch(updateRedux({ key: "admin_employee_details_loading", value: true }))
  axiosFormInstance.post(`${API_BASE_URL}hr/edit-employee`, formData)
    .then(res => {
      dispatch(updateRedux({ key: "admin_employee_details_loading", value: false }))
      if (callback) {
        callback(res);
      }
    }).catch((err) => {
      dispatch(updateRedux({ key: "admin_employee_details_loading", value: false }))
      console.log(err);
    })
}

export const applyLeave = (data, callback) => (dispatch) => {
  axiosInstance.post(`${API_BASE_URL}hr/applyLeave`, data).then((res) => {
    if (res.data.status) {
      toast.success(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
      if (callback) {
        callback()
      }
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
      if (callback) {
        callback()
      }
    }
  }).catch((err) => {
    callback()
  })
}

export const getRoleDetails = (id, callback) => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}hr/getRoleDetails/${id}`).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "role_details", value: res.data.modules }))
      if (callback) callback(res.data.section_details)
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const deleteLeave = (id, callback) => (dispatch) => {
  axiosInstance.delete(`${API_BASE_URL}hr/delete-leave/${id}`,).then((res) => {
    if (res.data.status) {
      if (callback) {
        callback(res)
      }
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const updateLeave = (data, callback) => () => {
  axiosInstance.put(`${API_BASE_URL}hr/update-leave`, data)
    .then(res => {
      if (res.data.status) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000
        })
        if (callback) {
          callback(res)
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000
        })
      }
    }).catch((err) => {
      console.log(err);
    })
}

export const getOnboardingReport = (formData) => (dispatch) => {
  dispatch(updateRedux({ key: "onboarding_report_loading", value: true }))
  axiosFormInstance.post(`${API_BASE_URL}hr/get-onboarding-reports`, formData).then((res) => {
    dispatch(updateRedux({ key: "onboarding_report_loading", value: false }))
    if (res.data.status) {
      dispatch(updateRedux({ key: "onboarding_report_list", value: res.data.data.requests }))
      dispatch(updateRedux({ key: "onboarding_report_pages", value: res.data.data.pages }))
      dispatch(updateRedux({ key: "onboarding_report_page_status", value: res.data.data.page_status }))
      // let countObject = {
      //   total: res.data.data.totalCount,
      //   open: res.data.data.openCount,
      //   closed: res.data.data.closedCount,
      //   forwarded: res.data.data.forwardedCount,
      //   needInfo: res.data.data.returnedCount,
      //   processing: res.data.data.processingCount,
      // }
      let count = [
        { title: 'Total', count: res.data.data.totalCount },
        { title: 'Open', count: res.data.data.openCount },
        { title: 'Forwarded', count: res.data.data.forwardedCount },
        { title: 'Need Info', count: res.data.data.returnedCount},
        { title: 'Processing', count: res.data.data.processingCount },
        { title: 'Closed', count: res.data.data.closedCount },
      ]
      dispatch(updateRedux({ key: "onboarding_report_count", value: count }))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    dispatch(updateRedux({ key: "certificate_report_loading", value: false }))
    console.log(err)
  })
}