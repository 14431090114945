import React, { useEffect, useState } from "react";
// import "../style.scss";
// import "../ManageIncidents/incidents.scss";
import { Form, Spinner, } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
    log_from_date,
    log_list_loading,
    log_page,
    log_per_page,
    log_sort_ascending,
    log_sort_column,
    log_to_date,
    session_log_list,
    session_log_page_status,
    session_log_total_count,
    updateRedux,
} from "../../redux/commonReducer";
import { getUserSessionLog } from "../../redux/actionCreator";
// import Pagination from "../../../components/Pagination/Pagination";
// import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import moment from "moment";
import Table from "../../commonComponents/Table/Table";
// import useTrans from "../../../utils/useTrans";

const SessionLogData = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    //   const { t } = useTrans()
    const t = text => text

    const [data, setData] = useState([])

    const logData = useSelector(session_log_list);
    const loading = useSelector(log_list_loading);
    const pageCount = useSelector(session_log_total_count);
    const pageStatus = useSelector(session_log_page_status);
    const sortColumn = useSelector(log_sort_column);
    const sortAscending = useSelector(log_sort_ascending);
    const page = useSelector(log_page);
    const fromDate = useSelector(log_from_date);
    const toDate = useSelector(log_to_date);
    const perPage = useSelector(log_per_page);

    useEffect(() => {
        if (id) {
            let value = {
                userId: id,
                page: 0,
                from_date: fromDate ? moment(fromDate).format("YYYY-MM-DD") : "",
                to_date: toDate ? moment(toDate).format("YYYY-MM-DD") : "",
                sort_key: sortColumn,
                sort_order: sortAscending ? "1" : "-1",
                perPage: perPage,
            };
            dispatch(getUserSessionLog(value));
        }
    }, [id]);

    useEffect(() => {
        setData([]);
        if (logData) {
            logData?.forEach((item, i) => {
                setData((s) => [...s,
                {
                    login_date: moment(item?.login_time).format("DD MMM YYYY") ?? "",
                    login_time: moment(item?.login_time?.split(".")[0]).format("hh:mm:ss A") ?? "",
                    logout_time: item?.logout_time ? moment(item?.logout_time?.split(".")[0]).format("hh:mm:ss A") ?? "" : "-",
                    duration: item?.duration?.length ? item?.duration : item?.logout_time ? "0 Secs" : "-"
                }
                ]);
            });
        }
    }, [logData])

    const tableHeaders = [
        { name: t("Date"), sort_key: "login_date" },
        { name: t("Login Time"), sort_key: "login_time" },
        { name: t("Logout Time"), sort_key: "logout_time" },
        { name: t("Duration"), sort_key: "" },
        // { name: "Action", sort_key: "action" },
    ];

    const getSortingClassName = (title) => {
        let className = "";
        if (sortColumn === title) {
            if (sortAscending) {
                className = "active";
            } else {
                className = "inactive";
            }
        }
        return className;
    };

    const handlePageChange = (e) => {
        dispatch(updateRedux({ key: "log_page", value: e.selected }));
        let value = {
            userId: id,
            page: e.selected,
            from_date: fromDate ? moment(fromDate).format("YYYY-MM-DD") : "",
            to_date: toDate ? moment(toDate).format("YYYY-MM-DD") : "",
            sort_key: sortColumn,
            sort_order: sortAscending ? "1" : "-1",
            perPage: perPage,
        };
        dispatch(getUserSessionLog(value));
    };

    const onSortHandler = (type) => {
        dispatch(updateRedux({ key: "log_sort_ascending", value: !sortAscending }));
        dispatch(updateRedux({ key: "log_sort_column", value: type.key }));
        let value = {
            userId: id,
            page: page,
            from_date: fromDate ? moment(fromDate).format("YYYY-MM-DD") : "",
            to_date: toDate ? moment(toDate).format("YYYY-MM-DD") : "",
            sort_key: type.key,
            sort_order: !sortAscending ? "1" : "-1",
            perPage: perPage,
        };
        dispatch(getUserSessionLog(value));
    };

    return (
        <>
            <section className="table">
                <Table className={'css-tbl-esc2'}
                    titles={[
                        { title: "Date", key: "login_date", sort: true, dir: 'ltr' },
                        { title: "Login Time", key: "login_time", sort: true, dir: 'ltr' },
                        { title: "Logout Time", key: "logout_time", sort: true, dir: 'ltr' },
                        { title: "Duration", key: "duration", dir: 'ltr' },
                    ]}
                    content={data}
                    onSort={(e) => onSortHandler(e)}
                    sortValue={{ key: sortColumn, value: sortAscending ? 1 : -1 }}
                    page={page}
                    pagesCount={pageCount}
                    pageStatus={pageStatus}
                    onPageChange={handlePageChange}
                />
            </section>
        </>
    );
};

export default SessionLogData;
