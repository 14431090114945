import { createSlice } from '@reduxjs/toolkit';

export const commonSlice = createSlice({
    name : 'commonReducer',
    initialState : {
        value: true,
        department_details : [],
        log_per_page: 10,
        fileUploadLoader : false
        
    },
    reducers : {
        updateRedux: (state , data) => {
            state[data.payload.key ] = data.payload.value
        },
    },
})
export const { updateRedux } = commonSlice.actions;

export const value = (state ) => state.commonReducer.value;
export const request_type_details = (state ) => state.commonReducer.request_type_details;
export const user_list = (state ) => state.commonReducer.user_list;
export const section_details = (state ) => state.commonReducer.section_details;
export const department_details = (state ) => state.commonReducer.department_details;
export const request_status_details = (state ) => state.commonReducer.request_status_details;
export const certificate_request_list = (state ) => state.commonReducer.certificate_request_list;
export const certificate_path = (state ) => state.commonReducer.certificate_path;
export const certificate_pages = (state ) => state.commonReducer.certificate_pages;
export const certificate_page_status = (state ) => state.commonReducer.certificate_page_status;
export const all_hr_users_list = (state ) => state.commonReducer.all_hr_users_list;
export const certificate_details = (state ) => state.commonReducer.certificate_details;
export const certificate_details_path = (state ) => state.commonReducer.certificate_details_path;
export const forward_hr_user_list = (state ) => state.commonReducer.forward_hr_user_list;
export const permission_list = (state ) => state.commonReducer.permission_list;
export const permission_pages = (state ) => state.commonReducer.permission_pages;
export const permission_page_status = (state ) => state.commonReducer.permission_page_status;
export const modules = (state ) => state.commonReducer.modules;
export const notification_lists = (state ) => state.commonReducer.notification_lists;
export const certificate_requests_report = (state ) => state.commonReducer.certificate_requests_report;
export const page_status_reports = (state ) => state.commonReducer.page_status_reports;
export const page_count_reports = (state ) => state.commonReducer.page_count_reports;
// export const current_user_permissions = (state ) => state.commonReducer.current_user_permissions;
export const current_user_permissions = (state ) => state.commonReducer.current_user_permissions;
export const users_list_loading = (state ) => state.commonReducer.users_list_loading;
export const users_list = (state ) => state.commonReducer.users_list;
export const users_list_total_count = (state ) => state.commonReducer.users_list_total_count;
export const users_list_page_count = (state ) => state.commonReducer.users_list_page_count;
export const users_list_page_status = (state ) => state.commonReducer.users_list_page_status;
export const log_list_loading = (state ) => state.commonReducer.log_list_loading;
export const activity_log_list = (state ) => state.commonReducer.activity_log_list;
export const activity_log_total_count = (state ) => state.commonReducer.activity_log_total_count;
export const activity_log_page_count = (state ) => state.commonReducer.activity_log_page_count;
export const activity_log_page_status = (state ) => state.commonReducer.activity_log_page_status;
export const session_log_list = (state ) => state.commonReducer.session_log_list;
export const session_log_total_count = (state ) => state.commonReducer.session_log_total_count;
export const session_log_page_count = (state ) => state.commonReducer.session_log_page_count;
export const session_log_page_status = (state ) => state.commonReducer.session_log_page_status;
export const log_from_date = (state ) => state.commonReducer.log_from_date;
export const log_to_date = (state ) => state.commonReducer.log_to_date;
export const log_sort_column = (state ) => state.commonReducer.log_sort_column;
export const log_sort_ascending = (state ) => state.commonReducer.log_sort_ascending;
export const log_page = (state ) => state.commonReducer.log_page;
export const log_per_page = (state ) => state.commonReducer.log_per_page;
export const log_user_data = (state ) => state.commonReducer.log_user_data;
export const all_hr_users_list_for_log = (state ) => state.commonReducer.all_hr_users_list_for_log;
export const users_list_log_loading = (state ) => state.commonReducer.users_list_log_loading;
export const users_list_for_log_total_count = (state ) => state.commonReducer.users_list_for_log_total_count;
export const users_list_for_log_page_status = (state ) => state.commonReducer.users_list_for_log_page_status;
export const update_certificate_request_loading = (state ) => state.commonReducer.update_certificate_request_loading;
export const certificate_request_change_status_loading = (state ) => state.commonReducer.certificate_request_change_status_loading;
export const certificate_details_loading = (state ) => state.commonReducer.certificate_details_loading;
export const certificate_loading = (state ) => state.commonReducer.certificate_loading;
export const certificate_report_loading = (state ) => state.commonReducer.certificate_report_loading;
export const request_type_loading = (state ) => state.commonReducer.request_type_loading;
export const permission_list_loading = (state ) => state.commonReducer.permission_list_loading;
export const certificate_requests_report_count = (state ) => state.commonReducer.certificate_requests_report_count;
export const all_hr_users_without_roles_list = (state ) => state.commonReducer.all_hr_users_without_roles_list;
export const login_loading = (state ) => state.commonReducer.login_loading;
export const logout_loader = (state ) => state.commonReducer.logout_loader;
export const add_certificate_loading = (state ) => state.commonReducer.add_certificate_loading;
export const add_update_delete_roles_loading = (state ) => state.commonReducer.add_update_delete_roles_loading;
export const notification_count = (state ) => state.commonReducer.notification_count;
export const all_hr_users_list_for_log_loading = (state ) => state.commonReducer.all_hr_users_list_for_log_loading;
export const update_request_type_loading = (state ) => state.commonReducer.update_request_type_loading;
export const admin_onboarding_list = (state ) => state.commonReducer.admin_onboarding_list;
export const admin_onboarding_pages = (state ) => state.commonReducer.admin_onboarding_pages;
export const admin_onboarding_page_status = (state ) => state.commonReducer.admin_onboarding_page_status;
export const admin_onboarding_details = (state ) => state.commonReducer.admin_onboarding_details;
export const admin_onboarding_history = (state ) => state.commonReducer.admin_onboarding_history;
export const employeeNumber = (state ) => state.commonReducer.employeeNumber;
export const all_hr_users_without_roles_list_total_count = (state ) => state.commonReducer.all_hr_users_without_roles_list_total_count;
export const all_hr_users_without_roles_list_page_count = (state ) => state.commonReducer.all_hr_users_without_roles_list_page_count;
export const all_hr_users_without_roles_list_page_status = (state ) => state.commonReducer.all_hr_users_without_roles_list_page_status;
export const all_hr_users_without_roles_list_loading = (state ) => state.commonReducer.all_hr_users_without_roles_list_loading;
export const request_status_details_for_admin = (state ) => state.commonReducer.request_status_details_for_admin;
export const access = (state ) => state.commonReducer.access;
export const main_access = (state ) => state.commonReducer.main_access;
export const user_logged_in = (state ) => state.commonReducer.user_logged_in;
export const onborading_loading = (state ) => state.commonReducer.onborading_loading;
export const fileUploadLoader = (state ) => state.commonReducer.fileUploadLoader;
export const downloadLoader = (state ) => state.commonReducer.downloadLoader;
export const admin_onboarding_details_loading = (state ) => state.commonReducer.admin_onboarding_details_loading;
export const employee_management_list_loading = (state ) => state.commonReducer.employee_management_list_loading;
export const employee_management_list = (state ) => state.commonReducer.employee_management_list;
export const employee_management_pages = (state ) => state.commonReducer.employee_management_pages;
export const employee_management_page_status = (state ) => state.commonReducer.employee_management_page_status;
export const designations_list = (state ) => state.commonReducer.designations_list;
export const employee_details = (state ) => state.commonReducer.employee_details;
export const admin_employee_details_loading = (state ) => state.commonReducer.admin_employee_details_loading;
export const role_details = (state ) => state.commonReducer.role_details;
export const onboarding_report_loading = (state ) => state.commonReducer.onboarding_report_loading;
export const onboarding_report_list = (state ) => state.commonReducer.onboarding_report_list;
export const onboarding_report_pages = (state ) => state.commonReducer.onboarding_report_pages;
export const onboarding_report_page_status = (state ) => state.commonReducer.onboarding_report_page_status;
export const onboarding_report_count = (state ) => state.commonReducer.onboarding_report_count;
// export const value = (state ) => state.commonReducer.value;
// export const value = (state ) => state.commonReducer.value;
// export const value = (state ) => state.commonReducer.value;
// export const value = (state ) => state.commonReducer.value;
// export const value = (state ) => state.commonReducer.value;
// export const value = (state ) => state.commonReducer.value;
// export const value = (state ) => state.commonReducer.value;
// export const value = (state ) => state.commonReducer.value;
// export const value = (state ) => state.commonReducer.value;
// export const value = (state ) => state.commonReducer.value;
// export const value = (state ) => state.commonReducer.value;

export default commonSlice.reducer;