import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRequestType } from "../../redux/actionCreator";
import { request_type_details } from "../../redux/commonReducer";
import "./RequestDetailsForm.scss";
import TitleBar from "../../commonComponents/TitleBar/TitleBar";
import Select from "../../commonComponents/Select/Select";
import Input from "../../commonComponents/Input/Input";
import TextArea from "../../commonComponents/TextArea/TextArea";
import useTranslate from "../../utils/useTranslate";

export default function RequestDetailsForm({
  data,
  onChangeHandler,
  certificate,
  setCerificate,
  language,
  setLanguage,
  err,
  page
}) {
  const dispatch = useDispatch();
  const { t } = useTranslate()

  const requestTypeList = useSelector(request_type_details);

  useEffect(() => {
    dispatch(getRequestType());
  }, []);

  return (
    <div id="RequestDetailsForm row" className="RequestDetailsForm">
      <TitleBar
        title={'Request Details'}
        top={page === 'addrequest_admin' ? false : true}
      >

        <div className="input col-md-7 p-3 mt-2">
          <div className="row">
            <div className="col-md-6">
              <Select
                className='css-slc-esc1'
                placeholder={`* ${t("Select Certificate")}`}
                title={`* ${t("Select Certificate")}`}
                options={requestTypeList}
                value={certificate}
                labelSetter={(e) => e.request_type}
                valueSetter={(e) => e._id}
                onChange={(e) => { console.log(e.request_type); setCerificate(e) }}
                error={err.certificate}
              />
            </div>
            <div className="col-md-6">
              <Select
                className='css-slc-esc1 col-md-6'
                placeholder={`* ${t("Select Certificate Language")}`}
                title={`* ${t("Select Certificate Language")}`}
                options={[
                  { label: 'Arabic', value: 'Arabic' },
                  { label: 'English', value: 'English' },
                ]}
                value={language}
                labelSetter={(e) => e.label}
                valueSetter={(e) => e.value}
                onChange={setLanguage}
                error={err.language}
              />
            </div>
          </div>
          <div className="col-md-12">
            <Input type='text'
              placeholder={`* ${t("Enter Addressee")}`}
              label={`* ${t("Enter Addressee")}`}
              className='css-inp-esc1 w-100 mb-3'
              field='address'
              onChange={(value, key) => onChangeHandler(key, value)}
              value={data.address}
              error={err.address}
            />
          </div>
          <TextArea
            className='css-txt-esc1'
            placeholder={`* ${t("Comments")}`}
            label={`* ${t("Comments")}`}
            onChange={(e) => onChangeHandler("comment", e)}
            value={data.comment}
            error={err.comment}
          />
        </div>
      </TitleBar>
    </div>
  );
}