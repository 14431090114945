import React from 'react'
import './PersonalInformation.scss'
import TitleValue from '../../commonComponents/TitleValue/TitleValue'

export default function PersonalInformation({ user, page }) {
    return (
        <div id='PersonalInformation'>
            <article>
                <TitleValue title='Employee No' value={user?.employee_id} />
                <TitleValue title='First Name' value={user?.firstname} />
                <TitleValue title='Last Name' value={user?.lastname} />
                <TitleValue title='Email' value={user?.email} />
                <TitleValue title='Phone' value={user?.phone} />
                <TitleValue title='Designation' value={user?.designation?.[0]?.designation_name} />
                <TitleValue title='Department' value={user?.department?.department_name} />
                <TitleValue title='Section' value={user?.section?.[0]?.section_name} />
            </article>
        </div>
    )
}
