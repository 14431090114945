import React, { useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate'
import './Pagination.scss'

const Pagination = ({ page, pageCount, pageStatus, onChange, width }) => {

    const [currPage, setCurrPage] = useState(0)
    const [pagesCount, setPagesCount] = useState(0)

    useEffect(() => {
        page && setCurrPage(page)
        pageCount && setPagesCount(pageCount)
        return () => {
            setCurrPage(0)
            setPagesCount(0)
        }
    }, [page, pageCount])

    function pageChangeHandler(e) {
        // e.selected += 1
        onChange && onChange(e)
    }

    if (!pageCount && !pageStatus) {
        return null
    }
    return (
        <div id="Pagination" style={{ width: width || '' }}>
            {pageStatus && <p className='status'>{pageStatus}</p>}
            {pageCount > 1 ? (
                <>
                    <ReactPaginate
                        previousLabel={<>❮</>}
                        nextLabel={<>❯</>}
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        // breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        pageCount={pagesCount}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={5}
                        // initialPage={0}
                        forcePage={currPage}
                        onPageChange={pageChangeHandler}
                        containerClassName="pagination"
                        activeClassName="pageActive"
                    />
                </>
            ) : null}
        </div >
    )
}

export default Pagination