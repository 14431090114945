import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import "./Header.scss";
import { Capitalize } from "../../utils/helpers";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteAllNotification,
  deleteNotification,
  getNotification,
  getUserAccess,
  logout,
} from "../../redux/actionCreator";
import {
  logout_loader,
  notification_count,
  notification_lists,
} from "../../redux/commonReducer";
import Modals from "../Modals/Modals";
import { roles } from "../../constants/configuration";
import useTranslate from "../../utils/useTranslate";
import { LangSwitch } from "../../App";

export default function Header({ setNavToggle }) {
  const [notification, setNotification] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const notificationItems = useSelector(notification_lists);
  const noificationCount = useSelector(notification_count);

  const loggedUser = localStorage.getItem("user_logged");

  const { t, lang } = useTranslate();

  // console.log({language, lang})

  const [user, setUSer] = useState({
    username: "",
    userType: "",
    department: "",
  });
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setUSer({
      username: localStorage.getItem("username"),
      user: JSON.parse(localStorage.getItem("user")),
      userType: localStorage.getItem("user_type"),
      department:
        JSON.parse(localStorage.getItem("user"))?.department &&
        JSON.parse(localStorage.getItem("user"))?.department.length &&
        JSON.parse(localStorage.getItem("user"))?.department?.department_name,
    });
    dispatch(getUserAccess());
  }, []);

  const logOut = () => {
    dispatch(
      logout(() => {
        localStorage.removeItem("token");
        localStorage.removeItem("user_id");
        localStorage.removeItem("user");
        localStorage.removeItem("username");
        localStorage.removeItem("access");
        localStorage.removeItem("main_access");
        localStorage.removeItem("user_type");
        navigate("/login");
      })
    );
  };

  const handleNotification = () => {
    setNotification(!notification);
  };

  useEffect(() => {
    let formData = new FormData();
    formData.append("is_admin_mode", loggedUser == "User" ? 0 : 1);
    dispatch(getNotification(formData));
  }, [loggedUser]);

  const clearAllNotification = () => {
    dispatch(
      deleteAllNotification(() => {
        let formData = new FormData();
        formData.append("is_admin_mode", loggedUser == "User" ? 0 : 1);
        dispatch(getNotification(formData));
      })
    );
    setNotification(false);
  };

  const clearNotification = (id) => {
    const data = { id: id };
    dispatch(
      deleteNotification(data, () => {
        setLoading({ [id]: true });
        let formData = new FormData();
        formData.append("is_admin_mode", loggedUser == "User" ? 0 : 1);
        dispatch(
          getNotification(formData, () => {
            setLoading(false);
          })
        );
      })
    );
  };
  const onViewHandler = (ele) => {
    if (ele.type === "certificate_request") {
      if (loggedUser === "User") {
        if (ele.title.includes("returned")) {
          navigate(
            `/human_resource/certificate_requests/request_details/${ele?.request_id}/edit`
          );
        }
      } else {
        if (ele.action !== "Approve" && ele.action !== "Reject") {
          navigate(
            `/admin/human_resource/certificate_requests/request_details/${ele?.request_id}/view`
          );
        }
      }

      const data = { id: ele?._id };
      dispatch(
        deleteNotification(data, () => {
          let formData = new FormData();
          formData.append("is_admin_mode", loggedUser == "User" ? 0 : 1);
          dispatch(getNotification(formData));
        })
      );
      setNotification(false);
    } else {
      // navigate(`/admin/incidents/incident_details/${ele.incident_id?._id}/${ele.display_type}/${ele.task_no}`)
    }
  };

  useEffect(() => {
    window.document.addEventListener("click", (e) => {
      let notification_viewer = document.getElementById("notification_wrap");
      let bell_icon = document.getElementById("notification_bell");
      if (notification_viewer) {
        if (!notification_viewer.contains(e.target) && e.target !== bell_icon) {
          setNotification(false);
        }
      }
    });
  }, []);

  // const isHRUser = () => {
  //   let flag = false;
  //   let userObject = JSON.parse(localStorage.getItem("user"))
  //   let userPermissions =
  //   if (userPermissions && userPermissions.length && userPermissions.some(item => item.module_name === "certificate_request" && item.admin === "1")) {
  //     flag = true
  //   }
  //   console.log(userObject)
  //   if(userObject && userObject.role[0] === "super_admin"){
  //     flag = true
  //   }
  //   return flag
  // }

  const getDesignation = () => {
    let userRole =
      user &&
      user.user &&
      user.user.role &&
      user.user.role.length &&
      user.user.role[0];
    return userRole ? roles[userRole] : "";
  };

  return (
    (
      <>
        <div id="Header">
          <i className="hamburger" onClick={() => setNavToggle((s) => !s)}>
            <img src="/assets/icons/hamburger.svg" alt="" />
          </i>
          <div className="user">
            <div className="notification">
              {notificationItems?.length !== 0 && (
                <span className="count"></span>
              )}

              <i className="bell">
                <img
                  id="notification_bell"
                  src="/assets/icons/icon.svg"
                  alt=""
                  onClick={handleNotification}
                />
              </i>

              {notification && notificationItems?.length !== 0 && (
                <>
                  <div className="notification__lists" id="notification_wrap">
                    <div className="close">
                      <img
                        src="/assets/icons/close.svg"
                        alt=""
                        onClick={() => setNotification(!notification)}
                      />
                      <p>
                        {t("Notifications")} ({notificationItems.length})
                      </p>
                    </div>
                    <div className="content">
                      <ul>
                        {notificationItems?.map((ele, i) => {
                          return (
                            <li key={i}>
                              <span onClick={() => onViewHandler(ele)}>
                                {lang === "Ar" ? ele?.title : ele?.title_ar}
                              </span>
                              {loading?.[ele._id] ? (
                                <div className="spinner-border" role="status">
                                  <span className="sr-only"></span>
                                </div>
                              ) : (
                                <img
                                  onClick={() => clearNotification(ele?._id)}
                                  src="/assets/icons/Close_circle.svg"
                                  alt=""
                                />
                              )}
                            </li>
                          );
                        })}
                      </ul>
                      {notificationItems && (
                        <div className="clear">
                          <p onClick={clearAllNotification}>{t("Clear All")}</p>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
            <div>
              <p className="name" style={{ marginBottom: 0 }}>
                {Capitalize(user?.user?.firstname + " " + user?.user?.lastname)}
              </p>
              <p style={{ margin: 0 }} className="designation">
                {/* {getDesignation()} */}
                {Capitalize(JSON.parse(localStorage.getItem("user"))?.designation?.designation_name)}
              </p>
              <p style={{ margin: 0 }} >
                {Capitalize(JSON.parse(localStorage.getItem("user"))?.department?.department_name)}
              </p>
              
            </div>
            <i className="logout" onClick={() => setShowModal(true)}>
              <img src="/assets/icons/logout-svgrepo-com.svg" alt="" />
            </i>
          </div>
          {/* <Modal
        show={showModal}
        handleClose={() => setShowModal(false)}
        handleSubmit={() => logoutHandler()}
        title={t("Confirm Logout")}
        description={t("Are you sure you want to logout?")}
        submitLoading={logoutLoading}
      /> */}
        </div>
        <Modals
          show={showModal}
          setShow={setShowModal}
          type={"logout"}
          onLogout={logOut}
        />
      </>
    )
  );
}
