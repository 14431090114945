import React from 'react'
import './SearchBox.scss'
import Select from '../Select/Select'
import DatePick from '../DatePick/DatePick'
import Button from '../Button/Button'
import AdvancedSearch from "../../components/AdvancedSearch/AdvancedSearch";

export default function SearchBox({ elements, options, onChange, onSearch, onClear, showClearBtn, showAdvancedSearch, onExport, handleAdvanceFilterChange, advancedFilter, children }) {

  return (
    <div id='SearchBox'>
      <div className="inputs">
        {
          elements && elements.map((element, index) => {
            return element.type === 'select'
              ? <Select
                key={index}
                className={element.className}
                title={element.label}
                placeholder={element.placeholder}
                style={{ width: element.width }}
                options={options[element.key]?.options}
                labelSetter={options[element.key]?.labelSetter}
                valueSetter={options[element.key]?.valueSetter}
                onChange={(e) => onChange(e, element.key)}
                value={options[element.key]?.value}
                showCloseBtn
                isMulti={element.isMulti}
              />
              : element.type === 'datePicker'
                ? <DatePick
                  key={index}
                  className={element.className}
                  title={element.label}
                  placeholder={element.placeholder}
                  style={{ width: element.width }}
                  selected={options[element.key]?.selected}
                  onChange={(date) => onChange(date, element.key)}
                  filterTime={options[element.key]?.filterTime}
                  dateFormat={options[element.key]?.dateFormat}
                  maxDate={options[element.key]?.maxDate}
                  minDate={options[element.key]?.minDate}
                  showCloseBtn
                />
                : null
          })
        }
        {children}
        <span>
          <Button className="css-btn-esc2" content={"Search"} onClick={onSearch && onSearch} />
          {showClearBtn && <Button className="css-btn-esc2 sm" onClick={onClear && onClear} content="✖" />}
        </span>
        {showAdvancedSearch && <AdvancedSearch className='mt-3' onChange={handleAdvanceFilterChange} advancedFilter={advancedFilter} />}
      </div>
      {onExport &&
        <Button className="css-btn-esc4 export_btn" iconWidth={14} content={"Export"} icon="/assets/icons/upload.svg" reverse onClick={() => onExport()} />}
    </div>
  )
}



{/*

<SearchBox
  elements={[
    { label: 'Date', key: 'start_date', type: 'datePicker', className: 'css-dtp-esc1', placeholder: 'From' },
    { label: '', key: 'end_date', type: 'datePicker', className: 'css-dtp-esc1', placeholder: 'To' },
    { label: 'Request Type', key: 'request_type', type: 'select', className: 'css-slc-esc1', placeholder: 'Select', width: '200px' },
    { label: 'Department ', key: 'department', type: 'select', className: 'css-slc-esc1', placeholder: 'Department' },
    { label: 'Employee ', key: 'user', type: 'select', className: 'css-slc-esc1', placeholder: 'Select User', width: '200px' },
    { label: 'Status ', key: 'status', type: 'select', className: 'css-slc-esc1', placeholder: 'Select' },
  ]}
  options={{
    start_date: { selected: filterData?.start_date, filterTime: filterPassedTime, maxDate: filterData?.end_date ? filterData?.end_date : new Date(), dateFormat: "dd/MM/yy" },
    end_date: { selected: filterData?.end_date, filterTime: filterPassedTime, minDate: filterData?.start_date && filterData?.start_date, maxDate: new Date(), dateFormat: "dd/MM/yy" },
    request_type: { options: requestTypeList, value: filterData?.request_type, labelSetter: (option) => option.request_type, valueSetter: (option) => option._id },
    department: { options: departmentList, value: filterData?.department, labelSetter: (option) => option.department_name, valueSetter: (option) => option._id },
    user: { options: userList, labelSetter: (option) => option.name, valueSetter: (option) => option._id, value: filterData?.user },
    status: { options: requestStatus, labelSetter: (option) => option.request_status, valueSetter: (option) => option._id, value: filterData?.status, },
  }}
  showClearBtn={Object.values(filterData).some(e => Boolean(e))}
  showAdvancedSearch
  onSearch={applySearch}
  onClear={clearFilter}
  onExport={handleExport}
  onChange={searchBoxChangeHandler}
/>

*/}
