import React, { useEffect, useState } from "react";
import Title from "../../../commonComponents/Title/Title";
import Breadcrump from "../../../commonComponents/Breadcrump/Breadcrump";
import Button from "../../../commonComponents/Button/Button";
import { useNavigate } from "react-router-dom";
import Table from "../../../commonComponents/Table/Table";
import SearchBox from "../../../commonComponents/SearchBox/SearchBox";
import {
  admin_onboarding_list,
  admin_onboarding_page_status,
  admin_onboarding_pages,
  all_hr_users_list,
  department_details,
  designations_list,
  employee_management_list,
  employee_management_list_loading,
  employee_management_page_status,
  employee_management_pages,
  onborading_loading,
  section_details,
  updateRedux,
} from "../../../redux/commonReducer";
import { useDispatch, useSelector } from "react-redux";
import useTranslate from "../../../utils/useTranslate";
import {
  applyLeave,
  deleteLeave,
  downloadIdCard,
  exportOnboardings,
  getAllUsersForHR,
  getDepartment,
  getDesignations,
  getEmployeeManagementList,
  getOnboardingRequests,
  getSection,
  updateLeave,
} from "../../../redux/actionCreator";
import DateTimeViewer from "../../../commonComponents/DateTimeViewer/DateTimeViewer";
import StatusBox from "../../../commonComponents/StatusBox/StatusBox";
import PageHeader from "../../../commonComponents/PageHeader/PageHeader";
import Actions from "../../../commonComponents/Actions/Actions";
import moment from "moment";
import EntriesCounter from "../../../commonComponents/EntriesCounter/EntriesCounter";
import Input from "../../../commonComponents/Input/Input";
import useFilter from "../../../utils/useFilter";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import Modal from "../../../commonComponents/Modal/Modal";
import ModalContent from "../../../commonComponents/ModalContent/ModalContent";
import DatePick from "../../../commonComponents/DatePick/DatePick";

function EmployeeListing({ userpage }) {
  const initialFilter = {
    department: "",
    section: "",
    user: "",
    keyword: "",
    status: "",
    designation: "",
    page: 0,
    perPage: 10,
    sortColumn: "last_update",
    sortAscending: 1,
  };

  const requestStatusList = [
    { value: "processing", label: "Processing" },
    { value: "forward", label: "Forwarded" },
    { value: "return", label: "Need info" },
    { value: "close", label: "Closed" },
  ];

  const itRequestStatusList = [
    { value: "processing", label: "Processing" },
    { value: "open", label: "Open" },
    { value: "return", label: "Need info" },
    { value: "close", label: "Closed" },
  ];

  const titles = [
    { title: "Employee ID", key: "employee_id", sort: true, width: "90px" },
    { title: "Employee Name", key: "name", sort: true, width: "110px" },
    {
      title: "Department",
      key: "department",
      sort: true,
      width: "90px",
      dir: "ltr",
    },
    { title: "Section", key: "section", sort: true, width: "90px", dir: "ltr" },
    { title: "Designation", key: "designation", sort: true, width: "90px" },
    { title: "Email", key: "email", sort: true, width: "90px" },
    { title: "Phone", key: "phone", sort: true, width: "90px" },
    { title: "Status", key: "status", width: "85px" },
    { title: "Action", key: "action", width: "85px" },
  ];

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslate();
  const [filterData, setFilterData, F_navigate] = useFilter({
    initialFilter,
    dataFetch: (filter) => getSearchDataHandler(filter),
  });

  const userList = useSelector(all_hr_users_list);
  const loading = useSelector(employee_management_list_loading);
  const requestData = useSelector(employee_management_list);
  const pageCount = useSelector(employee_management_pages);
  const pageStatus = useSelector(employee_management_page_status);
  const departmentList = useSelector(department_details);
  const sectionList = useSelector(section_details);
  const designationList = useSelector(designations_list);

  const [data, setData] = useState([]);
  const [leave, setLeave] = useState({
    employee_id: "",
    start_date: "",
    end_date: "",
  });
  const [selectedLeave, setSelectedLeave] = useState({
    start_date: "",
    end_date: "",
  });
  const [err, setErr] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [btnDisable, setbtnDisable] = useState(false);

  useEffect(() => {
    let formData = new FormData();
    formData.append("department", "");
    formData.append("section", "");
    dispatch(getAllUsersForHR(formData));
    dispatch(getDepartment());
    dispatch(getDesignations());
  }, [userpage]);

  useEffect(() => {
    setData([]);
    if (requestData) {
      requestData?.forEach((item, i) => {
        setData((s) => [
          ...s,
          {
            employee_id: item?.employee_id,
            name: item?.name,
            department: item?.department?.department_name,
            section: item?.section?.length
              ? item?.section[0]?.section_name
              : "--",
            email: item?.email,
            phone: item?.phone,
            designation: item?.designation?.designation_name,
            status: item?.status,
            action: (
              <Actions
                rootPath="/assets/icons/"
                buttons={{ edit: "Edit.svg", calendar: "calendar_color.svg" }}
                visibility={{
                  edit: true,
                  calendar: true,
                }}
                onEdit={() => {
                  F_navigate(
                    `/admin/human_resource/employee_list/edit_details/${item?.employee_id}`
                  );
                }}
                onCalendar={() => {
                  setLeave({
                    ...leave,
                    employee_id: item?.employee_id,
                    leaveUpdate:
                      item?.leave != "" && item?.leave != undefined
                        ? item?.leave
                        : "",
                    start_date: item?.leave?.start_date
                      ? new Date(
                        moment
                          .utc(item?.leave?.start_date)
                          .format("YYYY-MM-DD")
                      )
                      : "",
                    end_date: item?.leave?.end_date
                      ? new Date(
                        moment.utc(item?.leave?.end_date).format("YYYY-MM-DD")
                      )
                      : "",
                  });
                  setShowModal(true);
                }}
              />
            ),
          },
        ]);
      });
    }
  }, [requestData]);

  useEffect(() => {
    if (!showModal) {
      setErr({
        ...err,
        start_date: "",
        end_date: "",
      });
    }
  }, [showModal]);

  const applySearch = () => {
    let filter = { ...filterData, page: 0 };
    setFilterData(filter);
    getSearchDataHandler(filterData);
  };

  const clearFilter = () => {
    let filter = initialFilter;
    setFilterData(filter);
    dispatch(updateRedux({ key: "section_details", value: [] }));
    dispatch(updateRedux({ key: "all_hr_users_list", value: [] }));
    let formData = new FormData();
    formData.append("department", "");
    formData.append("section", "");
    dispatch(getAllUsersForHR(formData));
    getSearchDataHandler(filter);
  };

  const onSortHandler = ({ key, value }) => {
    let filter = { ...filterData, sortColumn: key, sortAscending: value };
    setFilterData(filter);
    getSearchDataHandler(filter);
  };

  const handlePageChange = (e) => {
    let filter = { ...filterData, page: e.selected };
    setFilterData(filter);
    getSearchDataHandler(filter);
  };

  const onLimitChangeHandler = (value) => {
    let filter = { ...filterData, page: 0, perPage: parseInt(value) };
    setFilterData(filter);
    getSearchDataHandler(filter);
  };

  const getSearchDataHandler = (data) => {
    let formData = new FormData();
    formData.append("page", data.page);
    formData.append("perPage", data.perPage);
    formData.append("sort_order", data.sortAscending);
    formData.append("sort_key", data.sortColumn);
    formData.append("department", data?.department?._id || "");
    formData.append("section", data?.section?._id || "");
    formData.append("name", data?.user?._id || "");
    formData.append("status", data?.status?.value || "");
    formData.append("designation", data?.designation?._id || "");
    formData.append("keyword", data?.keyword?.trim() || "");
    dispatch(getEmployeeManagementList(formData));
  };

  const onDepartmentChangeHandler = (value) => {
    setFilterData({ ...filterData, department: value, section: "", user: "" });
    dispatch(updateRedux({ key: "section_details", value: [] }));
    dispatch(updateRedux({ key: "all_hr_users_list", value: [] }));
    value._id && dispatch(getSection(value._id));
    let formData = new FormData();
    formData.append("department", value?._id || "");
    formData.append("section", "");
    dispatch(getAllUsersForHR(formData));
  };

  const onSectionChangeHandler = (value) => {
    setFilterData({ ...filterData, section: value, user: "" });
    dispatch(updateRedux({ key: "all_hr_users_list", value: [] }));
    let formData = new FormData();
    formData.append("department", filterData?.department?._id);
    formData.append("section", value?._id);
    dispatch(getAllUsersForHR(formData));
  };

  function searchBoxChangeHandler(value, key) {
    switch (key) {
      case "department":
        onDepartmentChangeHandler(value);
        break;
      case "section":
        onSectionChangeHandler(value);
        break;
      default:
        setFilterData((s) => ({ ...s, ...filterData, [key]: value }));
    }
  }
  const dateTomorrow = () => {
    // const date = new Date();
    // date.setDate(1);
    // return date;
    const currentDate = new Date();
    const tomorrow = new Date(currentDate);
    tomorrow.setDate(currentDate.getDate() + 1);
    return new Date(tomorrow.toDateString());
  };

  const checkForTodayOrPastDate = (inputDate) => {
    const currentDate = new Date();
    const date = new Date(inputDate);
    if (date <= currentDate) {
      // "Past"
      return false;
    } else {
      return true;
    }
  };

  const checkForPastDate = (inputDate) => {
    // const currentDate = new Date();
    // const date = new Date(inputDate);
    // if (date <= currentDate) {
    //   // "Past"
    //   return false;
    // } else {
    //   return true;
    // }
    const date = new Date(inputDate);
    date.setHours(0, 0, 0, 0);
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    if (date < currentDate) {
      // "Past";
      return false;
    } else {
      // "Future";
      return true;
    }
  };
  const handleSubmit = () => {
    if (validate()) {
      const data = {
        employee_id: leave?.employee_id,
        start_date: leave?.start_date
          ? moment(leave?.start_date)?.format("YYYY-MM-DD")?.toLocaleString()
          : "",
        end_date: leave?.end_date
          ? moment(leave?.end_date)?.format("YYYY-MM-DD")?.toLocaleString()
          : "",
      };
      setbtnDisable(true);
      if (leave?.leaveUpdate) {
        dispatch(
          updateLeave(data, () => {
            setbtnDisable(false);
            setShowModal(false);
            dispatch(getEmployeeManagementList());
          })
        );
      } else {
        dispatch(
          applyLeave(data, () => {
            setbtnDisable(false);
            setShowModal(false);
            dispatch(getEmployeeManagementList());
          })
        );
      }
    }
  };

  const handleLeaveDelete = () => {
    dispatch(
      deleteLeave(leave?.employee_id, () => {
        setShowModal(false);
        dispatch(getEmployeeManagementList());
      })
    );
  };

  const handleCancel = () => {
    setLeave({});
    setShowModal(false);
  };
  const onChangeHandler = (key, value) => {
    setLeave({
      ...leave,
      [key]: value,
    });
    setErr({
      ...err,
      [key]: "",
    });
  };

  const required = {
    start_date: true,
    end_date: true,
  };

  const validate = () => {
    let obj = {};
    if (leave?.start_date == "") {
      obj = { ...obj, start_date: "Please choose start date" };
    }
    if (leave?.end_date == "") {
      obj = { ...obj, end_date: "Please choose end date" };
    }
    if (Object.values(obj).length != 0) {
      setErr(obj);
      return false;
    } else {
      setErr({});
      return true;
    }
  };

  return (
    <div className="onboarding__process" id="onboarding__process">
      <section>
        <PageHeader>
          <Title content="Employee Management" />
          <Breadcrump
            content={[
              {
                name: "Employee List",
                path: "/admin/human_resource/employee_list",
              },
              {
                name: "HR",
                path: "/admin/human_resource/certificate_requests",
              },
              { name: "Home", path: "/admin" },
            ]}
          />
        </PageHeader>
      </section>
      <section>
        <SearchBox
          elements={[
            {
              label: "Department",
              key: "department",
              type: "select",
              className: "css-slc-esc2",
              placeholder: "Select",
              width: "220px",
            },
            {
              label: "Section",
              key: "section",
              type: "select",
              className: "css-slc-esc2",
              placeholder: "Select",
              width: "180px",
            },
            {
              label: "Employee",
              key: "user",
              type: "select",
              className: "css-slc-esc2",
              placeholder: "Select",
              width: "180px",
            },
            {
              label: "Designation",
              key: "designation",
              type: "select",
              className: "css-slc-esc2",
              placeholder: "Select",
              width: "200px",
            },
            {
              label: "Status",
              key: "status",
              type: "select",
              className: "css-slc-esc2",
              placeholder: "Select",
              width: "180px",
            },
          ]}
          options={{
            department: {
              options: departmentList,
              value: filterData?.department,
              labelSetter: (option) => option.department_name,
              valueSetter: (option) => option._id,
            },
            section: {
              options: sectionList,
              value: filterData?.section,
              labelSetter: (option) => option.section_name,
              valueSetter: (option) => option._id,
            },
            user: {
              options: userList,
              labelSetter: (option) => option.name,
              valueSetter: (option) => option._id,
              value: filterData?.user,
            },
            designation: {
              options: designationList,
              labelSetter: (option) => option.designation_name,
              valueSetter: (option) => option._id,
              value: filterData?.designation,
            },
            status: {
              options: [
                { value: "Active", label: "Active" },
                { value: "Inactive", label: "Inactive" },
                { value: "On Leave", label: "On Leave" },
              ],
              labelSetter: (option) => option.label,
              valueSetter: (option) => option.value,
              value: filterData?.status,
            },
          }}
          onSearch={applySearch}
          onClear={clearFilter}
          showClearBtn={
            filterData?.department ||
            filterData?.section ||
            filterData?.user ||
            filterData?.designation ||
            filterData?.keyword ||
            filterData?.status
          }
          onChange={searchBoxChangeHandler}
        />
      </section>
      <section className="d-flex justify-content-between">
        <EntriesCounter
          value={filterData.perPage}
          onChange={onLimitChangeHandler}
        />
        <Input
          value={filterData?.keyword}
          type="text"
          className="css-inp-esc2 search"
          icon="/assets/icons/Search1.svg"
          placeholder={t("Generic Search")}
          reverse
          width="300px"
          field="keyword"
          onChange={(value) => {
            setFilterData((s) => ({ ...s, keyword: value }));
          }}
          onSubmit={applySearch}
          // showCloseBtn
          onIconClick={applySearch}
        />
      </section>
      {loading ? (
        <LoadingSpinner height="200px" />
      ) : (
        <section className="table">
          <Table
            className={"css-tbl-esc2"}
            titles={titles}
            content={data}
            onSort={(e) => onSortHandler(e)}
            page={filterData.page}
            pagesCount={pageCount}
            pageStatus={pageStatus}
            onPageChange={handlePageChange}
            sortValue={{
              key: filterData.sortColumn,
              value: filterData.sortAscending,
            }}
          />
        </section>
      )}
      <>
        <Modal show={showModal} setShow={setShowModal}>
          <ModalContent header="Leave">
            <div className="row mb-5 align-items-end">
              <div className="col">
                <DatePick
                  className="css-dtp-esc2"
                  title={"Start Date"}
                  placeholder="Choose a date"
                  selected={leave?.start_date}
                  onChange={(date) => onChangeHandler("start_date", date)}
                  dateFormat="dd/MM/yyyy"
                  minDate={dateTomorrow()}
                  maxDate={leave?.end_date}
                  error={err.start_date}
                  required={required?.start_date}
                  disabled={!checkForTodayOrPastDate(leave?.start_date)}
                />
              </div>
              <div className="col">
                <DatePick
                  className="css-dtp-esc2"
                  title={"End Date"}
                  placeholder="Choose a date"
                  selected={leave?.end_date}
                  onChange={(date) => onChangeHandler("end_date", date)}
                  dateFormat="dd/MM/yyyy"
                  minDate={
                    checkForTodayOrPastDate(leave?.start_date)
                      ? leave?.start_date
                      : new Date()
                  }
                  error={err.end_date}
                  required={required?.end_date}
                  disabled={!checkForPastDate(leave?.end_date, "end")}
                />
              </div>
              {leave?.leaveUpdate && (
                <div className="col-md-1">
                  {checkForTodayOrPastDate(leave?.start_date) &&
                    checkForPastDate(leave?.end_date) ? (
                    <Button
                      icon="/assets/icons/trash-2.svg"
                      height={40}
                      width={40}
                      content=""
                      onClick={handleLeaveDelete}
                      className="css-btn-esc6"
                    />
                  ) : (
                    ""
                  )}
                </div>
              )}
            </div>
            {checkForTodayOrPastDate(leave?.start_date) ||
              checkForPastDate(leave?.end_date) ? (
              <Button
                className="css-btn-esc3"
                onClick={() => handleSubmit()}
                disabled={btnDisable ? true : false}
                content={leave?.leaveUpdate ? "Update" : "Submit"}
              />
            ) : (
              <></>
            )}
            <Button
              className="css-btn-esc3"
              onClick={() => handleCancel()}
              content="Cancel"
            />
          </ModalContent>
        </Modal>
      </>
    </div>
  );
}

export default EmployeeListing;
