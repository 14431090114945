import React, { useEffect, useState } from "react";
// import "../style.scss";
import { Form, Spinner, } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import AdvancedSearch from "../EducationAllowance/AdvancedSearch";
import {
    all_hr_users_list,
    all_hr_users_list_for_log,
    all_hr_users_list_for_log_loading,
    department_details,
    request_status_details,
    request_type_details,
    updateRedux,
    users_list,
    users_list_for_log_page_status,
    users_list_for_log_total_count,
    users_list_log_loading,
    users_list_page_status,
    users_list_total_count,
} from "../../redux/commonReducer";
import { getAllIncidentUsers, getAllUsersForHR, getAllUsersForLogs, getDepartment, getRequestStatus, getRequestType, getSection } from "../../redux/actionCreator";
// import Pagination from "../../../components/Pagination/Pagination";
// import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
// import useTrans from "../../../utils/useTrans";
import { roles } from "../../constants/configuration";
import Title from "../../commonComponents/Title/Title";
import Breadcrump from "../../commonComponents/Breadcrump/Breadcrump";
import Button from "../../commonComponents/Button/Button";
import SearchBox from "../../commonComponents/SearchBox/SearchBox";
import EntriesCounter from "../../commonComponents/EntriesCounter/EntriesCounter";
import Input from "../../commonComponents/Input/Input";
import moment from "moment";
import Actions from "../../commonComponents/Actions/Actions";
import Table from "../../commonComponents/Table/Table";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import useTranslate from "../../utils/useTranslate";
import PageHeader from "../../commonComponents/PageHeader/PageHeader";

function UsersListing() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    //   const { t } = useTrans()

    const { t } = useTranslate()

    const usersList = useSelector(all_hr_users_list);
    const loading = useSelector(all_hr_users_list_for_log_loading);
    const pageCount = useSelector(users_list_for_log_total_count);
    const pageStatus = useSelector(users_list_for_log_page_status);
    const departmentList = useSelector(department_details);
    const userList = useSelector(all_hr_users_list_for_log);
    const requestStatus = useSelector(request_status_details);
    const requestTypeList = useSelector(request_type_details);

    const [filterData, setFilterData] = useState({
        department: "",
        section: "",
        employee_name: "",
        role: "",
        user_status: "",
        keyword: "",
    });
    const [appliedFilterData, setAppliedFilterData] = useState({
        department: "",
        section: "",
        employee_name: "",
        role: "",
        user_status: "",
        keyword: "",
    });
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(10)
    const [customShow, setCustomShow] = useState(10);
    const [sortColumn, setSortColumn] = useState("createdAt");
    const [sortAscending, setSortAscending] = useState(false);
    const [data, setData] = useState([])

    useEffect(() => {
        let obj = JSON.parse(localStorage.getItem('userlistingfilter'))
        getDataHandler(obj);
        dispatch(getDepartment())
        dispatch(getRequestStatus())
        dispatch(getAllUsersForHR())
        dispatch(getRequestType())
    }, []);

    const getDataHandler = (filter) => {
        let formData = new FormData();
        formData.append("department", filter?.department?._id || "");
        formData.append("section", filter?.section?._id || "");
        formData.append("employee_name", filter?.user?.name || "");
        formData.append("role", filter?.role?.key || "");
        formData.append("keyword", filter?.keyword.trim() || "");
        formData.append("user_status", filter?.status?.value || "");
        formData.append("page", filter?.page || 0);
        formData.append("perPage", customShow);
        formData.append("sort_key", "createdAt");
        formData.append("sort_order", -1);
        dispatch(getAllUsersForLogs(formData, (status, data) => {
            setPage(data.page)
        }));
    };

    const tableHeaders = [
        { name: t("Employee ID"), sort_key: "employee_id" },
        { name: t("Employee Name"), sort_key: "name" },
        { name: t("Department"), sort_key: "department" },
        { name: t("Section"), sort_key: "section" },
        { name: t("Role"), sort_key: "role" },
        { name: t("Email"), sort_key: "email" },
        { name: t("Phone"), sort_key: "phone" },
        { name: t("Status"), sort_key: "user_status" },
        { name: t("Action"), sort_key: "" },
    ];

    const getSortingClassName = (title) => {
        let className = "";
        if (sortColumn === title) {
            if (sortAscending) {
                className = "active";
            } else {
                className = "inactive";
            }
        }
        return className;
    };

    const clearFilter = () => {
        setFilterData({
            department: "",
            section: "",
            employee_name: "",
            role: "",
            user_status: "",
            keyword: "",
        });
        setAppliedFilterData({
            department: "",
            section: "",
            employee_name: "",
            role: "",
            user_status: "",
            keyword: "",
        });
        setPage(0);
        setSortAscending(false);
        setSortColumn("createdAt");
        let data = {
            department: "",
            section: "",
        };
        // dispatch(getEmployeelist(data));
        getDataHandler(0);
        dispatch(updateRedux({ key: "section_details", value: [] }));
        let formData = new FormData();
        formData.append("department", "");
        formData.append("section", "");
        dispatch(getAllUsersForHR(formData));
    };

    const applySearch = () => {
        // console.log({ filterData })
        setPage(0);
        localStorage.setItem('userlistingfilter', JSON.stringify(filterData))
        let formData = new FormData();
        formData.append("department", filterData?.department?._id ?? "");
        formData.append("section", filterData?.section?._id ?? "");
        formData.append("employee_name", filterData?.user?.name ?? "");
        formData.append("role", filterData?.role?.key ?? "");
        formData.append("keyword", filterData?.keyword.trim());
        formData.append("user_status", filterData?.status?.value ?? "");
        formData.append("page", 0);
        formData.append("perPage", customShow);
        formData.append("sort_key", sortColumn);
        formData.append("sort_order", sortAscending ? 1 : -1);
        console.log(formData)
        setAppliedFilterData(filterData)
        dispatch(getAllUsersForLogs(formData));
    };

    const handlePageChange = (e) => {
        let obj = filterData
        obj.page = e.selected
        localStorage.setItem('userlistingfilter', JSON.stringify(obj))
        setPage(e.selected);
        let formData = new FormData();
        formData.append("department", appliedFilterData?.department?._id ?? "");
        formData.append("section", appliedFilterData?.section?._id ?? "");
        formData.append("employee_name", appliedFilterData?.user?.name ?? "");
        formData.append("role", appliedFilterData?.role?.key ?? "");
        formData.append("keyword", appliedFilterData?.keyword.trim());
        formData.append("user_status", appliedFilterData?.user_status?.value ?? "");
        formData.append("page", e.selected);
        formData.append("perPage", customShow);
        formData.append("sort_order", sortAscending ? 1 : -1);
        formData.append("sort_key", sortColumn);
        dispatch(getAllUsersForLogs(formData));
        setFilterData(appliedFilterData)
    };

    const onSortHandler = (type) => {
        setSortAscending(!sortAscending);
        setSortColumn(type.key);
        let formData = new FormData();
        formData.append("department", appliedFilterData?.department?._id ?? "");
        formData.append("section", appliedFilterData?.section?._id ?? "");
        formData.append("employee_name", appliedFilterData?.user?.name ?? "");
        formData.append("role", appliedFilterData?.role?.key ?? "");
        formData.append("keyword", appliedFilterData?.keyword.trim());
        formData.append("user_status", appliedFilterData?.user_status?.value ?? "");
        formData.append("page", page);
        formData.append("perPage", customShow);
        formData.append("sort_key", type?.key);
        formData.append("sort_order", !sortAscending ? 1 : -1);
        dispatch(getAllUsersForLogs(formData));
        setFilterData(appliedFilterData)
    };

    const onLimitChangeHandler = (value) => {
        setCustomShow(parseInt(value));
        setPage(0);
        setPerPage(value)
        let formData = new FormData();
        formData.append("department", appliedFilterData?.department?._id ?? "");
        formData.append("section", appliedFilterData?.section?._id ?? "");
        formData.append("employee_name", appliedFilterData?.user?.name ?? "");
        formData.append("role", appliedFilterData?.role?.key ?? "");
        formData.append("keyword", appliedFilterData?.keyword.trim());
        formData.append("user_status", appliedFilterData?.user_status?.value ?? "");
        formData.append("page", 0);
        formData.append("perPage", parseInt(value));
        formData.append("sort_order", sortAscending ? 1 : -1);
        formData.append("sort_key", sortColumn);
        dispatch(getAllUsersForLogs(formData));
        setFilterData(appliedFilterData)
    };

    const onViewHandler = (ele) => {
        navigate(`/admin/users/user-log/${ele._id}`);
    };

    const onDepartmentChangeHandler = (value) => {
        setFilterData({ ...filterData, department: value, section: "" });
        dispatch(updateRedux({ key: "section_details", value: [] }));
        value._id && dispatch(getSection(value._id));
        let formData = new FormData();
        formData.append("department", value?._id || '');
        formData.append("section", "");
        dispatch(getAllUsersForHR(formData));
    };


    function searchBoxChangeHandler(value, key) {
        switch (key) {
            case 'request_type': setFilterData({ ...filterData, request_type: value })
                break;
            case 'date_from': setFilterData({ ...filterData, start_date: value })
                break;
            case 'date_to': setFilterData({ ...filterData, end_date: value })
                break;
            case 'department': onDepartmentChangeHandler(value)
                break;
            case 'employee': setFilterData({ ...filterData, user: value })
                break;
            case 'keyword': setFilterData({ ...filterData, keyword: value })
                break;
            case 'status': setFilterData({ ...filterData, status: value })
                break;
            default:
        }
    }

    useEffect(() => {
        setData([]);
        if (userList) {
            userList?.forEach((item, i) => {
                setData((s) => [...s,
                {
                    employee_id: item?.employee_id,
                    employee_name: item?.name,
                    department: item?.department?.department_name ?? '--',
                    designation: item?.designation?.[0]?.designation_name || '',
                    section: item?.section?.length ? item?.section[0].section_name : '--',
                    email: item?.email,
                    phone: item?.phone,
                    status: item?.user_status === "1" ? "Active" : "Inactive",
                    action: <Actions rootPath='/assets/icons/'
                        buttons={{ view: 'log.svg' }}
                        onView={() => navigate(`/admin/users/user-log/${item?._id}`)}
                    />
                },
                ]);
            });
        }
    }, [userList]);

    const filterPassedTime = (time) => {
        const currentDate = new Date();
        const selectedDate = new Date(time);
        return currentDate.getTime() < selectedDate.getTime();
    };

    // console.log({ data })

    return (
        <div id="HumanResource_admin">
            <>
                <section>
                    <PageHeader>
                        <Title content="Users" />
                        <Breadcrump content={[{ name: "Users", path: "/admin/users" }, { name: "Home", path: "/admin" }]} />
                        <Button className="css-btn-esc1" width={150} content="Sync Users" icon="/assets/icons/sync-svgrepo-com.svg" reverse onClick={() => { }} />
                    </PageHeader>
                </section>
                <section>
                    <SearchBox
                        elements={[
                            { label: 'Department', key: 'department', type: 'select', className: 'css-slc-esc2', placeholder: 'Select Department' },
                            { label: 'Employee', key: 'employee', type: 'select', className: 'css-slc-esc2', placeholder: 'Select User', width: '200px' },
                            { label: 'Status', key: 'status', type: 'select', className: 'css-slc-esc2', placeholder: 'Select Status' },
                        ]}
                        options={{
                            department: { options: departmentList, value: filterData?.department, labelSetter: (option) => option.department_name, valueSetter: (option) => option._id },
                            employee: { options: usersList, labelSetter: (option) => option.name, valueSetter: (option) => option._id, value: filterData?.user },
                            status: { options: [{ value: 1, label: 'Active' }, { value: 0, label: 'Inactive' }], labelSetter: (option) => option.label, valueSetter: (option) => option.value, value: filterData?.status, },
                        }}
                        onSearch={applySearch}
                        onClear={clearFilter}
                        showClearBtn={filterData?.request_type || filterData?.start_date || filterData?.end_date || filterData?.department || filterData?.user || filterData?.status || filterData.keyword}
                        onChange={searchBoxChangeHandler} />
                </section>
                <section className="d-flex justify-content-between">
                    <EntriesCounter value={perPage} onChange={onLimitChangeHandler} />
                    <Input value={filterData?.keyword} type='text' className="css-inp-esc2 search" icon='/assets/icons/Search1.svg' placeholder={t('Generic Search')} reverse width='300px'
                        field='keyword' onChange={searchBoxChangeHandler} onSubmit={applySearch} onIconClick={applySearch}
                    />
                </section>
                {loading ? <LoadingSpinner height="600px" /> :
                    <section className="table">
                        <Table className={'css-tbl-esc2'}
                            titles={[
                                { title: "Employee ID", key: "employee_id", width: '120px', sort: true },
                                { title: "Employee Name", key: "employee_name", sort: true },
                                { title: "Department", key: "department", sort: true },
                                { title: "Section", key: "section", sort: true, dir: 'ltr' },
                                { title: "Designation", key: "designation", sort: true, dir: 'ltr' },
                                { title: "Email", key: "email", sort: true, dir: 'ltr' },
                                { title: "Phone", key: "phone", sort: true },
                                { title: "Status", key: "status", width: '100px' },
                                { title: "Action", key: "action", width: '100px' },
                            ]}
                            content={data}
                            onSort={(e) => onSortHandler(e)}
                            sortValue={{ key: sortColumn, value: sortAscending ? 1 : -1 }}
                            page={page}
                            pagesCount={pageCount}
                            pageStatus={pageStatus}
                            onPageChange={handlePageChange}
                        />
                    </section>
                }
            </>
        </div>
    );
}

export default UsersListing;
