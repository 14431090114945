import React from 'react'
import PageHeader from '../../../commonComponents/PageHeader/PageHeader'
import Title from '../../../commonComponents/Title/Title'
import Breadcrump from '../../../commonComponents/Breadcrump/Breadcrump'
import TitleBar from '../../../commonComponents/TitleBar/TitleBar'
import Select from '../../../commonComponents/Select/Select'
import Input from '../../../commonComponents/Input/Input'
import FooterButtons from '../../../commonComponents/FooterButtons/FooterButtons'
import Button from '../../../commonComponents/Button/Button'

export default function EA_settings_add() {
    return (
        <div id='EA_settings_add'>
            <section className='mb-4'>
                <PageHeader>
                    <Title content="Education Allowance" />
                    <Breadcrump content={[
                        { name: "Educational Allowance", path: "/admin/settings/educational_allowance" },
                        { name: "Settings", path: "/admin/settings/certificate_type" },
                        { name: "Home", path: "/admin" }
                    ]} />
                </PageHeader>
            </section>
            <section>
                <TitleBar title='Allowance Details'>
                    <Select title='Select Grade' className='css-slc-esc1 ' width={150} options={[{ year: 2010 }, { year: 2011 }]}
                        valueSetter={(o) => o.year} labelSetter={(o) => o.year} />
                    <div className='d-flex gap-3 col-6'>
                        <Input className='css-inp-esc1' label='Government School Allowance ( AED )' placeholder='Enter Allowance' />
                        <Input className='css-inp-esc1' label='Private School Allowance ( AED )' placeholder='Enter Allowance' />
                    </div>
                </TitleBar>
            </section>
            <FooterButtons>
                <Button right className='css-btn-esc3' content='Back' icon='/assets/icons/Back.svg' reverse iconWidth='15px' />
                <Button left className='css-btn-esc3' content='Submit' />
                <Button left className='css-btn-esc3' content='Cancel' />
            </FooterButtons>
        </div>
    )
}