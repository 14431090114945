import React, { useEffect, useState } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'

function PrivateRoutes() {
  const location = useLocation()
  const token = localStorage.getItem('token')



  useEffect(() => {
    let path = location.pathname
    if (!path.includes('/admin/users/user-log/') && !path.includes('/admin/users')) {
      let isInUsersPage = localStorage.getItem('userlistingfilter')
      isInUsersPage && localStorage.setItem('userlistingfilter', null)
    }
  }, [location])



  // let auth = {'token':token}
  let auth = { 'token': token }



  return (
    auth.token ? <Outlet /> : <Navigate to='/login' />
  )
}

export default PrivateRoutes