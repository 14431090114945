import React, { useState } from 'react'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { updateRedux } from '../redux/commonReducer'

export default function usePrevRouteCatcher() {

    const location = useLocation()
    const dispatch = useDispatch()

    const [currRoute, setCurrRoute] = useState('')

    useEffect(() => {
        const path = location.pathname
        const prev = currRoute
        const curr = path
        setCurrRoute(curr)
        dispatch(updateRedux({ key: 'prevRoute', value: { curr: curr, prev: prev } }))
    }, [document.location.href])

    return null
}
