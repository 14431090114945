import React, { useState, useEffect } from 'react'
import './Select.scss'
import ReactSelect from 'react-select'
import useTranslate from '../../utils/useTranslate'

export default function Select({ placeholder, title, options, value, labelSetter, valueSetter, onChange, className, isMulti, style, width, error, showCloseBtn, required, showToggleBtn, disabled, classNamePrefix }) {

  const { t } = useTranslate()

  const [list, setList] = useState([])
  const [inValue, setInValue] = useState('')
  const [toggleBtn, setToggelBtn] = useState(false)

  useEffect(() => {
    options && setList(options)
    value && setInValue(value)
    return () => {
      setList([])
      setInValue('')
    }
  }, [options, value])

  function changeHandler(e) {
    setInValue(e)
    onChange && onChange(e)
  }

  return (
    <span id='Select' className={className + (showCloseBtn && inValue ? ' close_button_appear' : '')} style={{ width: width, ...style }}>
      {title && <p className='title'>
        {t(title)}
        {required && <em>*</em>}
        {showToggleBtn && <img className={'toggle_arrow' + (toggleBtn ? ' expanded' : ' collapsed')}
          src="/assets/icons/Icon material-arrow-drop-down1.svg" alt=""
          onClick={() => setToggelBtn(s => !s)}
        />}
      </p>}
      {!toggleBtn && <span className='select_span'>
        <ReactSelect
          isMulti={isMulti}
          options={list}
          value={inValue}
          placeholder={t(placeholder)}
          isDisabled={list.length === 0 || disabled}
          getOptionLabel={labelSetter}
          getOptionValue={valueSetter}
          onChange={changeHandler}
          classNamePrefix={classNamePrefix}
        />
        {showCloseBtn && inValue && <span className='close_btn' onClick={() => { setInValue(''); onChange && onChange('') }}>✖</span>}
      </span>}
      {error && <small id='error' className='error'>{error}</small>}
    </span>
  )
}



{/* 

<Select
  className='css-slc-esc1'
  placeholder='select...'
  options={[
    { a: 1 },
    { a: 2 },
    { a: 3 },
  ]}
  value={{}}
  labelSetter={(e) => e.a}
  valueSetter={(e) => e.a}
  onChange={(e) => { }}
  error={'error message'}
  title=''
  isMulti
  showCloseBtn
  showToggleBtn
/>

*/}