import React, { useEffect, useState } from 'react'
import './Button.scss'
import useTranslate from '../../utils/useTranslate'
import { Spinner } from 'react-bootstrap'

export default function Button({ content = '', icon, className = '', onClick, loader, reverse, width, height, disabled, iconWidth }) {

  const { t } = useTranslate()
  
  return (
    <button
      id='Button'
      className={className + ' ' + (reverse ? 'reverse' : '')}
      style={{ minWidth: width, height: height }}
      onClick={() => onClick && onClick()}
      disabled={disabled}
    >
      {t(content)}
      {loader
        ? <Spinner animation="border" role="status" size="sm"></Spinner>
        : icon && <img src={icon} style={{ width: iconWidth }} alt="" />
      }
    </button>
  )
}
Button.displayName = 'Button'



{/* 

<Button
  className=''
  content={'button name'}
  icon={'icon path'}
  reverse
  iconWidth='15px'
  width='100px'
  height='50px'
  disabled={true || false}
  onClick={() => {}}
/> 

*/}