import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  certificate_details,
  certificate_details_loading,
  certificate_details_path,
  request_status_details,
  update_certificate_request_loading,
} from "../../../../redux/commonReducer";
import { useNavigate, useParams } from "react-router-dom";
import {
  certificateRequestDetails,
  getRequestStatus,
  updateCertificateRequest,
} from "../../../../redux/actionCreator";
import { useEffect } from "react";
import { Tooltip } from "react-bootstrap";
import moment from "moment";
import Table from "../../../../commonComponents/Table/Table";
import TitleBar from "../../../../commonComponents/TitleBar/TitleBar";
import AttachmentsEyeView from "../../../../commonComponents/AttachmentsEyeView/AttachmentsEyeView";
import StatusBox from "../../../../commonComponents/StatusBox/StatusBox";
import TableCommentsViewer from "../../../../commonComponents/TableCommentsViewer/TableCommentsViewer";
import LoadingSpinner from "../../../../components/LoadingSpinner/LoadingSpinner";
import Title from "../../../../commonComponents/Title/Title";
import Breadcrump from "../../../../commonComponents/Breadcrump/Breadcrump";
import FooterButtons from "../../../../commonComponents/FooterButtons/FooterButtons";
import { toast } from "react-toastify";
import Button from "../../../../commonComponents/Button/Button";
import useTranslate from "../../../../utils/useTranslate";
import DateTimeViewer from "../../../../commonComponents/DateTimeViewer/DateTimeViewer";
import Modals from "../../../../components/Modals/Modals";
import { API_BASE_URL } from "../../../../constants/configuration";
import PageNotFound from "../../../general/PageNotFound"
import PageHeader from "../../../../commonComponents/PageHeader/PageHeader";
import RequestDetailsData from "../../../../components/RequestDetailsData/RequestDetailsData";
import CommentsWithAttachments from "../../../../components/CommentsWithAttachments/CommentsWithAttachments";
import FileViewModal from "../../../../components/FileViewModal/fileViewModal";

export default function RequestDetails() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslate()

  const { id, mode } = useParams();

  const isEditMode = mode === "edit"

  const details = useSelector(certificate_details);
  const url = useSelector(certificate_details_path);
  const allStatus = useSelector(request_status_details)
  const loading = useSelector(certificate_details_loading)
  const requestStatus = useSelector(request_status_details);
  const certficateUpdateLoading = useSelector(update_certificate_request_loading)

  const [comment, setComment] = useState("");
  const [err, setErr] = useState({});
  const [uploadFiles, setUploadFiles] = useState([]);
  const [fileView, setFileView] = useState(null);
  const [fileType, setFileType] = useState(null);
  const [fileNumber, setFileNumber] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [oldFileView, setOldFileView] = useState(null);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (id) {
      dispatch(certificateRequestDetails(id));
    }
  }, [id]);

  const getAssignedTo = (item, index) => {
    return ((item?.status?.handle_key == "return" || item?.status?.handle_key == "reject" || item?.status?.handle_key == "approve" || item?.status?.handle_key == "close") ? `${details?.request_user?.name}` :
      item?.status?.handle_key == "open" ? item?.handled_by?._id === details?.request_user?._id ? details?.actions[index + 1]?.handled_by?.name : item?.handled_by?.name :
        item?.forwarded_to?.name ?? item?.handled_by?.name)
  }

  useEffect(() => {
    setData([]);
    if (details?.actions) {
      details?.actions?.forEach((item, i) => {
        let userObject = JSON.parse(localStorage.getItem("user"))
        setData((s) => [
          ...s,
          {
            sl_no: details?.actions?.length - i,
            received_date: <DateTimeViewer dateTime={item?.received_date} />,
            last_update: <DateTimeViewer dateTime={item?.last_update} />,
            // handled_by: item?.handled_by?.name ?? "-",
            handled_by: (item?.status?.handle_key == "close" && item?.handled_by === null) ? "System" : item?.handled_by?.name ?? "-",
            // assigned_to: item?.forwarded_to?.name ?? "-",
            assigned_to: getAssignedTo(item, i),
            attachments: <AttachmentsEyeView files={{ path: API_BASE_URL + 'certificateRequests/', endpoints: item?.attachments }} />,
            status: <StatusBox status={item?.status?.handle_key == "return" ? "Need Info" : item?.status?.handle_key == "forward" ? "Processing" : item?.status?.request_status} />,
            comments: <TableCommentsViewer comment={item?.comment} />,
          },
        ]);
      });
    }
  }, [details]);

  useEffect(() => {
    dispatch(getRequestStatus())
  }, [])


  //   const data = [
  //     {
  //       sl_no: "01",
  //       received_date: "Tue Mar 28 2023	",
  //       last_update: "Tue Mar 28 2023	",
  //       handled_by: "Mona",
  //       assigned_to: "",
  //       attachments: "",
  //       status: "Need Info",
  //       comments: "Please mention why you need salary certificate",
  //     },
  //   ];
  const onFileChangeHandler = (e, isInvalid, message) => {
    if (isInvalid) {
      setErr({
        ...err,
        source_file:
          message
      });
    } else {
      setUploadFiles(e);
    }
    // let temp = [];
    // let size = 0;
    // e?.forEach((ele) => {
    //   const file = ele;
    //   const fileType = file.name
    //     .substr(file.name.lastIndexOf(".") + 1)
    //     .toLowerCase();
    //   size = size + ele.size;
    //   console.log({ fileType })
    //   if (
    //     ["jpeg", "jpg", "png", "pdf", "mp4", "docx", "doc"].includes(
    //       fileType
    //     )
    //   ) {
    //     setErr({ ...err, source_file: "" });
    //   } else {
    //     temp.push(ele);
    //     setErr({
    //       ...err,
    //       uploadFiles:
    //         "Please upload files with jpeg, jpg, png, pdf, mp4, docx, doc formats",
    //     });
    //   }
    // });
    // // let prevDataSize = 0;
    // // if (uploadFiles?.length != 0) {
    // //   uploadFiles?.forEach((ele) => {
    // //     prevDataSize = prevDataSize + ele.size;
    // //   });
    // // }
    // let totalFileSize = size
    // const fileSize = Math.round(totalFileSize / 1024);
    // console.log({ fileSize })
    // if (fileSize > 50 * 1024) {
    //   setErr({
    //     ...err,
    //     uploadFiles: "File too Big, please select files less than 50mb",
    //   });
    // } else {
    //   if (temp?.length === 0) {
    //     setErr({
    //       ...err,
    //       uploadFiles: "",
    //     });
    //   }
    // }
    // if (fileSize <= 50 * 1024 && temp?.length == 0) {
    //   setUploadFiles(e);
    // }
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {props.label}
    </Tooltip>
  );

  const fileUploadViewHandler = (ele) => {
    console.log(ele);
    setFileView(ele);
    setFileType("");
    setFileNumber("");
    setModalShow(true);
  };

  const onSaveHandler = () => {
    if (comment?.trim()?.length != 0) {
      // if (!/^[a-zA-Z0-9ء-ي .؟،,-:!"?()'@;\n]+$/.test(comment)) {
      //   setErr({
      //     ...err,
      //     comment: "Invalid characters",
      //   });
      // } else {
      // }
      if (err.uploadFiles) {
        toast.error(err.uploadFiles, {
          position: "bottom-center",
          autoClose: 3000
        })
        return
      }
      updateHandler();
    } else {
      setErr({
        ...err,
        comment: "Please Enter Comment",
      });
    }
  };

  const updateHandler = () => {
    let formData = new FormData();
    formData.append("id", id);
    formData.append("comment", comment);
    formData.append("user_id", details?.request_user._id);
    formData.append("request_type", details?.request_type?._id);
    formData.append("certificate_langage", details?.certificate_langage);
    formData.append("certificate_addressee", details.certificate_addressee);
    formData.append("status", allStatus?.find(item => item.handle_key === "open")._id)
    formData.append("is_status_changed", 1)
    formData.append("is_resubmit", 1)
    uploadFiles?.forEach((item, i) => formData.append("attachments", item.metaFile));
    console.log(formData, "update");
    dispatch(
      updateCertificateRequest(formData, (res) => {
        navigate("/human_resource/certificate_requests");
      })
    );
  };

  const onCancel = () => {
    setComment("")
    setErr({})
    setUploadFiles([])
  }

  console.log({ details })

  const hasPermission = () => {
    if (!details) {
      return false
    } else {
      if (localStorage.getItem("user_id") !== details?.request_user?._id) {
        return false
      }
    }
    return true
  }

  return (
    <div id="RequestDetails">
      {loading ?
        <LoadingSpinner height={"600px"} /> :
        hasPermission() ? <>
          {/* FILE VIEW */}
          <FileViewModal
            modalshow={modalShow}
            setModalShow={setModalShow}
            fileView={fileView}
            oldFileView={oldFileView}
            url={url}
            fileType={fileType}
            fileNumber={fileNumber}
            setOldFileView={setOldFileView}
            setFileView={setFileView}
            setFileNumber={setFileNumber}
            setFileType={setFileType}
          />
          <PageHeader>
            <Title content="Human Resource" />
            <Breadcrump content={[
              { name: "Certificate Requests", path: "/human_resource/certificate_requests" },
              { name: "HR", path: "/human_resource/certificate_requests" },
              { name: "Home", path: "/" }]}
            />
            <StatusBox status={details?.status?.handle_key === "return" ? "Need Info" : details?.status?.handle_key === "forward" ? "Processing" : details?.status?.request_status} />
          </PageHeader>
          <section className="details">
            <TitleBar title={'Request Details'} toggleButton defaultToggle={'show'} top>
              <RequestDetailsData data={details} />
            </TitleBar>
          </section>
          {details?.actions?.length ? <section>
            <TitleBar title={'History'} />
            <Table
              className={'css-tbl-esc1'}
              titles={[
                { title: 'Sl No', key: 'sl_no' },
                { title: 'Received Date', key: 'received_date', dir: 'ltr' },
                { title: 'Last Update', key: 'last_update', dir: 'ltr' },
                { title: 'Handled By', key: 'handled_by' },
                { title: 'Assigned To', key: 'assigned_to' },
                { title: 'Attachments', key: 'attachments' },
                { title: 'Status', key: 'status' },
                { title: 'Comments', key: 'comments', width: '25%' },
              ]}
              content={data}
            />
          </section> : null}
          {(details?.status?.handle_key == "return" && isEditMode) ? (
            <section className="comment">
              <CommentsWithAttachments
                comment={comment}
                setComment={setComment}
                setErr={setErr}
                err={err}
                onFileChangeHandler={onFileChangeHandler}
                uploadFiles={uploadFiles}
                renderTooltip={renderTooltip}
                fileUploadViewHandler={fileUploadViewHandler}
                setUploadFiles={onFileChangeHandler}
              />
            </section>
          ) : null}
          <section>
            <FooterButtons>
              <Button className="css-btn-esc3" right content='Back' icon='/assets/icons/Back.svg' reverse iconWidth='15px'
                onClick={() => navigate('/human_resource/certificate_requests')} />
              {isEditMode && details?.status?.handle_key === "return" &&
                <Button className="css-btn-esc3" left content='Submit' onClick={onSaveHandler} disabled={certficateUpdateLoading} />}
              {isEditMode && details?.status?.handle_key === "return" &&
                <Button className="css-btn-esc3" left content='Cancel' onClick={onCancel} />}
            </FooterButtons>
          </section>
        </> :
          <PageNotFound />
      }
    </div>
  );
}