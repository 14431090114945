import React, { useEffect, useState } from 'react'
import ReportCounts from '../../../../components/ReportCounts/ReportCounts'
import EntriesCounter from '../../../../commonComponents/EntriesCounter/EntriesCounter'
import Input from '../../../../commonComponents/Input/Input'
import Table from '../../../../commonComponents/Table/Table'
import StatusBox from '../../../../commonComponents/StatusBox/StatusBox'
import Actions from '../../../../commonComponents/Actions/Actions'
import { useDispatch, useSelector } from 'react-redux'
import { all_hr_users_list, certificate_report_loading, certificate_requests_report, certificate_requests_report_count, department_details, page_count_reports, page_status_reports, request_status_details, request_type_details } from '../../../../redux/commonReducer'
import moment from "moment/moment";
import { useNavigate } from 'react-router-dom'
import { downloadCertificate, exportCertificateRequestReports, getAllUsersForHR, getCertificateRequestsReport, getDepartment, getRequestStatus, getRequestType } from '../../../../redux/actionCreator'
import SearchBox from '../../../../commonComponents/SearchBox/SearchBox'
import Button from '../../../../commonComponents/Button/Button'
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner'
import { toast } from 'react-toastify'
import Title from '../../../../commonComponents/Title/Title'
import Breadcrump from '../../../../commonComponents/Breadcrump/Breadcrump'
import { statusLabelSetter } from '../../../../utils/helpers'
import useTranslate from '../../../../utils/useTranslate'
import DateTimeViewer from '../../../../commonComponents/DateTimeViewer/DateTimeViewer'
import PageHeader from '../../../../commonComponents/PageHeader/PageHeader'

export default function CertificateRequests_admin_report() {
    const loggedUser = localStorage.getItem("user_logged");
    const certificateRequestData = useSelector(certificate_requests_report)
    const pageStatus = useSelector(page_status_reports);
    const pageCount = useSelector(page_count_reports);
    const loading = useSelector(certificate_report_loading);
    const reportCount = useSelector(certificate_requests_report_count)

    const { t } = useTranslate()

    const [data, setData] = useState([]);
    const [sortAscending, setSortAscending] = useState(false);
    const [sortColumn, setSortColumn] = useState("last_update");
    const [page, setPage] = useState(0);


    const requestTypeList = useSelector(request_type_details);
    const departmentList = useSelector(department_details);
    const userList = useSelector(all_hr_users_list);
    const requestStatus = useSelector(request_status_details);


    const [perPage, setPerPage] = useState(10);
    const [modalRequestshow, setModalRequestShow] = useState(false);
    const [delId, setDelId] = useState("");
    const [advancedFilter, setAdvacnedFilter] = useState([
        { label: 'Department', value: 'department' },
        { label: 'Employee', value: 'employee' },
        { label: 'Request Type', value: 'request_type' },
        { label: 'Requested Date', value: 'request_date' },
        { label: 'Last Update', value: 'last_update' },
        { label: 'Resolution Time', value: 'resolution_time' },
        { label: 'Status', value: 'status' },
    ])

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const titles = [
        { title: 'Request No', key: 'request_no', sort: true, default: true },
        { title: 'Department', key: 'department', sort: true, default: true },
        { title: 'Section', key: 'section', sort: true, default: false },
        { title: 'Employee', key: 'employee', sort: true, default: true },
        { title: 'Employee ID', key: 'employee_id', sort: true },
        { title: 'Request Type', key: 'request_type', sort: true, default: true },
        { title: 'Requested Date', key: 'request_date', sort: true, dir: 'ltr', default: true },
        { title: 'Last Update', key: 'last_update', sort: true, dir: 'ltr', default: true },
        { title: 'Resolution Time', key: 'resolution_time', sort: true, dir: 'ltr', default: true },
        { title: 'Status', key: 'status', default: true },
        { title: 'Handled By', key: 'handled_by', sort: true },
        { title: 'Assigned To', key: 'assigned_to', sort: true },
        { title: 'Certificate Language', key: 'certificate_language', sort: true },
        { title: 'Addressee', key: 'addressee', sort: true },
        { title: 'Action', key: 'action', width: 100 }
    ]



    const getSearchDataHandler = (pag, perpage, sort, column, enableAppliedFilter) => {
        let currentFilter = enableAppliedFilter ? appliedFilterData: filterData
        let formData = new FormData();

        formData.append("keyword", currentFilter?.keyword ? currentFilter?.keyword?.trim() : "");
        formData.append("page", pag);
        formData.append("perPage", perpage);
        formData.append("sort_order", sort ?? -1);
        formData.append("sort_key", column);
        if (currentFilter?.start_date?.length != 0) {
            let s_date =
                moment(currentFilter?.start_date)
                    .format("YYYY-MM-DD hh:mm:ss")
                    ?.split(" ")[0] + " 00:00:00";
            formData.append("start_date", s_date);
        } else {
            formData.append("start_date", "");
        }
        if (currentFilter?.end_date?.length != 0) {
            let e_date =
                moment(currentFilter?.end_date)
                    .format("YYYY-MM-DD hh:mm:ss")
                    ?.split(" ")[0] + " 23:59:59";
            formData.append("end_date", e_date);
        } else {
            formData.append("end_date", "");
        };
        formData.append(
            "request_type",
            currentFilter?.request_type ? currentFilter?.request_type?._id : ""
        );
        formData.append(
            "department",
            currentFilter?.department ? currentFilter?.department?._id : ""
        );
        formData.append(
            "resolution_time",
            filterData?.resolution_time ? filterData?.resolution_time?.value : ""
        );
        formData.append("employee", currentFilter?.user ? currentFilter?.user?._id : "");
        currentFilter.status && currentFilter?.status?.forEach((item, index) => {
            formData.append(`status[${index}]`, item?._id);
        })
        formData.append("is_admin_mode", 1);

        dispatch(getCertificateRequestsReport(formData));
        enableAppliedFilter && setFilterData(appliedFilterData)

    }


    useEffect(() => {
        dispatch(getRequestType());
        dispatch(getDepartment());
        dispatch(getAllUsersForHR());
        dispatch(getRequestStatus());
    }, []);

    const downloadHandler = (id) => {
        dispatch(downloadCertificate(id))
    }

    const [filterData, setFilterData] = useState({
        start_date: '',
        end_date: "",
        request_type: "",
        department: "",
        employee: "",
        status: "",
        keyword: "",
        resolution_time: ""
    });
    const [appliedFilterData, setAppliedFilterData] = useState({
        start_date: '',
        end_date: "",
        request_type: "",
        department: "",
        employee: "",
        status: "",
        keyword: "",
        resolution_time: ""
    });
    const getDataHandler = (pag) => {
        // console.log("Aslam",{ filterData })

        let formData = new FormData();
        formData.append("page", pag);
        formData.append("perPage", perPage);
        formData.append("sort_order", sortAscending ? 1 : -1);
        formData.append("sort_key", sortColumn);
        if (filterData?.start_date?.length != 0) {
            let s_date =
                moment(filterData?.start_date)
                    .format("YYYY-MM-DD hh:mm:ss")
                    ?.split(" ")[0] + " 00:00:00";
            formData.append("start_date", s_date);
        } else {
            formData.append("start_date", "");
        }
        if (filterData?.end_date?.length != 0) {
            let e_date =
                moment(filterData?.end_date)
                    .format("YYYY-MM-DD hh:mm:ss")
                    ?.split(" ")[0] + " 23:59:59";
            formData.append("end_date", e_date);
        } else {
            formData.append("end_date", "");
        };
        formData.append(
            "request_type",
            filterData?.request_type ? filterData?.request_type?._id : ""
        );
        formData.append(
            "department",
            filterData?.department ? filterData?.department?._id : ""
        );
        formData.append(
            "resolution_time",
            filterData?.resolution_time ? filterData?.resolution_time?.value : ""
        );
        formData.append("employee", filterData?.user ? filterData?.user?._id : "");
        filterData.status && filterData?.status?.forEach((item, index) => {
            formData.append(`status[${index}]`, item?._id);
        })
        formData.append("is_admin_mode", 1);
        dispatch(getCertificateRequestsReport(formData));
    }


    useEffect(() => {
        getDataHandler(0)
    }, []);

    const getAssignedTo = (item) => {
        console.log(item)
        return ((item?.status[0]?.handle_key == "return" || item?.status[0]?.handle_key == "reject" || item?.status[0]?.handle_key == "approve" || item?.status[0]?.handle_key == "close") ? `${item?.request_user[0]?.name}` :
            item?.status[0]?.handle_key == "open" ?
                (item?.handled_by[0]?._id === item?.request_user[0]?._id && item?.actions?.length) ? item?.actions?.at(-2)?.handled_by?.name || 'HR Pool' :
                    item?.actions?.length ? item?.handled_by[0]?.name : "HR Pool"
                : item?.forwarded_to[0]?.name ?? item?.handled_by[0]?.name)
    }

    useEffect(() => {
        setData([]);
        if (certificateRequestData) {
            certificateRequestData?.forEach((item, i) => {
                setData((s) => [...s,
                {
                    request_no: item?.request_no,
                    employee_id: item?.request_user[0]?.employee_id,
                    employee: item?.request_user[0]?.name,
                    department: item?.request_user[0]?.department?.department_name ?? "-",
                    section: item?.request_user[0]?.section?.length ? item?.request_user[0]?.section[0]?.section_name : "-",
                    request_type: item?.request_type[0]?.request_type,
                    request_date: <DateTimeViewer dateTime={item?.request_date} />,
                    last_update: <DateTimeViewer dateTime={item?.last_update} />,
                    handled_by: item?.handled_by?.length ? item?.handled_by[0]?.name : "-",
                    assigned_to: getAssignedTo(item),
                    certificate_language: item?.certificate_langage,
                    addressee: item?.certificate_addressee,
                    resolution_time: item?.resolution_time,
                    status: <StatusBox status={item?.status[0]?.handle_key == "return" ? "Need Info" : item?.status[0]?.request_status} />,
                    action: <Actions rootPath='/assets/icons/'
                        buttons={{ view: 'Eye-Open.svg', download: 'Group 12237.svg' }}
                        visibility={{ download: item?.status[0]?.handle_key == "approve" }}
                        onView={() => navigate(`/admin/report/certificate_requests/request_details/${item?._id}/view`)}
                        // onDelete={() => deleteRequest(item?._id)}
                        onDownload={() => { downloadHandler(item?._id) }}
                    />
                },
                ]);
            });
        }
    }, [certificateRequestData]);
    const onSortHandler = ({ key, value }) => {
        setSortAscending(value === 1);
        setSortColumn(key);
        getSearchDataHandler(page, perPage, value, key, true)
    };

    const handlePageChange = (e) => {
        setPage(e.selected);
        let sort = sortAscending ? 1 : -1
        getSearchDataHandler(e.selected, perPage, sort, sortColumn, true)
    };


    function searchBoxChangeHandler(value, key) {
        if (key === "department") {
            setFilterData(s => ({ ...s, employee: "" }))
            let formData = new FormData();
            formData.append("department", value?._id || '');
            formData.append("section", "");
            dispatch(getAllUsersForHR(formData));
        }
        setFilterData(s => ({ ...s, [key]: value }))
    }


    const handleExport = () => {
        let formData = new FormData();
        formData.append("keyword", appliedFilterData?.keyword ? appliedFilterData?.keyword?.trim() : "");
        if (appliedFilterData?.start_date?.length != 0) {
            let s_date =
                moment(appliedFilterData?.start_date)
                    .format("YYYY-MM-DD hh:mm:ss")
                    ?.split(" ")[0] + " 00:00:00";
            formData.append("start_date", s_date);
        } else {
            formData.append("start_date", "");
        }
        if (appliedFilterData?.end_date?.length != 0) {
            let e_date =
                moment(appliedFilterData?.end_date)
                    .format("YYYY-MM-DD hh:mm:ss")
                    ?.split(" ")[0] + " 23:59:59";
            formData.append("end_date", e_date);
        } else {
            formData.append("end_date", "");
        };
        formData.append(
            "request_type",
            appliedFilterData?.request_type ? appliedFilterData?.request_type?._id : ""
        );
        formData.append(
            "department",
            appliedFilterData?.department ? appliedFilterData?.department?._id : ""
        );
        formData.append(
            "resolution_time",
            filterData?.resolution_time ? filterData?.resolution_time?.value : ""
        );
        formData.append("employee", appliedFilterData?.user ? appliedFilterData?.user?._id : "");
        appliedFilterData.status && appliedFilterData?.status?.forEach((item, index) => {
            formData.append(`status[${index}]`, item?._id);
        })
        formData.append("is_admin_mode", 1);
        formData.append(`advanced_filter[${0}]`, "request_no")
        advancedFilter.filter(item => item !== "request_no").reverse().forEach((item, index) => {
            formData.append(`advanced_filter[${index + 1}]`, item.value)
        })

        dispatch(exportCertificateRequestReports(formData))
    }
    const onLimitChangeHandler = (value) => {
        console.log('value', value)
        setPerPage(parseInt(value));
        setPage(0);
        let sort = sortAscending ? 1 : -1;
        getSearchDataHandler(0, parseInt(value), sort, sortColumn, true);
    };
    const clearFilter = () => {
        setFilterData({
            start_date: "",
            end_date: "",
            department: "",
            user: "",
            status: "",
            keyword: "",
            resolution_time: ""
        });
        setAppliedFilterData({
            start_date: "",
            end_date: "",
            department: "",
            user: "",
            status: "",
            keyword: "",
            resolution_time: ""
        });
        let formData = new FormData();
        formData.append("page", 0);
        formData.append("perPage", perPage);
        formData.append("is_admin_mode", 1);
        dispatch(getCertificateRequestsReport(formData));
    };
    const applySearch = () => {
        console.log('test', { filterData })
        if (filterData?.start_date || filterData?.end_date) {
            if (!(filterData?.start_date) || !(filterData?.end_date)) {
                toast.error("Start date and end date are mandatory", {
                    position: "bottom-center",
                    autoClose: 3000
                })
                return
            }
        }
        setPage(0)
        setAppliedFilterData(filterData)
        let sort = sortAscending ? 1 : -1
        getSearchDataHandler(0, perPage, sort, sortColumn)
    };

    const filterPassedTime = (time) => {
        const currentDate = new Date();
        const selectedDate = new Date(time);
        return currentDate.getTime() < selectedDate.getTime();
    };

    const handleAdvanceFilterChange = (value) => {
        setAdvacnedFilter(value)
    }

    const activeTitles = () => {
        let titlesToShow = [...titles]
        if (advancedFilter.length) {
            titlesToShow = titlesToShow.filter((item => {
                if (item.key === "action" || item.key === "request_no") {
                    return true
                }
                if (advancedFilter.some(obj => obj.value === item.key)) {
                    return true
                } else {
                    return false
                }
            }))
        } else {
            titlesToShow = titlesToShow.filter((item => {
                if (item.key === "action" || item.key === "request_no") {
                    return true
                }
                return false
            }))
        }
        return titlesToShow
    }

    console.log({ filterData })

    return (
        <div id='CertificateRequests_admin_report'>
            <>
                <section>
                    <PageHeader>
                        <Title content="Human Resource" />
                        <Breadcrump content={[
                            { name: "Reports", path: "/admin/report/certificate_requests" },
                            { name: "HR", path: "/admin" },
                            { name: "Home", path: "/admin" }
                        ]} />
                    </PageHeader>
                </section>
                <section>
                    <SearchBox
                        elements={[
                            { label: 'Date', key: 'start_date', type: 'datePicker', className: 'css-dtp-esc3', placeholder: 'From' },
                            { label: '', key: 'end_date', type: 'datePicker', className: 'css-dtp-esc3', placeholder: 'To' },
                            { label: 'Request Type', key: 'request_type', type: 'select', className: 'css-slc-esc2', placeholder: 'Select Request Type', width: '210px' },
                            { label: 'Department', key: 'department', type: 'select', className: 'css-slc-esc2', placeholder: 'Select Department', width: '205px' },
                            { label: 'Employee', key: 'user', type: 'select', className: 'css-slc-esc2', placeholder: 'Select User', width: '200px' },
                            { label: 'Status', key: 'status', type: 'select', className: 'css-slc-esc2', placeholder: 'Select Status', isMulti: true },
                            { label: 'Resolution Time', key: 'resolution_time', type: 'select', className: 'css-slc-esc2', placeholder: 'Select Resolution Time' },
                        ]}
                        options={{
                            start_date: { selected: filterData?.start_date, filterTime: filterPassedTime, maxDate: filterData?.end_date ? filterData?.end_date : new Date(), dateFormat: "dd/MM/yy" },
                            end_date: { selected: filterData?.end_date, filterTime: filterPassedTime, minDate: filterData?.start_date && filterData?.start_date, maxDate: new Date(), dateFormat: "dd/MM/yy" },
                            request_type: { options: requestTypeList, value: filterData?.request_type, labelSetter: (option) => option.request_type, valueSetter: (option) => option._id },
                            department: { options: departmentList, value: filterData?.department, labelSetter: (option) => option.department_name, valueSetter: (option) => option._id },
                            user: { options: userList, labelSetter: (option) => option.name, valueSetter: (option) => option._id, value: filterData?.employee },
                            status: { options: requestStatus, labelSetter: (option) => statusLabelSetter(option), valueSetter: (option) => option._id, value: filterData?.status, },
                            resolution_time: { options: [{value: 1, label: 'Less than 2 days'}, {value: 2, label: 'Between 2 to 5 days'}, {value: 3, label: 'More than 5 days'}], labelSetter: (option) => option.label, valueSetter: (option) => option.value, value: filterData?.resolution_time, },
                        }}
                        showClearBtn={Object.values(filterData).some(e => Boolean(e))}
                        showAdvancedSearch
                        onSearch={applySearch}
                        onClear={clearFilter}
                        onExport={handleExport}
                        onChange={searchBoxChangeHandler}
                        handleAdvanceFilterChange={handleAdvanceFilterChange}
                        advancedFilter={advancedFilter}
                    />
                </section>
                <section>
                    {reportCount?.length && <ReportCounts data= {reportCount}/>}
                </section>
                <section className="d-flex justify-content-between">
                    <EntriesCounter value={perPage} onChange={onLimitChangeHandler} />
                    <Input value={filterData.keyword} type='text' className="css-inp-esc2 search" icon='/assets/icons/Search1.svg' placeholder={t('Generic Search')} reverse width='300px'
                        field='keyword' onChange={(value) => { setFilterData(s => ({ ...s, keyword: value })); }} onSubmit={applySearch} showCloseBtn onIconClick={applySearch}
                    />
                </section>
                {loading ? <LoadingSpinner height={"200px"} /> :
                    <section className='table'>
                        <Table className={'css-tbl-esc2'}
                            titles={activeTitles()}
                            content={data}
                            onSort={(e) => onSortHandler(e)}
                            page={page}
                            pagesCount={pageCount}
                            pageStatus={pageStatus}
                            onPageChange={handlePageChange}
                            sortValue={{ key: sortColumn, value: sortAscending ? 1 : -1 }}
                        />
                    </section>}
            </>
        </div>
    )
}
