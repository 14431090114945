import React, { useEffect, useState } from 'react'
import './DatePick.scss'
import DatePicker from "react-datepicker";
import useTranslate from '../../utils/useTranslate';

export default function DatePick({ title, selected, onChange, filterTime, dateFormat, required, maxDate, minDate, className, disabled, placeholder, error, showCloseBtn }) {

  const { t } = useTranslate()

  function changeHandler(e) {
    onChange && onChange(e)
  }

  return (
    <span id='DatePick' className={className}>
      {title && <label className='mb-1'>{t(title)}{required && <em>*</em>}</label>}
      <span>
        <DatePicker
          selected={selected}
          onChange={changeHandler}
          filterTime={filterTime}
          dateFormat={dateFormat}
          maxDate={maxDate}
          minDate={minDate}
          placeholderText={t(placeholder)}
          disabled={disabled}
        />
        {(showCloseBtn && selected)
          ? <span className='close_btn' onClick={() => { onChange && onChange('') }}>✖</span>
          : <img src="/assets/icons/calendar.svg" alt="" />
        }
      </span>
      {error && <small id='error' className='error'>{t(error)}</small>}
    </span>
  )
}
