import React from "react";
import { useState } from "react";
import RequestDetailsForm from "../../../../components/RequestDetailsForm/RequestDetailsForm";
import {
  addAdminRequest,
  addCertificateRequest,
  getRequestStatus,
} from "../../../../redux/actionCreator";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { add_certificate_loading, request_status_details } from "../../../../redux/commonReducer";
import FooterButtons from "../../../../commonComponents/FooterButtons/FooterButtons";
import Title from "../../../../commonComponents/Title/Title";
import Breadcrump from "../../../../commonComponents/Breadcrump/Breadcrump";
import Button from "../../../../commonComponents/Button/Button";
import PageHeader from "../../../../commonComponents/PageHeader/PageHeader";

export default function AddRequest() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user_id = localStorage.getItem("user_id");
  const requestStatus = useSelector(request_status_details);
  const addCertificateLoading = useSelector(add_certificate_loading)

  const [data, setData] = useState({
    address: "",
    comment: "",
  });
  const [certificate, setCerificate] = useState("");
  const [language, setLanguage] = useState("");
  const [err, setErr] = useState({
    // certificate: "err",
    // language: "err",
    // address: "err",
    // comment: "err",
  });


  useEffect(() => {
    dispatch(getRequestStatus());
  }, []);

  const onChangeHandler = (key, value) => {
    console.log(key, value)
    setData({
      ...data,
      [key]: value,
    });
  };

  const validate = () => {
    let temp = {};
    if (data.address?.trim()?.length == 0) {
      console.log(data)
      temp = { ...temp, address: "Please Add Address" };
    }
    if (data.comment?.trim()?.length == 0) {
      temp = { ...temp, comment: "Please Add Comment" };
    }
    if (certificate?.length == 0) {
      temp = { ...temp, certificate: "Please Select Certificate" };
    }
    if (language?.length == 0) {
      temp = { ...temp, language: "Please Select Language" };
    }
    if (Object.values(temp).length != 0) {
      console.log(err);
      setErr(temp);
      return false;
    } else {
      return true;
    }
  };

  const onSaveHandler = () => {
    console.log({ certificate })
    if (validate()) {
      let formData = new FormData();
      formData.append("user_id", user_id);
      formData.append("request_type", certificate?._id);
      formData.append("certificate_langage", language?.value);
      formData.append("certificate_addressee", data.address?.trim());
      formData.append("request_comment", data.comment?.trim());
      formData.append("status", requestStatus?.filter((ele, i) => ele.handle_key == "open")[0]?._id);
      console.log(formData);
      dispatch(
        addCertificateRequest(formData, () => {
          setData({});
          setCerificate("");
          setLanguage("");
          navigate("/human_resource/certificate_requests");
        })
      );
    }
  };

  const onCancel = () => {
    setData({
      address: "",
      comment: "",
    })
    setLanguage("")
    setCerificate("")
    setErr({})
  }

  return (
    (
      <div id="AddRequest">
        <section>
          <PageHeader>
            <Title content="Add Request" />
            <Breadcrump content={[
              { name: "Add Request", path: "" },
              { name: "HR", path: "/human_resource/certificate_requests" },
              { name: "Home", path: "/" }
            ]} />
          </PageHeader>
        </section>
        <section className="details mt-5">
          <RequestDetailsForm
            data={data}
            onChangeHandler={onChangeHandler}
            certificate={certificate}
            setCerificate={setCerificate}
            language={language}
            setLanguage={setLanguage}
            err={err}
          />
        </section>
        <section>
          <FooterButtons>
            <Button right className="css-btn-esc3" content='Back' icon='/assets/icons/Back.svg' reverse iconWidth='15px'
              onClick={() => navigate('/human_resource/certificate_requests')} />
            <Button left className="css-btn-esc3" content='Submit' onClick={onSaveHandler} disabled={addCertificateLoading} />
            <Button left className="css-btn-esc3" content='Cancel' onClick={onCancel} />
          </FooterButtons>
        </section>
      </div>
    )
  );
}
