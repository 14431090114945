import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllEmployeeList,
  getAllUsers,
  getAllUsersForHR,
  getAllUsersForHr,
  getDepartment,
  getSection,
} from "../../redux/actionCreator";
import {
  all_employee_list,
  all_hr_users_list,
  department_details,
  section_details,
  updateRedux,
  user_list,
} from "../../redux/commonReducer";
import "./SelectEmployee.scss";
import Select from "../../commonComponents/Select/Select";
import TitleBar from "../../commonComponents/TitleBar/TitleBar";
import Button from "../../commonComponents/Button/Button";
import useTranslate from "../../utils/useTranslate";
// import Select from "react-select";

export default function SelectEmployee({
  department,
  setDepartment,
  section,
  setSection,
  user,
  setUser,
  err,
  page,
  applySearch,
  onClear
}) {
  const dispatch = useDispatch();
  const { t } = useTranslate()

  const departmentList = useSelector(department_details);
  const sectionList = useSelector(section_details);
  const userList = useSelector(all_hr_users_list);

  useEffect(() => {
    dispatch(getDepartment());
    let formData = new FormData();
    formData.append("department", "");
    formData.append("section", "");
    dispatch(getAllUsersForHR(formData));
  }, []);

  const onDepartmentChangeHandler = (value) => {
    setDepartment(value);
    setSection("");
    dispatch(updateRedux({ key: "section_details", value: [] }));
    console.log(value)
    value._id && dispatch(getSection(value._id));
    let formData = new FormData();
    formData.append("department", value?._id || '');
    formData.append("section", "");
    dispatch(getAllUsersForHR(formData));
  };

  const onSectionChangeHandler = (value) => {
    setSection(value);
    let formData = new FormData();
    formData.append("department", department?._id);
    formData.append("section", value?._id);
    dispatch(getAllUsersForHR(formData));
  };

  const onUserChangeHandler = (value) => {
    setUser(value);
    // let formData = new FormData();
    // formData.append("department", department ? department._id : "");
    // formData.append("section", section ? section._id : "");
    // formData.append("user", value?._id);
    // dispatch(getAllUsersForHR(formData));
  };

  return (
    <div id="SelectEmployee">
      <TitleBar title='Select Employee'  >

        <div className="inputs" style={{ alignItems: 'flex-start' }}>
          <Select
            className='css-slc-esc1'
            placeholder={t('Select Department')}
            title={t('Select Department')}
            options={departmentList}
            value={department}
            labelSetter={(e) => e.department_name}
            valueSetter={(e) => e._id}
            onChange={(e) => onDepartmentChangeHandler(e)}
            error={err.department}
          />
          <Select
            className='css-slc-esc1'
            placeholder={t('Select Section')}
            title={t('Select Section')}
            options={sectionList}
            value={section}
            labelSetter={(e) => e.section_name}
            valueSetter={(e) => e._id}
            onChange={(e) => onSectionChangeHandler(e)}
            error={err.section}
          />
          <Select
            className='css-slc-esc1'
            placeholder={t('Select User')}
            title={t('Select User')}
            options={userList}
            value={user}
            labelSetter={(e) => e.name}
            valueSetter={(e) => e._id}
            onChange={(e) => onUserChangeHandler(e)}
            error={err.user}
          />
          <div className="search_button" style={{ height: 40.44, marginTop: '32px' }}>
            {(department || section || user) ?
              <Button className="css-btn-esc2 sm" onClick={onClear && onClear} content="✖" /> : ""}
          </div>
        </div>
      </TitleBar>
    </div>
  );
}