import React from "react";
import "./CommentsWithAttachments.scss";
import { OverlayTrigger } from "react-bootstrap";
import TextArea from "../../commonComponents/TextArea/TextArea";
import FileUpload from "../../commonComponents/FileUpload/FileUpload";
import useTranslate from "../../utils/useTranslate";
import TitleBar from "../../commonComponents/TitleBar/TitleBar";

export default function CommentsWithAttachments({
  comment,
  setComment,
  setReturnComment,
  setErr,
  err,
  onFileChangeHandler,
  uploadFiles,
  renderTooltip,
  fileUploadViewHandler,
  setUploadFiles,
  setModalErr,
}) {
  const { t } = useTranslate();
  return (
    <div id="CommentsWithAttachments">
      <TextArea
        label="Comment"
        className={"css-txt-esc2"}
        error={err?.comment}
        placeholder={t("Comment here")}
        value={comment}
        onChange={(e) => {
          setComment(e);
          setErr({ ...err, comment: "" });
          setModalErr({});
        }}
      />
      <FileUpload
        className="css-atc-esc1 mt-3"
        buttonLabel={t("Add Attachments")}
        multiple
        accept={["pdf", "doc", "docx", "jpg", "jpeg", "png", "mp4"]}
        info={t(
          "Supported formats : jpeg, jpg, png, pdf, mp4, docx, doc and Size upto 50 MB"
        )}
        showPreview
        files={uploadFiles}
        onChange={(e, isInvalid, message) => {
          setUploadFiles(e, isInvalid, message);
        }}
      />
      <div className="text-danger">
        <small>{err?.uploadFiles}</small>
      </div>
    </div>
  );
}
