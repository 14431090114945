import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PersonalInformation from "../../../../components/PersonalInformation/PersonalInformation";
import RequestDetailsForm from "../../../../components/RequestDetailsForm/RequestDetailsForm";
import SelectEmployee from "../../../../components/SelectEmployee/SelectEmployee";
import { addCertificateRequest, getAllUsersForHR, getRequestStatus } from "../../../../redux/actionCreator";
import { add_certificate_loading, request_status_details, updateRedux, user_list } from "../../../../redux/commonReducer";
import Modals from "../../../../components/Modals/Modals";
import { useNavigate } from "react-router-dom";
import Title from "../../../../commonComponents/Title/Title";
import Breadcrump from "../../../../commonComponents/Breadcrump/Breadcrump";
import FooterButtons from "../../../../commonComponents/FooterButtons/FooterButtons";
import Button from "../../../../commonComponents/Button/Button";
import PageHeader from "../../../../commonComponents/PageHeader/PageHeader";
import TitleBar from "../../../../commonComponents/TitleBar/TitleBar";

export default function AddRequest_admin() {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const userList = useSelector(user_list);

  const requestStatus = useSelector(request_status_details);
  const addCertificateLoading = useSelector(add_certificate_loading)


  const [department, setDepartment] = useState("");
  const [section, setSection] = useState("");
  const [user, setUser] = useState("");
  const [data, setData] = useState({
    address: "",
    comment: "",
    personalData: {},
  });
  const [certificate, setCerificate] = useState("");
  const [language, setLanguage] = useState("");
  const [certificateStatus, setCertificateStatus] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [err, setErr] = useState({
    // department: "err",
    // section: "err",
    // user: "err",
    // certificate: "err",
    // language: "err",
    // address: "err",
    // comment: "err",
    // certificateStatus: "err",
  });
  const [input, setInput] = useState({
    file: "",
    finalComment: "",
    forwardUser: "",
    returnComment: "",
    rejectionComment: "",
    closeComment: "",
  });
  const [modalErr, setModalErr] = useState({});
  const [comment, setComment] = useState("");

  useEffect(() => {
    dispatch(getRequestStatus());
  }, []);

  // useEffect(() => {
  //   if (Object.keys(user)?.length) {
  //     onChangeHandler(
  //       "personalData",
  //       userList?.filter((ele) => ele._id == user?.value)
  //     );
  //   }
  // }, [user]);

  const onChangeHandler = (key, value) => {
    setData({
      ...data,
      [key]: value,
    });
  };

  const validate = () => {
    let temp = {};
    if (data.address?.trim()?.length == 0) {
      temp = { ...temp, address: "Please Add Address" };
    }
    if (data.comment?.trim()?.length == 0) {
      temp = { ...temp, comment: "Please Add Comment" };
    }
    // if (department?.length == 0) {
    //   temp = { ...temp, department: "Please Select Department" };
    // }
    // if (section?.length == 0) {
    //   temp = { ...temp, section: "Please Select Section" };
    // }
    if (user?.length == 0) {
      temp = { ...temp, user: "Please Select User" };
    }
    if (certificate?.length == 0) {
      temp = { ...temp, certificate: "Please Select Certificate" };
    }
    if (language?.length == 0) {
      temp = { ...temp, language: "Please Select Language" };
    }
    if (Object.values(temp).length != 0) {
      console.log(err);
      setErr(temp);
      return false;
    } else {
      return true;
    }
  };

  const onSaveHandler = () => {
    if (validate()) {
      onAddRequestHandler();
    }
  };


  const onAddRequestHandler = () => {

    let formData = new FormData();
    formData.append("user_id", user?._id);
    formData.append("request_type", certificate?._id);
    formData.append("certificate_langage", language?.value);
    formData.append("certificate_addressee", data.address?.trim());
    formData.append("request_comment", data.comment?.trim());
    formData.append("status", requestStatus?.filter((ele, i) => ele.handle_key == "open")[0]?._id);
    console.log(formData)
    dispatch(addCertificateRequest(formData, () => {
      setModalShow(false)
      navigate("/admin/human_resource/certificate_requests")
    }))
  };

  function selectHandler(e) {
    setCertificateStatus(e);
    if (e && e.handle_key !== "processing") setModalShow(true)
  }

  const onCancel = () => {
    setData({
      address: "",
      comment: "",
      personalData: {},
    })
    setLanguage("")
    setCerificate("")
    setErr({})
    setComment("")
    setModalErr({})
    setInput({
      file: "",
      finalComment: "",
      forwardUser: "",
      returnComment: "",
      rejectionComment: "",
      closeComment: "",
    })
    setDepartment("")
    setSection("")
    setUser("")
    dispatch(updateRedux({ key: "section_details", value: [] }));
    let formData = new FormData();
    formData.append("department", "");
    formData.append("section", "");
    dispatch(getAllUsersForHR(formData));
  }

  const onClear = () => {
    setDepartment("")
    setSection("")
    setUser("")
    dispatch(updateRedux({ key: "section_details", value: [] }));
    let formData = new FormData();
    formData.append("department", "");
    formData.append("section", "");
    dispatch(getAllUsersForHR(formData));
  }

  return (
    (
      <div id="AddRequest_admin">
        <section>
          <PageHeader>
            <Title content="Add Request" />
            <Breadcrump content={[{ name: "Certificate Requests", path: "" }, { name: "HR", path: "/admin/human_resource/certificate_requests" }, { name: "Home", path: "/admin" }]} />
          </PageHeader>
        </section>
        <section>
          <SelectEmployee
            department={department}
            setDepartment={setDepartment}
            section={section}
            setSection={setSection}
            user={user}
            setUser={setUser}
            err={err}
            onClear={onClear}
          />
        </section>
        {user &&
          <section>
            <TitleBar title='Personal Information' toggleButton defaultToggle={'show'}>
              <PersonalInformation user={user} />
            </TitleBar>
          </section>}
        <section>
          <RequestDetailsForm
            data={data}
            onChangeHandler={onChangeHandler}
            certificate={certificate}
            setCerificate={setCerificate}
            language={language}
            setLanguage={setLanguage}
            err={err}
            page={'addrequest_admin'}
          />
        </section>
        <section>
          <FooterButtons>
            <Button right className="css-btn-esc3" content='Back' icon='/assets/icons/Back.svg' reverse iconWidth='15px'
              onClick={() => navigate('/admin/human_resource/certificate_requests')} />
            <Button left className="css-btn-esc3" content='Submit' onClick={onSaveHandler} disabled={addCertificateLoading} />
            <Button left className="css-btn-esc3" content='Cancel' onClick={onCancel} />
          </FooterButtons>
        </section>
        <Modals
          show={modalShow}
          setShow={setModalShow}
          type={certificateStatus.handle_key}
          onAddRequestHandler={onAddRequestHandler}
          input={input}
          setInput={setInput}
          modalErr={modalErr}
          setModalErr={setModalErr}
          comment={comment}
          setComment={setComment}
          setCertificateStatus={setCertificateStatus}
        />
      </div>
    )
  );
}
