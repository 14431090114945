import React, { useEffect, useState } from 'react'
import './Layout.scss'
import { Outlet } from 'react-router-dom'
import Header from '../Header/Header'
import NavBarHeader from '../NavBarHeader/NavBarHeader'
import NavItemsListing from '../NavItemsListing/NavItemsListing'

export default function Layout() {

    const [navToggle, setNavToggle] = useState(true)
    const [selected, setSelected] = useState({ item: 1, subItem: 0 })

    useEffect(() => {
        window.addEventListener('load', (e) => e.currentTarget.innerWidth <= 1200 ? setNavToggle(false) : setNavToggle(true))
        window.addEventListener('resize', (e) => e.target.innerWidth <= 1200 ? setNavToggle(false) : setNavToggle(true))
    })

    return (
        <div id='Layout'>
            <main className={'main' + (navToggle ? ' show' : ' hide')}>
                <Header setNavToggle={setNavToggle} />
                <div className='outlet' dir='rtl'>
                    <Outlet />
                </div>
            </main>
            <aside className={'navigation' + (navToggle ? ' show' : ' hide')} dir='rtl'>
                <section className='header'>
                    <NavBarHeader setSelected={setSelected} />
                </section>
                <section>
                    <NavItemsListing selected={selected} setSelected={setSelected} />
                </section>
            </aside >
        </div >
    )
}
