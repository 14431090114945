import React from 'react'
import './ReportCounts.scss'
import { certificate_requests_report_count } from '../../redux/commonReducer'
import { useSelector } from 'react-redux'
import useTranslate from '../../utils/useTranslate'

export default function ReportCounts({data}) {

  // const reportCount = useSelector(certificate_requests_report_count)
  const {t} = useTranslate()

  // const data = [
  //   { title: 'Total', count: reportCount?.total },
  //   { title: 'Open', count: reportCount?.open },
  //   { title: 'Forwarded', count: reportCount?.forwarded },
  //   { title: 'Need Info', count: reportCount?.needInfo },
  //   { title: 'Processing', count: reportCount?.processing },
  //   { title: 'Approved', count: reportCount?.approved },
  //   { title: 'Rejected', count: reportCount?.rejected },
  //   { title: 'Closed', count: reportCount?.closed },
  // ]

  return (
    <div id='ReportCounts'>

      {
        data.map((datom, i) => (
          <span key={i} className={datom.title}>
            <p>{t(datom.title)}</p>
            <p>{datom.count}</p>
          </span>
        ))
      }
    </div>
  )
}
