import React, { useEffect, useState } from 'react'
import PageHeader from '../../../commonComponents/PageHeader/PageHeader';
import Title from '../../../commonComponents/Title/Title';
import Breadcrump from '../../../commonComponents/Breadcrump/Breadcrump';
import Button from '../../../commonComponents/Button/Button';
import SearchBox from '../../../commonComponents/SearchBox/SearchBox';
import EntriesCounter from '../../../commonComponents/EntriesCounter/EntriesCounter';
import Input from '../../../commonComponents/Input/Input';
import Table from '../../../commonComponents/Table/Table';
import useTranslate from '../../../utils/useTranslate';
import { useNavigate } from 'react-router-dom';
import StatusBox from '../../../commonComponents/StatusBox/StatusBox';
import Actions from '../../../commonComponents/Actions/Actions';
import useFilter from '../../../utils/useFilter';

export default function EducationalAllowance() {

  const navigate = useNavigate()
  const { t } = useTranslate()

  const [data, setData] = useState([])
  const [filterData, setFilterData, F_navigate] = useFilter({
    initialFilter: {},
    dataFetch: (filter) => { }
  })

  useEffect(() => {
    setData([
      {
        request_no: 'HR23008',
        academic_year: 'Sep 2023 - June 2024',
        requested_date: '03 Feb 2023',
        last_update: '03 Feb 2023',
        child_name: 'Jane Doe',
        handled_by: 'Maitha',
        status: <StatusBox status='open' />,
        action: <Actions />
      }
    ])
  }, [])

  function applySearch() {

  }

  function onSortHandler() {

  }

  function clearFilter() {

  }


  function filterPassedTime(time) {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    return currentDate.getTime() < selectedDate.getTime();
  }

  return (
    <div id='EducationalAllowance'>
      <section className='mb-4'>
        <PageHeader>
          <Title content="Onboarding Process" />
          <Breadcrump content={[
            { name: "Educational Allowance", path: "/admin/human_resource/educational_allowance" },
            { name: "Human Resource", path: "/admin/human_resource/certificate_requests" },
            { name: "Home", path: "/admin" }
          ]} />
          <Button width={80} content={"Add Request"} className="css-btn-esc1" icon="/assets/icons/Add Request.svg" reverse
            onClick={() => navigate("/admin/human_resource/educational_allowance/add_request")} />
        </PageHeader>
      </section>
      <section className='mb-4'>
        <SearchBox
          elements={[
            { label: "Date", key: "date_from", type: "datePicker", className: "css-dtp-esc3", placeholder: "From", },
            { label: "", key: "date_to", type: "datePicker", className: "css-dtp-esc3", placeholder: "To", },
            { label: "Academic Year", key: "academic_year", type: "select", className: "css-slc-esc2", placeholder: "Select Year", width: "200px", },
            { label: "Status", key: "status", type: "select", className: "css-slc-esc2", placeholder: "Select Status", },
          ]}
          options={{
            date_from: { selected: filterData?.start_date, filterTime: filterPassedTime, maxDate: filterData?.end_date || new Date(), dateFormat: "dd/MM/yy", },
            date_to: { selected: filterData?.end_date, filterTime: filterPassedTime, minDate: filterData?.start_date, maxDate: new Date(), dateFormat: "dd/MM/yy", },
            academic_year: { options: [], labelSetter: (o) => o.name, valueSetter: (o) => o._id, value: filterData?.year, },
            status: { options: [], labelSetter: (o) => o.label, valueSetter: (o) => o.value, value: filterData?.status, },
          }}
          onSearch={applySearch}
          onClear={clearFilter}
          showClearBtn
          onChange={() => { }}
        />
      </section>
      <section className="d-flex justify-content-between mb-4">
        <EntriesCounter value={filterData.entries} onChange={() => { }} />
        <Input value={filterData.keyword} type="text" className="css-inp-esc2 search" icon="/assets/icons/Search1.svg"
          placeholder={t("Generic Search")} reverse width="300px" field="keyword"
          onChange={(value) => { setFilterData((s) => ({ ...s, keyword: value })); }}
          onSubmit={applySearch} showCloseBtn onIconClick={applySearch} />
      </section>
      <section className="table">
        <Table
          className={"css-tbl-esc2"}
          titles={[
            { title: 'Request No', key: 'request_no', sort: true },
            { title: 'Academic Year', key: 'academic_year', sort: true },
            { title: 'Requested Date', key: 'requested_date', sort: true },
            { title: 'Last Update', key: 'last_update', sort: true },
            { title: 'Child Name', key: 'child_name', sort: true },
            { title: 'Handled By', key: 'handled_by', sort: true },
            { title: 'Status', key: 'status', sort: true },
            { title: 'Action', key: 'action' },
          ]}
          content={data}
          onSort={(e) => onSortHandler(e)}
          page={filterData.page}
          pagesCount={data?.pagesCount}
          pageStatus={data?.pageStatus}
          onPageChange={() => { }}
          sortValue={{ key: '', value: 1 }}
        />
      </section>
    </div>
  )
} 