import React, { useEffect, useRef, useState } from 'react'
import './TableCommentsViewer.scss'
import arrowIcon from './arrow-down-tan.svg'

export default function TableCommentsViewer({ comment = '' }) {

  const currSpanElem = useRef()
  let lineHeight = 25
  let numberOfLines = 2

  const [isCollapsed, setIsCollapsed] = useState(true)
  const [isArrowVisible, setIsArrowVisible] = useState(false)
  const [isOverflowContent, setIsOverflowContent] = useState(false)

  useEffect(() => {
    let elemHeight = currSpanElem.current?.clientHeight
    if (elemHeight > (lineHeight * numberOfLines)) {
      setIsArrowVisible(true)
      setIsOverflowContent(true)
    }
    return () => {
      setIsArrowVisible(false)
      setIsOverflowContent(false)
    }
  }, [currSpanElem, comment])

  return (
    <span
      id='TableCommentsViewer'
      ref={currSpanElem}
      style={{ lineHeight: lineHeight + 'px', WebkitLineClamp: numberOfLines }}
      className={isOverflowContent && isCollapsed ? 'less__content' : ''}
    >
      {comment}
      <img
        className={'read_more' + (isArrowVisible ? ' show' : ' hide') + (isCollapsed ? '' : ' up')}
        src={arrowIcon} onClick={() => { setIsCollapsed(s => !s) }}
      />
    </span>
  )
}