import React, { useEffect, useState } from "react";
import Title from "../../../commonComponents/Title/Title";
import Breadcrump from "../../../commonComponents/Breadcrump/Breadcrump";
import Button from "../../../commonComponents/Button/Button";
import { useNavigate } from "react-router-dom";
import Table from "../../../commonComponents/Table/Table";
import SearchBox from "../../../commonComponents/SearchBox/SearchBox";
import {
  admin_onboarding_list,
  admin_onboarding_page_status,
  admin_onboarding_pages,
  all_hr_users_list,
  onborading_loading,
} from "../../../redux/commonReducer";
import { useDispatch, useSelector } from "react-redux";
import useTranslate from "../../../utils/useTranslate";
import {
  downloadIdCard,
  exportOnboardings,
  getAllUsersForHR,
  getOnboardingRequests,
} from "../../../redux/actionCreator";
import DateTimeViewer from "../../../commonComponents/DateTimeViewer/DateTimeViewer";
import StatusBox from "../../../commonComponents/StatusBox/StatusBox";
import PageHeader from "../../../commonComponents/PageHeader/PageHeader";
import Actions from "../../../commonComponents/Actions/Actions";
import moment from "moment";
import EntriesCounter from "../../../commonComponents/EntriesCounter/EntriesCounter";
import Input from "../../../commonComponents/Input/Input";
import useFilter from "../../../utils/useFilter";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";

function OnboardingProcess({ userpage }) {
  const initialFilter = {
    department: "",
    section: "",
    user: "",
    keyword: "",
    status: "",
    joining: "",
    start_date: "",
    end_date: "",
    page: 0,
    perPage: 10,
    sortColumn: "last_update",
    sortAscending: -1,
  };

  const requestStatusList = [
    { value: "processing", label: "Processing" },
    { value: "forward", label: "Forwarded" },
    { value: "return", label: "Need info" },
    { value: "close", label: "Closed" },
  ];

  const itRequestStatusList = [
    { value: "processing", label: "Processing" },
    { value: "open", label: "Open" },
    { value: "return", label: "Need info" },
    { value: "close", label: "Closed" },
  ];

  const titles = [
    { title: "Request No", key: "request_no", sort: true, width: "90px" },
    {
      title: "Created Date",
      key: "created_date",
      sort: true,
      width: "100px",
      dir: "ltr",
    },
    { title: "Employee", key: "first_name", sort: true, width: "110px" },
    {
      title: "Last Update",
      key: "last_update",
      sort: true,
      width: "90px",
      dir: "ltr",
    },
    {
      title: "Joining Date",
      key: "joining_date",
      sort: true,
      width: "90px",
      dir: "ltr",
    },
    { title: "Handled By", key: "handled_by", sort: true, width: "90px" },
    { title: "Assigned To", key: "assigned_to", sort: true, width: "90px" },
    { title: "Request Status", key: "status", sort: true, width: "90px" },
    { title: "Action", key: "action", width: "85px" },
  ];

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslate();

  const [filterData, setFilterData, F_navigate] = useFilter({
    initialFilter,
    dataFetch: (filter) => { getSearchDataHandler(filter) },
    dependency: [userpage]
  })

  const userList = useSelector(all_hr_users_list);
  const loading = useSelector(onborading_loading);
  const requestData = useSelector(admin_onboarding_list);
  const pageCount = useSelector(admin_onboarding_pages);
  const pageStatus = useSelector(admin_onboarding_page_status);

  const [data, setData] = useState([]);

  useEffect(() => {
    let formData = new FormData();
    formData.append("department", "");
    formData.append("section", "");
    dispatch(getAllUsersForHR(formData));
  }, [userpage]);

  useEffect(() => {
    setData([]);
    if (requestData) {
      requestData?.forEach((item, i) => {
        setData((s) => [
          ...s,
          {
            request_no: item?.request?.request_no,
            created_date: (
              <DateTimeViewer dateTime={item?.request?.created_date} />
            ),
            first_name: item?.request?.first_name
              ? `${item?.request?.first_name} ${
                  item?.request?.second_name
                    ? item?.request?.second_name
                    : item?.request?.last_name
                    ? item?.request?.last_name
                    : ""
                }`
              : "-",
            last_update: <DateTimeViewer dateTime={item?.last_update} />,
            joining_date: item?.request?.joining_date ? (
              <DateTimeViewer dateTime={item?.request?.joining_date} dateOnly />
            ) : (
              "-"
            ),
            handled_by: item?.handled_by?.name,
            assigned_to: item?.assigned_to || "-",
            status: (
              <StatusBox
                status={
                  item?.status?.handle_key == "return"
                    ? "Need Info"
                    : item?.status?.request_status
                }
              />
            ),
            action: (
              <Actions
                rootPath="/assets/icons/"
                buttons={{
                  edit: "Edit.svg",
                  non_edit: "Edit Grey.svg",
                  view: "Eye-Open.svg",
                  download: "Group 12237.svg",
                }}
                visibility={{
                  download:
                    item?.request?.id_card != undefined &&
                    item?.request?.id_card != "",
                  edit:
                    userpage == "hr"
                      ? (item?.status?.handle_key === "processing" &&
                          !item?.request?.is_completed) ||
                        item?.status?.handle_key === "return"
                      : item?.status?.handle_key === "processing" ||
                        item?.status?.handle_key === "open",
                  non_edit:
                    userpage == "hr"
                      ? item?.status?.handle_key === "forward" ||
                        item?.status?.handle_key === "open" ||
                        (item?.status?.handle_key === "processing" &&
                          item?.request?.is_completed) ||
                        item?.status?.handle_key === "close"
                      : item?.status?.handle_key === "close" ||
                        item?.status?.handle_key === "return",
                }}
                onView={() => {
                  userpage == "hr"
                    ? F_navigate(
                        `/admin/human_resource/onboarding_process/request_details/${item?.request?._id}/view`
                      )
                    : F_navigate(
                        `/admin/it/onboarding_process/request_details/${item?.request?._id}/view`
                      );
                }}
                onEdit={() => {
                  userpage == "it"
                    ? F_navigate(
                        `/admin/it/onboarding_process/request_details/${item?.request?._id}/edit`
                      )
                    : item?.request?.is_completed
                    ? F_navigate(
                        `/admin/human_resource/onboarding_process/request_details/${item?.request?._id}/edit`
                      )
                    : F_navigate(
                        `/admin/human_resource/onboarding_process/edit_onboarding/${item?.request?._id}/edit`
                      );
                }}
                onDownload={() => {
                  onDownloadHandler(item?.request?._id);
                }}
              />
            ),
          },
        ]);
      });
    }
  }, [requestData]);

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    return currentDate.getTime() < selectedDate.getTime();
  };

  const applySearch = () => {
    let filter = { ...filterData, page: 0 };
    setFilterData(filter);
    getSearchDataHandler(filterData);
  };

  const clearFilter = () => {
    let filter = initialFilter;
    setFilterData(filter);
    getSearchDataHandler(filter);
  };

  const onSortHandler = ({ key, value }) => {
    let filter = { ...filterData, sortColumn: key, sortAscending: value };
    setFilterData(filter);
    getSearchDataHandler(filter);
  };

  const handlePageChange = (e) => {
    let filter = { ...filterData, page: e.selected };
    setFilterData(filter);
    getSearchDataHandler(filter);
  };

  const onLimitChangeHandler = (value) => {
    let filter = { ...filterData, page: 0, perPage: parseInt(value) };
    setFilterData(filter);
    getSearchDataHandler(filter);
  };

  const getSearchDataHandler = (data) => {
    let formData = new FormData();
    formData.append("page", data?.page);
    formData.append("perPage", data?.perPage);
    formData.append("sort_order", data?.sortAscending);
    formData.append("sort_key", data?.sortColumn);
    formData.append("createdDateFrom", dateFormatter(data?.start_date, true));
    formData.append("createdDateTo", dateFormatter(data?.end_date, false));
    formData.append("employee", data?.user?._id || "");
    formData.append("status", data?.status?.value || "");
    formData.append("joining", data?.joining?.value || "");
    formData.append("keyword", data?.keyword?.trim() || "");
    formData.append("list_type", userpage);
    dispatch(getOnboardingRequests(formData));
  };

  const handleExport = () => {
    let formData = new FormData();
    formData.append("page", filterData.page);
    formData.append("perPage", filterData.perPage);
    formData.append("sort_order", filterData.sortAscending);
    formData.append("sort_key", filterData.sortColumn);
    formData.append("createdDateFrom", dateFormatter(data?.start_date, true));
    formData.append("createdDateTo", dateFormatter(data?.end_date, false));
    formData.append("employee", filterData?.user?._id || "");
    formData.append("status", filterData?.status?.value || "");
    formData.append("joining", filterData?.joining?.value || "");
    formData.append("keyword", filterData?.keyword.trim());
    formData.append("list_type", userpage);
    console.log(formData);
    dispatch(exportOnboardings(formData));
  };

  function dateFormatter(date, isStart) {
    if (filterData?.start_date?.length === 0) return "";
    else
      return (
        moment(data?.start_date).format("YYYY-MM-DD hh:mm:ss")?.split(" ")[0] +
        (isStart ? " 00:00:00" : " 23:59:59")
      );
  }

  function searchBoxChangeHandler(value, key) {
    setFilterData((s) => ({ ...s, ...filterData, [key]: value }));
  }

  const onDownloadHandler = (id) => {
    dispatch(downloadIdCard(id));
  };

  return (
    <div className="onboarding__process" id="onboarding__process">
      <section>
        <PageHeader>
          <Title content="Onboarding Process" />
          <Breadcrump
            content={[
              {
                name: "Onboarding Process",
                path:
                  userpage == "hr"
                    ? "/admin/human_resource/onboarding_process"
                    : "/admin/it/onboarding_process",
              },
              {
                name: userpage == "hr" ? "Human Resource" : "IT",
                path:
                  userpage == "hr"
                    ? "/admin/human_resource/certificate_requests"
                    : "/admin/it/onboarding_process",
              },
              { name: "Home", path: "/admin" },
            ]}
          />
          {userpage == "hr" ? (
            <Button
              width={80}
              content={t("Add")}
              className="css-btn-esc1"
              icon="/assets/icons/Add Request.svg"
              reverse
              onClick={() => navigate("/admin/human_resource/add_onboarding")}
            />
          ) : (
            <></>
          )}
        </PageHeader>
      </section>
      <section>
        <SearchBox
          elements={[
            {
              label: "Date",
              key: "start_date",
              type: "datePicker",
              className: "css-dtp-esc3",
              placeholder: "From",
            },
            {
              label: "",
              key: "end_date",
              type: "datePicker",
              className: "css-dtp-esc3",
              placeholder: "To",
            },
            // { label: "Employee Name", key: "user", type: "select", className: "css-slc-esc2", placeholder: "Select User", width: "200px", },
            {
              label: "Request Status",
              key: "status",
              type: "select",
              className: "css-slc-esc2",
              placeholder: "Select Request Status",
            },
            {
              label: "Joining Status",
              key: "joining",
              type: "select",
              className: "css-slc-esc2",
              placeholder: "Select Joining Status",
            },
          ]}
          options={{
            start_date: {
              selected: filterData?.start_date,
              filterTime: filterPassedTime,
              maxDate: filterData?.end_date ? filterData?.end_date : new Date(),
              dateFormat: "dd/MM/yy",
            },
            end_date: {
              selected: filterData?.end_date,
              filterTime: filterPassedTime,
              minDate: filterData?.start_date && filterData?.start_date,
              maxDate: new Date(),
              dateFormat: "dd/MM/yy",
            },
            // user: { options: userList, labelSetter: (option) => option.name, valueSetter: (option) => option._id, value: filterData?.user, },
            status: {
              options:
                userpage == "hr" ? requestStatusList : itRequestStatusList,
              labelSetter: (option) => option.label,
              valueSetter: (option) => option.value,
              value: filterData?.status,
            },
            joining: {
              options: [
                { value: 1, label: "Joined" },
                { value: 0, label: "Not Joined" },
              ],
              labelSetter: (option) => option.label,
              valueSetter: (option) => option.value,
              value: filterData?.joining,
            },
          }}
          onSearch={applySearch}
          onClear={clearFilter}
          showClearBtn={
            filterData?.start_date ||
            filterData?.end_date ||
            filterData?.user ||
            filterData?.status ||
            filterData?.joining ||
            filterData?.keyword
          }
          onChange={searchBoxChangeHandler}
          onExport={handleExport}
        />
      </section>
      <section className="d-flex justify-content-between">
        <EntriesCounter
          value={filterData.perPage}
          onChange={onLimitChangeHandler}
        />
        <Input
          value={filterData?.keyword}
          type="text"
          className="css-inp-esc2 search"
          icon="/assets/icons/Search1.svg"
          placeholder={t("Generic Search")}
          reverse
          width="300px"
          field="keyword"
          onChange={(value) => {
            setFilterData((s) => ({ ...s, keyword: value }));
          }}
          onSubmit={applySearch}
          // showCloseBtn
          onIconClick={applySearch}
        />
      </section>
      {loading ? (
        <LoadingSpinner height="200px" />
      ) : (
        <section className="table">
          <Table
            className={"css-tbl-esc2"}
            titles={titles}
            content={data}
            onSort={(e) => onSortHandler(e)}
            page={filterData.page}
            pagesCount={pageCount}
            pageStatus={pageStatus}
            onPageChange={handlePageChange}
            sortValue={{
              key: filterData.sortColumn,
              value: filterData.sortAscending,
            }}
          />
        </section>
      )}
    </div>
  );
}

export default OnboardingProcess;
