import React from 'react'
import './FooterButtons.scss'

export default function FooterButtons({ className, children }) {

  return (
    <div id='FooterButtons' className={className || ''}>
      <div>
        {
          children && (!Array.isArray(children) ? [children] : children).map((item, i) => {
            if (item?.props?.right)
              return item
          })
        }
      </div>
      <div>
        {
          children && (!Array.isArray(children) ? [children] : children).map((item, i) => {
            if (item?.props?.left)
              return item
          })
        }
      </div>
    </div>
  )
}