import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";

import AddRequest_admin from "../pages/hr/certificateRequests/admin/AddRequest_admin";
import CertificateRequests_admin_report from "../pages/hr/certificateRequests/admin/CertificateRequests_admin_report";
import HumanResource_admin from "../pages/hr/certificateRequests/admin/HumanResource_admin";
import RequestDetails_admin from "../pages/hr/certificateRequests/admin/RequestDetails_admin";

import AddRequest from "../pages/hr/certificateRequests/user/AddRequest";
import CertificateRequests from "../pages/hr/certificateRequests/user/CertificateRequests";

import Layout from "../components/Layout/Layout";
import Login from "../pages/general/Login";

import Dashboard from "../pages/general/Dashboard";
import PageNotFound from "../pages/general/PageNotFound";
import PrivateRoutes from "../utils/PrivateRoutes";
import CertificateTypeList from "../pages/settings/certificateType";
import UsersListing from "../pages/manageUser/usersListing";
import UsersLog from "../pages/manageUser/UsersLog";
import Translation, { ImportDynamicText } from "../pages/settings/Translation";
import { current_user_permissions, user_logged_in } from "../redux/commonReducer";
import { useSelector } from "react-redux";
import OnboardingProcess from "../pages/hr/onBoarding/OnboardingProcess";
import AddOnboarding from "../pages/hr/onBoarding/AddOnboarding";
import OnboardingDetails from "../pages/hr/onBoarding/OnboardingDetails";

import EducationalAllowance_Listing from '../pages/hr/educationalAllowance/EA_listing'
import EducationalAllowance_AddRequest from '../pages/hr/educationalAllowance/EA_addRequest'
import EA_settings from "../pages/hr/educationalAllowance/EA_settings";
import EA_settings_add from "../pages/hr/educationalAllowance/EA_settings_add";
import { moduleAccess, moduleMainAccess, userType } from "../utils/helpers";
import usePrevRouteCatcher from "../utils/usePrevRouteCatcher";
import AddPermission from "../pages/settings/permission/addPermission";
import EditPermission from "../pages/settings/permission/editPermission";
import PermissionList from "../pages/settings/permission/permissions";
import RequestDetails from "../pages/hr/certificateRequests/user/RequestDetails";
import EmployeeDetails from "../pages/hr/employeeList/employeeDetails";
import EmployeeListing from "../pages/hr/employeeList/employeeListing";
import OnboardingHRReport from "../pages/hr/onBoarding/onboardingReport";
import OnboardingReport from "../pages/hr/onBoarding/onboardingReport";

function RouteHandler() {

  usePrevRouteCatcher()
  const userPermissions = useSelector(current_user_permissions)
  const user_logged = localStorage.getItem("user_logged");
  const userlogged = useSelector(user_logged_in)

  localStorage.setItem('userlistingfilter', null)

  const hasAdminPrivilage = () => {
    let flag = false;
    if (userPermissions && userPermissions.length && userPermissions.some(item => item.module_name === "certificate_request" && item.admin === "1")) {
      flag = true
    }
    let userObject = JSON.parse(localStorage.getItem("user"))
    // console.log(userObject)
    if (userObject && userObject.role?.[0] === "super_admin") {
      flag = true
    }
    return flag
  }

  return (
    // console.log(user_logged),
    <>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route element={<PrivateRoutes />}>
          <Route path="/" element={<Layout />}>
            <Route index element={<Dashboard role={"User"} />} />
            <Route path="human_resource/certificate_requests" element={<CertificateRequests />} />
            <Route path="human_resource/certificate_requests/request_details/:id/:mode" element={<RequestDetails />} />
            <Route path="human_resource/certificate_requests/add_request" element={<AddRequest />} />
            <Route path="human_resource/educational_allowance" element={<EducationalAllowance_Listing />} />
            <Route path="human_resource/educational_allowance/add_request" element={<EducationalAllowance_AddRequest />} />
            <Route path="*" element={<PageNotFound />} />
          </Route>
          {moduleAccess()?.length && <Route path="/admin" element={<Layout />}>
            <Route index element={<Dashboard role={"Admin"} />} />
            {
              (moduleAccess()?.includes("certificate_request_admin") || userType() == "super_admin") && user_logged == "Admin"
                ? <>
                  <Route path="human_resource/certificate_requests" element={<HumanResource_admin />} />
                  <Route path="human_resource/certificate_requests/request_details/:id/:mode" element={<RequestDetails_admin />} />
                  <Route path="human_resource/certificate_requests/add_request" element={<AddRequest_admin />} />
                </>
                : null
            }
            {
              ((moduleMainAccess()?.includes("HR") && moduleAccess()?.includes("onboarding_hr_admin")) || userType() == "super_admin") && user_logged == "Admin"
                ? <>
                  <Route path="human_resource/add_onboarding" element={<AddOnboarding />} />
                  <Route path="human_resource/onboarding_process/edit_onboarding/:id/:mode" element={<AddOnboarding />} />
                  <Route path="human_resource/onboarding_process/request_details/:id/:mode" element={<OnboardingDetails userpage={"hr"} />} />
                  <Route path="human_resource/onboarding_process" element={<OnboardingProcess userpage={"hr"} />} />
                </>
                : null
            }
            {
              ((moduleMainAccess()?.includes("IT") && moduleAccess()?.includes("onboarding_it_admin")) || userType() == "super_admin") && user_logged == "Admin"
                ? <>
                  <Route path="it/onboarding_process/request_details/:id/:mode" element={<OnboardingDetails userpage={"it"} />} />
                  <Route path="it/onboarding_process" element={<OnboardingProcess userpage={"it"} />} />
                </>
                : null
            }
            <Route path="human_resource/educational_allowance" element={<EducationalAllowance_Listing />} />
            <Route path="human_resource/educational_allowance/add_request" element={<EducationalAllowance_AddRequest />} />
            <Route path="human_resource/deputation_allowance" element={<PageNotFound />} />

            
            {
              (moduleAccess()?.includes("employee_management_admin") || userType() == "super_admin") && user_logged == "Admin"
              ? <>
                <Route path="human_resource/employee_list" element={<EmployeeListing />} />
                <Route path="human_resource/employee_list/edit_details/:id" element={<EmployeeDetails />} />
              </>
              :<></>
            }
            {
              ((moduleMainAccess()?.includes("REPORTS") && moduleAccess()?.includes("certificate_request_report")) || userType() == "super_admin") && user_logged == "Admin"
              ?<>
                <Route path="report/certificate_requests" element={<CertificateRequests_admin_report />} />
                <Route path="report/certificate_requests/request_details/:id/:mode" element={<RequestDetails_admin />} />
              </>
              :<></>
            }
            {
              ((moduleMainAccess()?.includes("REPORTS") && moduleAccess()?.includes("onboarding_hr_report")) || userType() == "super_admin") && user_logged == "Admin"
              ?<>
                <Route path="report/onboarding_hr_report" element={<OnboardingReport userpage={"hr"}/>} />
                <Route path="report/onboarding_hr_report/request_details/:id/:mode" element={<OnboardingDetails userpage={"hr_report"} />} />
              </>
              :<></>
            }
            {
              ((moduleMainAccess()?.includes("REPORTS") && moduleAccess()?.includes("onboarding_it_report")) || userType() == "super_admin") && user_logged == "Admin"
              ?<>
                <Route path="report/onboarding_it_report" element={<OnboardingReport userpage={"it"}/>} />
                <Route path="report/onboarding_it_report/request_details/:id/:mode" element={<OnboardingDetails userpage={"it_report"} />} />
              </>
              :<></>
            }
            

            <Route path="settings/certificate_type" element={<CertificateTypeList />} />
            <Route path="settings/educational_allowance" element={<EA_settings />} />
            <Route path="settings/educational_allowance/add_allowance" element={<EA_settings_add />} />
            <Route path="settings/permissions" element={<PermissionList />} />
            <Route path="settings/permissions/add-permission" element={<AddPermission />} />
            <Route path="settings/permissions/update-permission/:id" element={<EditPermission/>} />
            <Route path="settings/translation" element={<Translation />} />
            <Route path="settings/dynamicTextImport" element={<ImportDynamicText />} />
            <Route path="users" element={<UsersListing />} />
            <Route path="users/user-log/:id" element={<UsersLog />} />
            <Route path="*" element={<PageNotFound />} />
          </Route>}
        </Route>

        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </>
  );
}

export default RouteHandler;
