import React, { Fragment, useEffect, useMemo, useState } from "react";
import "./onboarding.scss";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  admin_onboarding_details,
  admin_onboarding_details_loading,
  admin_onboarding_history,
  department_details,
  designations_list,
  employeeNumber,
  fileUploadLoader,
  section_details,
  updateRedux,
} from "../../../redux/commonReducer";
import {
  addOnboardingRequest,
  checkEmployeeNo,
  deleteOnboard,
  editOnboardingRequest,
  errorToast,
  getDepartment,
  getDesignations,
  getEmployeeNo,
  getSection,
  OnboardingRequestDetails,
  successToast,
  uploadFile,
} from "../../../redux/actionCreator";
import countryList from "react-select-country-list";
import Title from "../../../commonComponents/Title/Title";
import Breadcrump from "../../../commonComponents/Breadcrump/Breadcrump";
import TitleBar from "../../../commonComponents/TitleBar/TitleBar";
import Select from "../../../commonComponents/Select/Select";
import Input from "../../../commonComponents/Input/Input";
import DatePick from "../../../commonComponents/DatePick/DatePick";
import TextArea from "../../../commonComponents/TextArea/TextArea";
import FileUpload from "../../../commonComponents/FileUpload/FileUpload";
import FooterButtons from "../../../commonComponents/FooterButtons/FooterButtons";
import PageHeader from "../../../commonComponents/PageHeader/PageHeader";
import "./onboarding.scss";
import Button from "../../../commonComponents/Button/Button";
import Modals from "../../../components/Modals/Modals";
import Checkbox from "../../../commonComponents/Checkbox/Checkbox";
import TitleValue from "../../../commonComponents/TitleValue/TitleValue";
import moment from "moment";
import { toast } from "react-toastify";
import { API_BASE_URL } from "../../../constants/configuration";
import Modal from "../../../commonComponents/Modal/Modal";
import ModalContent from "../../../commonComponents/ModalContent/ModalContent";
import useTranslate from "../../../utils/useTranslate";
import { addOnboardingSaveValidation } from "../../../utils/validation";
import RadioButtons from "../../../commonComponents/RadioButtons/RadioButtons";
import { requiredFields } from "../../../utils/validation_required";
import { getIntoError } from "../../../utils/helpers";
import DateTimeViewer from "../../../commonComponents/DateTimeViewer/DateTimeViewer";
import StatusBox from "../../../commonComponents/StatusBox/StatusBox";
import AttachmentsEyeView from "../../../commonComponents/AttachmentsEyeView/AttachmentsEyeView";
import TableCommentsViewer from "../../../commonComponents/TableCommentsViewer/TableCommentsViewer";
import Table from "../../../commonComponents/Table/Table";

function AddOnboarding() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { t } = useTranslate();

  const countryOptionsList = useMemo(() => countryList().getData(), []);

  const departmentList = useSelector(department_details);
  const sectionList = useSelector(section_details);
  const onboardDetails = useSelector(admin_onboarding_details);
  const employeeNo = useSelector(employeeNumber);
  const upload_loader = useSelector(fileUploadLoader);
  const loading = useSelector(admin_onboarding_details_loading);
  const history = useSelector(admin_onboarding_history);
  const designationList = useSelector(designations_list);

  const [data, setData] = useState({
    first_name: "",
    second_name: "",
    last_name: "",
    first_name_ar: "",
    second_name_ar: "",
    last_name_ar: "",
    email: "",
    phone: "",
    alternate_phone: "",
    emirates_id: "",
    passport_no: "",
    child_passport: "",
    child_emirates_id: "",
    child_name: "",
    spouse_passport_no: "",
    spouse_emirates_id: "",
    spouse_name: "",
    mother_passport_no: "",
    mother_emirates_id: "",
    father_emirates_id: "",
    father_passport_no: "",
    designation: "",
    secondary_designation: "",
    photo: [],
    emirates_id_proof: [],
    passport_proof: "",
    police_clearance_certificate: "",
    educational_certificate: "",
    medical_report: "",
    job_description_doc: "",
    cv: "",
    offer_letter: "",
    nda: "",
    executive_letter_certificate: "",
    financial_disclosure: "",
    family_book: "",
    previous_salary_certificate: "",
    experience_certificate: "",
    welcome_email: "",
    father_passport_proof: "",
    father_emirates_id_proof: "",
    mother_emirates_id_proof: "",
    mother_passport_proof: "",
    spouse_passport_proof: "",
    spouse_emirates_id_proof: "",
    have_children: "",
    oracle_updated: false,
    employee_joined: false,
    request_comment: "",
    birth_certificate: "",
    military_certificate: "",
    dob: "",
    joining_date: "",
    employee_no: "",
    grade: "",
    gender: "",
    attachments: [],
    divorce_certificate: "",
    marital_other: "",
  });
  const [employeeType, setEmployeeType] = useState("");
  const [nationality, setNationality] = useState("");
  const [department, setDepartment] = useState("");
  const [section, setSection] = useState("");
  const [maritalStatus, setMaritalStatus] = useState("");
  const [err, setErr] = useState({});
  const [childData, setChildData] = useState([
    {
      id: new Date().getTime(),
      child_name: "",
      child_gender: "",
      child_dob: "",
      child_emirates_id: "",
      child_emirates_id_proof: "",
      child_passport: "",
      child_passport_proof: "",
    },
  ]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [trackId, setTrackId] = useState("");
  const [status, setStatus] = useState("");
  const [deleteShow, setDeleteShow] = useState(false);
  const [delId, setDelId] = useState("");
  const [verifyEmployeeId, setVerifyEmployeeId] = useState(false);
  const [submitShow, setSubmitShow] = useState(false);
  const [historyData, setHistoryData] = useState([]);

  const employeeTypeOptions = [
    { label: "Military", value: "military" },
    { label: "Civilian", value: "civilian" },
  ];

  const maritalStatusOptions = [
    { label: "Single", value: "single" },
    { label: "Married", value: "married" },
    { label: "Divorced", value: "divorced" },
    { label: "Other", value: "other" },
  ];

  const statusOptions = [
    { label: "Submit", value: "submit" },
    { label: "Notify IT", value: "notify_it" },
    { label: "Delete", value: "delete" },
  ];

  useEffect(() => {
    if (!id) {
      dispatch(updateRedux({ key: "admin_onboarding_details", value: {} }));
      dispatch(updateRedux({ key: "admin_onboarding_history", value: [] }));
    }
    dispatch(getDepartment());
    dispatch(getDesignations());
  }, []);

  // useEffect(() => {
  //   if (employeeNo && !id) {
  //     let no = employeeNo?.slice(3);
  //     setData({
  //       ...data,
  //       employee_no: no,
  //     });
  //     setVerifyEmployeeId(true);
  //   }
  // }, [employeeNo]);

  useEffect(() => {
    if (id) {
      let formData = new FormData();
      formData.append("id", id);
      formData.append("list_type", "hr");
      dispatch(OnboardingRequestDetails(formData));
    }
  }, [id]);

  useEffect(() => {
    let uae = countryOptionsList?.filter((ele, i) => ele?.value == "AE");
    let other = countryOptionsList?.filter((ele, i) => ele?.value != "AE");
    let arr = [];
    arr = [uae[0], ...other];
    setCountryOptions([...arr]);
  }, [countryOptionsList]);

  useEffect(() => {
    if (onboardDetails && id) {
      let keys = Object.keys(onboardDetails).filter((item) =>
        [
          "photo",
          "birth_certificate",
          "military_certificate",
          "emirates_id_proof",
          "passport_proof",
          "police_clearance_certificate",
          "educational_certificate",
          "medical_report",
          "job_description_doc",
          "cv",
          "offer_letter",
          "nda",
          "executive_letter_certificate",
          "financial_disclosure",
          "experience_certificate",
          "previous_salary_certificate",
          "welcome_email",
          "family_book",
          "marital_other",
          "father_emirates_id_proof",
          "father_passport_proof",
          "mother_emirates_id_proof",
          "mother_passport_proof",
          "spouse_emirates_id_proof",
          "spouse_passport_proof",
          "divorce_certificate",
        ].includes(item)
      );
      let result = {
        ...data,
        ...onboardDetails,
        dob: onboardDetails?.dob ? new Date(onboardDetails?.dob) : "",
        joining_date: onboardDetails?.joining_date
          ? new Date(onboardDetails?.joining_date)
          : "",
        have_children: onboardDetails?.children?.length ? "yes" : "no",
        employee_no: onboardDetails?.employee_no?.slice(3),
        employee_joined: onboardDetails?.employee_joined,
        oracle_updated: onboardDetails?.oracle_updated,
      };
      result = {
        ...result,
        ...Object.fromEntries(
          keys.map((item) => {
            let obj = onboardDetails?.[item]?.split(".")?.pop()?.length
              ? [
                  {
                    type: onboardDetails?.[item].split(".").pop(),
                    url: `${API_BASE_URL}onboarding_files/${onboardDetails?.[item]}`,
                    name: `${onboardDetails?.[item]}`,
                    response: `${onboardDetails?.[item]}`,
                  },
                ]
              : "";
            return [item, obj];
          })
        ),
      };
      setData(result);
      setEmployeeType(
        onboardDetails.employee_type
          ? employeeTypeOptions?.filter(
              (ele) => ele.value == onboardDetails.employee_type
            )[0]
          : ""
      );
      setNationality(
        onboardDetails.nationality
          ? countryOptions?.filter(
              (ele) => ele.label == onboardDetails.nationality
            )[0]
          : ""
      );
      setMaritalStatus(
        onboardDetails.marital_status
          ? maritalStatusOptions?.filter(
              (ele) =>
                ele.value.toUpperCase() ===
                onboardDetails.marital_status.toUpperCase()
            )[0]
          : ""
      );
      if (onboardDetails?.department && departmentList?.length) {
        setDepartment(onboardDetails?.department);
        dispatch(
          getSection(onboardDetails?.department?._id, (value) => {
            if (onboardDetails?.section) {
              setSection(onboardDetails?.section);
            }
          })
        );
      }
      if (onboardDetails?.children?.length != 0) {
        let arr = onboardDetails?.children?.map((ele) => {
          let obj = { ...ele };
          obj = {
            id: new Date().getTime(),
            child_name: ele?.name,
            child_gender: ele?.gender,
            child_dob: ele?.dob ? new Date(ele?.dob) : "",
            child_emirates_id: ele?.emirates_id,
            child_emirates_id_proof: ele?.emirates_id_proof
              ? [
                  {
                    type: ele?.emirates_id_proof.split(".").pop(),
                    url: `${API_BASE_URL}onboarding_files/${ele?.emirates_id_proof}`,
                    name: `${ele?.emirates_id_proof}`,
                    response: `${ele?.emirates_id_proof}`,
                  },
                ]
              : "",
            child_passport: ele?.passport_no,
            child_passport_proof: ele?.passport_proof
              ? [
                  {
                    type: ele?.passport_proof.split(".").pop(),
                    url: `${API_BASE_URL}onboarding_files/${ele?.passport_proof}`,
                    name: `${ele?.passport_proof}`,
                    response: `${ele?.passport_proof}`,
                  },
                ]
              : "",
          };
          return obj;
        });
        if (arr?.length) {
          setChildData([...arr]);
        }
      }
      // setTrackId(onboardDetails?.track_id);
      if (onboardDetails?.attachments?.length) {
        let arr = onboardDetails?.attachments?.map((ele, i) => {
          let obj = {
            type: ele?.split(".").pop(),
            url: `${API_BASE_URL}onboarding_files/${ele}`,
            name: `${ele}`,
            response: `${ele}`,
          };
          return obj;
        });
        setData({
          ...result,
          attachments: [...arr],
        });
      }
    }
  }, [onboardDetails]);

  useEffect(() => {
    setHistoryData([]);
    if (history) {
      history?.forEach((item, i) => {
        setHistoryData((s) => [
          ...s,
          {
            sl_no: history?.length - i,
            received_date: <DateTimeViewer dateTime={item?.received_date} />,
            last_update: <DateTimeViewer dateTime={item?.last_update} />,
            handled_by: item?.handled_by?.name ?? "-",
            status: (
              <StatusBox
                status={
                  item?.status?.handle_key == "return"
                    ? "Need Info"
                    : item?.status?.request_status
                }
              />
            ),
            assigned_to: item?.assigned_to || "-",
            attachments: (
              <AttachmentsEyeView
                files={{
                  path: API_BASE_URL + "onboarding_files/",
                  endpoints: item?.attachments,
                }}
              />
            ),
            comments: <TableCommentsViewer comment={item?.comment} />,
          },
        ]);
      });
    }
  }, [history]);

  const onDepartmentChangeHandler = (value) => {
    setDepartment(value);
    setErr({
      ...err,
      department: "",
    });
    dispatch(updateRedux({ key: "section_details", value: [] }));
    value._id && dispatch(getSection(value._id));
  };

  const onChangeHandler = (key, value) => {
    setData({
      ...data,
      [key]: value,
    });
    setErr({
      ...err,
      [key]: "",
    });
  };

  const singleImageSelectedHandler = (field, e, isInvalid, message) => {
    if (!isInvalid) {
      uploadFileHandler(field, e, (res) => {
        setData({
          ...data,
          [field]: [
            {
              type: "image",
              url: e[0].url,
              name: e[0]?.name,
              metaFile: e,
              response: res?.data[0],
            },
          ],
        });
        setErr({
          ...err,
          [field]: "",
        });
      });
    } else {
      if (message?.length) {
        setErr({
          ...err,
          [field]: message ?? "Invalid file format",
        });
      }
    }
  };

  const documentUploadHandler = (field, e, isInvalid) => {
    if (isInvalid) {
      setErr({
        ...err,
        [field]: "Invalid file format",
      });
    } else {
      setErr({
        ...err,
        [field]: "",
      });
      uploadFileHandler(field, e, (res) => {
        setData({
          ...data,
          [field]: [
            {
              type: "pdf",
              url: e[0].url,
              name: e[0]?.name,
              metaFile: e,
              response: res?.data[0],
            },
          ],
        });
        setErr({
          ...err,
          [field]: "",
        });
      });
    }
  };

  const uploadFileHandler = (field, e, handler) => {
    let formData = new FormData();
    if (trackId?.length == 0) {
      let track_id = Math.floor(1000 + Math.random() * 9000).toString();
      setTrackId(track_id);
      formData.append("track_id", track_id);
    } else {
      formData.append("track_id", trackId);
    }
    formData.append("file", e[0]?.metaFile);
    dispatch(
      uploadFile(formData, (res) => {
        // toast.success(`${field} uploaded successfully`, {
        //   position: "bottom-center",
        //   autoClose: 3000,
        // });
        handler(res);
      })
    );
  };

  const onAddChildHandler = () => {
    setChildData((s) => [
      ...s,
      {
        id: new Date().getTime(),
        child_name: "",
        child_gender: "",
        child_dob: "",
        child_emirates_id: "",
        child_emirates_id_proof: "",
        child_passport: "",
        child_passport_proof: "",
      },
    ]);
  };

  const onDeleteChildHandler = (index) => {
    let arr = childData?.filter((e, i) => i != index);
    setChildData([...arr]);
  };

  const onChildDataChangeHandler = (key, value, i, unId) => {
    let obj = { ...childData[i] };
    let arr = [...childData];
    obj = {
      ...obj,
      [key]: value,
    };
    arr[i] = obj;
    setChildData([...arr]);
    if (unId) {
      setErr({
        ...err,
        [key + unId]: "",
      });
    }
  };

  const childDataDocumentChangeHandler = (
    field,
    e,
    i,
    isInvalid,
    message,
    unId
  ) => {
    if (isInvalid) {
      setErr({
        ...err,
        [field]: message ?? "Invalid file format",
      });
    } else {
      let formData = new FormData();
      if (trackId?.length == 0) {
        let track_id = Math.floor(1000 + Math.random() * 9000).toString();
        setTrackId(track_id);
        formData.append("track_id", track_id);
      } else {
        formData.append("track_id", trackId);
      }
      formData.append("file", e[0]?.metaFile);
      dispatch(
        uploadFile(formData, (res) => {
          // toast.success(`${field} uploaded successfully`, {
          //   position: "bottom-center",
          //   autoClose: 3000,
          // });
          let obj = { ...childData[i] };
          let arr = [...childData];
          obj = {
            ...obj,
            [field]: [
              {
                type: "pdf",
                url: e[0].url,
                name: e[0]?.name,
                metaFile: e,
                response: res?.data[0],
              },
            ],
          };
          arr[i] = obj;
          setChildData([...arr]);
          // if (unId) {
          //   setErr({
          //     ...err,
          //     [unId]: {
          //       ...err[unId],
          //       [field]: "",
          //     },
          //   });
          // }
          if (unId) {
            setErr({
              ...err,
              [field + unId]: "",
            });
          }
        })
      );
    }
  };

  const fileSelectedHandler = (e, isInvalid, message) => {
    if (isInvalid) {
      setErr({
        ...err,
        attachments: message ?? "file format not supported",
      });
    } else {
      if (e?.some((elem, i) => elem?.metaFile)) {
        let formData = new FormData();
        if (trackId?.length == 0) {
          let track_id = Math.floor(1000 + Math.random() * 9000).toString();
          setTrackId(track_id);
          formData.append("track_id", track_id);
        } else {
          formData.append("track_id", trackId);
        }
        e?.filter((el) => el?.metaFile != undefined)?.forEach((item, i) =>
          formData.append(`file`, item?.metaFile)
        );
        dispatch(
          uploadFile(formData, (res) => {
            // toast.success(`attachments uploaded successfully`, {
            //   position: "bottom-center",
            //   autoClose: 3000,
            // });
            let oldFiles = e?.filter((el) => el?.metaFile == undefined);
            let arr = e
              ?.filter((el) => el?.metaFile != undefined)
              ?.map((ele, i) => {
                return {
                  ...ele,
                  response: res?.data[i],
                };
              });
            setErr({
              ...err,
              attachments: "",
            });
            setData({
              ...data,
              attachments: [...oldFiles, ...arr],
            });
          })
        );
      } else {
        setData({
          ...data,
          attachments: e?.length ? e : [],
        });
      }
    }
  };

  const onStatusChangeHandler = (value) => {
    setStatus(value);
    if (value?.value == "delete") {
      deleteRequest(id);
    } else if (value?.value == "notify_it") {
      onDataSaveHandler("notify_it", value);
    } else if (value?.value == "submit") {
      onSubmitHandler("submit");
    }
  };

  const onSubmitHandler = async (button) => {
    let isValidated = await addOnboardingSaveValidation(
      {
        ...data,
        employeeType,
        nationality,
        department,
        section,
        maritalStatus,
        childData,
        button: button,
      },
      setErr
    );
    if (isValidated) {
      if (button == "submit") {
        dispatch(
          getEmployeeNo((res) => {
            let no = res?.data?.slice(3);
            setData({
              ...data,
              employee_no: no,
              current_no: res?.current_no,
            });
            setVerifyEmployeeId(true);
            setSubmitShow(true);
          })
        );
      } else {
        onDataSaveHandler(button);
      }
    } else {
      setStatus("");
    }
  };

  const onDataSaveHandler = (button, value) => {
    let formData = new FormData();
    formData.append("employee_type", employeeType?.value ?? "");
    formData.append("first_name", data?.first_name ?? "");
    formData.append("first_name_ar", data?.first_name_ar ?? "");
    formData.append("second_name", data?.second_name ?? "");
    formData.append("second_name_ar", data?.second_name_ar ?? "");
    formData.append("last_name", data?.last_name ?? "");
    formData.append("last_name_ar", data?.last_name_ar ?? "");
    formData.append("email", data?.email ?? "");
    formData.append("phone", data?.phone ?? "");
    formData.append("alternate_phone", data?.alternate_phone ?? "");
    formData.append("gender", data?.gender ?? "");
    formData.append("nationality", nationality?.label ?? "");
    formData.append("photo", data?.photo[0]?.response ?? "");
    formData.append(
      "dob",
      data?.dob ? moment(data?.dob)?.format("YYYY-MM-DD")?.toLocaleString() : ""
    );
    formData.append(
      "birth_certificate",
      data?.birth_certificate[0]?.response ?? ""
    );
    formData.append(
      "military_certificate",
      data?.military_certificate[0]?.response ?? ""
    );
    formData.append("emirates_id", data?.emirates_id ?? "");
    formData.append(
      "emirates_id_proof",
      data?.emirates_id_proof[0]?.response ?? ""
    );
    formData.append("passport_no", data?.passport_no ?? "");
    formData.append("passport_proof", data?.passport_proof[0]?.response ?? "");
    formData.append(
      "police_clearance_certificate",
      data?.police_clearance_certificate[0]?.response ?? ""
    );
    formData.append(
      "educational_certificate",
      data?.educational_certificate[0]?.response ?? ""
    );
    formData.append("medical_report", data?.medical_report[0]?.response ?? "");
    formData.append("designation", data?.designation?._id ?? "");
    formData.append(
      "secondary_designation",
      data?.secondary_designation?._id ?? ""
    );
    formData.append(
      "joining_date",
      data?.joining_date
        ? moment(data?.joining_date)?.format("YYYY-MM-DD")?.toLocaleString()
        : ""
    );
    formData.append("department", department?._id ?? "");
    formData.append("section", section?._id ?? "");
    formData.append(
      "employee_no",
      data?.employee_no ? "ESC" + data?.employee_no : ""
    );
    // formData.append("employee_no", "ESC005");
    formData.append("grade", data?.grade ?? "");
    formData.append(
      "job_description_doc",
      data?.job_description_doc[0]?.response ?? ""
    );
    formData.append("cv", data?.cv[0]?.response ?? "");
    formData.append("offer_letter", data?.offer_letter[0]?.response ?? "");
    formData.append("nda", data?.nda[0]?.response ?? "");
    formData.append(
      "executive_letter_certificate",
      data?.executive_letter_certificate[0]?.response ?? ""
    );
    formData.append(
      "financial_disclosure",
      data?.financial_disclosure[0]?.response ?? ""
    );
    formData.append(
      "experience_certificate",
      data?.experience_certificate[0]?.response ?? ""
    );
    formData.append(
      "previous_salary_certificate",
      data?.previous_salary_certificate[0]?.response ?? ""
    );
    formData.append("welcome_email", data?.welcome_email[0]?.response ?? "");
    formData.append("family_book", data?.family_book[0]?.response ?? "");
    formData.append("marital_status", maritalStatus?.value ?? "");
    if (maritalStatus?.value == "other") {
      formData.append("marital_other", data?.marital_other[0]?.response ?? "");
      formData.append("divorce_certificate", "");
    }
    if (maritalStatus?.value == "divorced") {
      formData.append(
        "divorce_certificate",
        data?.divorce_certificate[0]?.response ?? ""
      );
      formData.append("marital_other", "");
    }
    formData.append("father_emirates_id", data?.father_emirates_id ?? "");
    formData.append(
      "father_emirates_id_proof",
      data?.father_emirates_id_proof[0]?.response ?? ""
    );
    formData.append("father_passport_no", data?.father_passport_no ?? "");
    formData.append(
      "father_passport_proof",
      data?.father_passport_proof[0]?.response ?? ""
    );
    formData.append("mother_emirates_id", data?.mother_emirates_id ?? "");
    formData.append(
      "mother_emirates_id_proof",
      data?.mother_emirates_id_proof[0]?.response ?? ""
    );
    formData.append("mother_passport_no", data?.mother_passport_no ?? "");
    formData.append(
      "mother_passport_proof",
      data?.mother_passport_proof[0]?.response ?? ""
    );
    if (maritalStatus?.value == "married") {
      formData.append("spouse_name", data?.spouse_name ?? "");
      formData.append("spouse_emirates_id", data?.spouse_emirates_id ?? "");
      formData.append(
        "spouse_emirates_id_proof",
        data?.spouse_emirates_id_proof[0]?.response ?? ""
      );
      formData.append("spouse_passport_no", data?.spouse_passport_no ?? "");
      formData.append(
        "spouse_passport_proof",
        data?.spouse_passport_proof[0]?.response ?? ""
      );
    } else {
      formData.append("spouse_name", "");
      formData.append("spouse_emirates_id", "");
      formData.append("spouse_emirates_id_proof", "");
      formData.append("spouse_passport_no", "");
      formData.append("spouse_passport_proof", "");
    }
    formData.append("have_children", data.have_children == "yes" ? 1 : 0);
    if (data.have_children == "yes" && childData?.length) {
      childData?.forEach((item, i) => {
        formData.append(`children[${i}].[name]`, item.child_name ?? "");
        formData.append(`children[${i}].[gender]`, item.child_gender ?? "");
        formData.append(
          `children[${i}].[dob]`,
          item.child_dob
            ? moment(item.child_dob)?.format("YYYY-MM-DD")?.toLocaleString()
            : ""
        );
        formData.append(
          `children[${i}].[emirates_id]`,
          item.child_emirates_id ?? ""
        );
        formData.append(
          `children[${i}].[emirates_id_proof]`,
          item?.child_emirates_id_proof[0]?.response ?? ""
        );
        formData.append(
          `children[${i}].[passport_no]`,
          item.child_passport ?? ""
        );
        formData.append(
          `children[${i}].[passport_proof]`,
          item?.child_passport_proof[0]?.response ?? ""
        );
      });
    } else {
      formData.append(`children`, []);
    }
    formData.append("employee_joined", data?.employee_joined ?? "");
    formData.append("oracle_updated", data?.oracle_updated ?? "");
    formData.append("request_comment", data?.request_comment?.trim() ?? "");

    data?.attachments?.forEach((item, i) =>
      formData.append(`attachments`, item?.response)
    );
    formData.append("track_id", trackId);
    formData.append("list_type", "hr");
    // formData.append("submit_type", "submit");
    if (button == "submit" || button == "notify_it") {
      formData.append(
        "submit_type",
        value != undefined ? value?.value : status != "" ? status?.value : ""
      );
    } else {
      formData.append("submit_type", "save");
    }
    console.log(formData);
    if (id) {
      formData.append("id", id);
      formData.append("user", "hr");
      dispatch(
        editOnboardingRequest(id, formData, (res) => {
          if (res.data.status == true) {
            successToast(res.data.message);
            navigate("/admin/human_resource/onboarding_process");
          } else {
            errorToast(res.data.message);
          }
        })
      );
    } else {
      dispatch(
        addOnboardingRequest(formData, (res) => {
          if (res.data.status == true) {
            successToast(res.data.message);
            navigate("/admin/human_resource/onboarding_process");
          } else {
            errorToast(res.data.message);
          }
        })
      );
    }
  };

  const activeActionsList = () => {
    let list = statusOptions;
    if (!id) {
      list = list?.filter(
        (item) => item.value !== "notify_it" && item.value !== "delete"
      );
    }
    return list;
  };

  const deleteRequest = (id) => {
    setDelId(id);
    // setSubmitShow(true);
    setDeleteShow(true);
  };

  const onDeleteHandler = () => {
    dispatch(
      deleteOnboard(delId, (res) => {
        if (res.data.status == true) {
          successToast(res.data.message);
          setDeleteShow(false);
          setStatus("");
          navigate("/admin/human_resource/onboarding_process");
        } else {
          errorToast(res.data.message);
        }
      })
    );
  };

  const employeeNumberChangeHandler = (key, value) => {
    setData({
      ...data,
      [key]: value,
    });
    setErr({
      ...err,
      [key]: "",
    });
    if (value?.length > 2) {
      setVerifyEmployeeId(false);
      let formData = new FormData();
      formData.append("number", "ESC" + value);
      dispatch(
        checkEmployeeNo(formData, (res) => {
          if (res) {
            setVerifyEmployeeId(false);
            setErr({
              ...err,
              employee_no: t(
                "Employee ID is already used. Enter another employee ID"
              ),
            });
          } else {
            setErr({
              ...err,
              employee_no: "",
            });
            setVerifyEmployeeId(true);
          }
        })
      );
    }
  };

  const onCancel = () => {
    setErr({});
    setData({});
    setEmployeeType("");
    setNationality("");
    setDepartment("");
    setSection("");
    setMaritalStatus("");
    if (id) {
      let formData = new FormData();
      formData.append("id", id);
      formData.append("list_type", "hr");
      dispatch(OnboardingRequestDetails(formData));
    }
  };

  const required = requiredFields({ employeeType, ...data });

  return (
    // console.log(err),
    <div className="add__onboarding">
      {/* delete modal */}
      <Modal setShow={setDeleteShow} show={deleteShow}>
        <ModalContent header="Delete Onboard">
          <h3>{t("?Are you sure you want to delete")}</h3>
          <Button
            className="css-btn-esc3"
            onClick={() => onDeleteHandler()}
            content={"Confirm"}
          />
          <Button
            className="css-btn-esc3"
            onClick={() => setDeleteShow(false)}
            content="Cancel"
          />
        </ModalContent>
      </Modal>
      {/* submit modal */}
      <Modal setShow={setSubmitShow} show={submitShow}>
        <ModalContent header="Submit Onboard Request">
          <h3>{t("Please Confirm Employee Number")}</h3>
          <Input
            className="css-inp-esc1"
            // label="Employee No"
            placeholder="Type here"
            value={data.employee_no}
            field={"employee_no"}
            onChange={(value, key) => employeeNumberChangeHandler(key, value)}
            error={err.employee_no}
            fixed="ESC-"
          />
          {data?.current_no ? (
            <p>{`Lastly submitted employee number is ${data?.current_no} `}</p>
          ) : (
            <></>
          )}
          <Button
            className="css-btn-esc3"
            onClick={() =>
              onDataSaveHandler("submit", {
                label: "Submit",
                value: "submit",
              })
            }
            content={"Confirm"}
            disabled={!verifyEmployeeId}
          />
          <Button
            className="css-btn-esc3"
            onClick={() => {
              setSubmitShow(false);
              setStatus("");
            }}
            content="Cancel"
          />
        </ModalContent>
      </Modal>
      <section className="heading mb-4">
        <PageHeader>
          <Title
            content={id ? "Edit Employee Details" : "Add Employee Details"}
          />
          <Breadcrump
            content={[
              {
                name: id ? "Edit Request" : "Add Request",
                path: `/admin/human_resource/onboarding_process/edit_onboarding/${id}/edit`,
              },
              {
                name: "Human Resource",
                path: "/admin/human_resource/onboarding_process",
              },
              { name: "Home", path: "/" },
            ]}
          />
        </PageHeader>
      </section>
      <TitleBar title={"Personal Information"} toggleButton defaultToggle={'show'}>
        <div className="add__onboarding_forms">
          <div className="row">
            <div className="col-md-4">
              <Select
                className={"css-slc-esc1"}
                title="Employee Type"
                placeholder={"Select any option"}
                options={employeeTypeOptions}
                labelSetter={(option) => option.label}
                valueSetter={(option) => option.value}
                value={employeeType}
                onChange={(e) => {
                  setEmployeeType(e);
                  setErr({
                    ...err,
                    employeeType: "",
                  });
                }}
                error={err.employeeType}
                required={required?.employeeType}
              />
            </div>
            <div className="col-md-4">
              <Input
                className="css-inp-esc1"
                label="First Name"
                placeholder="Type here"
                value={data.first_name}
                field={"first_name"}
                onChange={(value, key) => onChangeHandler(key, value)}
                error={err.first_name}
                required={required?.first_name}
              />
            </div>
            <div className="col-md-4">
              <Input
                className="css-inp-esc1"
                label="First Name Arabic"
                placeholder="Type here"
                value={data.first_name_ar}
                field={"first_name_ar"}
                onChange={(value, key) => onChangeHandler(key, value)}
                error={err.first_name_ar}
                required={required?.first_name_ar}
              />
            </div>
            <div className="col-md-4">
              <Input
                className="css-inp-esc1"
                label="Second Name"
                placeholder="Type here"
                value={data.second_name}
                field={"second_name"}
                onChange={(value, key) => onChangeHandler(key, value)}
                error={err.second_name}
                required={required?.second_name}
              />
            </div>
            <div className="col-md-4">
              <Input
                className="css-inp-esc1"
                label="Second Name Arabic"
                placeholder="Type here"
                value={data.second_name_ar}
                field={"second_name_ar"}
                onChange={(value, key) => onChangeHandler(key, value)}
                error={err.second_name_ar}
                required={required?.second_name_ar}
              />
            </div>
            <div className="col-md-4">
              <Input
                className="css-inp-esc1"
                label="Last Name"
                placeholder="Type here"
                value={data.last_name}
                field={"last_name"}
                onChange={(value, key) => onChangeHandler(key, value)}
                error={err.last_name}
                required={required?.last_name}
              />
            </div>
            <div className="col-md-4">
              <Input
                className="css-inp-esc1"
                label="Last Name Arabic"
                placeholder="Type here"
                value={data.last_name_ar}
                field={"last_name_ar"}
                onChange={(value, key) => onChangeHandler(key, value)}
                error={err.last_name_ar}
                required={required?.last_name_ar}
              />
            </div>
            <div className="col-md-4">
              <Input
                className="css-inp-esc1"
                label="Personal Email"
                placeholder="Type here"
                value={data.email}
                field={"email"}
                onChange={(value, key) => onChangeHandler(key, value)}
                error={err.email}
                required={required?.email}
              />
            </div>
            <div className="col-md-4">
              <Input
                className="css-inp-esc1"
                label="Phone No"
                placeholder="Type here"
                value={data.phone}
                field={"phone"}
                onChange={(value, key) => onChangeHandler(key, value)}
                error={err.phone}
                required={required?.phone}
              />
            </div>
            <div className="col-md-4">
              <Input
                className="css-inp-esc1"
                label="Alternative Phone No"
                placeholder="Type here"
                value={data.alternate_phone}
                field={"alternate_phone"}
                onChange={(value, key) => onChangeHandler(key, value)}
                error={err.alternate_phone}
                required={required?.alternate_phone}
              />
            </div>
            <div className="col-md-4">
              <FileUpload
                className="css-atc-esc2"
                label={"Upload Photo"}
                buttonLabel="Choose file"
                accept={["jpeg", "jpg", "png"]}
                onChange={(e, isInvalid, message) =>
                  singleImageSelectedHandler("photo", e, isInvalid, message)
                }
                maxFileSizeMB={10}
                error={err.photo}
                id="photo"
                files={data?.photo}
                required={required?.photo}
              />
            </div>
            <div className="col-md-4">
              <RadioButtons
                label="Select Gender"
                buttons={[
                  { label: "Male", value: "Male" },
                  { label: "Female", value: "Female" },
                ]}
                name="gender"
                value={{ gender: data.gender }}
                error={err.gender}
                onChange={(key, value) => onChangeHandler(key, value)}
                required={required?.gender}
              />
            </div>
            <div className="col-md-4">
              <Select
                className={"css-slc-esc1"}
                title="Nationality"
                placeholder={"Select"}
                options={countryOptions}
                labelSetter={(option) => option.label}
                valueSetter={(option) => option.value}
                value={nationality}
                onChange={(e) => {
                  setNationality(e);
                  setErr({
                    ...err,
                    nationality: "",
                  });
                }}
                error={err.nationality}
                required={required?.nationality}
              />
            </div>
            <div className="col-md-4">
              <DatePick
                className="css-dtp-esc2"
                title={"Date of Birth"}
                placeholder="DD/MM/YYYY"
                selected={data?.dob}
                onChange={(date) => onChangeHandler("dob", date)}
                // filterTime={filterPassedTime}
                dateFormat="dd/MM/yyyy"
                maxDate={new Date()}
                error={err.dob}
                required={required?.dob}
              />
            </div>
            <div className="col-md-4">
              <FileUpload
                className="css-atc-esc2"
                label={"Upload Birth Certificate"}
                buttonLabel="Choose file"
                accept={["pdf"]}
                onChange={(e, isInvalid) =>
                  documentUploadHandler("birth_certificate", e, isInvalid)
                }
                error={err.birth_certificate}
                id="birth_certificate"
                files={data?.birth_certificate}
                required={required?.birth_certificate}
              />
            </div>
            <div className="col-md-4 offset-md-8">
              <FileUpload
                className="css-atc-esc2"
                label={"Upload Military Certificate"}
                buttonLabel="Choose file"
                accept={["pdf"]}
                onChange={(e, isInvalid) =>
                  documentUploadHandler("military_certificate", e, isInvalid)
                }
                error={err.military_certificate}
                id="military_certificate"
                files={data?.military_certificate}
                required={required?.military_certificate}
              />
            </div>
            <div className="col-md-4">
              <Input
                className="css-inp-esc1"
                label="Emirates ID No"
                placeholder="Type here"
                value={data.emirates_id}
                field={"emirates_id"}
                onChange={(value, key) => onChangeHandler(key, value)}
                error={err.emirates_id}
                required={required?.emirates_id}
              />
            </div>
            <div className="col-md-4 offset-md-3">
              <FileUpload
                className="css-atc-esc2"
                label={"Upload Emirates ID Proof"}
                buttonLabel="Choose file"
                accept={["pdf"]}
                onChange={(e, isInvalid) =>
                  documentUploadHandler("emirates_id_proof", e, isInvalid)
                }
                error={err.emirates_id_proof}
                id="emirates_id_proof"
                files={data?.emirates_id_proof}
                required={required?.emirates_id_proof}
              />
            </div>
            <div className="col-md-4">
              <Input
                className="css-inp-esc1"
                label="Enter Passport No"
                placeholder="Type here"
                value={data.passport_no}
                field={"passport_no"}
                onChange={(value, key) => onChangeHandler(key, value)}
                error={err.passport_no}
                required={required?.passport_no}
              />
            </div>
            <div className="col-md-4 offset-md-3">
              <FileUpload
                className="css-atc-esc2"
                label={"Upload Passport Proof"}
                buttonLabel="Choose file"
                accept={["pdf"]}
                onChange={(e, isInvalid) =>
                  documentUploadHandler("passport_proof", e, isInvalid)
                }
                error={err.passport_proof}
                id="passport_proof"
                files={data?.passport_proof}
                required={required?.passport_proof}
              />
            </div>
            <div className="col-md-4">
              <FileUpload
                className="css-atc-esc2"
                label={"Upload Police Clearance Certificate"}
                buttonLabel="Choose file"
                accept={["pdf"]}
                onChange={(e, isInvalid) =>
                  documentUploadHandler(
                    "police_clearance_certificate",
                    e,
                    isInvalid
                  )
                }
                error={err.police_clearance_certificate}
                id="police_clearance_certificate"
                files={data?.police_clearance_certificate}
                required={required?.police_clearance_certificate}
              />
            </div>
            <div className="col-md-4">
              <FileUpload
                className="css-atc-esc2"
                label={"Upload Educational Certificates"}
                buttonLabel="Choose file"
                accept={["pdf"]}
                onChange={(e, isInvalid) =>
                  documentUploadHandler("educational_certificate", e, isInvalid)
                }
                error={err.educational_certificate}
                id="educational_certificate"
                files={data?.educational_certificate}
                required={required?.educational_certificate}
              />
            </div>
            <div className="col-md-4">
              <FileUpload
                className="css-atc-esc2"
                label={"Upload Medical Report"}
                buttonLabel="Choose file"
                accept={["pdf"]}
                onChange={(e, isInvalid) =>
                  documentUploadHandler("medical_report", e, isInvalid)
                }
                error={err.medical_report}
                id="medical_report"
                files={data?.medical_report}
                required={required?.medical_report}
              />
            </div>
          </div>
        </div>
      </TitleBar>
      <TitleBar
        toggleButton
        defaultToggle="show"
        className="mt-3"
        title={"Job Details"}
      >
        <div className="add__onboarding_forms">
          <div className="row">
            {/* <div className="col-md-4">
              <Input
                className="css-inp-esc1"
                label="Designation"
                placeholder="Type here"
                value={data.designation}
                field={"designation"}
                onChange={(value, key) => onChangeHandler(key, value)}
                error={err.designation}
                required={required?.designation}
              />
            </div>
            <div className="col-md-4">
              <Input
                className="css-inp-esc1"
                label="Secondary Designation"
                placeholder="Type here"
                value={data.secondary_designation}
                field={"secondary_designation"}
                onChange={(value, key) => onChangeHandler(key, value)}
                error={err.secondary_designation}
                required={required?.secondary_designation}
              />
            </div> */}
            <div className="col-md-4">
              <Select
                className={"css-slc-esc1"}
                title="Designation"
                placeholder={"Select"}
                options={designationList}
                labelSetter={(option) => option.designation_name}
                valueSetter={(option) => option._id}
                value={data.designation}
                onChange={(e) => onChangeHandler("designation", e)}
                error={err.designation}
                required={required?.designation}
              />
            </div>
            <div className="col-md-4">
              <Select
                className={"css-slc-esc1"}
                title="Secondary Designation"
                placeholder={"Select"}
                options={designationList}
                labelSetter={(option) => option.designation_name}
                valueSetter={(option) => option._id}
                value={data.secondary_designation}
                onChange={(e) => onChangeHandler("secondary_designation", e)}
                error={err.secondary_designation}
                required={required?.secondary_designation}
              />
            </div>
            <div className="col-md-4">
              <DatePick
                className="css-dtp-esc2"
                title={"Date of Joining"}
                placeholder="DD/MM/YYYY"
                onChange={(date) => onChangeHandler("joining_date", date)}
                // filterTime={filterPassedTime}
                dateFormat="dd/MM/yyyy"
                // maxDate={new Date()}
                error={err.joining_date}
                selected={data?.joining_date}
                required={required?.joining_date}
              />
            </div>
            <div className="col-md-4">
              <Select
                className={"css-slc-esc1"}
                title="Department"
                placeholder={"Select"}
                options={departmentList}
                labelSetter={(option) => option.department_name}
                valueSetter={(option) => option._id}
                value={department}
                onChange={(e) => onDepartmentChangeHandler(e)}
                error={err.department}
                required={required?.department}
              />
            </div>
            <div className="col-md-4">
              <Select
                className={"css-slc-esc1"}
                title="Section"
                placeholder={"Select"}
                options={sectionList}
                labelSetter={(option) => option.section_name}
                valueSetter={(option) => option._id}
                value={section}
                onChange={(e) => {
                  setSection(e);
                  setErr({
                    ...err,
                    section: "",
                  });
                }}
                error={err.section}
                required={required?.section}
              />
            </div>
            {/* <div className="col-md-4">
                <Input
                  className="css-inp-esc1"
                  label="Employee No"
                  placeholder="Type here"
                  value={data.employee_no}
                  field={"employee_no"}
                  onChange={(value, key) =>
                    employeeNumberChangeHandler(key, value)
                  }
                  error={err.employee_no}
                  fixed="ESC"
                />
              </div> */}
            <div className="col-md-4">
              <Input
                className="css-inp-esc1"
                label="Grade"
                placeholder="Type here"
                value={data.grade}
                field={"grade"}
                onChange={(value, key) => onChangeHandler(key, value)}
                error={err.grade}
                required={required?.grade}
              />
            </div>
            <div className="col-md-4">
              <FileUpload
                className="css-atc-esc2"
                label={"Upload Job Description"}
                buttonLabel="Choose file"
                accept={["pdf"]}
                onChange={(e, isInvalid) =>
                  documentUploadHandler("job_description_doc", e, isInvalid)
                }
                error={err.job_description_doc}
                id="job_description_doc"
                files={data?.job_description_doc}
                required={required?.job_description_doc}
              />
            </div>
            <div className="col-md-4">
              <FileUpload
                className="css-atc-esc2"
                label={"Upload Updated CV"}
                buttonLabel="Choose file"
                accept={["pdf"]}
                onChange={(e, isInvalid) =>
                  documentUploadHandler("cv", e, isInvalid)
                }
                error={err.cv}
                id="cv"
                files={data?.cv}
                required={required?.cv}
              />
            </div>
            <div className="col-md-4">
              <FileUpload
                className="css-atc-esc2"
                label={"Upload Offer Letter"}
                buttonLabel="Choose file"
                accept={["pdf"]}
                onChange={(e, isInvalid) =>
                  documentUploadHandler("offer_letter", e, isInvalid)
                }
                error={err.offer_letter}
                id="offer_letter"
                files={data?.offer_letter}
                required={required?.offer_letter}
              />
            </div>
            <div className="col-md-4">
              <FileUpload
                className="css-atc-esc2"
                label={"Upload NDA"}
                buttonLabel="Choose file"
                accept={["pdf"]}
                onChange={(e, isInvalid) =>
                  documentUploadHandler("nda", e, isInvalid)
                }
                error={err.nda}
                id="nda"
                files={data?.nda}
                required={required?.nda}
              />
            </div>
            <div className="col-md-4">
              <FileUpload
                className="css-atc-esc2"
                label={"Upload Letter from Executive Director"}
                buttonLabel="Choose file"
                accept={["pdf"]}
                onChange={(e, isInvalid) =>
                  documentUploadHandler(
                    "executive_letter_certificate",
                    e,
                    isInvalid
                  )
                }
                error={err.executive_letter_certificate}
                id="executive_letter_certificate"
                files={data?.executive_letter_certificate}
                required={required?.executive_letter_certificate}
              />
            </div>
            <div className="col-md-4">
              <FileUpload
                className="css-atc-esc2"
                label={"Upload Financial Disclousure"}
                buttonLabel="Choose file"
                accept={["pdf"]}
                onChange={(e, isInvalid) =>
                  documentUploadHandler("financial_disclosure", e, isInvalid)
                }
                error={err.financial_disclosure}
                id="financial_disclosure"
                files={data?.financial_disclosure}
                required={required?.financial_disclosure}
              />
            </div>
            <div className="col-md-4">
              <FileUpload
                className="css-atc-esc2"
                label={"Latest Experience Certifcate"}
                buttonLabel="Choose file"
                accept={["pdf"]}
                onChange={(e, isInvalid) =>
                  documentUploadHandler("experience_certificate", e, isInvalid)
                }
                error={err.experience_certificate}
                id="experience_certificate"
                files={data?.experience_certificate}
                required={required?.experience_certificate}
              />
            </div>
            <div className="col-md-4">
              <FileUpload
                className="css-atc-esc2"
                label={"Previous Salary Certificate"}
                buttonLabel="Choose file"
                accept={["pdf"]}
                onChange={(e, isInvalid) =>
                  documentUploadHandler(
                    "previous_salary_certificate",
                    e,
                    isInvalid
                  )
                }
                error={err.previous_salary_certificate}
                id="previous_salary_certificate"
                files={data?.previous_salary_certificate}
                required={required?.previous_salary_certificate}
              />
            </div>
            <div className="col-md-4">
              <FileUpload
                className="css-atc-esc2"
                label={"Upload Scanned Welcome email"}
                buttonLabel="Choose file"
                accept={["pdf"]}
                onChange={(e, isInvalid) =>
                  documentUploadHandler("welcome_email", e, isInvalid)
                }
                error={err.welcome_email}
                id="welcome_email"
                files={data?.welcome_email}
                required={required?.welcome_email}
              />
            </div>
          </div>
        </div>
      </TitleBar>
      <TitleBar
        toggleButton
        defaultToggle={"show"}
        t
        className="mt-3"
        title={"Family Details"}
      >
        <div className="add__onboarding_forms">
          <div className="row">
            <div className="col-md-4">
              <FileUpload
                className="css-atc-esc2"
                label={"Upload Family Book"}
                buttonLabel="Choose file"
                accept={["pdf"]}
                onChange={(e, isInvalid) =>
                  documentUploadHandler("family_book", e, isInvalid)
                }
                error={err.family_book}
                id="family_book"
                files={data?.family_book}
                required={required?.family_book}
              />
            </div>
            <div className="col-md-4">
              <Select
                className={"css-slc-esc1"}
                title="Marital Status"
                placeholder={"Select"}
                options={maritalStatusOptions}
                labelSetter={(option) => option.label}
                valueSetter={(option) => option.value}
                value={maritalStatus}
                onChange={(e) => {
                  setMaritalStatus(e);
                  setErr({
                    ...err,
                    maritalStatus: "",
                  });
                }}
                error={err.maritalStatus}
                required={required?.maritalStatus}
              />
            </div>
            {maritalStatus?.value == "other" ? (
              <div className="col-md-4">
                <FileUpload
                  className="css-atc-esc2"
                  label={"Attach proof"}
                  buttonLabel="Choose file"
                  accept={["pdf"]}
                  onChange={(e, isInvalid) =>
                    documentUploadHandler("marital_other", e, isInvalid)
                  }
                  error={err.marital_other}
                  id="marital_other"
                  files={data?.marital_other}
                  required={required?.marital_other}
                />
              </div>
            ) : (
              <></>
            )}
            {maritalStatus?.value == "divorced" ? (
              <div className="col-md-4">
                <FileUpload
                  className="css-atc-esc2"
                  label={"Attach Divorce proof"}
                  buttonLabel="Choose file"
                  accept={["pdf"]}
                  onChange={(e, isInvalid) =>
                    documentUploadHandler("divorce_certificate", e, isInvalid)
                  }
                  error={err.divorce_certificate}
                  id="divorce_certificate"
                  files={data?.divorce_certificate}
                  required={required?.divorce_certificate}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
          <TitleBar title="Parent's Details" className="css-tbr-esc2" />
          <div className="row">
            <div className="col-md-4">
              <Input
                className="css-inp-esc1"
                label="Father’s Emirates ID No"
                placeholder="Type here"
                value={data.father_emirates_id}
                field={"father_emirates_id"}
                onChange={(value, key) => onChangeHandler(key, value)}
                error={err.father_emirates_id}
                required={required?.father_emirates_id}
              />
            </div>
            <div className="col-md-4 offset-md-4">
              <FileUpload
                className="css-atc-esc2"
                label={"Upload Father’s Emirates ID Proof"}
                buttonLabel="Choose file"
                accept={["pdf"]}
                onChange={(e, isInvalid) =>
                  documentUploadHandler(
                    "father_emirates_id_proof",
                    e,
                    isInvalid
                  )
                }
                error={err.father_emirates_id_proof}
                id="father_emirates_id_proof"
                files={data?.father_emirates_id_proof}
                required={required?.father_emirates_id_proof}
              />
            </div>
            <div className="col-md-4">
              <Input
                className="css-inp-esc1"
                label="Father’s Passport No"
                placeholder="Type here"
                value={data.father_passport_no}
                field={"father_passport_no"}
                onChange={(value, key) => onChangeHandler(key, value)}
                error={err.father_passport_no}
                required={required?.father_passport_no}
              />
            </div>
            <div className="col-md-4 offset-md-4">
              <FileUpload
                className="css-atc-esc2"
                label={"Upload Father’s Passport Proof"}
                buttonLabel="Choose file"
                accept={["pdf"]}
                onChange={(e, isInvalid) =>
                  documentUploadHandler("father_passport_proof", e, isInvalid)
                }
                error={err.father_passport_proof}
                id="father_passport_proof"
                files={data?.father_passport_proof}
                required={required?.father_passport_proof}
              />
            </div>
            <div className="col-md-4">
              <Input
                className="css-inp-esc1"
                label="Mother’s Emirates ID No"
                placeholder="Type here"
                value={data.mother_emirates_id}
                field={"mother_emirates_id"}
                onChange={(value, key) => onChangeHandler(key, value)}
                error={err.mother_emirates_id}
                required={required?.mother_emirates_id}
              />
            </div>
            <div className="col-md-4 offset-md-4">
              <FileUpload
                className="css-atc-esc2"
                label={"Upload Mother’s Emirates ID Proof"}
                buttonLabel="Choose file"
                accept={["pdf"]}
                onChange={(e, isInvalid) =>
                  documentUploadHandler(
                    "mother_emirates_id_proof",
                    e,
                    isInvalid
                  )
                }
                error={err.mother_emirates_id_proof}
                id="mother_emirates_id_proof"
                files={data?.mother_emirates_id_proof}
                required={required?.mother_emirates_id_proof}
              />
            </div>
            <div className="col-md-4">
              <Input
                className="css-inp-esc1"
                label="Mother’s Passport No"
                placeholder="Type here"
                value={data.mother_passport_no}
                field={"mother_passport_no"}
                onChange={(value, key) => onChangeHandler(key, value)}
                error={err.mother_passport_no}
                required={required?.mother_passport_no}
              />
            </div>
            <div className="col-md-4 offset-md-4">
              <FileUpload
                className="css-atc-esc2"
                label={"Upload Mother’s Passport Proof"}
                buttonLabel="Choose file"
                accept={["pdf"]}
                onChange={(e, isInvalid) =>
                  documentUploadHandler("mother_passport_proof", e, isInvalid)
                }
                error={err.mother_passport_proof}
                id="mother_passport_proof"
                files={data?.mother_passport_proof}
                required={required?.mother_passport_proof}
              />
            </div>
          </div>
          {maritalStatus?.value == "married" ? (
            <>
              <TitleBar title="Spouse Details" className="css-tbr-esc2" />
              <div className="row">
                <div className="col-md-4">
                  <Input
                    className="css-inp-esc1"
                    label="Spouse Name"
                    placeholder="Type here"
                    value={data.spouse_name}
                    field={"spouse_name"}
                    onChange={(value, key) => onChangeHandler(key, value)}
                    error={err.spouse_name}
                    required={required?.spouse_name}
                  />
                </div>
                <div className="col-md-4">
                  <Input
                    className="css-inp-esc1"
                    label="Spouse Emirates ID No"
                    placeholder="Type here"
                    value={data.spouse_emirates_id}
                    field={"spouse_emirates_id"}
                    onChange={(value, key) => onChangeHandler(key, value)}
                    error={err.spouse_emirates_id}
                    required={required?.spouse_emirates_id}
                  />
                </div>
                <div className="col-md-4">
                  <FileUpload
                    className="css-atc-esc2"
                    label={"Upload Spouse Emirates ID Proof"}
                    buttonLabel="Choose file"
                    accept={["pdf"]}
                    onChange={(e, isInvalid) =>
                      documentUploadHandler(
                        "spouse_emirates_id_proof",
                        e,
                        isInvalid
                      )
                    }
                    error={err.spouse_emirates_id_proof}
                    id="spouse_emirates_id_proof"
                    files={data?.spouse_emirates_id_proof}
                    required={required?.spouse_emirates_id_proof}
                  />
                </div>
                <div className="col-md-4">
                  <Input
                    className="css-inp-esc1"
                    label="Spouse Passport No"
                    placeholder="Type here"
                    value={data.spouse_passport_no}
                    field={"spouse_passport_no"}
                    onChange={(value, key) => onChangeHandler(key, value)}
                    error={err.spouse_passport_no}
                    required={required?.spouse_passport_no}
                  />
                </div>
                <div className="col-md-4 offset-md-4">
                  <FileUpload
                    className="css-atc-esc2"
                    label={"Upload Spouse Passport Proof"}
                    buttonLabel="Choose file"
                    accept={["pdf"]}
                    onChange={(e, isInvalid) =>
                      documentUploadHandler(
                        "spouse_passport_proof",
                        e,
                        isInvalid
                      )
                    }
                    error={err.spouse_passport_proof}
                    id="spouse_passport_proof"
                    files={data?.spouse_passport_proof}
                    required={required?.spouse_passport_proof}
                  />
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          <div className="col-md-4">
            <RadioButtons
              label="?Have Children"
              buttons={[
                { label: "Yes", value: "yes" },
                { label: "No", value: "no" },
              ]}
              name="have_children"
              value={{ have_children: data.have_children }}
              error={err.have_children}
              onChange={(key, value) => onChangeHandler(key, value)}
              required={required?.have_children}
            />
          </div>
          {data.have_children == "yes" ? (
            <>
              <TitleBar title="Children Details" className="css-tbr-esc2" />
              {childData?.map((item, i) => {
                return (
                  <Fragment key={i}>
                    <div className="row" key={i}>
                      <div className="col-md-4">
                        <Input
                          className="css-inp-esc1"
                          label="Name of Child"
                          placeholder="Type here"
                          value={item.child_name}
                          field={"child_name"}
                          onChange={(value, key) =>
                            onChildDataChangeHandler(key, value, i, item?.id)
                          }
                          error={err?.["child_name" + item.id]}
                          required={required?.child_name}
                        />
                      </div>
                      <div className="col-md-4">
                        <DatePick
                          className="css-dtp-esc2"
                          title={"Date of Birth"}
                          selected={item?.child_dob}
                          onChange={(date) =>
                            onChildDataChangeHandler(
                              "child_dob",
                              date,
                              i,
                              item?.id
                            )
                          }
                          // filterTime={filterPassedTime}
                          dateFormat="dd/MM/yyyy"
                          maxDate={new Date()}
                          error={err?.["child_dob" + item.id]}
                          required={required?.child_dob}
                        />
                      </div>
                      <div className="col-md-4">
                        <RadioButtons
                          label="Select Gender"
                          buttons={[
                            { label: "Male", value: "Male" },
                            { label: "Female", value: "Female" },
                          ]}
                          name={"child_gender" + i}
                          value={{ ["child_gender" + i]: item.child_gender }}
                          onChange={(key, value) =>
                            onChildDataChangeHandler(
                              key.slice(0, -1),
                              value,
                              i,
                              item?.id
                            )
                          }
                          error={err?.["child_gender" + item.id]}
                          required={required?.child_gender}
                        />
                      </div>
                      <div className="col-md-4">
                        <Input
                          className="css-inp-esc1"
                          label="Child’s Emirates ID No"
                          placeholder="Type here"
                          value={item.child_emirates_id}
                          field={"child_emirates_id"}
                          onChange={(value, key) =>
                            onChildDataChangeHandler(key, value, i, item?.id)
                          }
                          error={err?.["child_emirates_id" + item.id]}
                          required={required?.child_emirates_id}
                        />
                      </div>
                      <div className="col-md-4 offset-md-4">
                        <FileUpload
                          className="css-atc-esc2"
                          label={"Upload Child’s Emirates ID Proof"}
                          buttonLabel="Choose file"
                          accept={["pdf"]}
                          // files={[item["child_emirates_id_proof"]?.[0]]}
                          onChange={(e, isInvalid, message) =>
                            childDataDocumentChangeHandler(
                              "child_emirates_id_proof",
                              e,
                              i,
                              isInvalid,
                              message,
                              item?.id
                            )
                          }
                          id={`child_emirates_id_proof${i}`}
                          error={err?.["child_emirates_id_proof" + item.id]}
                          files={item?.child_emirates_id_proof}
                          required={required?.child_emirates_id_proof}
                        />
                      </div>
                      <div className="col-md-4">
                        <Input
                          className="css-inp-esc1"
                          label="Child’s Passport No"
                          placeholder="Type here"
                          value={item.child_passport}
                          field={"child_passport"}
                          onChange={(value, key) =>
                            onChildDataChangeHandler(key, value, i, item?.id)
                          }
                          error={err?.["child_passport" + item.id]}
                          required={required?.child_passport}
                        />
                      </div>
                      <div className="col-md-4">
                        <FileUpload
                          className="css-atc-esc2"
                          label={"Upload Child’s Passport Proof"}
                          buttonLabel="Choose file"
                          accept={["pdf"]}
                          // files={[item["child_passport_proof"]?.[0]]}
                          onChange={(e, isInvalid, message) =>
                            childDataDocumentChangeHandler(
                              "child_passport_proof",
                              e,
                              i,
                              isInvalid,
                              message,
                              item?.id
                            )
                          }
                          id={`child_passport_proof${i}`}
                          error={err?.["child_passport_proof" + item.id]}
                          files={item?.child_passport_proof}
                          required={required?.child_passport_proof}
                        />
                      </div>
                      <div className="col-md-4 d-flex align-items-center mt-3 justify-content-center">
                        {childData.length == 1 ? (
                          <></>
                        ) : (
                          <Button
                            content="Remove"
                            className="css-btn-esc2 px-4"
                            onClick={() => onDeleteChildHandler(i)}
                          />
                        )}

                        {i === childData.length - 1 && (
                          <Button
                            content="Add Child"
                            className="css-btn-esc2 px-4"
                            onClick={() => onAddChildHandler(i)}
                          />
                        )}
                      </div>
                    </div>
                    <hr />
                  </Fragment>
                );
              })}
            </>
          ) : (
            <></>
          )}
          {err.childData && (
            <small className="text-danger">{err.childData}</small>
          )}
        </div>
      </TitleBar>
      {/* </>
        )} */}

      <TitleBar
        toggleButton
        defaultToggle={"show"}
        className="mt-3"
        title={"HR Update"}
      >
        <div className="add__onboarding_forms d-flex flex-column gap-2">
          <Checkbox
            label="?Employee Joined"
            name="Gender"
            className="form-check form-switch"
            inputClassName="form-check-input me-3"
            checked={data.employee_joined}
            onChange={(e) => onChangeHandler("employee_joined", e)}
            error={err.employee_joined}
            required={required?.employee_joined}
          />
          <Checkbox
            label="?Updated in Oracle"
            name="Gender"
            className="form-check form-switch"
            inputClassName="form-check-input me-2"
            checked={data.oracle_updated == 1}
            onChange={(e) => onChangeHandler("oracle_updated", e)}
            error={err.oracle_updated}
            required={required?.oracle_updated}
          />
        </div>
      </TitleBar>
      {history?.length ? (
        <section>
          <TitleBar
            title={"History"}
            toggleButton
            defaultToggle={"hide"}
            className="mt-4"
            boxClassName="p-0"
          >
            <Table
              className={"css-tbl-esc1"}
              titles={[
                { title: "Sl No", key: "sl_no", width: "6%" },
                {
                  title: "Received Date",
                  key: "received_date",
                  width: "12%",
                  dir: "ltr",
                },
                {
                  title: "Last Update",
                  key: "last_update",
                  width: "11%",
                  dir: "ltr",
                },
                { title: "Handled By", key: "handled_by", width: "11%" },
                { title: "Assigned To", key: "assigned_to", width: "11%" },
                { title: "Attachments", key: "attachments", width: "10%" },
                { title: "Status", key: "status", width: "12%" },
                { title: "Comments", key: "comments", width: "31%" },
              ]}
              content={historyData}
            />
          </TitleBar>
        </section>
      ) : null}
      <div className="add__comment mt-3">
        <TextArea
          className="css-txt-esc2 mb-3"
          label="Comment"
          value={data.request_comment}
          onChange={(e) => onChangeHandler("request_comment", e)}
          error={err.request_comment}
        />
        <FileUpload
          label="Add Attachments"
          className="css-atc-esc1"
          buttonLabel="Add Attachments"
          accept={[
            "jpg",
            "jpeg",
            "png",
            "pdf",
            "docx",
            "doc",
            "xlsx",
            "xls",
            "csv",
            "ppt",
            "pptx",
          ]}
          onChange={(e, isInvalid, message) =>
            fileSelectedHandler(e, isInvalid, message)
          }
          error={err.attachments}
          id="attachments"
          maxFileSizeMB={50}
          multiple={true}
          showPreview
          files={data?.attachments}
        />
      </div>

      <section className="footer-btn">
        <FooterButtons>
          <Button
            right
            className="css-btn-esc3"
            content="Back"
            icon="/assets/icons/Back.svg"
            reverse
            iconWidth="15px"
            onClick={() => navigate(-1)}
          />
          {id ? (
            <Select
              className={"css-slc-esc3"}
              left
              placeholder="Select"
              options={activeActionsList()}
              labelSetter={(e) => e.label}
              valueSetter={(e) => e.value}
              error={err.status}
              value={status}
              onChange={(e) => onStatusChangeHandler(e)}
              disabled={upload_loader}
            />
          ) : (
            <Button
              left
              className="css-btn-esc3"
              content={"Submit"}
              onClick={() => onSubmitHandler("submit")}
              disabled={upload_loader || loading}
              loader={loading}
            />
          )}

          <Button
            left
            className="css-btn-esc3"
            content={"Save"}
            onClick={() => onSubmitHandler("save")}
            disabled={upload_loader || loading}
            loader={loading}
          />

          <Button
            left
            className="css-btn-esc3"
            content="Cancel"
            onClick={onCancel}
            disabled={upload_loader}
          />
        </FooterButtons>
      </section>
    </div>
  );
}

export default AddOnboarding;
